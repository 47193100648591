import React from "react";
import { TableCell, TableSortLabel } from '@mui/material';
import { Sort } from "../../domain/Sort";

interface Props<T extends string> {
  label: string;
  fieldName: T;
  sort: Sort<T>;
  setSort: (fieldName: T, sort: Sort<T>) => void;
  className?: string;
}

const TableSortHeader = <T extends string>(props: Props<T>) => {
  const { label, fieldName, sort, setSort, className } = props;

  return (
		<TableCell align="left" className={'header ' + className} onClick={() => setSort(fieldName, sort)}>
      <TableSortLabel
        direction={sort.field === fieldName ? sort.direction : 'asc'}
        active={sort.field === fieldName}>
        {label}
      </TableSortLabel>
    </TableCell>
  );
}

export default TableSortHeader;
