/** These declarations should be kept in sync with
 *  https://github.com/Content-Technology-Partners-Ltd/wallet_api/src/modules/accounts/v2Renderer.ts */

export type CurrencyCode = 'GBP' | 'CST' | 'GBX';

export interface Amount {
    units: number;
    tenths?: number;
    hundredths?: number;
    thousandths?: number;
    millionths?: number;
    negative?: boolean;
    currency: CurrencyCode;
}

export enum AccountType {
    USER = 'USER',
    RECAST = 'RECAST',
    COUNTER = 'COUNTER',
    EXTERNAL = 'EXTERNAL',
    ADVERTISER = 'ADVERTISER',
    TAX = 'TAX',
    PUBLISHER = 'PUBLISHER',
}

export enum Type {
    MANUAL = 'MANUAL',
    REWARD = 'REWARD',
    TOPUP = 'TOPUP',
    PAYOUT = 'PAYOUT',
    SWEEP = 'SWEEP',
    AD = 'AD',
    BONUS = 'BONUS',
    GOODWILL = 'GOODWILL',
    PLAY = 'PLAY',
    PURCHASE = 'PURCHASE',
    PURCHASE_REFUND = 'PURCHASE_REFUND'
}

/** The bare-bones information which is embedded in PublicLedgerLine.
 * This also forms the base for the PublicAccountDetail object */
export interface PublicAccountSummary {
    /** Unique identifier for the account */
    id: number;
    /** The type of the account, eg USER|PUBLISHER|RECAST|TAX */
    type: AccountType;
    /** The entity linked with this account, for example the owning user or 
     * publisher */
     linkedId: string;
    /** Text description for the account purpose. This is not normally supplied
     * since purpose can be determined from the type and/or linkedId */
    description: string;
    location: string;
  }
  
  /** The detailed view of accounts which is returned in account listings
   * responses */
  export interface PublicAccountDetail extends PublicAccountSummary {
    /** Is the balance of this account allowed to go below 0 in normal use? */
    negativeBalance: boolean;
    /** Creation time for the account in epoch secs */
    created: number;
    /** The most recent line from the account history, containg the current
     * balance */
    current: PublicLineBalances;
  }
  
  /** The information which is embedded in PublicAccountLine.
   * This also forms the base for the PublicLedgerDetail object */
  export interface PublicLedger {
    id: number;
    type: Type;
    linkedId?: string;
    /** Description of the ledger. This will normally only be set for manual 
     * ledgers since for other purposes it can be inferred from the type and
     * linked id */
    description: string;
    /** Creation time for the ledger in epoch secs */
    created: string;
    /** If the ledger is intended to be backdated, the corect effective time for 
     * the ledger in epoch secs */
    effective?: string;
    /** Location of record for the transaction. Typically the location of the user
     * doing the transacting */
    location: string;
  }
    
  /** A ledger line in an absolutely minimal format to provide the current status
   * of an account */
  export interface PublicLineBalances {
    /** Line number within the account history */
    index: number; 
    /** Account balance following this line */
    balance: Amount; 
  }
    
  interface PublicLineBase extends PublicLineBalances {
    /** Amount being transferred. +ve if the account is being credited */
    amt: Amount; 
    /** String form of the line type (this is calculated from the line type and
     * is specific to the associated ledger type) */
    type: string;
    /** String description. Generally only present in manual ledgers. For display
     * purposes the ledger description should be prepended (with a full stop or 
     * dash) in order to fully describe the transaction line */
    description?: string;
  }
  
  /** A ledger line in a format suitable for listing in an API response.
   * This format should be used when listing lines for an account. 
   * PublicLedgerLine (which doesn't exist yet), should be used for listing
   * a ledger response */
  export interface PublicAccountLine extends PublicLineBase{
    ledger: PublicLedger;
  }
  
  /** A ledger line in a format suitable for listing in an API response.
   * This format should be used when listing lines for an account. 
   * PublicLedgerLine (which doesn't exist yet), should be used for listing
   * a ledger response */
  export interface PublicLedgerLine extends PublicLineBase{
    account: PublicAccountSummary;
  }
