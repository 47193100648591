import { FilterValue } from '../../../app/components/filter/FilterUseCase';
import FilterGroup from '../../../app/components/filter/FilterGroup';
import React from 'react';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import { InvitationsQuery } from '../store/InvitationsListReducer';
import { InvitationStatus } from '../../../app/domain/Invitation';
import {
    invitationSetAdminIdSearchTextAction,
    invitationSetEmailSearchTextAction,
    invitationSetFilterStatusesAction
} from '../store/InvitationsListActions';
import InvitationDateFilter from './InvitationDateFilter';

interface StateProps {
    invitationsQuery: InvitationsQuery;
}

interface DispatchProps {
  filterByStatuses: (statuses: InvitationStatus[]) => void;
  adminIdSearchText: (queryText?: string) => void;
  emailSearchText: (queryText?: string) => void;
}

type Props = StateProps & DispatchProps;

const allFilters: FilterValue[] = [
  { name: 'NEW', label: 'New' },
  { name: 'USED', label: 'Used' },
  { name: 'SENT', label: 'Sent' },
  { name: 'REVOKED', label: 'Revoked' }
];

const InvitationsFilter = (props: Props) => {
  const { invitationsQuery, filterByStatuses, adminIdSearchText, emailSearchText } = props;

  const statuses = allFilters.filter(el => invitationsQuery.statuses.includes(el.name as InvitationStatus));

  const setStatuses = (values: FilterValue[]) => {
    const statuses = values.map(el => el.name) as InvitationStatus[];
      filterByStatuses(statuses);
  };

  return (
    <div className="filter">
      <div>
        <InvitationDateFilter />
      </div>
      <div>
        <TextField
            style={{width: '350px'}}
            id="standard-basic"
            label="Admin id query"
            onChange={ event => {adminIdSearchText(event.target.value)}}
					variant="standard"
        />
      </div>
      <div>
        <TextField
            style={{width: '350px'}}
            id="standard-basic"
            label="Email id query"
            onChange={ event => {emailSearchText(event.target.value)}}
					variant="standard"
        />
      </div>
      <div>
        <span className="label">Statuses</span>
        <FilterGroup currentValues={statuses} allFilters={allFilters} setCurrentValues={setStatuses}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  invitationsQuery: state.invitations.list.filters
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  filterByStatuses: (statuses: InvitationStatus[]) => {
    dispatch(invitationSetFilterStatusesAction(statuses));
  },
  adminIdSearchText: (searchText?: string) => {
    dispatch(invitationSetAdminIdSearchTextAction(searchText));
    },
  emailSearchText: (searchText?: string) => {
    dispatch(invitationSetEmailSearchTextAction(searchText));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsFilter);
