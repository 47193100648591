import { SnackbarType } from "./reducers";

interface Redirect {
  type: 'REDIRECT';
  path: string;
}

interface OpenSnackbarMessage {
  type: 'OPEN_SNACKBAR_MESSAGE';
  message: string;
  snackbarType: SnackbarType;
}

interface CloseSnackbarMessage {
  type: 'CLOSE_SNACKBAR_MESSAGE';
}

interface OpenRemoveModal {
  type: 'OPEN_REMOVE_MODAL';
}

interface CloseRemoveModal {
  type: 'CLOSE_REMOVE_MODAL';
}

export type AppActionTypes = Redirect |
  OpenSnackbarMessage |
  CloseSnackbarMessage |
  OpenRemoveModal |
  CloseRemoveModal;

export function redirectAction(path: string): AppActionTypes {
  return {
    type: 'REDIRECT',
    path,
  };
}

export function openSnackbarMessageAction(snackbarType: SnackbarType, message: string): AppActionTypes {
  return {
    type: 'OPEN_SNACKBAR_MESSAGE',
    message,
    snackbarType,
  };
}

export function closeSnackbarMessageAction(): AppActionTypes {
  return {
    type: 'CLOSE_SNACKBAR_MESSAGE',
  };
}
