import React, { useEffect, useState } from 'react';
import { categoriesToString, Category } from 'app/domain/Category';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { useField } from 'formik';
import { useAllCategories } from 'categories/categoriesUseCase';
import { prepareCeilingCategories } from 'categories/tree/categoriesTreeManager';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface CategoriesMultiSelectProps {
	field: any;
	label?: string;
	variant?: 'standard' | 'outlined' | 'filled';
	fullWidth?: boolean;
}

const CategoriesMultiSelect = (props: CategoriesMultiSelectProps) => {
	const [field, state, { setValue, setTouched }] = useField<number[]>(props.field.name);
	const { categories } = useAllCategories();

	const [visibleCategories, setVisibleCategories] = useState<Category[]>([]);
	const categoriesIds: number[] = state?.value ?? [];

	useEffect(() => {
		setVisibleCategories(prepareCeilingCategories(categories || []));
	}, [categories]);

	const onBlur = () => setTouched(true, true);
	const onCategoriesChange: SelectInputProps<number[]>['onChange'] = event => setValue(event.target.value as number[]);

	const renderValue = () => categoriesToString(visibleCategories, categoriesIds);

	const renderCategoryMenuItem = (category: Category) => (
		<MenuItem key={`category_${category.id}`} value={category.id}>
			<Checkbox checked={categoriesIds.includes(category.id)} />
			<ListItemText primary={category.name} />
		</MenuItem>
	);

	return (
		<FormControl fullWidth={props.fullWidth} variant={props.variant}>
			<InputLabel className="categoriesMultiSelect__label">{props.label}</InputLabel>
			<Select
				name={field.name}
				value={categoriesIds}
				onChange={onCategoriesChange}
				onBlur={onBlur}
				renderValue={renderValue}
				multiple
			>
				{visibleCategories.map(renderCategoryMenuItem)}
			</Select>
		</FormControl>
	);
};

export default CategoriesMultiSelect;
