import React from 'react';
import { TextField } from '@mui/material';

interface Props {
    query: string;
    setQuery: (query?: string) => void;
}
const UnverifiedFilter = ({ query, setQuery }: Props) => {
  return (
    <div className="filter">
      <div style={{ 'marginBottom': '10px' }}>
        <TextField
            style={{width: '350px'}}
            id="standard-basic"
            label="Search email query"
            value={query}
            onChange={ event => setQuery(event.target.value)}
					variant="standard"
        />
      </div>
    </div>
  );
}

export default UnverifiedFilter;

