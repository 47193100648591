import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import { ChannelConfig } from './ChannelConfigScreen';
import DrmModal from './DrmModal';
import SectionRow from 'app/components/SectionRow';
import CustomisableConfig from './CustomisableConfig';

interface DrmSectionProps {
	isEnabled: boolean;
	saveConfiguration: (overrides: Partial<ChannelConfig>) => void;
	packageDefault: boolean;
	isDisabled: boolean;
}

const DrmToggleSection = ({ isEnabled, saveConfiguration, packageDefault, isDisabled }: DrmSectionProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const isCustomised = isEnabled !== packageDefault;

	const onSave = (value: boolean) => {
		saveConfiguration({
			drm: {
				isEnabled: value
			}
		});
	};
	return (
		<>
			<Box className={`section ${isDisabled ? 'section--disabled' : ''}`} component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">DRM</span>
					{!isDisabled && (
						<Tooltip title="Edit drm config" arrow>
							<IconButton onClick={() => setIsEditing(true)} size="large">
								<EditIcon className="editIcon" />
							</IconButton>
						</Tooltip>
					)}
				</div>
				<SectionRow
					label="Enable DRM on the channel"
					content={
						<CustomisableConfig
							value={isEnabled ? 'Yes' : 'No'}
							customisedText={isCustomised ? `(default is ${packageDefault ? 'Yes' : 'No'})` : ''}
						/>
					}
				/>
			</Box>
			<DrmModal isEnabled={isEnabled} isOpen={isEditing} onSave={onSave} onClose={() => setIsEditing(false)} />
		</>
	);
};

export default DrmToggleSection;
