import React from 'react';
import { SocialMediaTier } from 'app/domain/Tier';

interface SocialMediaTierMenuItemProps {
	socialMedia: SocialMediaTier;
}

type Props = SocialMediaTierMenuItemProps;

const SocialMediaTierMenuItem = (props: Props) => {
	const { socialMedia } = props;

	return (
		<>
			<span style={{ width: '5em', display: 'inline-block' }}>
				<strong>Id:</strong> {socialMedia.id}
			</span>
			<span style={{ width: '5em', display: 'inline-block' }}>
				<strong>Tier:</strong> {socialMedia.tier}
			</span>
			<span style={{ width: '15em', display: 'inline-block' }}>
				<strong>Lower threshold:</strong> {socialMedia.lowerThreshold ?? '-'}
			</span>
			<span style={{ width: '15em', display: 'inline-block' }}>
				<strong>Upper threshold:</strong> {socialMedia.upperThreshold ?? '-'}
			</span>
		</>
	);
};

export default SocialMediaTierMenuItem;
