import React, { useEffect } from 'react';
import { TableBody } from '@mui/material';
import { RootState } from 'app/store/store';
import { useSelector } from 'react-redux';
import { invitationFetchPageAction } from 'invitations/list/store/InvitationsListActions';
import InvitationRow from './InvitationRow';
import { useAppDispatch } from 'app/store/hooks';

const InvitationsTableBody = () => {
	const dispatch = useAppDispatch();
	const invitations = useSelector((state: RootState) => state.invitations.list.invitationPage.items);
	const isCreateModalOpen = useSelector((state: RootState) => state.invitations.actions.createModal.isOpen);

	useEffect(() => {
		!isCreateModalOpen && dispatch(invitationFetchPageAction());
	}, [isCreateModalOpen]);

	return (
		<TableBody>
			{invitations.map(invitation => (
				<InvitationRow key={invitation.id} invitation={invitation} />
			))}
		</TableBody>
	);
};

export default InvitationsTableBody;
