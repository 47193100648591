import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import { Country } from '../../../../videos/details/sections/summary/edit/country/CountryUseCase';
import { CurrencyCountriesActionTypes } from './CurrencyCountriesActionsReducer';

interface OpenEditModal {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_EDIT_MODAL;
}

interface CloseEditModal {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_EDIT_MODAL;
}

interface OpenEditCountriesModal {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_EDIT_COUNTRIES_MODAL;
    currencyCountry: CurrencyCountries;
}

interface CloseEditCountriesModal {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_EDIT_COUNTRIES_MODAL;
}

interface OpenCreateModal {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_CREATE_MODAL;
}

interface CloseCreateModal {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_CREATE_MODAL;
}

interface SetAllSelectedCode {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_ALL_SELECTED_CODE;
    codes: string[];
}

interface SetAllSupportedCountries {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_SUPPORTED_COUNTRIES;
    supportedCountries: Country[];
}

interface SetCurrencyCountries {
    type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES;
    currencyCountries: CurrencyCountries[];
}

export type CurrencyCountriesActionsStateTypes =
    SetAllSelectedCode |
    SetAllSupportedCountries |
    OpenEditCountriesModal |
    CloseEditCountriesModal |
    OpenEditModal |
    OpenCreateModal |
    CloseCreateModal |
    CloseEditModal |
    SetCurrencyCountries;

export function currencyCountriesOpenEditModalAction(): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_EDIT_MODAL
    };
}

export function currencyCountriesCloseEditModalAction(): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_EDIT_MODAL,
    };
}

export function currencyCountriesOpenEditCountriesModalAction(currencyCountry: CurrencyCountries): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_EDIT_COUNTRIES_MODAL,
        currencyCountry,
    };
}

export function currencyCountriesCloseEditCountriesModalAction(): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_EDIT_COUNTRIES_MODAL,
    };
}

export function currencyCountriesOpenCreateModalAction(): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_CREATE_MODAL,
    };
}

export function currencyCountriesCloseCreateModalAction(): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_CREATE_MODAL,
    };
}

export function currenciesSetAllSelectedCodeAction(codes: string[]): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_ALL_SELECTED_CODE,
        codes,
    };
}

export function currenciesSetSupportedCountriesAction(supportedCountries: Country[]): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_SUPPORTED_COUNTRIES,
        supportedCountries,
    };
}

export function currenciesSetCurrencyCountriesAction(currencyCountries: CurrencyCountries[]): CurrencyCountriesActionsStateTypes {
    return {
        type: CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES,
        currencyCountries,
    };
}
