import { Box, Container, Tab, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from "react";
import Title from "../../app/components/title/Title";
import UsersTableHeader from "./table/UsersTableHeader";
import UsersPagination from "./table/UsersPagination";
import UsersFilter, { UsersQuery } from "./filter/UsersFilter";
import { Page } from "app/domain/Page";
import { User } from "app/domain/User";
import { fetchUsersApi } from "./UsersUseCase";
import UserRow from "./table/UserRow";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useHistory, useLocation } from "react-router-dom";
import UnverifiedScreen from "unverified/UnverifiedScreen";
import { useDebouncedAction } from "app/utils/searchManager";
import "./_users.scss";

const tabValues = ["existing", "unverified"];

const UserScreen = () => {

	const location = useLocation();
	const history = useHistory();
	const hash = location.hash?.substring(1).split("/");
	const selectedTab = hash[0] && tabValues.includes(hash[0]) ? hash[0] : "existing";

	const handleChange = (_: React.ChangeEvent<unknown>, newValue: string) => {
		history.replace({ ...location, hash: newValue});
	};


  const [ data, setData ] = useState<Page<User>>({
		currPage: 1, //page,
		items: [],
		perPage: 20,
		totalCount: 0,
		pageCount: 0,
	});

  const [ query, setQuery ] = useState<UsersQuery>({});

  const fetch = useDebouncedAction(() => {
    fetchUsersApi(query)
    .then(s=>{ console.log("response", s); return s; })
    .then(setData);
  }, 500);

  useEffect(fetch, [query]);

  return (
    <Container className="usersScreen">
      <TabContext value={selectedTab}>
          <TabList
              centered
              className="tabs"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}>
              <Tab label="Existing Users" value="existing"/>
              <Tab label="Unverified Users" value="unverified"/>
          </TabList>
          <TabPanel value="unverified">
            <UnverifiedScreen/>
          </TabPanel>
          <TabPanel value="existing" className="verified-tab">
          <Title>Users</Title>
            <UsersFilter usersQuery={query} onChange={setQuery} />
            <Table className="table" size="small" padding="checkbox" stickyHeader>
              <UsersTableHeader />
              <TableBody>
                {
                  data.items.map((user) => (
                    <UserRow key={user.uid} user={user} onRefresh={fetch} />
                  ))
                }
              </TableBody>
            </Table>
            <Box justifyContent="center" className="paginationContainer">
              <UsersPagination page={data} changePage={page=>setQuery({ ...query, page })} />
            </Box>
          </TabPanel>
        </TabContext>
    </Container>
  );
};

export default UserScreen;
