import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { ContentProportionTier, SocialMediaTier } from 'app/domain/Tier';
import {
	createPartnerEditTierData,
	EditPartnerTier,
	editPartnerTierApi,
	fetchPartnerContentProportionTiersApi,
	fetchPartnerSocialTiersApi,
	validateTiersData
} from 'partners/actions/edit/editTier/EditPartnerTierUseCase';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import SocialMediaTierMenuItem from 'partners/actions/edit/editTier/SocialMediaTierMenuItem';
import ContentProportionTierMenuItem from 'partners/actions/edit/editTier/ContentProportionTierMenuItem';
import { Partner } from 'app/domain/Partner';

export const NO_TIER_ID = 0;

interface Props {
	isOpen: boolean;
	refresh: () => void;
	closeModal: () => void;
	partner: Partner;
}

const EditPartnerTierModal = ({closeModal, isOpen, refresh, partner}: Props) => {
	const [initialValues, setInitialValues] = useState<EditPartnerTier>(createPartnerEditTierData(partner));

	const [socialMediaTiers, setSocialMediaTiers] = useState<SocialMediaTier[]>();
	const [contentProportionTiers, setContentProportionTiers] = useState<ContentProportionTier[]>();

	const onSubmit = (values: EditPartnerTier) => {
		editPartnerTierApi(partner.uid, values).then(refresh);
		closeModal();
	};

	useEffect(() => {
		fetchPartnerSocialTiersApi().then(setSocialMediaTiers);
		fetchPartnerContentProportionTiersApi().then(setContentProportionTiers);
		setInitialValues(createPartnerEditTierData(partner));
	}, [partner.uid]);

	const renderSocialMediaTier = (socialMedia: SocialMediaTier) => (
		<MenuItem key={socialMedia.id} value={socialMedia.id}>
			<SocialMediaTierMenuItem socialMedia={socialMedia} />
		</MenuItem>
	);

	const renderContentProportionTier = (contentProportion: ContentProportionTier) => (
		<MenuItem key={contentProportion.id} value={contentProportion.id}>
			<ContentProportionTierMenuItem contentProportion={contentProportion} />
		</MenuItem>
	);

	return (
		<Dialog className="dialog" open={isOpen} onClose={closeModal} fullWidth={true} maxWidth={'lg'}>
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validateTiersData}
			>
				<Form>
					<DialogTitle>Edit partner tiers</DialogTitle>
					<DialogContent>
						<Field
							className="formField"
							label="Social media"
							select
							fullWidth
							name="socialMediaTierId"
							variant="outlined"
							component={TextField}
						>
							<MenuItem value={0}>No value</MenuItem>
							{socialMediaTiers?.map(renderSocialMediaTier)}
						</Field>
						<Field
							className="formField"
							label="Content proportion"
							select
							fullWidth
							name="contentProportionTierId"
							variant="outlined"
							component={TextField}
						>
							<MenuItem value={0}>No value</MenuItem>
							{contentProportionTiers?.map(renderContentProportionTier)}
						</Field>
					</DialogContent>
					<DialogActions>
						<Button onClick={closeModal} color="primary">
							Close
						</Button>
						<Button type="submit" color="primary" autoFocus>
							Save
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
};

export default EditPartnerTierModal;
