export interface UserEditReferrerData {
	referrerUid: string;
}

export const createEmpty = () => ({ referrerUid: '' });

export const validateUserEditReferrer = (values: UserEditReferrerData) => {
	const errors: Record<string, string> = {};

	if (!values.referrerUid) {
		errors.email = 'Referrer Uid is required';
	}

	return errors;
};
