export interface Color {
	value: string;
	position: number;
}

export interface Gradient {
	angle: number;
	colors: Color[];
}

const LINEAR_GRADIENT_REGEX = /^linear-gradient *\(([ ,#%a-zA-Z0-9]+)\)$/;
const ANGLE_REGEX = /([0-9]+)deg/;
const COLOR_REGEX = /(#[0-9A-Fa-f]{6}) ([0-9]+)%/;
export const parseToGradient = (gradientString: string): Gradient => {
	const gradientArgsString = LINEAR_GRADIENT_REGEX.exec(gradientString)?.[1] as string;
	const gradientArgs = gradientArgsString.split(',');
	const angle = +(ANGLE_REGEX.exec(gradientArgs[0])?.[1] as string);
	const colors = gradientArgs.slice(1).map<Color>(colorString => {
		const [, value, position] = COLOR_REGEX.exec(colorString) as string[];
		return { value, position: +position };
	});

	return {
		angle,
		colors
	};
};
export const stringifyGradient = (gradient: Gradient) => {
	const colorsString = gradient.colors.map(({ value, position }) => `${value} ${position}%`).join(',');
	return `linear-gradient(${gradient.angle}deg, ${colorsString})`;
};
