import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { PartnerDetails } from './EditPartnerModal';
import { organisationPartnerValidation } from './validations';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CustomTextField from '../../../app/components/inputs/CustomTextField';
import ScrollToError from '../../../app/components/inputs/ScrollToError';

const ValidationSchema = Yup.object().shape(organisationPartnerValidation);

interface Props {
	partner: PartnerDetails;
	closeModal: ()=>void;
	onSubmit: (values: PartnerDetails)=>void
}

const OrganisationPartnerForm = ({ partner, closeModal, onSubmit }: Props) => {
	return (
		<Formik
			enableReinitialize={true}
			initialValues={partner}
			validationSchema={ValidationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }): React.ReactElement => (
				<Form>
					<ScrollToError />
					<DialogTitle>Edit organisation partner</DialogTitle>
					<DialogContent>
						<DialogContentText>This action might confuse partner so do it sparingly!</DialogContentText>

						<CustomTextField label="Registered Organisation Name" name="name" />
						<CustomTextField label="Registered Organisation Number" name="companyNumber" />
						<CustomTextField label="VAT number" name="vatNumber" />
						<CustomTextField label="Communication email" name="email" type="email" />
						<CustomTextField label="Phone" name="phone" />
						<CustomTextField label="Position" name="position" />
						<CustomTextField label="Address line 1" name="addressLines" />
						<CustomTextField label="Address line 2 (optional)" name="secondAddressLine" />
						<CustomTextField label="State (optional)" name="stateCode" />
						<CustomTextField label="Postcode" name="postalCode" />
						<CustomTextField label="Country code" name="countryCode" />
					</DialogContent>
					<DialogActions className={`dialogActions`}>
						<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
							Cancel
						</Button>

						<Button
							disabled={isSubmitting}
							type="submit"
							variant="contained"
							color="secondary"
							className={`button-yes`}
						>
							Save
						</Button>
					</DialogActions>
				</Form>
			)}
		</Formik>
	);
};

export default OrganisationPartnerForm;
