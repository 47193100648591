import React, { useRef } from 'react';
import ReactCropper, { ReactCropperElement } from 'react-cropper';
import { Button } from '@mui/material';
import 'cropperjs/dist/cropper.css';

interface CropperImageProps {
	image: string;
	ratio?: number;
	isBannerImage?: boolean;
	onClose: () => void;
	onSave: (file: File) => void;
}

const CropperImage: React.FC<CropperImageProps> = props => {
	const { onSave, onClose, ratio, image, isBannerImage } = props;
	const cropper: React.Ref<ReactCropperElement> = useRef(null);
	const RESTRICT_CROP_BOX_TO_CANVAS = 3;

	const setImage = () => {
		const cropperInitializationDelay = 500;

		setTimeout(() => {
			cropper.current &&
				cropper.current.cropper.getCroppedCanvas().toBlob(blob => {
					blob && onSave(new File([blob], `image.png`, { type: blob.type }));
				});
		}, cropperInitializationDelay);
	};

	return (
		<div className="croppingModal">
			<div className={`CropperImage ${isBannerImage ? '' : 'CropperImage-profile'}`}>
				<ReactCropper
					ref={cropper}
					src={image}
					aspectRatio={ratio}
					viewMode={RESTRICT_CROP_BOX_TO_CANVAS}
					dragMode="move"
					style={{ height: 500, width: 500 }}
				/>
			</div>
			<div className="CropperButton">
				<Button onClick={onClose} color="primary">
					Close
				</Button>
				<Button type="submit" color="primary" autoFocus onClick={setImage}>
					Save
				</Button>
			</div>
		</div>
	);
};

CropperImage.defaultProps = {
	ratio: 1
};

export default CropperImage;
