import { FilterValue } from 'app/components/filter/FilterUseCase';
import FilterGroup from 'app/components/filter/FilterGroup';
import React from 'react';
import { TextField } from '@mui/material';
import { ChannelStatus } from 'app/domain/Channel';
import { Sort } from 'app/domain/Sort';

export type ChannelSortField = 'uid' | 'created' | 'name' | 'partnerName' | 'followerCount';

export interface ChannelsQuery {
	sort: Sort<ChannelSortField>
	channelNameSearchText?: string;
	partnerNameSearchText?: string;
	emailSearchText?: string;
	channelUidSearchText?: string;
	statuses: ChannelStatus[];
	page?: number;
	channelUids?: string[];
	perPage?: number;
  }
  

const allFilters: FilterValue[] = [
	{ name: 'DRAFT', label: 'Draft' },
	{ name: 'READY', label: 'Ready' },
	{ name: 'LIVE', label: 'Live' },
	{ name: 'SUSPENDED', label: 'Suspended' },
	{ name: 'DELETED', label: 'Deleted' }
];

export interface ChannelFilterProps {
	filters: ChannelsQuery;
	setFilters: (q: ChannelsQuery) => void;
}

const ChannelsFilter = ({filters, setFilters}: ChannelFilterProps) => {

	const channelNameSearchText = (name: string) => 
		setFilters({ ...filters, channelNameSearchText: name});
	const emailSearchText = (email: string) => 
		setFilters({ ...filters, emailSearchText: email});
	const partnerNameSearchText = (name: string) => 
		setFilters({ ...filters, partnerNameSearchText: name});
	const channelUidSearchText = (uid: string) => 
		setFilters({ ...filters, channelUidSearchText: uid});

	const roles = allFilters.filter(el => filters.statuses.includes(el.name as ChannelStatus));

	const setStatuses = (values: FilterValue[]) => {
		const statuses = values.map(el => el.name) as ChannelStatus[];
		setFilters({ ...filters, statuses});
	};

	return (
		<div className="filter">
			<div className="row">
				<div className="col-6">
					<TextField
						style={{ width: '350px' }}
						id="standard-basic"
						label="Channel name query"
						onChange={event => {
							channelNameSearchText(event.target.value);
						}}
						variant="standard"
					/>
				</div>
				<div className="col-6">
					<TextField
						style={{ width: '350px' }}
						id="standard-basic"
						label="Publisher name query"
						onChange={event => {
							partnerNameSearchText(event.target.value);
						}}
						variant="standard"
					/>
				</div>
			</div>
			<div className="row mb-2">
				<div className="col-6">
					<TextField
						style={{ width: '350px' }}
						id="standard-basic"
						label="Email query"
						onChange={event => {
							emailSearchText(event.target.value);
						}}
						variant="standard"
					/>
				</div>
				<div className="col-6">
					<TextField
						style={{ width: '350px' }}
						id="standard-basic"
						label="Channel uid query"
						onChange={ event => channelUidSearchText(event.target.value) }
						variant="standard"
					/>
				</div>
			</div>
			<div>
				<span className="label">Roles</span>
				<FilterGroup currentValues={roles} allFilters={allFilters} setCurrentValues={setStatuses} />
			</div>
		</div>
	);
};

export default ChannelsFilter;
