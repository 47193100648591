import React from 'react';
import { connect } from 'react-redux';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import Pagination from '../../../app/components/pagination/Pagination';
import { Page } from '../../../app/domain/Page';
import { Invitation } from '../../../app/domain/Invitation';
import { invitationSetCurrentPageAction } from '../store/InvitationsListActions';

interface InvitationsPaginationProps {
  page: Page<Invitation>;
  changePage: (page: number) => void;
}

const InvitationsPagination = (props: InvitationsPaginationProps) => {
  return <Pagination { ...props } />;
};

const mapStateToProps = (state: RootState) => ({
  page: state.invitations.list.invitationPage,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  changePage: (page: number) => {
    dispatch(invitationSetCurrentPageAction(page));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsPagination);
