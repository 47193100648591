import React from "react";
import { TableCell, TableHead, TableRow } from '@mui/material';

const UsersTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" className="header">Uid</TableCell>
        <TableCell align="left" className="header">Email</TableCell>
        <TableCell align="left" className="header">Status</TableCell>
        <TableCell align="left" className="header">Roles</TableCell>
        <TableCell align="left" className="header">Referrer</TableCell>
        <TableCell align="left" className="header">Sign up date</TableCell>
        <TableCell align="left" className="header">Actions</TableCell>
      </TableRow>
    </TableHead>
  )
};

export default UsersTableHeader;

