import dayjs from 'dayjs';
import { useLocalQuery } from 'app/utils/searchManager';
import {
	addTotals,
	ChannelPassDayData,
	fetchChannelPassDayData,
	fetchPublisherEarnings,
	fetchTariffPurchasesByTypeAndSource,
	fetchVideoDayData,
	Filter,
	PublisherDayData,
	TariffPurchasesData,
	VideoDayData
} from 'app/stats/statsManager';

export interface ChannelDailyStats {
	videoEarnings: number;
	channelPassEarnings: number;
	shareEarnings: number;
	referralEarnings: number;
	channelPassPurchases: number;
	videoPurchases: number;
	date: string;
}

export interface ChannelStats {
	totalEarnings: number;
	videoTotalEarnings: number;
	channelPassTotalEarnings: number;
	shareTotalEarnings: number;
	referralTotalEarnings: number;
	totalPurchases: number;
	videoTotalPurchases: number;
	channelPassTotalPurchases: number;
	channelDailyStats: ChannelDailyStats[];
	videoDailyStats: VideoDayData[];
	isLoading: boolean;
	purchasesBySourceAndTypeData: TariffPurchasesData[];
}

const aggregateChannelDailyStats = ({
	videoDayData,
	channelPassDayData,
	publisherData
}: {
	videoDayData?: VideoDayData[];
	channelPassDayData?: ChannelPassDayData[];
	publisherData?: PublisherDayData[];
}): ChannelDailyStats[] => {
	const emptyDayEarnings: ChannelDailyStats = {
		date: '',
		channelPassEarnings: 0,
		referralEarnings: 0,
		shareEarnings: 0,
		videoEarnings: 0,
		channelPassPurchases: 0,
		videoPurchases: 0
	};
	const dayToData: Record<string, ChannelDailyStats> = {};
	const aggregateDaily = <T extends { date: string }>(
		data: T[],
		aggregate: (stats: ChannelDailyStats, data: T) => ChannelDailyStats
	): void => {
		data.forEach(singleDayData => {
			const base = dayToData[singleDayData.date] || { ...emptyDayEarnings, date: singleDayData.date };
			dayToData[singleDayData.date] = aggregate(base, singleDayData);
		});
	};

	const sortStatsAscByDate = (a: any, b: any) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1);

	aggregateDaily(videoDayData || [], (base, singleDayData) => ({
		...base,
		videoEarnings: singleDayData.directEarnings,
		videoPurchases: singleDayData.purchases
	}));

	aggregateDaily(channelPassDayData || [], (base, singleDayData) => ({
		...base,
		channelPassEarnings: singleDayData.directEarnings,
		channelPassPurchases: singleDayData.purchases
	}));

	aggregateDaily(publisherData || [], (base, singleDayData) => ({
		...base,
		shareEarnings: singleDayData.shareGbp || 0,
		referralEarnings: singleDayData.referralGbp || 0
	}));

	const unsorted = Object.values(dayToData);
	return unsorted.sort(sortStatsAscByDate);
};

export const useFetchChannelStats = (filter: Filter): ChannelStats => {
	const [{ data: videoDayData, directEarnings: videoTotalEarnings, purchases: videoTotalPurchases }] = useLocalQuery(
		() => fetchVideoDayData(filter).then(d => addTotals(d, 'directEarnings', 'purchases')),
		[filter],
		{}
	);

	const [
		{ data: channelPassDayData, directEarnings: channelPassTotalEarnings, purchases: channelPassTotalPurchases }
	] = useLocalQuery(
		() => fetchChannelPassDayData(filter).then(d => addTotals(d, 'directEarnings', 'purchases')),
		[filter],
		{}
	);

	const [{ data: publisherData, shareGbp: shareTotalEarnings, referralGbp: referralTotalEarnings }] = useLocalQuery(
		() => fetchPublisherEarnings(filter).then(d => addTotals(d, 'shareGbp', 'referralGbp')),
		[filter],
		{}
	);

	const [purchasesBySourceAndTypeData] = useLocalQuery(() => fetchTariffPurchasesByTypeAndSource(filter), [filter]);

	const channelDailyStats = aggregateChannelDailyStats({ videoDayData, channelPassDayData, publisherData });
	const totalEarnings = shareTotalEarnings + referralTotalEarnings + videoTotalEarnings + channelPassTotalEarnings;
	const totalPurchases = videoTotalPurchases + channelPassTotalPurchases;
	const isLoading = !publisherData || !videoDayData || !channelPassDayData || !purchasesBySourceAndTypeData;

	return {
		totalEarnings,
		videoTotalEarnings,
		channelPassTotalEarnings,
		shareTotalEarnings,
		referralTotalEarnings,
		totalPurchases,
		videoTotalPurchases,
		channelPassTotalPurchases,
		channelDailyStats,
		videoDailyStats: videoDayData,
		purchasesBySourceAndTypeData,
		isLoading
	};
};
