import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip
} from '@mui/material';
import Title from '../../../../app/components/title/Title';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { copyToClipboard } from 'app/utils/copyManager';
import { doFetchStreamKeysApi } from './api/fetchStreamKeysApi';
import { StreamKeyStaff } from 'app/domain/StreamKey';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'config/appConfig';
import { Box } from '@mui/material';
import SectionRow from 'app/components/SectionRow';
import RoutePaths from '../../../../app/navigation/RoutePaths';
import './_streamKeys.scss';

interface Props {
	channelUid: string
}

const StreamKeysScreen = (props: Props) => {
	const { channelUid } = props;
	const history = useHistory();
	const [data, setData] = useState<StreamKeyStaff[]>([]);
	const streamUrl = getConfig().RTMP_BASE_URL;

	const fetchStreamKeysData = () => {
		doFetchStreamKeysApi(channelUid).then(responseBody => setData(responseBody.expanded));
	};
	const navigateToVideoDetails = (uid: string) => {
		history.push(`${RoutePaths.VideoDetails}/${uid}`);
	};

	useEffect(() => fetchStreamKeysData(), [channelUid]);
	
	return (
        <Container className="tagsScreen">
				<Grid item xs={11}>
					<Title>Stream Keys</Title>
					<Box className="section" component="div">
						<SectionRow label="RTMP" content={streamUrl} />
					</Box>
				</Grid>
			<Table className="stream-keys-table" size="small" padding="checkbox" stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell className="id-cell header" align="left">
							ID
						</TableCell>
						<TableCell className="stream-key-name-cell header" align="left">
							Name
						</TableCell>
						<TableCell className="key-cell header" align="left">
							Stream Key
						</TableCell>
						<TableCell className="videos-cell header" align="left">
							Videos attached
						</TableCell>
						<TableCell className="copy-rtmp-cell header" align="left">
							Copy Full URL
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(streamKey => (
						<TableRow key={streamKey.id}>
							<TableCell className="id-cell">{streamKey.id}</TableCell>
							<TableCell className="stream-key-name-cell">{streamKey.name}</TableCell>
							<TableCell className="key-cell">{streamKey.key}</TableCell>
							<TableCell className="videos-cell">
								{streamKey.videos.map(video => (
									<Tooltip key={video.id} title="Go to stream" arrow>
										<span onClick={() => navigateToVideoDetails(video.id)}>
											{video.id}<br/>
										</span>
									</Tooltip>
								))}
							</TableCell>
							<TableCell className="copy-rtmp-cell">
								<IconButton
                                    onClick={() => copyToClipboard(streamUrl + '/' + streamKey.key)}
                                    size="large">
                                	<FileCopyIcon/>
                            	</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Container>
    );
	
};

export default StreamKeysScreen;