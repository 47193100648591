import React from 'react';
import Box from '@mui/material/Box';
import { Colors } from 'Theme';

const CodeBox = ({ codeContent }: { codeContent: string }) => {
	return (
		<Box
			component="pre"
			sx={{
				whiteSpace: 'pre-wrap',
				wordBreak: 'break-all',
				border: `1px solid ${Colors.Border}`,
				borderRadius: '8px',
				padding: '24px',
				margin: '24px 0px 24px 0px',
				backgroundColor: Colors.CodeBoxBackground,
				color: Colors.White,
				letterSpacing: '0px',
				textAlign: 'left',
				fontFamily: 'Poppins',
				fontSize: '12px',
				lineHeight: '20px',
				fontWeight: '400'
			}}
		>
			<span>{codeContent}</span>
		</Box>
	);
};

export default CodeBox;
