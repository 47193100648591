
export interface Sort<T extends string> {
  field: T;
  direction: SortDirection;
}

export type SortDirection = 'asc' | 'desc';

export function createDefaultSort<T extends string>(defaultField: T, direction?: SortDirection): Sort<T> {
  return {
    field: defaultField,
    direction: direction ? direction : 'asc',
  };
}

export function changeSort<T extends string>(field: T, sort: Sort<T>): Sort<T> {
  if (sort.field === field) {
    return {
      field,
      direction: sort.direction === 'asc' ? 'desc' : 'asc',
    };
  } else {
    return {
      field,
      direction: 'asc',
    };
  }
}
