import { DELETE, GET, POST, PUT } from 'app/http/mediaApi/mediaClient';
import { VideoFeature } from 'app/domain/VideoFeature';
import { PurchaseVideo } from 'app/domain/PurchaseVideo';
import { PageResponse, toPage } from 'app/http/PageResponse';

export interface VideoFeatureRequestBody {
	uid: string;
	priority: number;
	startDate: string | null;
	endDate: string | null;
}

export const fetchVideoFeaturedListApi = (videoId: string) =>
	GET<VideoFeature[]>(`/admin/video/${videoId}/featured`).then(r => r.data.data);

export const addVideoFeatureApi = (input: VideoFeatureRequestBody) =>
	POST<VideoFeature>(`/admin/video/${input.uid}/featured`, input).then(r => r.data.data);

export const editVideoFeatureApi = (featureId: string, input: VideoFeatureRequestBody) =>
	PUT<VideoFeature>(`/admin/video/${input.uid}/featured/${featureId}`, input).then(r => r.data.data);

export const removeVideoFeatureApi = (featureId: string, videoUid: string) =>
	DELETE<VideoFeature>(`/admin/video/${videoUid}/featured/${featureId}`).then(r => r.data.data);

export const fetchPurchaseVideoListApi = (uid: string, page: number) =>
	GET<PageResponse<PurchaseVideo>>(`/admin/video/${uid}/purchases`, { params: { page } }).then(r =>
		toPage(r.data.data)
	);
