import { TableBody } from '@mui/material';
import React, { useEffect } from 'react';
import CurrencyCountriesRow from './CurrencyCountriesRow';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import { connect } from 'react-redux';
import { fetchCurrencyCountriesUseCase } from '../../../CurrencyUseCase';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';

interface StateProps {
    currencyCountries: CurrencyCountries[];
}

interface DispatchProps {
    fetchCurrencyCountries: () => Promise<void>;
}

type Props = StateProps & DispatchProps;

const CurrencyCountriesTableBody = (props: Props) => {
    const { currencyCountries, fetchCurrencyCountries } = props;

    useEffect(() => {
        fetchCurrencyCountries().then();
    }, [fetchCurrencyCountries]);

    return (
        <TableBody>
            {
                currencyCountries.map((currency) => (
                    <CurrencyCountriesRow key={currency.currencyCode} currency={currency} />
                ))
            }
        </TableBody>
    )
};

const mapStateToProps = (state: RootState) => ({
    currencyCountries: state.currency.currencyCountries.list.currencyCountries,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    fetchCurrencyCountries: async () => {
        dispatch(fetchCurrencyCountriesUseCase());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyCountriesTableBody);
