import React, { SyntheticEvent, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ChannelStatus } from 'app/domain/Channel';
import { LiveTv } from '@mui/icons-material';
import PublishVideosModal from 'channels/actions/publishVideos/PublishVideosModal';
import { fetchSharedDataCsv, setChannelStatusApi } from 'channels/details/section/summary/ChannelDetailsSummaryUseCase';
import ConfirmModal from 'channels/ConfirmModal';
import { useLocalQuery } from 'app/utils/searchManager';
import { doFetchChannelConfigApi } from 'channels/details/section/admins/api/fetchPartnerApi';

interface Props {
	uid: string;
	status: ChannelStatus;
	refresh: () => void;
}

const ChannelActionButtons = ({ uid, status, refresh }: Props) => {
	const [showConfirm, setShowConfirm] = useState<{ action?: string; message: string; onConfirm: () => void }>();
	const [showPublish, setShowPublish] = useState(false);
	const [config, refreshConfig] = useLocalQuery(() => doFetchChannelConfigApi(uid), [uid]);

	const onSuspendChannel = (e: SyntheticEvent) => {
		e.stopPropagation();
		setShowConfirm({
			message: 'Do you really want to suspend channel?',
			action: 'Suspend',
			onConfirm: () =>
				setChannelStatusApi(uid, 'SUSPENDED').then(() => {
					refresh();
					refreshConfig();
				})
		});
	};

	const onUnsuspendChannel = (e: SyntheticEvent) => {
		e.stopPropagation();
		setShowConfirm({
			message: 'Do you really want to unsuspend channel?',
			action: 'Unsuspend',
			onConfirm: () =>
				setChannelStatusApi(uid, 'LIVE').then(() => {
					refresh();
					refreshConfig();
				})
		});
	};

	const onLiveChannel = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const onRemoveChannel = (e: SyntheticEvent) => {
		e.stopPropagation();
		setShowConfirm({
			message: "This will permanently delete the item (it's not reversible!).",
			onConfirm: () =>
				setChannelStatusApi(uid, 'DELETED').then(() => {
					refresh();
					refreshConfig();
				})
		});
	};

	const onDownloadSharedFile = async (e: SyntheticEvent) => {
		e.stopPropagation();
		const csv = await fetchSharedDataCsv(uid);
		const blob = new Blob([csv], { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = 'data_share.csv';
		link.href = url;
		link.click();
	};

	const isSharedDataEnabled = config?.dataProfile.isEnabled;

	return (
		<div className="actions">
			{'SUSPENDED' === status ? (
				<Tooltip title="Unsuspend" arrow>
					<IconButton className="button button-green" onClick={onUnsuspendChannel} size="large">
						<LockOpenIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Suspend" arrow>
					<IconButton className="button button-orange" onClick={onSuspendChannel} size="large">
						<LockIcon />
					</IconButton>
				</Tooltip>
			)}
			{'READY' === status && (
				<Tooltip title="Live" arrow>
					<IconButton className="button button-green" onClick={onLiveChannel} size="large">
						<LiveTv />
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title="Delete" arrow>
				<IconButton className="button button-red" onClick={onRemoveChannel} size="large">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
			{isSharedDataEnabled && (
				<Tooltip title="Download share data" arrow>
					<IconButton className="button button-blue" onClick={onDownloadSharedFile} size="large">
						<CloudDownloadIcon />
					</IconButton>
				</Tooltip>
			)}
			{showConfirm && (
				<ConfirmModal isOpen={true} closeModal={() => setShowConfirm(undefined)} {...showConfirm} />
			)}
			<PublishVideosModal isOpen={showPublish} closeModal={() => setShowPublish(false)} uid={uid} videos={[]} />
		</div>
	);
};

export default ChannelActionButtons;
