import React from 'react';
import {AppThunkDispatch, RootState} from '../../../app/store/store';
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material';
import {userCloseAwardCastModalAction,} from "../store/UsersActionsActions";
import {
    AwardCastData,
    awardCastUserUseCase,
    createInitialAwardCasData,
    validateAwardCastData
} from "./AwardCastUseCase";
import {Field, Form, Formik} from "formik";
import {User} from "../../../app/domain/User";
import {TextField} from "formik-material-ui";

interface StateProps {
    isOpen: boolean;
    user: User;
}

interface DispatchProps {
    awardCastUser: (uid: string, awardCast: AwardCastData) => void;
    closeModal: () => void;
}

type Props = StateProps & DispatchProps;


const AwardCastModal = (props: Props) => {
    const {isOpen, user, awardCastUser, closeModal} = props;
    const initialValues = createInitialAwardCasData();

    const saveChanges = (awardCastData: AwardCastData) => {
        awardCastUser(user.uid, awardCastData)
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            maxWidth="lg">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={validateAwardCastData}
                onSubmit={(values: AwardCastData) => {
                    saveChanges(values);
                }}>
                <Form>
                    <DialogTitle>Award Cast</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This user will be awarded cast: <strong>{user.fullName}</strong>
                        </DialogContentText>
                        <br/>
                        <Divider className="divider"/>
                        <br/>
                        <DialogContentText>
                            <strong> Amount of Cast to Add to Wallet</strong>
                        </DialogContentText>
                        <div>
                            <Field className="field"
                                   fullWidth
                                   label="Amount of CAST"
                                   name="costUnits"
                                   variant="outlined"
                                   type="number"
                                   component={TextField}
                            />
                        </div>
                        <br/>
                        <DialogContentText>
                            <strong> Message (this should describe the reason for giving extra CAST)</strong>
                        </DialogContentText>
                        <div>
                            <Field className="field"
                                   fullWidth
                                   label="Reason for award - 5-40 characters"
                                   name="reason"
                                   variant="outlined"
                                   type="string"
                                   component={TextField}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal} color="primary">
                            Close
                        </Button>
                        <Button type="submit" color="primary" autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};


const mapStateToProps = (state: RootState) => ({
    isOpen: state.users.actions.awardCastModal.isOpen,
    user: state.users.actions.awardCastModal.user,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    awardCastUser: (uid: string, awardCast: AwardCastData) => {
        dispatch(awardCastUserUseCase(uid, awardCast));
    },
    closeModal: () => {
        dispatch(userCloseAwardCastModalAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AwardCastModal);
;
