import { AppThunk, AppThunkDispatch, RootState } from '../../../app/store/store';
import { Page } from '../../../app/domain/Page';
import { Invitation, InvitationSortField, InvitationStatus } from '../../../app/domain/Invitation';
import { fetchInvitationsPageUseCase } from '../InvitationsUseCase';
import { Sort } from '../../../app/domain/Sort';
import dayjs from 'dayjs';
import { dateCubeFormat } from '../../../app/store/common/Constants';

interface SetInvitationsPage {
  type: 'INVITATION_SET_INVITATIONS_PAGE';
  invitationPage: Page<Invitation>;
}

interface SetCurrentPage {
  type: 'INVITATION_SET_CURRENT_PAGE';
  page: number;
}

interface ClearPagination {
  type: 'INVITATION_CLEAR_PAGINATION'
}

interface SetAdminIdSearchText {
  type: 'INVITATION_SET_ADMIN_ID_SEARCH_TEXT';
  adminIdSearchText?: string;
}

interface SetEmailSearchText {
  type: 'INVITATION_SET_EMAIL_SEARCH_TEXT';
  emailSearchText?: string;
}

interface SetFilterStatuses {
  type: 'INVITATION_SET_FILTER_STATUSES';
  statuses: InvitationStatus[];
}

interface SetSort {
  type: 'INVITATION_SET_SORT';
  sort: Sort<InvitationSortField>;
}

interface SetStartDate {
  type: 'INVITATION_SET_START_DATE';
  startDate: Date;
}

interface SetEndDate {
  type: 'INVITATION_SET_END_DATE';
  endDate: Date;
}

export type InvitationsListStateTypes =
  SetInvitationsPage |
  SetCurrentPage |
  ClearPagination |
  SetAdminIdSearchText |
  SetEmailSearchText |
  SetSort |
  SetStartDate |
  SetEndDate |
  SetFilterStatuses;

export function invitationsSetPageAction(invitationPage: Page<Invitation>): InvitationsListStateTypes {
  return {
    type: 'INVITATION_SET_INVITATIONS_PAGE',
    invitationPage
  };
}

export function invitationSetCurrentPageAction(page: number): AppThunk {
  return (dispatch: AppThunkDispatch) => {
    dispatch({ type: 'INVITATION_SET_CURRENT_PAGE', page });
    dispatch(invitationFetchPageAction());
  }
}

export function invitationClearPaginationAction(): InvitationsListStateTypes {
  return {
    type: 'INVITATION_CLEAR_PAGINATION'
  };
}

export function invitationSetAdminIdSearchTextAction(adminIdSearchText?: string): AppThunk {
  return changeQueryParamsAction({ type: 'INVITATION_SET_ADMIN_ID_SEARCH_TEXT', adminIdSearchText });
}

export function invitationSetEmailSearchTextAction(emailSearchText?: string): AppThunk {
  return changeQueryParamsAction({ type: 'INVITATION_SET_EMAIL_SEARCH_TEXT', emailSearchText });
}

export function invitationSetFilterStatusesAction(statuses: InvitationStatus[]): AppThunk {
  return changeQueryParamsAction({ type: 'INVITATION_SET_FILTER_STATUSES', statuses });
}

export function invitationSetStartDate(startDate: Date): AppThunk {
  return changeQueryParamsAction({ type: 'INVITATION_SET_START_DATE', startDate });
}

export function invitationSetEndDate(endDate: Date): AppThunk {
  return changeQueryParamsAction({ type: 'INVITATION_SET_END_DATE', endDate });
}

export function invitationSetSortAction(sort: Sort<InvitationSortField>): AppThunk {
  return changeQueryParamsAction({ type: 'INVITATION_SET_SORT', sort });
}

function changeQueryParamsAction(action: InvitationsListStateTypes): AppThunk {
  return (dispatch: AppThunkDispatch) => {
    dispatch(invitationClearPaginationAction());
    dispatch(action);
    dispatch(invitationFetchPageAction());
  };
}

export function invitationFetchPageAction(): AppThunk {
  return (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const { invitationPage, filters } = getState().invitations.list;
    dispatch(fetchInvitationsPageUseCase({
      page: invitationPage.currPage,
      statuses: filters.statuses,
      adminIdSearchText: filters.adminIdSearchText,
      emailSearchText: filters.emailSearchText,
      sort: filters.sort.direction,
      order: filters.sort.field,
      startDate: dayjs(filters.startDate).format(dateCubeFormat),
      endDate: dayjs(filters.endDate).format(dateCubeFormat),
    }));
  };
}
