import { userCloseManualCastModalAction } from 'users/actions/store/UsersActionsActions';
import { Amount } from 'accounts/apiTypes';
import { setManualCastApi } from 'app/http/financeApi/financeApi';
import { AppDispatch } from 'app/store/store';

interface Lines {
	userAccountId: string;
	amount: Amount;
}

export interface ManualCast {
	adminId: string;
	type: string;
	description: string;
	lines: Lines | Lines[]
}

export enum ManualTypes {
	GOODWILL = 'goodwill',
	RETENTION = 'manual-retention',
	UNUSED = 'manual-unused'
}

const manualCastUseCase = async (data: ManualCast, dispatch: AppDispatch) => {
	const res = await setManualCastApi(data);

	if (res.error || !res.value) {
		return res;
	}

	dispatch(userCloseManualCastModalAction());
	return res;
};

export default manualCastUseCase;
