import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Pagination from "app/components/pagination/Pagination";
import { VideoSortField } from "app/domain/Video";
import TableSortHeader from "app/components/table/TableSortHeader";
import VideoRow from "./VideoRow";
import { doFetchVideoList, FetchVideoData } from "../fetchVideosApi";
import { createDefaultSort } from "app/domain/Sort";
import { useDebouncedAction, useLocalQuery } from "app/utils/searchManager";
import "./_videoList.scss";

interface Props {
  changeCriteria: (criteria: FetchVideoData) => void;
  hidePublisher?: boolean;
  criteria: FetchVideoData;
}

const VideoTable = (props: Props) => {
  const { changeCriteria, hidePublisher, criteria } = props;

  const [ data, doRefresh ] = 
    useLocalQuery(() => doFetchVideoList(criteria), [ criteria ]);
  const refresh = useDebouncedAction(doRefresh);

  const changePage = (page: number) => 
    changeCriteria({ ...criteria, page });

  const changeSort = (fieldname: VideoSortField) => 
    changeCriteria({ ...criteria, sort: criteria.sort==='asc' ? 'desc' : 'asc', order: fieldname });

  const sort = 
    createDefaultSort<VideoSortField>(criteria.order || 'created', criteria.sort);


	return !data ? (
		<>Loading...</>
	) : <>
      <Table className="table" size="small" padding="checkbox" stickyHeader>
        <TableHead>
        <TableRow>
          <TableCell align="left" className="image-cell header">Image</TableCell>
          <TableCell className='uid-cell header'>Id</TableCell>
          <TableSortHeader label="Title" className="name-cell" fieldName="name" sort={sort} setSort={changeSort} />
          {!hidePublisher && <TableCell align="left" className="publisher-cell header">Channel</TableCell>}
          <TableCell align="left" className="cost-cell header">Price</TableCell>
          <TableCell align="left" className="sales-cell header">Sales</TableCell>
          <TableCell align="left" className="spend-cell header">Spend</TableCell>
          <TableCell align="left" className="live-cell header">Live</TableCell>
          <TableCell align="left" className="published-cell header">Publ­ished</TableCell>
          <TableCell align="left" className="featured-cell header">Feat­ured</TableCell>
          <TableSortHeader label="Crea­ted" className="created-cell" fieldName="created" sort={sort} setSort={changeSort} />
        <TableCell align="left" className="actions-cell header">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.items.map(video => (
          <VideoRow key={video.uid} video={video} refreshList={refresh} hidePublisher={hidePublisher} />
        ))}
      </TableBody>
      </Table>
      <Box justifyContent="center" className="paginationContainer">
        <Pagination page={data} changePage={changePage} />
      </Box>
      <small style={{display: "block", textAlign: "left"}}>*sales and spend figures are updated daily</small>
    </>;
};

export default VideoTable;
