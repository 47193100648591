import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

const EditCurrencyCountriesTableHeader = () => {

  return (
      <TableHead>
          <TableRow>
            <TableCell align="left" className="header">Code</TableCell>
            <TableCell style={{ width: '14em' }} align="left" className="header">Countries</TableCell>
            <TableCell align="left" className="header">Actions</TableCell>
        </TableRow>
      </TableHead>
  );
};

export default EditCurrencyCountriesTableHeader;
