import React, { useState } from 'react';
import { Video } from '../../../../app/domain/Video';
import { Box, Button, Divider } from '@mui/material';
import PurchaseVideoTable from './table/PurchaseVideoTable';
import { renderCSTStat, renderGBPStat } from 'app/stats/StatsDisplay';
import { useQuery } from 'react-query';
import { fetchVideoPurchasesBySourceData } from 'app/stats/statsManager';
import { useDispatch } from 'react-redux';
import { openRefundProductConfirmationModalAction } from '../../../../refunds/store/refundsActions';
import RefundConfirmationModal from '../../../../refunds/action/RefundConfirmationModal';
import SectionRow from '../../../../app/components/SectionRow';
import { getBulkProductRefundDetails } from '../../../../accounts/AccountDetailsUseCase';
import FailedLedgersModal from '../../../../refunds/action/FailedLedgersModal';
import { useLocalQuery } from '../../../../app/utils/searchManager';
import { AdminApiPage } from '../../../../app/domain/AdminApiPage';
import { PurchaseVideo } from '../../../../app/domain/PurchaseVideo';
import { getVideoPurchasesData } from './table/PurchaseVideoTableUseCase';
import AdminApiPagination from '../../../../app/components/pagination/AdminApiPagination';
import { isLoggedAsFinanceAdmin } from 'app/domain/User';

const VideoStatsSection = (props: { video: Video }) => {
	const { video } = props;
	const [isPurchaseRefunded, setIsPurchaseRefunded] = useState(false);
	const [url, setUrl] = useState<string>();

	const dispatch = useDispatch();

	const [refunds] = useLocalQuery(() => getBulkProductRefundDetails(video.uid), [video.uid, isPurchaseRefunded]);
	const [videoData] = useLocalQuery<AdminApiPage<PurchaseVideo>>(
		() => getVideoPurchasesData(video.uid, url),
		[video.uid, url, isPurchaseRefunded]
	);

	const { data: purchasesBySource } = useQuery(
		`video-${video.uid}-purchases-by-source`,
		() => fetchVideoPurchasesBySourceData(video.uid),
		{ retry: false, refetchOnWindowFocus: false }
	);

	const onRefundProductTransaction = async () => {
		dispatch(openRefundProductConfirmationModalAction(video.uid, video.name));
	};

	const refreshPurchasesRefundStatus = async () => {
		setIsPurchaseRefunded(true);
	};
	const changePage = (url: string) => setUrl(url);

	const isRefundAllowed = () => videoData?.items.some(purchase => !purchase.revoked);
	const refundsReason = refunds?.map(refund => refund.bulkReason).join(', ');

	return (
		<Box className="section" component="div">
			<div className="sectionHeader">
				<span className="sectionTitle">Stats</span>
			</div>
			<div className="statsSection">
				<div className="purchasesSection">
					<div className="sectionRow">
						<strong>Total purchases: </strong>
						{video.stats?.purchases}
					</div>
					{purchasesBySource?.map(({ purchases, source }) => (
						<div key={source} className="sectionRow">
							<strong>Purchases on {source}: </strong>
							{purchases}
						</div>
					))}
				</div>
				<div className="refundsSection">
					<SectionRow label="Refunds" content={refunds?.length} />
					<SectionRow label="Bulk Refund Reason" content={refundsReason} />
					{isLoggedAsFinanceAdmin() && (
						<Button
							variant="outlined"
							className="text-capitalize"
							style={{ fontWeight: 'bold', fontSize: '15px' }}
							onClick={onRefundProductTransaction}
							disabled={!isRefundAllowed()}
						>
							Refund All Purchases
						</Button>
					)}
				</div>
			</div>
			<Divider />
			<div className="sectionRow">
				<strong>Views: </strong>
				{video.stats?.views} <small>(includes autoplays)</small>
			</div>
			<div className="sectionRow">
				<strong>Viewers Paid: </strong>
				{renderCSTStat(video.stats?.paid)}
			</div>
			<div className="sectionRow">
				<strong>Publisher Earnings: </strong>
				{renderGBPStat(video.stats?.directEarnings)}
			</div>
			<div className="sectionRow">
				<strong>Recaster Earnings: </strong>
				{renderCSTStat(video.stats?.fanRecasterEarnings)} &nbsp;&nbsp;
				{renderGBPStat(video.stats?.publisherRecasterEarnings)}
			</div>
			<Divider />
			<div>
				<PurchaseVideoTable data={videoData} />
				<Box justifyContent="center" className="paginationContainer">
					{videoData && (
						<AdminApiPagination items={videoData.items} paging={videoData.paging} changePage={changePage} />
					)}
				</Box>
			</div>
			<RefundConfirmationModal onRefresh={refreshPurchasesRefundStatus} />
			<FailedLedgersModal />
		</Box>
	);
};

export default VideoStatsSection;
