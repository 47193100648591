import React from 'react';
import { Container, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import CashOutsModal from 'cashouts/action/CashOutsModal';
import { openUploadModal } from 'cashouts/store/cashOutsActions';

const CashOutsScreen = () => {
	const dispatch = useDispatch();

	const onCsvUpload = () => {
		dispatch(openUploadModal());
	};

	return (
		<>
			<Container className="sportsScreen">
				<Button variant="contained" onClick={onCsvUpload}>
					Manual Cash Out
				</Button>
			</Container>
			<CashOutsModal/>
		</>
	);
};

export default CashOutsScreen;
