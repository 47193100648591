import React, { SyntheticEvent } from "react";
import { IconButton, Tooltip } from '@mui/material';
import { isSuspended, VideoStatus } from 'app/domain/Video';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import { videoOpenRemoveModalAction, videoOpenSuspendModalAction, videoOpenUnsuspendModalAction } from "./store/VideoActionsActions";
import RemoveVideoModal from "./remove/RemoveVideoModal";
import SuspendVideoModal from "./suspend/SuspendVideoModal";
import UnsuspendVideoModal from "./unsuspend/UnsuspendVideoModal";

interface VideoActionButtonsProps {
  name: string,
  videoUid: string,
  channelUid: string
  status: VideoStatus;
  onRefresh: () => void;
}

type Props = VideoActionButtonsProps;

const VideoActionButtons = (props: Props) => {
  const { videoUid, status, onRefresh } = props;
  const dispatch = useDispatch();

  const onSuspendVideo = (e: SyntheticEvent) => {
    dispatch(videoOpenSuspendModalAction(videoUid, onRefresh));
    e.stopPropagation();
  };

  const onUnsuspendVideo = (e: SyntheticEvent) => {
    dispatch(videoOpenUnsuspendModalAction(videoUid, onRefresh));
    e.stopPropagation();
  };

  const onRemoveVideo = (e: SyntheticEvent) => {
    dispatch(videoOpenRemoveModalAction(videoUid, onRefresh));
    e.stopPropagation();
  };

  return (
    <div className="actions">
      {
        isSuspended(status) ?
          (<Tooltip title="Unsuspend" arrow>
					<IconButton className="button button-green" onClick={e => onUnsuspendVideo(e)} size="large">
              <LockOpenIcon/>
            </IconButton>
          </Tooltip>)
          :
          (<Tooltip title="Suspend" arrow>
					<IconButton className="button button-orange" onClick={e => onSuspendVideo(e)} size="large">
              <LockIcon/>
            </IconButton>
          </Tooltip>)
      }
      <Tooltip title="Remove" arrow>
				<IconButton className="button button-red" onClick={e => onRemoveVideo(e)} size="large">
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const VideoActionModals = () => {
  return (
    <>
      <RemoveVideoModal />
      <SuspendVideoModal />
      <UnsuspendVideoModal />
    </>
  );
};

export default VideoActionButtons;
