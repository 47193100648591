import React from "react";
import { User } from "../../../../../../app/domain/User";
import {
  UserEditContactData,
  validateUserEditContact
} from "./UserEditContactUseCase";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

interface Props {
  user: User;
  save: (email: string) => void;
  onClose: () => void;
}

const UserEditContactModal = (props: Props) => {
  const { onClose, user, save } = props;
  const initialValues = { email: user.email };

  return (
    <Dialog
      className="editUserDialog"
      open={true}
      onClose={onClose}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateUserEditContact}
        onSubmit={(values: UserEditContactData) => {
          user && save(values.email);
        }}>
        <Form>
          <DialogTitle>Edit contact</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Remember changing email will stop the user from logging in!
            </DialogContentText>
            <Field className="formField" label="New email" fullWidth name="email" variant="outlined" component={TextField} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UserEditContactModal;
