import flagsmith from 'flagsmith';
import { decodeJwtToken } from 'app/http/authApi/token';

export const FEATURE_FLAGS = {
	ENABLE_PURCHASE_REFUNDS: 'enable_purchase_refunds',
	MY_DATA_WALLET_2: 'my_data_wallet_2',
	ENABLE_CHANNEL_CONFIG_MINIMUM_PRICING: 'partner_web_min_pricing',
	ENABLE_MAX_TRANSCODE_RESOLUTION_OPTION: 'enable_max_transcode_resolution_option',
	ENABLE_CHANNEL_CONFIG_DATA_DOWNLOAD: 'enable_channel_config_data_download',
	ENABLE_COMMENTS_ROLE: 'enable_comments_role',
	ENABLE_CHANNEL_CONFIG_ANON_DATA: 'enable_channel_config_anonymised_data',
	ENABLE_CHANNEL_CONFIG_TIER_SELECTION: 'enable_channel_config_tier_selection'
} as const;

export type FeatureFlagName = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];

export const getFeatureFlagValue = (featureFlagName: FeatureFlagName): boolean => {
	return flagsmith.hasFeature(featureFlagName);
};

export const getFeatureFlagValues = (featureFlagName: FeatureFlagName) => {
	return flagsmith.getValue(featureFlagName);
};

export const identifyUserForFlags = async (token: string) => {
	const decodedToken = decodeJwtToken(token);

	if (!decodedToken) return;

	const uid = decodedToken.uid;

	return await flagsmith.identify(uid, {
		isAdmin: !!decodedToken.isAdmin,
		isContributor: !!decodedToken.isContributor,
		isPartnerStaff: !!decodedToken.isPartnerStaff,
		isPublisher: !!decodedToken.isPublisher
	});
};

export const stopIdentifyingUserForFlags = async () => {
	return await flagsmith.logout();
};
