import React, { useState } from 'react';
import { Button, Container, Paper, TextField as MuiTextField } from '@mui/material';
import { GUID } from 'app/domain/Guid';
import Title from 'app/components/title/Title';

const PurchasesScreen = () => {

    const [guid, setGuid] = useState<GUID>();
    const [uid, setUid] = useState<string>('');
    const [uuid, setUuid] = useState('');

    const onConvertFromUid = () => {
        const guid = GUID.createFromUid(uid);
        setGuid(guid);
    };

    const onConvertFromUuid = () => {
        const guid = GUID.createFromUuid(uuid);
        setGuid(guid);
    };

    return (
        <Container className="userDetailsScreen">
            <Title>From Uid</Title>
            <Paper className="text-left p-2">
                <div>
                <MuiTextField
                    className="convertField"
                    label="uid"
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
						variant="standard"
                />
                </div>
                <div>
                    <label>
                        <b>UUID:</b> {guid?.toUuid()}
                    </label>
                    <br />
                    <label>
                        <b>CROCKFORD BASE 32:</b> {guid?.toCrockfordBase32()}
                    </label>
                </div>

                <Button variant="contained" className="text-right" onClick={onConvertFromUid}>
                    Convert
                </Button>
            </Paper>
            <Title>From Uuid</Title>
            <Paper className="text-left p-2">
                <div>
                <MuiTextField
                    className="convertField"
                    label="uuid"
                    value={uuid}
                    onChange={(e) => setUuid(e.target.value)}
						variant="standard"

                />
                </div>
                <div>
                    <label>
                        <b>UID:</b> {guid?.toLegacyUid()}
                    </label>
                    <br />
                    <label>
                        <b>CROCKFORD BASE 32:</b> {guid?.toCrockfordBase32()}
                    </label>
                </div>

                <Button variant="contained" onClick={onConvertFromUuid}>
                    Convert
                </Button>
            </Paper>
        </Container>
    );
};

export default PurchasesScreen;
