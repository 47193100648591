import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { VideoFeature } from 'app/domain/VideoFeature';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Video } from 'app/domain/Video';
import { addVideoFeatureApi, editVideoFeatureApi, fetchVideoFeaturedListApi, removeVideoFeatureApi } from './VideoFeatureApi';
import VideoFeatureFormModal from './VideoFeatureFormModal';
import { createVideoFeatureRequestBody } from './VideoFeatureUseCase';
import RemoveModal from 'app/components/modal/remove/RemoveModal';
import { useLocalQuery } from 'app/utils/searchManager';

interface Props { 
	video: Video, 
	canBeFeatured: boolean
}

const VideoDetailsSummaryFeatureSection = ({ video, canBeFeatured }: Props) => {
	const { videoId } = useParams<{videoId: string}>();
	const [ isCreating, setIsCreating ] = useState(false);
	const [ isEditing, setIsEditing ] = useState<VideoFeature>();
	const [ isRemoving, setIsRemoving ] = useState<VideoFeature>();


	const [ featuredList, refresh ] = useLocalQuery(()=>fetchVideoFeaturedListApi(videoId), [videoId]);

	return (
		<Box className="section" component="div">
			<div className="sectionHeader">
				<span className="sectionTitle">Feature</span>
			</div>

			<div className="sectionRow">
				<Table className="table">
					<TableHead>
						<TableRow>
							<TableCell className="header">Priority</TableCell>
							<TableCell className="header">Start Date</TableCell>
							<TableCell className="header">End Date</TableCell>
							<TableCell className="header">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{featuredList?.map(videoFeature => (
							<TableRow key={videoFeature.featureId}>
								<TableCell>{videoFeature.priority}</TableCell>
								<TableCell>{videoFeature.startDate || '-'}</TableCell>
								<TableCell>{videoFeature.endDate || '-'}</TableCell>
								<TableCell>
									<div className="actions">
										<Tooltip title="Update feature" arrow>
											<IconButton
												className="button button-blue"
												onClick={() => setIsEditing(videoFeature)}
												disabled={!canBeFeatured}
												size="large"
											>
												<EditIcon />
											</IconButton>
										</Tooltip>

										<Tooltip title="Remove feature" arrow>
											<IconButton
												className="button button-red"
												onClick={() => setIsRemoving(videoFeature)}
												size="large"
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>

			<div className="sectionButtons">
				<Button variant="outlined" color="primary" onClick={()=>setIsCreating(true)} disabled={!canBeFeatured}>
					Create feature
				</Button>
			</div>

			<VideoFeatureFormModal
				title={'Create feature'}
				saveButtonText={'Create'}
				isOpen={isCreating}
				initialValues={{ uid: video.uid, startDate: '', endDate: '', interestId: 0, priority: 50 }}
				closeModal={()=>setIsCreating(false)}
				submitFeature={data=>{
					addVideoFeatureApi(createVideoFeatureRequestBody(data)).then(refresh)
					setIsCreating(false);
				}}
			/>

			{ isEditing && <VideoFeatureFormModal
				title={'Update feature'}
				saveButtonText={'Save'}
				isOpen={!!isEditing}
				initialValues={{...isEditing, startDate: isEditing.startDate || "", endDate: isEditing.endDate || ""}}
				closeModal={()=>setIsEditing(undefined)}
				submitFeature={data=>{
					editVideoFeatureApi(isEditing.featureId, data).then(refresh)
					setIsEditing(undefined);
				}}
			/>}

			<RemoveModal 
				isOpen={!!isRemoving} 
				closeModal={()=>setIsRemoving(undefined)} 
				onRemove={()=>{
					isRemoving && removeVideoFeatureApi(isRemoving?.featureId, videoId).then(refresh);
					setIsRemoving(undefined);
				}} />
		</Box>
	);
};

export default VideoDetailsSummaryFeatureSection;
