import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface RemoveModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onRemove: () => void;
  text?: string
}

const RemoveModal = (props: RemoveModalProps) => {
  const { isOpen, closeModal, onRemove, text } = props;

  const handleClose = () => {
    closeModal();
  };

  const handleRemove = () => {
    onRemove();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text ? text : 'This will permanently delete the item (it\'s not reversible!).'}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={`dialogActions`}>
        <Button variant="contained" onClick={handleClose} className={`button-cancel`}>
          Close
        </Button>
        <Button variant="contained" onClick={handleRemove} color="secondary" className={`button-yes`}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default RemoveModal;
