import React from 'react';
import { ChannelConfig, ResolutionDetails } from './ChannelConfigScreen';
import { Box } from '@mui/material';
import ResolutionSection from './ResolutionSection';
import { DeepPartial } from 'redux';

interface PackageResolutions {
	720: ResolutionDetails;
	1080: ResolutionDetails & { isEnabled: boolean };
}

interface MaxResolutionSectionProps {
	resolutions: PackageResolutions;
	packageDefault: PackageResolutions;
	isDisabled: boolean;
	saveConfiguration: (overrides: DeepPartial<ChannelConfig>) => void;
}

const MaxResolutionSection = (props: MaxResolutionSectionProps) => {
	const { resolutions, packageDefault, isDisabled, saveConfiguration } = props;
	const isUsingCustomisedMaxResolution = packageDefault[1080].isEnabled !== resolutions[1080].isEnabled;

	return (
		<Box className={`section ${isDisabled ? 'section--disabled' : ''}`} component="div">
			<div className="sectionHeader">
				<span className="sectionTitle">Max Resolution</span>
				{isUsingCustomisedMaxResolution && (
					<span className="customisedConfigText">{`(default is ${
						packageDefault[1080].isEnabled ? '1080p' : '720p'
					})`}</span>
				)}
			</div>
			<ResolutionSection
				resolution={resolutions[720]}
				defaultResolution={packageDefault[720]}
				resolutionHeight={720}
				saveConfiguration={saveConfiguration}
				isDisabled={isDisabled}
			/>
			<ResolutionSection
				resolution={resolutions[1080]}
				defaultResolution={packageDefault[1080]}
				resolutionHeight={1080}
				saveConfiguration={saveConfiguration}
				isDisabled={isDisabled}
			/>
		</Box>
	);
};

export default MaxResolutionSection;
