import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createCurrencyCountriesUseCase, getCurrencyCountriesApi } from './CreateCurrencyCountriesUseCase';
import { Field, Form, Formik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { TextField } from 'formik-material-ui';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import { currencyCountriesCloseCreateModalAction } from '../store/CurrencyCountriesActions';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';

interface StateProps {
    isOpen: boolean;
    currencyCountries: CurrencyCountries[];
}

interface DispatchProps {
    saveCurrencyCountries: (currencyCountries: CurrencyCountries) => void;
    closeModal: () => void;
}

type Props = StateProps & DispatchProps;

interface FormValues {
    currencyCode: string;
}

const CreateCurrencyCountriesModal = (props: Props) => {
    const { closeModal, isOpen, saveCurrencyCountries, currencyCountries } = props;
    const initialValues: FormValues = createFromValues();

    const [currencyCodes, setCurrencyCodes] = useState<string[]>( [] );


    useEffect( () => {
        getCurrencyCountriesApi().then( currency => {
            const codes = currencyCountries.map( value => value.currencyCode )
            setCurrencyCodes( currency.filter(current => !codes.includes(current.currencyCode)).map(value => value.currencyCode) );
        } );
    }, [currencyCountries] );

    const onSubmit = async (values: FormValues) => {
        await saveCurrencyCountries( { currencyCode: values.currencyCode, countryCodes: [] } as CurrencyCountries );
    };

    return (
        <Dialog
            className="dialog"
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={closeModal}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                <Form>
                    <DialogTitle>Create new currency countries</DialogTitle>
                    <DialogContent>
                        <Field className="formField" label="Currency code" select fullWidth name="currencyCode" variant="outlined" component={TextField}>
                            {
                                currencyCodes.map((code) => <MenuItem key={code} value={code}>{code}</MenuItem>)
                            }
                        </Field>
                    </DialogContent>
                    <DialogActions className={`dialogActions`}>
						<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
                            Close
                        </Button>
                        <Button variant="contained" type="submit" color="secondary" className={`button-yes`}>
                            Save
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ( {
    isOpen: state.currency.currencyCountries.actions.createModal.isOpen,
    currencyCountries: state.currency.currencyCountries.actions.currencyCountries,
} );

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    saveCurrencyCountries: async (currencyCountries: CurrencyCountries) => {
        dispatch( createCurrencyCountriesUseCase( currencyCountries ) );
    },
    closeModal: () => {
        dispatch( currencyCountriesCloseCreateModalAction() );
    },
} );

function createFromValues(): FormValues {
    return {
        currencyCode: '',
    };
}


export default connect( mapStateToProps, mapDispatchToProps )( CreateCurrencyCountriesModal );
