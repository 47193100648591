import {
	CubeFilter,
	CubeQuery,
	CubeResponse,
	JoinCubeQuery,
	JoinCubeResponse,
	PublisherCube,
	publisherCubeQuery,
	VideoCube,
	videoCubeQuery,
	VideoInfoCube,
	WHTariffStatsCube,
	whTariffStatsCubeQuery,
	WHTariffTypeCube,
	whTariffTypeStatsCubeQuery,
	WHVideoStatsCube
} from 'app/http/cubeApi/cube';
import { sum } from 'lodash';
import { Dayjs } from 'dayjs';
import { asCubeDateRange } from 'report/reportFilter/DateFilterConfig';
import { TariffType } from "app/domain/Tariff";

export interface VideoBreakdown {
	uid: string;
	name: string;
	directEarnings: number;
	purchases: number;
	paid: number;
	uploadDate: string;
}

export interface Filter {
	checkedTitles: string[];
	dates: [ Dayjs, Dayjs ];
	channelUid?: string;
}

export interface ChannelPassBreakdown {
	id: string;
	purchases: number;
}

export interface PublisherChannelPassBreakdown extends ChannelPassBreakdown {
	name: string;
	rentalPeriod: number;
	price: number;
	netEarnings: number;
	status: string;
	totalRedemptions: number;
	averageRedemptions: number;
}

export interface VideoDayData {
	date: string;
	directEarnings: number;
	purchases: number;
}

export type ChannelPassDayData = VideoDayData;

export interface PublisherDayData {
	date: string;
	referralGbp: number;
	shareGbp: number;
	royaltiesGbp: number;
}

export interface TariffPurchasesData {
	source: string;
	purchases: number;
	type: TariffType;
}

/** Get a list of all the channel's videos which had purchases in the selected time period */
export const fetchVideosPurchaseData = async (uids: string[]) => {
	const query: JoinCubeQuery<WHVideoStatsCube, VideoInfoCube> = {
		measures: [
			'WHVideoStats.total_fans_paid',
			'WHVideoStats.purchase_count',
			'WHVideoStats.total_owner_earned',
			'WHVideoStats.total_fan_referrals_earned',
			'WHVideoStats.total_publisher_referrals_earned',
			'WHVideoStats.total_collaborators_earned'
		],
		filters: [{ member: 'WHVideoStats.video_uid', operator: 'equals', values: uids }],
		dimensions: ['WHVideoStats.video_uid']
	};

	return videoCubeQuery(query)
		.then(r => r.data)
		.then(r => r.map(rowToVideoPurchaseData));
};

const rowToVideoPurchaseData = (v: CubeResponse<WHVideoStatsCube>['data'][number]) => ({
	uid: v['WHVideoStats.video_uid'],
	directEarnings: gbpMillToGbp(v['WHVideoStats.total_owner_earned']),
	purchases: parseInt(v['WHVideoStats.purchase_count']),
	views: parseInt(v['WHVideoStats.purchase_count']),
	paid: cstThouToCst(v['WHVideoStats.total_fans_paid']),
	fanRecasterEarnings: cstThouToCst(v['WHVideoStats.total_fan_referrals_earned']),
	publisherRecasterEarnings: gbpMillToGbp(v['WHVideoStats.total_publisher_referrals_earned'])
});


export const addTotals = <T>(data: T[], ...keys: (keyof T)[]) => {
	type Out = { [Key in typeof keys[number]]: number } & { data: T[] };
	return { data, ...Object.fromEntries(keys.map(k => [k, !data ? 0 : sum(data.map(d => d[k] || 0))])) } as Out;
};

const videoChannelFilter = (channelUid: string) =>
	({
		member: 'WHVideo.user_uid',
		operator: 'equals',
		values: [channelUid]
	} as CubeFilter<VideoCube>);

const publisherChannelFilter = (channelUid: string) =>
	({
		member: 'WHPublisherEarnings.uid',
		operator: 'equals',
		values: [channelUid]
	} as CubeFilter<PublisherCube>);

/** Get the total number of videos which exist for this publisher in the cube
 * (might not exactly match the video table) */
export const fetchVideosCount = async (channelUid?: string) => {
	return !channelUid
		? undefined
		: await videoCubeQuery({
			measures: ['WHVideoInfo.count'],
			filters: [videoChannelFilter(channelUid)]
		}).then(r => {
			const count = r.data.pop()?.['WHVideoInfo.count'];
			return count == null ? undefined : parseInt(count);
		});
};

/** Get a list of all the channel's videos which had purchases in the selected time period */
export const fetchVideosTitleData = async (filter: Filter) => {
	const rows = await videoCubeQuery({
		dimensions: ['WHVideoInfo.name', 'WHVideo.video_uid'],
		timeDimensions: [{ dimension: 'WHVideo.date', dateRange: asCubeDateRange(filter.dates) }],
		filters: filter.channelUid ? [videoChannelFilter(filter.channelUid)] : [],
		measures: ['WHVideo.purchases']
	}).then(r => r.data);

	return rows.map(v => ({
		uid: v['WHVideo.video_uid'],
		name: v['WHVideoInfo.name']
	}));
};

const videoAggregateQuery = (filter: Filter) => {
	const uids = filter.checkedTitles;
	const query = {
		measures: ['WHVideo.owner_earned_gbpmill', 'WHVideo.purchases', 'WHVideo.users_paid_cst'],
		timeDimensions: [{ dimension: 'WHVideo.date', dateRange: asCubeDateRange(filter.dates) }],
		filters: filter.channelUid ? [videoChannelFilter(filter.channelUid)] : []
	} as JoinCubeQuery<VideoCube, VideoInfoCube>;
	if (uids.length) {
		query.filters?.push({ member: 'WHVideo.video_uid', operator: 'equals', values: uids });
	}
	return query;
};

/** Get a daily breakdown of video revenue and purchases for the selected period and videos */
export const fetchVideoDayData = async (filters: Filter) => {
	const query = videoAggregateQuery(filters);
	query.timeDimensions && (query.timeDimensions[0].granularity = 'day');

	return videoCubeQuery(query)
		.then(r => r.data)
		.then(r =>
			r.map(
				v =>
					({
						date: v['WHVideo.date'].substring(0, 10), //date portion only
						directEarnings: gbpMillToGbp(v['WHVideo.owner_earned_gbpmill']),
						purchases: parseInt(v['WHVideo.purchases'])
					} as VideoDayData)
			)
		);
};

export const fetchChannelPassDayData = async (filters: Filter): Promise<ChannelPassDayData[]> => {
	if (!filters.channelUid) {
		return [];
	}

	const query: CubeQuery<WHTariffTypeCube> = {
		measures: ['WHTariffType.owner_earned_gbpmill', 'WHTariffType.purchases'],
		timeDimensions: [{ dimension: 'WHTariffType.date', dateRange: asCubeDateRange(filters.dates) }],
		filters: [
			{
				member: 'WHTariffType.channel_uid',
				operator: 'equals',
				values: [filters.channelUid]
			},
			{
				member: 'WHTariffType.type',
				operator: 'equals',
				values: ['CHANNEL_PASS']
			}
		]
	};
	query.timeDimensions && (query.timeDimensions[0].granularity = 'day');

	return whTariffTypeStatsCubeQuery(query)
		.then(r => r.data)
		.then(r =>
			r.map(v => ({
				date: v['WHTariffType.date']?.substring(0, 10), //date portion only
				directEarnings: gbpMillToGbp(v['WHTariffType.owner_earned_gbpmill']),
				purchases: parseInt(v['WHTariffType.purchases'])
			}))
		);
};

const rowToVideoBreakdown = (v: JoinCubeResponse<VideoCube, VideoInfoCube>['data'][number]) =>
	({
		uid: v['WHVideo.video_uid'],
		name: v['WHVideoInfo.name'],
		directEarnings: gbpMillToGbp(v['WHVideo.owner_earned_gbpmill']),
		purchases: parseInt(v['WHVideo.purchases']),
		uploadDate: v['WHVideoInfo.created'],
		paid: parseInt(v['WHVideo.users_paid_cst'])
	} as VideoBreakdown);

/** Get a per-video breakdown of video revenue and purchases for the selected period and videos */
export const fetchVideoBreakdownData = async (filters: Filter) => {
	const query = videoAggregateQuery(filters);
	query.dimensions = ['WHVideo.video_uid', 'WHVideoInfo.name', 'WHVideoInfo.created'];

	return videoCubeQuery(query)
		.then(r => r.data)
		.then(r => r.map(rowToVideoBreakdown));
};


const rowToPublisherDayData = (v: CubeResponse<PublisherCube>['data'][number]) =>
	({
		date: v['WHPublisherEarnings.date'].substring(0, 10), //Date only
		referralGbp:
			gbpMillToGbp(v['WHPublisherEarnings.referral_gbpmill']) +
			gbpMillToGbp(v['WHPublisherEarnings.spent200_gbpmill']),
		shareGbp: gbpMillToGbp(v['WHPublisherEarnings.share_earnings_gbpmill']),
		royaltiesGbp: gbpMillToGbp(v['WHPublisherEarnings.royalties_gbpmill'])
	} as PublisherDayData);

/** Get a daily breakdown of non-video revenue for the selected period */
export const fetchPublisherEarnings = async (filter: Filter) => {
	const query = {
		measures: [
			'WHPublisherEarnings.referral_gbpmill',
			'WHPublisherEarnings.share_earnings_gbpmill',
			'WHPublisherEarnings.spent200_gbpmill',
			'WHPublisherEarnings.royalties_gbpmill'
		],
		timeDimensions: [
			{
				dimension: 'WHPublisherEarnings.date',
				dateRange: asCubeDateRange(filter.dates),
				granularity: 'day'
			}
		],
		filters: filter.channelUid ? [publisherChannelFilter(filter.channelUid)] : []
	} as CubeQuery<PublisherCube>;

	return publisherCubeQuery(query)
		.then(r => r.data)
		.then(r => r.map(rowToPublisherDayData));
};

const gbpMillToGbp = (unit: string): number => {
	return parseInt(unit) / 1000000;
};
const cstThouToCst = (unit: string): number => {
	return parseInt(unit) / 1000;
};


export const fetchChannelPassesBreakdownData = async (filter: Filter) => {
    const passIds = filter.checkedTitles?? [];
	const channelUid = filter.channelUid?? "";
    const query = {
        measures: ['WHTariffStats.purchase_count'],
        timeDimensions: [{
            dimension: 'WHTariffStats.date',
            dateRange: [
                filter.dates[0].format('YYYY-MM-DD'),
                filter.dates[1].format('YYYY-MM-DD') 
            ]
        }],
        dimensions: ['WHTariffStats.tariff_id'],
        filters: [
            {
                member: 'WHTariffStats.channel_uid',
                operator: 'equals',
                values: [channelUid]
            },
            {
                member: 'WHTariffStats.tariff_id',
                operator: 'equals',
                values: passIds
            }
        ]
    } satisfies CubeQuery<WHTariffStatsCube>;

    return whTariffStatsCubeQuery(query)
        .then(r => r.data)
        .then(r => r.map(rowToChannelPassBreakdown));
};

const rowToChannelPassBreakdown = (v: CubeResponse<WHTariffStatsCube>['data'][number]) =>
	({
		id: v['WHTariffStats.tariff_id'],
		purchases: parseInt(v['WHTariffStats.purchase_count']) || 0
	} as ChannelPassBreakdown);

export const fetchVideoPurchasesBySourceData = async (uid: string) => {
	const query: JoinCubeQuery<WHVideoStatsCube, VideoInfoCube> = {
		measures: ['WHVideoStats.purchase_count'],
		filters: [{ member: 'WHVideoStats.video_uid', operator: 'equals', values: [uid] }],
		dimensions: ['WHVideoStats.source'],
		limit: 10
	};

	const { data } = await videoCubeQuery(query);

	return data.map(v => ({
		source: v['WHVideoStats.source'],
		purchases: v['WHVideoStats.purchase_count']
	}));
};

export const fetchTariffPurchasesByTypeAndSource = async (filter: Filter): Promise<TariffPurchasesData[]> => {
	const query: CubeQuery<WHTariffTypeCube> = {
		measures: ['WHTariffType.purchases'],
		filters: [
			{ member: 'WHTariffType.channel_uid', operator: 'equals', values: filter.channelUid ? [filter.channelUid] : [] },
			{ member: 'WHTariffType.date', operator: 'inDateRange', values: asCubeDateRange(filter.dates) }
		],
		dimensions: ['WHTariffType.source', 'WHTariffType.type']
	};

	const { data } = await videoCubeQuery(query);

	return data
		.filter(row => row['WHTariffType.purchases'])
		.map(row => ({
			purchases: +row['WHTariffType.purchases'],
			source: row['WHTariffType.source'],
			type: row['WHTariffType.type'] as TariffType
		}))
		.sort((a, b) => b.purchases - a.purchases);
};
