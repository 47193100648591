import { BuyDrmStrategy } from './drms/BuyDrmStrategy';
import videojs from 'video.js';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { Video } from 'app/domain/Video';

export interface SourceConfig extends videojs.Tech.SourceObject {
	keySystems: any;
}
export enum DrmType {
	Widevine = 'Widevine',
	PlayReady = 'PlayReady',
	FairPlay = 'FairPlay'
}

export enum DrmProviderName {
	BuyDrm = 'buydrm',
	None = ''
}

export interface DrmInfo {
	enabled: boolean;
	provider: DrmProviderName;
	buydrm_certificate: string;
	buydrm_proxy: string;
}

export interface CdnDrmInfo {
	provider: DrmProviderName;
	certificate: string;
	licenseUrl: string;
}

interface CdnUrlWithDrm {
	url: string;
	drm?: DrmInfo;
}

export interface DrmStrategy {
	getDrmConfig: (streamUrl: string) => Promise<SourceConfig>;
}

export const isSafariBrowser = (): boolean => isSafari || isMobileSafari;

export const getRandomCdn = (video: Video | null | undefined): CdnUrlWithDrm => {
	const cdns = video?.cdns;

	if (cdns?.length) {
		const index = getRandomNumber(cdns.length);
		const endpoints = cdns[index].endpoints;
		const drm = cdnDrmInfoToDrmInfo(cdns[index].drm);
		return drm.enabled && !isSafariBrowser() ? { url: endpoints.dash, drm } : { url: endpoints.hls, drm };
	}

	// TODO: remove this fallback when fullUrl & fullUrlDash get phased out
	return { url: video?.fullUrl || '' };
};

export const cdnDrmInfoToDrmInfo = (drm?: CdnDrmInfo): DrmInfo =>
	drm
		? {
				enabled: true,
				buydrm_certificate: drm.certificate,
				buydrm_proxy: drm.licenseUrl,
				provider: drm.provider
		  }
		: {
				enabled: false,
				buydrm_certificate: '',
				buydrm_proxy: '',
				provider: DrmProviderName.None
		  };

export const getRandomNumber = (max: number): number => {
	return Math.floor(Math.random() * max);
};

const getDrmProvider = (drmProviderInfo: DrmInfo): DrmStrategy | null => {
	switch (drmProviderInfo.provider) {
		case DrmProviderName.BuyDrm:
			return new BuyDrmStrategy(drmProviderInfo);
		default:
			return null;
	}
};

export const getPlayerConfig = (streamUrl: string, drmProviderInfo: DrmInfo): Promise<SourceConfig> => {
	const drmProvider = getDrmProvider(drmProviderInfo);

	if (drmProvider) {
		return drmProvider.getDrmConfig(streamUrl);
	}

	return Promise.reject();
};
