import React from "react";
import { existsFilter, FilterValue, toggleFilter } from "./FilterUseCase";
import { Checkbox, FormControlLabel } from '@mui/material';

interface FilterItemProps {
  values: FilterValue[];
  value: FilterValue;
  setValues: (value: FilterValue[]) => void;
}

const FilterItem = (props: FilterItemProps) => {
  const { values, value, setValues } = props;

  const handleToggle = (filter: FilterValue) => {
    const result = toggleFilter(values, filter);
    setValues(result);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          name={value.name}
          checked={existsFilter(values, value)}
					onChange={() => handleToggle(value)}
				/>
      }
      label={value.label} />
  )
};

export default FilterItem;
