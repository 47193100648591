import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const defaultFormat = 'YYYY-MM-DD HH:mm:ss';
const MS_IN_SECOND = 1000;
const ONE_DAY_IN_SECONDS = 86400;

dayjs.extend(utc);

function fromText(input: string, format = defaultFormat): dayjs.Dayjs {
	return dayjs.utc(input, format);
}

function toText(input: dayjs.Dayjs): string {
	return input.format(defaultFormat);
}

function nowToText(): string {
	return now().format(defaultFormat);
}

function now(): dayjs.Dayjs {
	return dayjs.utc().millisecond(0);
}

function durationInSeconds(from: dayjs.Dayjs, to: dayjs.Dayjs): number {
	return from.diff(to, 'ms') / MS_IN_SECOND;
}

function getDurationInDays(rentalPeriod?: number): string {
	if (!rentalPeriod) {
		return '-';
	}
	const days = rentalPeriod / ONE_DAY_IN_SECONDS;
	return `${days} day${days > 1 ? 's' : ''}`;
}

export default {
	fromText,
	toText,
	now,
	getDurationInDays,
	nowToText,
	durationInSeconds
};
