import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { Currency } from '../../../../app/domain/Currency';
import EditIcon from '@mui/icons-material/Edit';
import { Remove } from '@mui/icons-material';

interface StateProps {
    current: Currency;
}

interface DispatchProps {
    revoke: (currencyCode: string) => void;
    edit: (current: Currency) => void;
}

type Props = StateProps & DispatchProps;

const EditCurrentRowModal = (props: Props) => {
    const { current, revoke, edit } = props;

    const onRevoke = () => {
        revoke(current.currencyCode);
    };

    const onEdit = () => {
        edit(current);
    };

    return (
        <TableRow className={"row"}>
            <TableCell>{current.currencyCode}</TableCell>
            <TableCell>{current.currencyName}</TableCell>
            <TableCell>{current.rate}</TableCell>
            <TableCell>{current.currencySymbol}</TableCell>
            <TableCell>{current.currencySymbolDisplayPosition}</TableCell>
            <TableCell>
                <Tooltip title="Edit" arrow>
					<IconButton className="button button-orange" onClick={onEdit} size="large">
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Revoke" arrow>
					<IconButton className="button button-red" onClick={onRevoke} size="large">
                        <Remove/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default EditCurrentRowModal;
