import { userCloseAwardCastModalAction } from 'users/actions/store/UsersActionsActions';
import { AppThunk, AppThunkDispatch } from 'app/store/store';
import { makeRequest } from 'app/http/request';
import { POST } from 'app/http/mediaApi/mediaClient';
import { createSuccessResult } from 'app/utils/result';

export interface AwardCastData {
	costUnits: number;
	reason: string;
}

const awardCastUserApi = async (dispatch: AppThunkDispatch, uid: string, awardCast: AwardCastData) =>
	makeRequest({
		dispatch,
		request: async () => {
			const result = await POST<any>(`admin/user/goodwill/${uid}`, {
				reason: awardCast.reason,
				cast: awardCast.costUnits
			});
			return result.data.data;
		},
		responseSuccessHandler: () => createSuccessResult()
	});

export function awardCastUserUseCase(uid: string, awardCast: AwardCastData): AppThunk {
	return async dispatch => {
		await awardCastUserApi(dispatch, uid, awardCast);
		dispatch(userCloseAwardCastModalAction());
	};
}

export const validateAwardCastData = (values: AwardCastData) => {
	const errors: Record<string, string> = {};

	if (values.costUnits == null) {
		errors.costUnits = 'Units are required';
	}

	if (values.costUnits < 0) {
		errors.costUnits = 'Units should be greater or equal 0';
	}

	if (values.reason.length > 40) {
		errors.reason = 'The reason can be - max 40 characters';
	}

	if (values.reason.length <= 5) {
		errors.reason = 'The reason can be - min 5 characters';
	}

	if (values.reason == null) {
		errors.reason = 'The reason are required';
	}
	return errors;
};

export const createInitialAwardCasData = (): AwardCastData => {
	return {
		costUnits: 0,
		reason: ''
	};
};
