import React, { ReactNode, useState } from 'react';
import { Box, Chip, Container, Divider, Grid, Tooltip } from '@mui/material';
import Title from '../../../../app/components/title/Title';
import { Partner } from '../../../../app/domain/Partner';
import PartnerActionButtons from '../../../actions/PartnerActionButtons';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EditPartnerModal from 'partners/actions/edit/EditPartnerModal';

interface Props {
  partner: Partner;
  refresh: ()=>void;
}

const SectionRow = ({name, value, hidden}: {name: string; value: ReactNode; hidden?: boolean})=>
  <div className="sectionRow" hidden={hidden}> <strong>{name}: </strong>{value || '-'}</div>;

const PartnerDetailsSummaryScreen = ({partner, refresh}: Props) => {
  const isPersonal = partner.isPersonal;

	const [ showEdit, setShowEdit ] = useState(false);

  return (
      <Container className="userDetailsScreen">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Title>
              Publisher : {partner.name}
              <Tooltip title="Edit channel" arrow>
                <IconButton onClick={ ()=>setShowEdit(true) }>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </Title>
          </Grid>
          <Grid className="userDetailsActions" item xs={12} sm={6}>
            <PartnerActionButtons partner={partner} onRefresh={refresh}/>
          </Grid>
        </Grid>
        <Divider />
        <Box className="section" component="div">
          <div className="sectionHeader">
            <span className="sectionTitle">Profile Info</span>
          </div>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container alignItems="center">
                <Grid item>
                  <SectionRow name="Uid" value={partner.uid}/>
                  <SectionRow name="Name" value={partner.name}/>
                  <SectionRow name="Sign up date" value={partner.created}/>
                  <SectionRow name="Last update date" value={partner.updated}/>
                  <SectionRow name="Company number" value={partner.companyNumber} hidden={isPersonal}/>
                  <SectionRow name="Vat number" value={partner.vatNumber} hidden={isPersonal}/>
                  <SectionRow name="Country code" value={partner.countryCode}/>
                  <SectionRow name="State code" value={partner.stateCode}/>
                  <SectionRow name="Postal code" value={partner.postalCode}/>
                  <SectionRow name="Address line" value={partner.addressLines}/>
                  <SectionRow name="Second address line" value={partner.secondAddressLine}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionRow name="Email" value={partner.email}/>
              <SectionRow name="Type" value={<Chip label={partner.isPersonal ? 'individual' : 'organisation'} />}/>
              <SectionRow name="Position" value={partner.position}/>
              <SectionRow name="Phone" value={partner.phone}/>
              <SectionRow name="Currency" value={partner.currency}/>
              <SectionRow name="Publisher updated" value={partner.updated}/>
              <SectionRow name="T&C accepted date" value={partner.legalAgreementsAccepted?.date}/>
              <SectionRow name="T&C accepted by" 
                value={<a href={"/user/" + partner.legalAgreementsAccepted?.uid}>{partner.legalAgreementsAccepted?.uid}</a>}/>
              <SectionRow name="Status" value={partner.status}/>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <EditPartnerModal 
          closeModal={()=>setShowEdit(false)} 
          isOpen={showEdit} 
          partner={partner} 
          refresh={refresh}
        />
      </Container>
  );
};

export default PartnerDetailsSummaryScreen;

