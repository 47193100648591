import React from 'react';
import { ContentProportionTier } from 'app/domain/Tier';

interface ContentProportionTierMenuItemProps {
	contentProportion: ContentProportionTier;
}

type Props = ContentProportionTierMenuItemProps;

const ContentProportionTierMenuItem = (props: Props) => {
	const { contentProportion } = props;

	return (
		<>
			<span style={{ width: '5em', display: 'inline-block' }}>
				<strong>Id:</strong> {contentProportion.id}
			</span>
			<span style={{ width: '5em', display: 'inline-block' }}>
				<strong>Tier:</strong> {contentProportion.tier}
			</span>
			<span style={{ width: '15em', display: 'inline-block' }}>
				<strong>Live:</strong> {contentProportion.live ?? '-'}%
			</span>
			<span style={{ width: '15em', display: 'inline-block' }}>
				<strong>On demand:</strong> {contentProportion.onDemand ?? '-'}%
			</span>
		</>
	);
};

export default ContentProportionTierMenuItem;
