import React from 'react';
import {ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Button from '@mui/material/Button';

interface ConfirmActionProps extends ButtonProps {
    show: boolean;
    onClose: () => void;
    description?: string;
    confirmedAction: () => void;
}

const ConfirmActionModal = (props: ConfirmActionProps) => {
    const {show, onClose, description, confirmedAction} = props;

    const onClickDiscard = () => {
        onClose();
    };

    const onClickSaveBtn = () => {
        confirmedAction();
    };

    return (
        <Dialog
            open={show}
            onClose={onClickDiscard}
            fullWidth={true}
            maxWidth={'sm'}
            className="confirmationDialog"
        >
            <DialogTitle id="alert-dialog-slide-title">Are you sure?</DialogTitle>
            <DialogContent className={"dialogContent"}>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={`dialogActions`}>
                <Button variant="contained" onClick={onClickDiscard} className={`button-cancel`}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onClickSaveBtn} color="secondary" className={`button-yes`}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmActionModal;
