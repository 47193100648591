import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { copyToClipboard } from 'app/utils/copyManager';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { AdminApiPage } from '../../../../../app/domain/AdminApiPage';
import { PurchaseVideo } from '../../../../../app/domain/PurchaseVideo';

interface Props {
    data: AdminApiPage<PurchaseVideo>;
}

const PurchaseVideoTable = (props: Props) => {
    const { data } = props;

    return (
        <>
            <Table className="table" size="small" padding="checkbox" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell align="left" className="header">Id</TableCell>
                    <TableCell align="left" className="header">Username</TableCell>
                    <TableCell align="left" className="header">Email</TableCell>
                    <TableCell align="left" className="header">Created</TableCell>
                    <TableCell align="left" className="header">Copy email</TableCell>
                    <TableCell align="left" className="header">Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { data && data.items.map((purchase) => (
                    <TableRow key={purchase.uid} style={{height: "3em"}}>
                        <TableCell>{purchase.uid}</TableCell>
                        <TableCell>{purchase.username}</TableCell>
                        <TableCell>{purchase.email}</TableCell>
                        <TableCell>{purchase.created}</TableCell>
                        <TableCell>
									<IconButton onClick={() => copyToClipboard(purchase.email)} size="large">
                                <FileCopyIcon/>
                            </IconButton>
                        </TableCell>
                        <TableCell><strong>{purchase.revoked ? 'Refunded' : '-'}</strong></TableCell>

                    </TableRow>
                )) }
            </TableBody>
            </Table>
        </>
    );
};

export default PurchaseVideoTable;
