import React from 'react';
import { Container, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UserDetailsSummarySection from 'users/details/sections/summary/UserDetailsSummarySection';
import TabLabelWithCounter from 'app/components/tabs/TabLabelWithCounter';
import { useParams } from 'react-router-dom';
import { isPublisher, UserWalletSummary } from 'app/domain/User';
import AccountDetailsScreen from '../../accounts/AccountDetailsScreen';
import { renderCST, renderGBP } from 'accounts/AmountDisplay';
import { prepareTechnicalValue } from 'app/utils/technical';
import { useUrlHash } from 'app/utils/useUrlHash';
import { fetchUserDetailsApi } from './UserDetailsUseCase';
import { useLocalQuery } from 'app/utils/searchManager';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';
import UserChannelPassesSection from 'users/details/sections/channelPasses/UserChannelPassesSection';

const UserDetailsScreen = () => {
	const { uid } = useParams<{ uid: string }>();
	const [[ tab ], setTab] = useUrlHash();

	const [ user, refresh ] = useLocalQuery(()=> fetchUserDetailsApi(uid), [uid]);

	let userWallet = {} as UserWalletSummary;

	const getBalance = () => {
		if (user && isPublisher(user.roles) && user.allAccounts?.publisher) {
			userWallet = user.allAccounts.publisher;
			return renderGBP(userWallet.balance);
		}
		if (user && user.allAccounts?.user) {
			userWallet = user.allAccounts.user;
			return renderCST(userWallet.balance);
		}
		return '';
	};

	return <Container className="userDetailsScreen">{
		!user ? <LoadingOverlay/> : <>
			<h2>{user.email}</h2>
			<TabContext value={tab || 'SUMMARY'}>
				<TabList 
					className="tabs" 
					indicatorColor="primary" 
					textColor="primary" 
					onChange={(_, tab)=>setTab(tab)}
					variant="scrollable"
			>
					<Tab label="Summary" value="SUMMARY" />
					<Tab
						label={<TabLabelWithCounter label="Wallet" counter={getBalance()} />}
						value="WALLET"
					/>
					<Tab label="Passes" value="PASSES" />
					<Tab label="Technical" value="TECHNICAL" />
				</TabList>

				<TabPanel value="SUMMARY">
					<UserDetailsSummarySection user={user} refresh={refresh}/>
				</TabPanel>
				<TabPanel value="WALLET">
					{!userWallet ? 'Loading...' : <AccountDetailsScreen id={userWallet.id} userUid={user.uid}/>}
				</TabPanel>
				<TabPanel value="PASSES">
					{!userWallet ? 'Loading...' : <UserChannelPassesSection userUid={user.uid} />}
				</TabPanel>

				<TabPanel value="TECHNICAL">
					<pre style={{ textAlign: 'left' }}>{prepareTechnicalValue(user)}</pre>
				</TabPanel>
			</TabContext></>
	}</Container>;
};

export default UserDetailsScreen;
