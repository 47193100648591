import React, { useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { AssignmentTypes, VideoCategory } from '../../app/domain/VideoCategory';
import AddVideoCategoryModal from '../actions/AddVideoCategoryModal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAllCategories } from 'categories/categoriesUseCase';

interface VideoCategoriesTableProps {
	videoCategories: VideoCategory[];
	onAdd: (category: VideoCategory) => void;
	onRemove: (categoryId: number) => void;
}

const VideoCategoriesTable: React.FC<VideoCategoriesTableProps> = props => {
	const { videoCategories, onAdd, onRemove } = props;
	const { categories } = useAllCategories();
	const [ showAddVideoCategoryModal, setShowAddVideoCategoryModal ] = useState(false);

	return (
		<>
			<Table className="table" padding="checkbox" stickyHeader style={{maxWidth:"50em",margin:"auto"}}>
			<TableHead>
				<TableRow>
					<TableCell align="left" className="header"  style={{width: "70%"}}>
						Name
					</TableCell>
					<TableCell align="center" className="header">
					&nbsp;&nbsp;Strength&nbsp;&nbsp;
					</TableCell>
					<TableCell align="left" className="header" style={{width: "100%"}}>
						Type
					</TableCell>
					<TableCell align="right" style={{width: "min-content"}}>
						<Tooltip title="Add" arrow>
							<IconButton onClick={()=>setShowAddVideoCategoryModal(true)}>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
			{ videoCategories.map(category =>
				<TableRow key={`video_category_${category.categoryId}`}>
					<TableCell>{categories?.find(c => c.id === category.categoryId)?.name || '?'}</TableCell>
					<TableCell align="center">{category.strength}</TableCell>
					<TableCell>{AssignmentTypes.find(c=>c[0]==category.assignmentType)?.[1]}</TableCell>
					<TableCell align="right">
						<Tooltip title="Remove" arrow>
									<IconButton
										onClick={() => onRemove(category.categoryId)}
										className="button button-red"
										size="large"
									>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			)}
			<AddVideoCategoryModal isOpen={showAddVideoCategoryModal} onSubmit={onAdd} onClose={()=>setShowAddVideoCategoryModal(false)}/>
			</TableBody>
			</Table>
		</>
	);
};

export default VideoCategoriesTable;
