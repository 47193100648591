import { VIDEO_INFO_USER } from '../UsersStatisticsApi';

export enum Operator {
    EQUALS = 'equals', NOT_EQUALS = 'notEquals'
}

function createQueryParams() {
    const measuresValue: string[] = [];
    const dimensionsValue: string[] = [];
	const timeDimensionsValue: [] = [];
	const filtersParams: [] = [];
	const orderValue = {};
    return {measuresValue, dimensionsValue, timeDimensionsValue, filtersParams, orderValue};
}

export const createPublisherUsersParams = () => {
    const {measuresValue, dimensionsValue, timeDimensionsValue, filtersParams, orderValue} = createQueryParams();

    dimensionsValue.push(VIDEO_INFO_USER);

    return createParamsUtils(measuresValue, timeDimensionsValue, filtersParams, dimensionsValue, orderValue);
};

export const createParamsUtils = (
	measuresValue: string[],
	timeDimensionsValue: [],
	filtersValue: [],
	dimensionsValue: string[],
	orderValue: unknown
) => {
    const measures: string[] = [];
    const dimensions: string[] = [];
	const timeDimensions: [] = [];
	const filters: [] = [];

    const params = {
        query: {
            measures: measures,
            timeDimensions: timeDimensions,
            order: {},
            dimensions: dimensions,
            filters: filters
        }
    }

    if (measuresValue.length > 0) {
        measuresValue.map(el => params.query.measures.push(el))
    }
    if (timeDimensionsValue.length > 0) {
        timeDimensionsValue.map(el => timeDimensions.push(el))
    }
    if (dimensionsValue.length !== 0) {
        dimensionsValue.map(el => params.query.dimensions.push(el))
    }
    if (filtersValue.length > 0) {
        filtersValue.map(el => filters.push(el));
    }
	if (orderValue) {
        params.query.order = orderValue;
    }
    return params;
};
