import React from 'react';
import { Switch } from '@mui/material';
import { Video } from 'app/domain/Video';
import { PUT } from 'app/http/adminApi/adminClient';
import { isPending } from './VideoDetailsLiveStreamingUseCase';

const VideoResolutionToggle = ({ video, refresh }: { video: Video; refresh: () => void }) => {
	const isChecked = () => {
		const { stream } = video;
		if (!stream.height) {
			return true;
		}
		return stream.height === 1080;
	};
	const onToggle = async (element: React.ChangeEvent<HTMLInputElement>) => {
		const maxResolution = element.target.checked ? 1080 : 720;
		await PUT(`/api/admin/stream/${video.uid}`, { maxResolution });
		await refresh();
	};
	return (
		<>
			<strong>Use 1080p:</strong>{' '}
			<Switch checked={isChecked()} onChange={onToggle} disabled={!isPending(video.stream?.status)} />
		</>
	);
};

export default VideoResolutionToggle;
