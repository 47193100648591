import { GET } from 'app/http/mediaApi/mediaClient';
import { PublicAccountLine, Type } from 'accounts/apiTypes';
import { fetchVideoProductsApi } from 'app/http/listingsApi/productsApi';
import { GET as adminGET, POST as adminPOST } from 'app/http/adminApi/adminClient';
import { ApiResponseData } from 'app/http/apiResponse';
import { getApiResponseData } from 'app/utils/apiPaginationUtil';
import { isPurchase, PurchaseApiEntity } from 'app/domain/Purchase';
import { isRefund, RefundApiEntity } from 'app/domain/PurchaseRefund';
import { API_PAGE_SIZE } from '../app/components/pagination/AdminApiPagination';
import { AdminApiPage } from '../app/domain/AdminApiPage';

export interface RefundedPurchase {
	purchase: PurchaseApiEntity;
	refund: RefundApiEntity;
}

export const fetchTransactions = async (accountId: number, pageUrl?: string): Promise<ApiResponseData> => {
	const url = pageUrl ?? `/finance/admin/account/${accountId}/transactions?offset=0&limit=${API_PAGE_SIZE}`;
	const resp = await adminGET<ApiResponseData>(url);
	return getApiResponseData(resp);
};

export const getTransactionsData = async (accountId: number, pagingUrl?: string): Promise<AdminApiPage<PublicAccountLine>> => {
	const transactionsApiResponse = await fetchTransactions(accountId, pagingUrl);

	return {
		paging: transactionsApiResponse.paging || { count: 0 , self: ''},
		items: transactionsApiResponse.expanded || []
	}
};

export const getTransactions = async (id: number) => {
	const resp = await GET('admin/ledger/transactions/wallet/' + id);
	return resp.data.data as PublicAccountLine[];
};

export const refundPurchaseTransaction = async (userUid: string, ledgerId: number) => {
	await adminPOST(`/api/admin/user/${userUid}/refunds/${ledgerId}`);
};

export const refundProductTransaction = async (productId: string, reason: string) => {
	const resp = await adminPOST<{
		status: { value: { failedLedgers?: number[] } };
	}>(`/api/admin/product/${productId}/refunds`, { bulkReason: reason });
	return resp.data.data;
};

export const getBulkProductRefundDetails = async (productId: string) => {
	const resp = await adminGET(`/api/admin/product/${productId}/bulkRefunds`);
	return resp.data.data as RefundApiEntity[];
};

const fetchUserPurchaseRefunds = async (userUid: string) => {
	const resp = await adminGET<ApiResponseData>(`/api/admin/user/${userUid}/refunds`);
	return getApiResponseData(resp);
};


const fetchUserPurchases = async (userUid: string) => {
	const resp = await adminGET<ApiResponseData>(`/api/admin/user/${userUid}/purchases?type=SINGLE_PRODUCT&limit=100`);
	return getApiResponseData(resp);
};

export const getUserPurchaseRefundsByLedgerId = async (userUid: string): Promise<Record<number, RefundedPurchase>> => {
	const purchasesRefundsApiResponse = await fetchUserPurchaseRefunds(userUid);

	const refunds = purchasesRefundsApiResponse.expanded.filter(isRefund);
	const purchases = purchasesRefundsApiResponse.expanded.filter(isPurchase);

	const refundedPurchases = refunds.map(refund => {
		const purchase = purchases.find(purchase => purchase.href === refund.purchase.href);
		return {
			purchase,
			refund
		} as RefundedPurchase;
	});

	return Object.fromEntries(refundedPurchases.map(refundedPurchase => [refundedPurchase.purchase.ledgerId, refundedPurchase]));
};

export const getPurchaseTransactionsVideosByTariffId = async (transactions: PublicAccountLine[]) => {
	const tariffIds = transactions
		.filter(trx => trx.ledger.type === Type.PURCHASE)
		.map(trx => trx.ledger.linkedId)
		.filter(linkedId => !!linkedId) as string[];
	const purchaseTransactions = tariffIds.length ? await fetchVideoProductsApi(tariffIds) : [];
	return Object.fromEntries(purchaseTransactions.map(videoData => [videoData.matchedTariff?.id, videoData]))
};

export const getUserPurchasesByLedgerId = async (userUid: string): Promise<Record<number, PurchaseApiEntity>> => {
	const purchasesApiResponse = await fetchUserPurchases(userUid);
	const purchases = purchasesApiResponse.expanded.filter(isPurchase);

	return Object.fromEntries(purchases.map(purchase => [purchase.ledgerId, purchase]));
};
