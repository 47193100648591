import React from 'react';
import VideoTable from 'videos/list/table/VideoTable';
import { FetchVideoData } from 'videos/list/fetchVideosApi';

interface Props {
	channelUid: string, 
	search: { [key: string]: any; }, 
	changeSearch: (search: { [key: string]: any; }) => void
}

const ChannelDetailsVideosSection = (props: Props) => {
	const { channelUid, search, changeSearch } = props;
	const criteria = {
		userId: channelUid,
		...search,
		page: search.page && parseInt(search.page)
	} as FetchVideoData;
	search.statusIds && (criteria.statusIds = search.statusIds.split(","));


	const changeCriteria = (criteria: FetchVideoData) => {
		changeSearch({ ...criteria });
	}

	return <VideoTable criteria={criteria} changeCriteria={changeCriteria} hidePublisher={true}/>;
};

export default ChannelDetailsVideosSection;
