import { GET, POST_FORM_DATA } from 'app/http/mediaApi/mediaClient';
import { GET as adminGET } from 'app/http/adminApi/adminClient';
import { StatusMessage, Video } from 'app/domain/Video';
import { Contributor } from 'app/domain/Contributor';
import { fetchUserDetailsApi } from 'users/details/UserDetailsUseCase';
import { fetchVideosPurchaseData } from 'app/stats/statsManager';

export async function doFetchVideoDetailsApi(videoId: string): Promise<Video> {
	const videoData = await GET<Video>(`admin/video/${videoId}`).then(r => r.data.data);
	const stats = await fetchVideosPurchaseData([videoId]);
	const videoStats = stats.find(stat => stat.uid === videoData.uid);
	return !videoStats ? videoData : { ...videoData, stats: videoStats };
}

export async function doFetchAdminVideoDetailsApi(videoId: string) {
	const videoData = await adminGET<Video>(`/api/admin/video/${videoId}`).then(r => r.data.item);
	const stats = await fetchVideosPurchaseData([videoId]);
	const videoStats = stats.find(stat => stat.uid === videoData.uid);
	return !videoStats ? videoData : { ...videoData, stats: videoStats };
}

export const fetchUsernamesForContributors = (toMap: Contributor[]) =>
	Promise.all(
		toMap.map(c => fetchUserDetailsApi(c.userUid).then(r => ({ ...c, username: r?.username } as Contributor)))
	);

export interface VideoEditBasicData {
	name: string;
	description: string;
}

export const editVideoApi = (videoId: string, data: VideoEditBasicData) =>
	POST_FORM_DATA<void>(`admin/video/${videoId}`, data).then(r => r.data.data);

export interface VideoEditStatusMessages {
	error: string;
	info: string;
}

/** Post the status messages, having converted them to the appropriate (nasty format).
 * Always create an array even if there are no messages because this is the only way to
 * delete existing messages */
export const editVideoStatusMessagesApi = (videoId: string, { error, info }: VideoEditStatusMessages) => {
	const messages: StatusMessage[] = [];
	error && messages.push({ type: 'ERROR', text: error });
	info && messages.push({ type: 'INFO', text: info });
	return POST_FORM_DATA<void>(`admin/video/${videoId}`, {
		statusMessages: JSON.stringify(messages.length ? messages : {}) // Hack around PHP
	}).then(r => r.data.data);
};
