import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    ListItemText,
    MenuItem,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { Country } from '../../../../videos/details/sections/summary/edit/country/CountryUseCase';
import { COUNTRY_REGION, Region, REGION_NAME, REGION_ORDER } from '../../../../constants/Regions';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import { currencyCountriesCloseEditCountriesModalAction } from '../store/CurrencyCountriesActions';
import { currencyCountriesUseCase } from './CurrencyCountriesUseCase';

interface StateProps {
    isOpen: boolean;
    currencyCountry: CurrencyCountries;
    checkedCodes: string[];
    supportedCountries: Country[];
}

interface DispatchProps {
    saveChanges: (currencyCountry: CurrencyCountries) => void;
    closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const CurrencyCountriesModal = (props: Props) => {
    const { isOpen, closeModal, saveChanges, currencyCountry, checkedCodes, supportedCountries } = props;

    const getInitialCountries = (): Country[] => {
        return supportedCountries.map( country => {
            country.region = COUNTRY_REGION[ country.code ];
            country.enabled = checkedCodes.includes( country.code ) && !currencyCountry.countryCodes.includes( country.code );
			country.checked = currencyCountry.countryCodes ? currencyCountry.countryCodes.includes( country.code ) : false;
            return country;
        } );
    };

    const [checkedCountries, setCheckedCountries] = useState<Country[]>( [] );

    useEffect( () => {
        setCheckedCountries( getInitialCountries );
    }, [currencyCountry, supportedCountries] );


    const saveCountries = () => {
        const currencyCountriesData = {
            currencyCode: currencyCountry.currencyCode,
            countryCodes: checkedCountries.filter( country => country.checked ).map( country => country.code ),
        };
        saveChanges( currencyCountriesData );
    };

    const selectCountry = (selectedCountry: Country) => {
        setCheckedCountries( checkedCountries.map( country => {
            if (country.code === selectedCountry.code) {
                selectedCountry.checked = !selectedCountry.checked;
            }
            return country;
        } ) );
    };

    const selectAllCountriesByRegion = (region: Region) => {
        setCheckedCountries( checkedCountries.map( country => {
            if (country.region === region) {
                country.checked = true;
            }
            return country;
        } ) );
    };

    const deselectAllCountriesByRegion = (region: Region) => {
        setCheckedCountries( checkedCountries.map( country => {
            if (country.region === region) {
                country.checked = false;
            }
            return country;
        } ) );
    };

    const selectAllCountries = () => {
        setCheckedCountries( checkedCountries.map( country => {
            country.checked = true;
            return country;
        } ) );
    };

    const deselectAllCountries = () => {
        setCheckedCountries( checkedCountries.map( country => {
            country.checked = false;
            return country;
        } ) );
    };

    const isNoCountriesChecked = () => {
        return checkedCountries.filter( country => !country.checked ).length === 0;
    };

    const isNoCountriesCheckedInRegion = (region: Region) => {
        return checkedCountries.filter( country => country.region === region && !country.checked ).length === 0;
    };

    return (
        <Dialog
            className="countryAvailabilityDialog"
            open={isOpen}
            onClose={closeModal}
        >
            <div className="scroll">
                <Grid item xs={12}>
                    <DialogTitle><strong>Country availability</strong></DialogTitle>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Grid item xs={6}>
                            <Button className="button-availability" variant="contained" color="secondary"
                                    onClick={() => selectAllCountries()}>
                                SELECT ALL REGIONS
                                {isNoCountriesChecked() && <CheckIcon/>}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className="button-availability" variant="contained"
                                    onClick={() => deselectAllCountries()}>
                                DESELECT ALL REGIONS
                            </Button>
                        </Grid>
                    </DialogActions>
                </Grid>
                {REGION_ORDER.map( region =>
                    <Accordion key={region} style={{ margin: '5px 10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <div style={{ flexBasis: '80%' }}>
                                <strong>{REGION_NAME[ region ]}</strong>
                            </div>
                            {!isNoCountriesCheckedInRegion( region ) &&
                            <IconButton size="small"
                                        onClick={() => selectAllCountriesByRegion( region )}>
                                <AddIcon/>
                            </IconButton>
                            }
                            {isNoCountriesCheckedInRegion( region ) &&
                            <IconButton size="small"
                                        onClick={() => deselectAllCountriesByRegion( region )}>
                                <CheckIcon/>
                            </IconButton>
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={'expansionPanelDetails'}>
                                <Typography>
                                    Live now in:
                                </Typography>
                                {checkedCountries.filter( country => country.region === region ).map( country =>
                                    <MenuItem key={country.code} disabled={country.enabled}
                                              onClick={() => selectCountry( country )}>
                                        <Checkbox checked={country.checked}/>
                                        <ListItemText primary={country.name}/>
                                    </MenuItem>,
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>,
                )
                }
                <DialogActions>
                    <Button variant="contained" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="contained" color="secondary" onClick={saveCountries}>Save</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ( {
    isOpen: state.currency.currencyCountries.actions.editCountriesModal.isOpen,
    currencyCountry: state.currency.currencyCountries.actions.currencyCountry,
    checkedCodes: state.currency.currencyCountries.actions.checkedCodes,
    supportedCountries: state.currency.currencyCountries.actions.supportedCountries,
} );

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    saveChanges: (currencyCountry: CurrencyCountries) => {
        dispatch( currencyCountriesUseCase( currencyCountry ) );
    },
    closeModal: () => {
        dispatch( currencyCountriesCloseEditCountriesModalAction() );
    },
} );

export default connect( mapStateToProps, mapDispatchToProps )( CurrencyCountriesModal );
