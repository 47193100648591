import { AppThunkDispatch } from 'app/store/store';
import {
	openSnackbarMessageAction
} from 'app/store/app/actions';
import logoutUseCase from 'login/LogoutUseCase';
import { Result } from 'app/utils/result';
import { getConfig } from 'config/appConfig';

export interface RequestInput<T, R, P> {
  dispatch: AppThunkDispatch;
  request: () => Promise<T>;
  responseSuccessHandler: (response: T) => Result<R, P>;
  responseErrorHandler?: (responseError: ResponseError) => Result<R, P> | undefined;
}

export interface ResponseError {
  status: number;
  message: any;
  statusText: string;
}

export const makeRequest: <T, R, P>(input: RequestInput<T, R, P>) => Promise<Result<R, P>> =
  async ({ dispatch, request, responseSuccessHandler, responseErrorHandler }) => {

  try {
    const response = await request();
    const result = responseSuccessHandler(response);
    return Promise.resolve(result);
  } catch (error) {
    return errorHandler(error, dispatch, responseErrorHandler);
  }
};

const errorHandler = <R, P>(error: any, dispatch: AppThunkDispatch, responseErrorHandler?: (responseError: ResponseError) => Result<R, P> | undefined): Promise<Result<R, P>> => {
  const parsedError = parseResponseError(error);

  if (!parsedError) {
    return Promise.reject(error);
  }

  if (responseErrorHandler === undefined) {
    return defaultResponseErrorHandler(dispatch, parsedError);
  }

  const result = responseErrorHandler(parsedError as ResponseError);

  if (result) {
    return Promise.resolve(result);
  } else {
    return defaultResponseErrorHandler(dispatch, parsedError);
  }
};

const parseResponseError = (error: any): ResponseError | false => {
  if (error && error.response) {
    const response = error.response;
    return { status: response.status, statusText: response.statusText, message: !!response.data && !!response.data.message ? response.data.message : 'Unknown error' };
  } else {
    return false;
  }
};

const defaultResponseErrorHandler = (dispatch: AppThunkDispatch, apiErrorResponse: ResponseError) => {
  if (apiErrorResponse.status === 401) {
    dispatch(openSnackbarMessageAction('error', 'Unauthorized request!'));
    logoutUseCase(dispatch);
  } else if (typeof apiErrorResponse.message === 'string') {
    const message = `Server error ${apiErrorResponse.status}: ${apiErrorResponse.message}`;
    dispatch(openSnackbarMessageAction('error', message));
  } else {
    const message = `Server error ${apiErrorResponse.status}: ${extractErrorMessage(apiErrorResponse.message)}`;
    dispatch(openSnackbarMessageAction('error', message));
  }

  return Promise.reject(apiErrorResponse);
};

function extractErrorMessage(errorMessage: any): string {
	const result = [];

  try {
    for (const fieldName in errorMessage) {
			if (Object.hasOwn(errorMessage, fieldName)) {
        const fieldError = errorMessage[fieldName].join(' and ');
        result.push(fieldError);
      }
    }

    return result.join('. ');
  } catch (e) {
    return 'Error occur when parsing error message!';
  }
}

export const getBaseApiUrl = (name: string, suffix = '', base = getConfig().API_BASE): string => {
	const isSlashIncluded = suffix?.charAt(0) === '/';
	return `${base}/${name}${isSlashIncluded ? suffix : `/${suffix}`}`;
};
