import { HTTP_STATUS_CODES, isHttpClientErrorStatus } from 'constants/Common';
import { ACCESS_TOKEN_NAME, AUTH_TOKEN_NAME, POST_AUTH } from 'app/http/authApi/authClient';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const INITIAL_FETCHING_COUNTER_VALUE = 0;
const MAX_FETCHING_COUNTER_VALUE = 3;

let fetchingTokenCounter = INITIAL_FETCHING_COUNTER_VALUE;
export const AUTH_TOKENS_KEY = 'authTokens';

export interface TokenPayload {
	exp: number;
	iat: number;
	isAdmin: boolean;
	isFinanceAdmin: boolean;
	isContributor: boolean;
	isPartnerStaff: boolean;
	isPublisher: boolean;
	uid: string;
}

const getAuthTokens = (): { accessToken: string; refreshToken: string } => {
	try {
		return JSON.parse(localStorage.getItem(AUTH_TOKENS_KEY) || 'false');
	} catch (e) {
		return { accessToken: '', refreshToken: '' };
	}
};

export const getAuthAccessToken = (): string => getAuthTokens().accessToken;
export const getAuthRefreshToken = (): string => getAuthTokens().refreshToken;

export const setAuthTokens = (tokens: object) => localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(tokens));
export const clearAuthTokens = () => localStorage.removeItem(AUTH_TOKENS_KEY);

const refreshAuthTokens = async () => {
	const res = await POST_AUTH<Response>('refresh-token', { refreshToken: getAuthRefreshToken() });
	if (HTTP_STATUS_CODES.OK_REQUEST === res.status) {
		setAuthTokens(res.data);
	}
};

const refreshLogin = async () => {
	const oldToken = getAuthRefreshToken();
	if (!oldToken) return false;

	fetchingTokenCounter = INITIAL_FETCHING_COUNTER_VALUE;
	while (fetchingTokenCounter < MAX_FETCHING_COUNTER_VALUE) {
		fetchingTokenCounter++;
		try {
			await refreshAuthTokens();
			return true;
		} catch (e) {
			const errorData = axios.isAxiosError(e) ? e?.response : undefined;
			if (errorData && isHttpClientErrorStatus(errorData.status)) {
				clearAuthTokens();
				return false;
			}
		}
	}
	return false;
};

export const addAccessToken = (config: InternalAxiosRequestConfig) => {
	const token = getAuthAccessToken();
	if (token) {
		config.headers.set(ACCESS_TOKEN_NAME, token);
	}
	return config;
};

export const addBearerToken = (config: InternalAxiosRequestConfig) => {
	const token = getAuthAccessToken();
	if (token) {
		config.headers.set(AUTH_TOKEN_NAME, `Bearer ${token}`);
	}
	return config;
};

export async function resetAccessTokenOnUnauthorized(err: unknown): Promise<AxiosResponse> {
	if (
		axios.isAxiosError(err) &&
		err.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED &&
		err.config &&
		(await refreshLogin())
	) {
		err.config.headers[ACCESS_TOKEN_NAME] = getAuthAccessToken();
		return axios(err.config);
	}

	return Promise.reject(err);
}

export async function resetBearerTokenOnUnauthorized(err: unknown): Promise<AxiosResponse> {
	try {
		if (
			axios.isAxiosError(err) &&
			err.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED &&
			err.config &&
			(await refreshLogin())
		) {
			err.config.headers.set(AUTH_TOKEN_NAME, 'Bearer ' + getAuthAccessToken());
			return axios(err.config);
		}
	} catch (e) { /* empty */ }
	return Promise.reject(err);
}

export const decodeJwtToken = (token?: string): TokenPayload | undefined => {
	if (token) {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (error) {
			return undefined;
		}
	}
	return undefined;
};

export const getTokenPayload = (): TokenPayload | undefined => {
	const token = getAuthAccessToken();
	return token ? decodeJwtToken(getAuthAccessToken()) : undefined;
};

export const getLoggedUserId = (): string => {
	return getTokenPayload()?.uid ?? '';
};
