import dayjs, { ConfigType } from 'dayjs';
import { VideoBreakdown } from 'app/stats/statsManager';
import { Sort, SortDirection } from 'app/domain/Sort';

export type VideosSortField = 'uid' | 'purchases' | 'name' | 'directEarnings' | 'uploadDate';

export function sortVideos(videos: VideoBreakdown[], sort: Sort<VideosSortField>) {
  if (sort.field === 'uploadDate') {
    return getSortedArrayByDate(sort.field, videos, sort.direction);
  }

  const sortFunc = sort.direction === 'asc' ? ascSortVideos : descSortVideos;
  return [...videos].sort((a, b) => sortFunc(a, b, sort.field));
}

const ascSortVideos = (a: VideoBreakdown, b: VideoBreakdown, sortField: VideosSortField) => {
  if (sortField === 'uid') {
    return (a['uid'] || '').localeCompare(b['uid'], 'en');
  }

  return Math.sign(((a[sortField] as number) || 0) - ((b[sortField] as number) || 0));
};

const descSortVideos = (a: VideoBreakdown, b: VideoBreakdown, sortField: VideosSortField) =>
  -ascSortVideos(a, b, sortField);

export const getSortedArrayByDate = <T>(dateKey: keyof T, array?: T[], order?: SortDirection): T[] => {
	return array
		? order === 'desc'
			? [...array].sort((a, b) => dayjs(a[dateKey] as ConfigType).diff(dayjs(b[dateKey] as ConfigType)))
			: [...array].sort((a, b) => dayjs(b[dateKey] as ConfigType).diff(dayjs(a[dateKey] as ConfigType)))
		: [];
};
