import React, { useState } from 'react';
import { Chip, TableCell, TableRow } from '@mui/material';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';

const NUMBER_OF_COUNTRIES_DISPLAYED = 15;

interface StateProps {
    currency: CurrencyCountries;
}

type Props = StateProps;

const CurrencyCountriesRow = (props: Props) => {
    const { currency } = props;

    const [showAllCountries, setShowCountries] = useState<boolean>( false );

    return (
        <TableRow>
            <TableCell>{currency.currencyCode}</TableCell>
            <TableCell style={{ width: 200 }}>
                {!showAllCountries && <>
                    {currency.countryCodes.slice( 0, NUMBER_OF_COUNTRIES_DISPLAYED ).map( code =>
                        <Chip key={code} style={{ margin: '3px' }} label={code}/> )}
                    {currency.countryCodes.length > NUMBER_OF_COUNTRIES_DISPLAYED &&
                    <Chip onClick={() => setShowCountries( true )} label="..."/>}
                </>
                }
                {showAllCountries && <>
                    {currency.countryCodes.map( code => <Chip key={code} style={{ margin: '3px' }} label={code}/> )}
                    <Chip key={'showCountriesChip'} onClick={() => setShowCountries( false )} label="..."/>
                </>
                }
            </TableCell>
        </TableRow>
    );
};

export default CurrencyCountriesRow;

