import React, { useEffect, useState } from 'react';
import { VideoCategory } from '../../../../app/domain/VideoCategory';
import { Box, Button } from '@mui/material';
import VideoCategoriesTable from '../../../../categories/table/VideoCategoriesTable';
import SetVideoCategoriesModal from '../../../../categories/actions/SetVideoCategoriesModal';
import { fetchVideoCategoriesApi, setVideoCategoriesApi } from 'app/http/listingsApi/videoCategoriesApi';

const VideoCategoriesSection = ({ uid }: { uid: string }) => {
	const [savedVideoCategories, setSavedVideoCategories] = useState<VideoCategory[]>([]);
	const [videoCategories, setVideoCategories] = useState<VideoCategory[]>([]);

	const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);

	const addVideoCategory = (values: VideoCategory) => {
		setVideoCategories(videoCategories => [...videoCategories, values]);
	};

	const removeVideoCategory = (categoryId: number) =>
		setVideoCategories(videoCategories => videoCategories.filter(category => category.categoryId !== categoryId));

	const updateVideoCategories = ()=>setVideoCategoriesApi(uid, videoCategories);

	useEffect(() => setVideoCategories(savedVideoCategories), [savedVideoCategories]);
	useEffect(() => { uid && fetchVideoCategoriesApi(uid).then(setSavedVideoCategories) }, [uid]);

	return (
		<div style={{margin: "0 0.2em"}}>
			<Box className="section" component="div">
				<VideoCategoriesTable
					videoCategories={videoCategories}
					onAdd={addVideoCategory}
					onRemove={removeVideoCategory}
				/>
			</Box>
			<div className="actions videoCategoriesActions">
				<Button onClick={()=>setShowConfirmationModal(true)} color="secondary" variant="contained">
					Save
				</Button>
			</div>
			<SetVideoCategoriesModal 
				onConfirm={updateVideoCategories} 
				isOpen={showConfirmationModal} 
				onClose={()=>setShowConfirmationModal(false)}/>
		</div>
	);
};

export default VideoCategoriesSection;
