import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { APIResponse, AxiosRequestConfigProps } from 'app/http/mediaApi/mediaClient';
import { getBaseApiUrl } from 'app/http/request';
import { addAccessToken, resetAccessTokenOnUnauthorized } from 'app/http/authApi/token';

const clientPayment: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});
clientPayment.interceptors.response.use(res => res, resetAccessTokenOnUnauthorized);
clientPayment.interceptors.request.use(addAccessToken, Promise.reject);

const getPaymentApiUrl = (suffix?: string): string => getBaseApiUrl('payment', suffix);

export const POST_PAYMENT = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<U>;
	return clientPayment.post<typeof data, Response>(getPaymentApiUrl(url), data, config);
};

export const GET_PAYMENT = <U>(url: string, config?: AxiosRequestConfig) => {
	return clientPayment.get<APIResponse<U>>(getPaymentApiUrl(url), config);
};
