import { Category } from 'app/domain/Category';
import { TreeNodeData } from 'app/components/tree/treeModel';

const buildTreeNode = (categories: Category[], parentCategory: Category, expanded: boolean): TreeNodeData => {
	return {
		id: parentCategory.id,
		title: parentCategory.name,
		expanded: expanded,
		children: categories
			.filter(category => category.parentId === parentCategory.id)
			.map(child => buildTreeNode(categories, child, expanded))
	};
};

export const buildTreeFromCategories = (categories: Category[], expanded: boolean): TreeNodeData[] => {
	return categories
		.filter(c => c.parentId === null)
		.map(category => buildTreeNode(categories, category, expanded));
};

export const getGeneralCategoryIds = (categories: Category[]) => {
	const parentIds = categories.filter(c => c.isChildrenGeneral).map(c => c.id);
	const childIds = categories.filter(c => c.parentId && parentIds.includes(c.parentId));
	return [...parentIds, ...childIds];
};

export const filterCategoriesByName = (categories: Category[], name: string) => {
	const lower = name.toLowerCase()
	const matches = categories.filter(c => c.name.toLowerCase().startsWith(lower));
	const parents = matches.flatMap(c => getParentCategories(c.parentId, categories));
	const children = matches.flatMap(c => getSubcategories(c.id, categories));
	return deduplicate([...parents, ...matches, ...children])
};

const deduplicate = (categories: Category[]) => {
	return  categories.filter((c, idx) => categories.findIndex(c2 =>(c2.id === c.id)) === idx)
};

export const getSubcategories = (rootId: number, categories: Category[]): Category[] => {
	return categories
		.filter(c => c.parentId === rootId)
		.flatMap(c => [c, ...getSubcategories(c.id, categories)]);
};

export const getParentCategories = (parentId: number | null, categories: Category[]): Category[] => {
	return categories
		.filter(c => c.id === parentId)
		.flatMap(c => [c, ...getParentCategories(c.parentId, categories)]);
};

export const prepareCeilingCategories = (categories: Category[]) => {
    const generalIds = getGeneralCategoryIds(categories);
    return (categories || [])
        .filter(c => !generalIds.includes(c.id))
        .sort((a, b) => ('' + a.name).localeCompare(b.name));
};
