import React from "react";

interface Props {
  children: React.ReactNode;
}

const Title = (props: Props) => {
  return (
    <div className="title">
      {props.children}
    </div>
  );
};

export default Title;

