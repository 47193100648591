import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface SelectItem {
  label: string;
  data: any;
}

interface Props {
  label: string;
  initialSelected: SelectItem[];
  items: SelectItem[];
  setItems: (items: SelectItem[]) => void;
}

const MultiSelect = (props: Props) => {
  const { initialSelected, items, setItems, label } = props;
  const [value, setValue] = useState<SelectItem[]>(initialSelected);

  useEffect(() => {
    setValue(initialSelected);
  }, [initialSelected]);

  return (
    <Autocomplete
      className="multiSelect"
      multiple
      options={items}
      disableCloseOnSelect
      value={value}
			onChange={(_, newValue) => {
        setValue(newValue);
        setItems(newValue);
      }}
      getOptionLabel={(option) => option.label}
			isOptionEqualToValue={(option, value) => option.label === value.label}
			renderOption={(props, { label }, { selected }) => (
				<li {...props}>
					<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
					{label}
				</li>
			)}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} placeholder="" />
      )}
    />
  );
};

export default  MultiSelect;
