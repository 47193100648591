import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { isStatusToEditTiers, isSuspended, Partner } from 'app/domain/Partner';
import EditPartnerTierModal from 'partners/actions/edit/editTier/EditPartnerTierModal';
import LinkIcon from '@mui/icons-material/Link';
import ConfirmModal from 'channels/ConfirmModal';
import { removePartnerApi, suspendPartnerApi } from './suspend/SuspendPartnerUseCase';

interface PartnerActionButtonsProps {
	partner: Partner;
	onRefresh: () => void;
}

const PartnerActionButtons = (props: PartnerActionButtonsProps) => {
	const { partner, onRefresh } = props;

	const [ showRemove, setShowRemove ] = useState(false);
	const [ showSuspend, setShowSuspend ] = useState(false);
	const [ showUnsuspend, setShowUnsuspend ] = useState(false);
	const [ showEditTiers, setShowEditTiers ] = useState(false);

	return (
		<div className="actions">
			{isSuspended(partner) ? (
				<Tooltip title="Unsuspend" arrow>
					<IconButton className="button button-green" onClick={() => setShowUnsuspend(true)} size="large">
						<LockOpenIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Suspend" arrow>
					<IconButton className="button-orange" onClick={() => setShowSuspend(true)} size="large">
						<LockIcon />
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title="Delete" arrow>
				<IconButton className="button button-red" onClick={() => setShowRemove(true)} size="large">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
			{isStatusToEditTiers(partner) && (
				<Tooltip title="Tier" arrow>
					<IconButton className="button-orange" onClick={() => setShowEditTiers(true)} size="large">
						<LinkIcon />
					</IconButton>
				</Tooltip>
			)}
			<ConfirmModal 
				closeModal={()=>setShowRemove(false)} 
				isOpen={showRemove} 
				message="Are you sure you want to permanently delete this publisher (this cannot be undone)?"
				onConfirm={()=>removePartnerApi(partner.uid).then(onRefresh)}
			/>
			<ConfirmModal 
				closeModal={()=>setShowSuspend(false)} 
				isOpen={showSuspend} 
				message="Are you sure you want to suspend this publisher?" 
				onConfirm={()=>suspendPartnerApi(partner.uid).then(onRefresh)}
			/>
			<ConfirmModal 
				closeModal={()=>setShowUnsuspend(false)} 
				isOpen={showUnsuspend} 
				message="Are you sure you want to unsuspend this publisher?" 
				onConfirm={()=>suspendPartnerApi(partner.uid).then(onRefresh)}
			/>
			<EditPartnerTierModal 
				closeModal={()=>setShowEditTiers(false)} 
				isOpen={showEditTiers} 
				partner={partner} 
				refresh={onRefresh}
			/>
		</div>
	);
};

export default PartnerActionButtons;
