import React, { useState } from 'react';
import { Pagination as MuiPagination } from '@mui/material';
import { ApiPaging } from 'app/http/apiResponse';

export const API_PAGE_SIZE = 100;

interface PaginationProps {
  items: any[];
  paging: ApiPaging
  changePage: (url: string) => void;
}

interface Boundaries {
  from: number;
  to: number;
}

const AdminApiPagination = (props: PaginationProps) => {
  const { paging, changePage } = props;
  const [page, setPage] = useState(1);
  const { from, to } = getBoundaries(page, paging.count);

	const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    changePage(getUrlWithCalculatedOffset(value, paging));
    setPage(value);
  };

  return (
    <div className="pagination">
      <span className="overview">{from} - {to} of { paging.count }</span>
      <MuiPagination className="numbers" count={getPageCount(paging.count)} page={page} onChange={handleChange} />
    </div>
  );
};

function getUrlWithCalculatedOffset(currentPage: number, paging: ApiPaging) {
  const calculatedOffset = (currentPage - 1) * API_PAGE_SIZE;
  return paging.self.replace(/offset=[0-9]+/g, `offset=${calculatedOffset}`);
}

function getPageCount(totalCount: number) {
  return Math.ceil(totalCount / API_PAGE_SIZE);
}

function getBoundaries(page: number, total: number): Boundaries {

  if (total === 0) {
    return { from: 0, to: 0 };
  } else {
    return {
      from: (page - 1) * API_PAGE_SIZE + 1,
      to: (page === getPageCount(total)) ? total : page * API_PAGE_SIZE,
    };
  }
}

export default AdminApiPagination;
