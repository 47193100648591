import { NavLink } from 'react-router-dom';
import RoutePaths from 'app/navigation/RoutePaths';
import { Button } from '@mui/material';
import React from 'react';
import { AppThunkDispatch } from 'app/store/store';
import logoutUseCase from 'login/LogoutUseCase';
import { connect } from 'react-redux';
import { isLoggedAsFinanceAdmin } from 'app/domain/User';

interface DispatchProps {
	logout: () => Promise<void>;
}

type Props = DispatchProps;

const LoggedInUserMenuOptions = (props: Props) => {
	const { logout } = props;

	return (
		<>
			<div>
				<NavLink to={RoutePaths.Videos} activeClassName="activeLink" color="inherit" className="link">
					Videos
				</NavLink>
				<NavLink to={RoutePaths.Users} activeClassName="activeLink" color="inherit" className="link">
					Users
				</NavLink>
				<NavLink to={RoutePaths.Channels} activeClassName="activeLink" color="inherit" className="link">
					Channels
				</NavLink>
				<NavLink to={RoutePaths.Categories} activeClassName="activeLink" color="inherit" className="link">
					Categories
				</NavLink>
				<NavLink to={RoutePaths.Accounts} activeClassName="activeLink" color="inherit" className="link">
					Accounts
				</NavLink>
				{/* <NavLink to={RoutePaths.Reports} activeClassName="activeLink" color="inherit" className="link">
					Reports
				</NavLink> */}
				{/* <NavLink to={RoutePaths.Unverified} activeClassName="activeLink" color="inherit" className="link">
					Unverified users
				</NavLink> */}
				{/* <NavLink to={RoutePaths.Invitations} activeClassName="activeLink" color="inherit" className="link">
					Invitations
				</NavLink>
				<NavLink to={RoutePaths.Publishers} activeClassName="activeLink" color="inherit" className="link">
					Publishers
				</NavLink> */}
				<NavLink to={RoutePaths.Currency} activeClassName="activeLink" color="inherit" className="link">
					Currency
				</NavLink>
				<NavLink to={RoutePaths.Purchases} activeClassName="activeLink" color="inherit" className="link">
					Purchases
				</NavLink>
				{ isLoggedAsFinanceAdmin() &&
					<NavLink to={RoutePaths.CashOuts} activeClassName="activeLink" color="inherit" className="link">
						Cash Outs
					</NavLink>
				}
			</div>
			<Button color="inherit" className="logout" onClick={logout}>
				Logout
			</Button>
		</>
	);
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
	logout: async () => {
		return logoutUseCase(dispatch);
	}
});

export default connect(null, mapDispatchToProps)(LoggedInUserMenuOptions);
