import React, { SyntheticEvent, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { UnverifiedUser } from 'app/domain/UnverifiedUser';
import ResendVerificationCodeModal from 'unverified/ResendVerificationCodeModal';
import { Send } from '@mui/icons-material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { removeUnverifiedUserApi, verifyUnverifiedUserApi } from 'unverified/UnverifiedUsersUseCase';
import ConfirmModal from 'channels/ConfirmModal';

interface UnverifiedActionButtonsProps {
	user: UnverifiedUser;
	onRefresh: () => void;
}

const UnverifiedUserActionButtons = ({ user, onRefresh }: UnverifiedActionButtonsProps) => {
	const [ showConfirm, setShowConfirm ] = useState<{ action?: string, message: string, onConfirm: ()=>void }>();
	const [ showResend, setShowResend ] = useState(false);

	const onRemoveUnverifiedUser = (_: SyntheticEvent, user: UnverifiedUser) => {
		setShowConfirm({
			message: "This will permanently delete the item (it's not reversible!).",
			onConfirm: ()=>removeUnverifiedUserApi(user.email).then(onRefresh),
		});
	};

	const onResendVerificationCode = (e: SyntheticEvent) => {
		setShowResend(true);
		e.stopPropagation();
	};

	const onVerify = async (e: SyntheticEvent, user: UnverifiedUser) => {
		setShowConfirm({
			message: "Are you sure that you want validate this user?",
			onConfirm: () => verifyUnverifiedUserApi(user.email).then(onRefresh),
		})
		e.stopPropagation();
	};

	return (
		<div className="actions">
			<Tooltip title="Verify" arrow>
				<IconButton className="button button-green" onClick={e => onVerify(e, user)} size="large">
					<VerifiedUserIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Resend verification code" arrow>
				<IconButton
					className="button button-orange"
					onClick={onResendVerificationCode}
					size="large"
				>
					<Send />
				</IconButton>
			</Tooltip>
			<Tooltip title="Delete" arrow>
				<IconButton className="button button-red" onClick={e => onRemoveUnverifiedUser(e, user)} size="large">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
			{showConfirm && 
				<ConfirmModal isOpen={true} closeModal={()=>setShowConfirm(undefined)} { ...showConfirm  } />}
			<ResendVerificationCodeModal isOpen={showResend} closeModal={()=>setShowResend(false)} user={user}/>
		</div>
	);
};

export default UnverifiedUserActionButtons;
