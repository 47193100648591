import { FilterValue } from "../../../app/components/filter/FilterUseCase";
import FilterGroup from "../../../app/components/filter/FilterGroup";
import React from "react";
import { UserRole } from "../../../app/domain/User";
import {TextField} from '@mui/material';

export interface UsersQuery {
  usernameSearchText?: string;
  emailSearchText?: string;
  referrerSearchText?: string;
  roles?: UserRole[];
  page?: number;
}

export const emptyUsersQuery = {
} as UsersQuery

interface Props {
  usersQuery: UsersQuery;
  onChange: (usersQuery: UsersQuery) => void;
}

const allFilters: FilterValue[] = [
  { name: 'admin', label: 'Admin' },
  // { name: 'livestreamer', label: 'Livestreamer' },
  { name: 'publisher', label: 'Publisher' },
  { name: 'user', label: 'User' }
];

const UsersFilter = (props: Props) => {
  const { usersQuery, onChange } = props;

  const roles = allFilters.filter(el => usersQuery.roles?.includes(el.name as UserRole));

  const setRoles = (values: FilterValue[]) => {
    const roles = values.map(el => el.name) as UserRole[];
    onChange({ ...usersQuery, roles});
  };

  return (
    <div className="filter">
      <div>
      <TextField
          style={{width: '350px'}}
          id="standard-basic" label="Email query"
          onChange={ e => {onChange({ ...usersQuery, emailSearchText: e.target.value})}}
					variant="standard"
      />
      </div>
      <div>
      <TextField
          style={{width: '350px'}}
          id="standard-basic" label="Referrer query"
          onChange={ e => {onChange({ ...usersQuery, referrerSearchText: e.target.value})}}
					variant="standard"
      />
      </div>
      <div>
        <span className="label">Roles</span>
        <FilterGroup currentValues={roles} allFilters={allFilters} setCurrentValues={setRoles}/>
      </div>
    </div>
  );
};

export default UsersFilter;
