import { connect } from "react-redux";
import { AppThunkDispatch, RootState } from "../../../app/store/store";
import { unsuspendVideoUseCase } from "./UnsuspendVideoUseCase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from "react";
import { videoCloseUnsuspendModalAction } from "../store/VideoActionsActions";

interface StateProps {
  videoId: string;
  isOpen: boolean;
}

interface DispatchProps {
  unsuspendVideo: (videoId: string, reason?: string) => void;
  closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const UnsuspendVideoModal = (props: Props) => {
  const { videoId, isOpen, closeModal, unsuspendVideo } = props;
  const [reason, onReasonChange] = useState('');

  const handleUnsuspend = () => {
    unsuspendVideo(videoId, reason);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will unsuspend the item. It will be visible publicly.
        </DialogContentText>
        <TextField fullWidth label="Reason (optional)" variant="outlined" value={reason} onChange={e => onReasonChange(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Disagree
        </Button>
        <Button onClick={handleUnsuspend} color="primary" autoFocus>
          Unsuspend
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.videos.actions.unsuspendModal.isOpen,
  videoId: state.videos.actions.unsuspendModal.videoId,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  unsuspendVideo: (videoId: string, reason?: string) => {
    dispatch(unsuspendVideoUseCase(videoId, reason));
  },
  closeModal: () => {
    dispatch(videoCloseUnsuspendModalAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnsuspendVideoModal);
