import { AppThunk } from 'app/store/store';
import {
	invitationCloseCopyModalAction,
	invitationOnRefreshAction
} from 'invitations/actions/store/InvitationActionsActions';
import { Invitation } from 'app/domain/Invitation';
import { removeInvitationApi } from 'invitations/actions/remove/RemoveInvitationUseCase';
import { createInvitationUseCase } from 'invitations/actions/add/CreateInvitationUseCase';

export function copyInvitationUseCase(invitation: Invitation): AppThunk {
	return async dispatch => {
		await removeInvitationApi(dispatch, invitation.id);
		await dispatch(
			createInvitationUseCase({
				email: invitation.email,
				ceilingCategoriesIds: invitation.ceilingCategoriesIds,
				userId: invitation.userId
			})
		);
		await dispatch(invitationOnRefreshAction());
		dispatch(invitationCloseCopyModalAction());
	};
}
