import { AppThunk, RootState } from '../../../app/store/store';
import { Invitation } from '../../../app/domain/Invitation';

interface OpenRemoveModal {
  type: 'INVITATION_OPEN_REMOVE_MODAL';
  invitationId: number;
  onRefresh: () => void;
}

interface CloseRemoveModal {
  type: 'INVITATION_CLOSE_REMOVE_MODAL';
}

interface OpenInfoModal {
  type: 'INVITATION_OPEN_INFO_MODAL';
  invitationLink: string;
  onRefresh: () => void;
}

interface CloseInfoModal {
  type: 'INVITATION_CLOSE_INFO_MODAL';
}

interface OpenSentModal {
  type: 'INVITATION_OPEN_SENT_MODAL';
  invitationId: number;
  onRefresh: () => void;
}

interface CloseSentModal {
  type: 'INVITATION_CLOSE_SENT_MODAL';
}

interface OpenCopyModal {
  type: 'INVITATION_OPEN_COPY_MODAL';
  invitation: Invitation;
  onRefresh: () => void;
}

interface CloseCopyModal {
  type: 'INVITATION_CLOSE_COPY_MODAL';
}

interface OpenCreateModal {
  type: 'INVITATION_OPEN_CREATE_MODAL';
}

interface CloseCreateModal {
  type: 'INVITATION_CLOSE_CREATE_MODAL';
}

export type InvitationActionsStateTypes=
    OpenCopyModal |
    CloseCopyModal |
    OpenInfoModal |
    CloseInfoModal |
    OpenSentModal |
    CloseSentModal |
    OpenRemoveModal |
    CloseRemoveModal |
    OpenCreateModal |
    CloseCreateModal;

export function invitationOpenRemoveModalAction(invitationId: number, onRefresh: () => void): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_OPEN_REMOVE_MODAL',
    invitationId,
    onRefresh
  };
}

export function invitationCloseRemoveModalAction(): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_CLOSE_REMOVE_MODAL'
  };
}

export function invitationOpenInfoModalAction(invitationLink: string, onRefresh: () => void): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_OPEN_INFO_MODAL',
    invitationLink,
    onRefresh
  };
}

export function invitationCloseInfoModalAction(): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_CLOSE_INFO_MODAL'
  };
}

export function invitationOpenSentModalAction(invitationId: number, onRefresh: () => void): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_OPEN_SENT_MODAL',
    invitationId,
    onRefresh
  };
}

export function invitationCloseSentModalAction(): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_CLOSE_SENT_MODAL'
  };
}

export function invitationOpenCopyModalAction(invitation: Invitation, onRefresh: () => void): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_OPEN_COPY_MODAL',
    invitation,
    onRefresh
  };
}

export function invitationCloseCopyModalAction(): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_CLOSE_COPY_MODAL'
  };
}

export function invitationOpenCreateModalAction(): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_OPEN_CREATE_MODAL',
  };
}

export function invitationCloseCreateModalAction(): InvitationActionsStateTypes {
  return {
    type: 'INVITATION_CLOSE_CREATE_MODAL',
  };
}

export function invitationOnRefreshAction(): AppThunk {
	return ((_, getState: () => RootState) => {
    const { onRefresh } = getState().invitations.actions;
    onRefresh();
  });
}
