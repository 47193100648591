import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { RootState } from 'app/store/store';
import { connect } from 'react-redux';
import { Sort } from 'app/domain/Sort';
import { useLocalQuery } from 'app/utils/searchManager';
import { fetchVideoBreakdownData, Filter } from 'app/stats/statsManager';
import dayjs from 'dayjs';
import TableSortHeader from 'app/components/table/TableSortHeader';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';
import { sortVideos, VideosSortField } from 'app/stats/statsSort';

interface StateProps {
	filter: Filter;
	sort: Sort<VideosSortField>;
}

type Props = StateProps ;

const VideoStatisticsTable = ({ filter }: Props) => {
    const [videos] = useLocalQuery(() => fetchVideoBreakdownData(filter), [filter]);
    const [sortedVideos, setSortedVideos] = useState<typeof videos>();
    const [sort, setActualSort] = useState<Sort<VideosSortField>>({
        direction: 'desc',
        field: 'directEarnings'
    });

    const setSort = (field: VideosSortField, { direction }: Sort<VideosSortField>) =>
      setActualSort({ field, direction });

    const toDateFormat = (date?: string): string => (date ? dayjs(date).format('D-MMM-YY') : '');

    useEffect(() => {
        setSortedVideos(videos ? sortVideos(videos, sort) : undefined);
    }, [videos, sort]);

    return (
      <>
          {!sortedVideos ? (
            <LoadingOverlay />
          ) : (
            <Paper className="statsTable" elevation={3}>
                <Typography className="statsTable__name" variant="h6">
                    Videos & Live Events
                </Typography>

                <Table>
                    <TableHead >
                        <TableRow className="statsTable__header">
                            <TableSortHeader label="Id" fieldName="uid" sort={sort} setSort={setSort} />
                            <TableSortHeader label="Video Title" fieldName="name" sort={sort} setSort={setSort} />
                            <TableSortHeader
                              label="Purchases"
                              fieldName="purchases"
                              sort={sort}
                              setSort={setSort}
                            />
                            <TableSortHeader
                              label="Direct earnings"
                              fieldName="directEarnings"
                              sort={sort}
                              setSort={setSort}
                            />
                            <TableSortHeader
                              label="Upload date"
                              fieldName="uploadDate"
                              sort={sort}
                              setSort={setSort}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedVideos.map(video => (
                          <TableRow key={video.uid}>
                              <TableCell size='medium'>{video.uid}</TableCell>
                              <TableCell>{video.name}</TableCell>
                              <TableCell>{video.purchases}</TableCell>
                              <TableCell>{`£${video.directEarnings.toFixed(2)}`}</TableCell>
                              <TableCell>{toDateFormat(video.uploadDate)}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
          )}
      </>
    );
}

const mapStateToProps = (state: RootState) => ({
    filter: state.report.filters,
    sort: state.report.sort
});

export default connect(mapStateToProps, {})(VideoStatisticsTable);


