import axios, {AxiosInstance} from "axios";
import {getConfig} from "config/appConfig";
import axiosRetry from "axios-retry";

export const endpointsClient: AxiosInstance = axios.create({
    baseURL: getConfig().ENDPOINTS_API,
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

axiosRetry(endpointsClient, { retries: 3, retryDelay: (retryCount: number) => retryCount * 500 });
