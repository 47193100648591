import { RouteProps } from 'react-router';
import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import RoutePaths from './RoutePaths';
import { RootState } from '../store/store';
import { connect } from 'react-redux';

interface StateProps {
  isAuthenticate: boolean;
}

type Props = StateProps & RouteProps;

const UnauthenticatedRoute = (props: Props): ReactElement => {
  const { isAuthenticate } = props;

  if (!isAuthenticate) {
    return <Route {...props} />;
  } else {
    const redirect = () => <Redirect to={{ pathname: RoutePaths.Default, state: { from: props.location } }} />;
    return <Route {...props} component={redirect} render={undefined} />;
  }
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticate: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {})(UnauthenticatedRoute);
