import { AppThunk, AppThunkDispatch } from 'app/store/store';
import { makeRequest } from 'app/http/request';
import { createSuccessResult } from 'app/utils/result';
import { DELETE } from 'app/http/mediaApi/mediaClient';
import { videoCloseRemoveModalAction, videoOnRefreshAction } from 'videos/actions/store/VideoActionsActions';

const removeVideoApi = async (dispatch: AppThunkDispatch, videoId: string) =>
	makeRequest({
		dispatch,
		request: async () => {
			return await DELETE<void>(`admin/asset/${videoId}`);
		},
		responseSuccessHandler: () => createSuccessResult<void, string>()
	});

export function removeVideoUseCase(videoId: string): AppThunk {
	return async dispatch => {
		await removeVideoApi(dispatch, videoId);
		await dispatch(videoOnRefreshAction());
		dispatch(videoCloseRemoveModalAction());
	};
}
