export enum Region {
    Europe = 1,
    NorthAmerica,
    CentralAmerica,
    SouthAmerica,
    Africa,
    MiddleEast,
    Asia,
    Oceania,
    Caribbean,
    Antarctica
}

export const REGION_NAME = [
    'Invalid region',
    'Europe',
    'North America',
    'Central America',
    'South America',
    'Africa',
    'Middle East',
    'Asia',
    'Oceania',
    'Caribbean',
    'Antarctica'
];

export const REGION_ORDER = [
    Region.Europe,
    Region.NorthAmerica,
    Region.CentralAmerica,
    Region.SouthAmerica,
    Region.Africa,
    Region.MiddleEast,
    Region.Asia,
    Region.Oceania,
    Region.Caribbean
];

export const COUNTRY_REGION: { [countryCode: string]: Region } = {
    'AF': Region.Asia,
    'AL': Region.Europe,
    'DZ': Region.Africa,
    'AS': Region.Oceania,
    'AD': Region.Europe,
    'AO': Region.Africa,
    'AI': Region.Caribbean,
    'AQ': Region.Antarctica,
    'AG': Region.Caribbean,
    'AR': Region.SouthAmerica,
    'AM': Region.Europe,
    'AW': Region.Caribbean,
    'AU': Region.Oceania,
    'AT': Region.Europe,
    'AZ': Region.Europe,
    'BS': Region.Caribbean,
    'BH': Region.MiddleEast,
    'BD': Region.Asia,
    'BB': Region.Caribbean,
    'BY': Region.Europe,
    'BE': Region.Europe,
    'BZ': Region.CentralAmerica,
    'BJ': Region.Africa,
    'BM': Region.Caribbean,
    'BT': Region.Asia,
    'BO': Region.SouthAmerica,
    'BQ': Region.Caribbean,
    'BA': Region.Europe,
    'BW': Region.Africa,
    'BV': Region.Antarctica,
    'BR': Region.SouthAmerica,
    'IO': Region.Asia,
    'BN': Region.Asia,
    'BG': Region.Europe,
    'BF': Region.Africa,
    'BI': Region.Africa,
    'CV': Region.Africa,
    'KH': Region.Asia,
    'CM': Region.Africa,
    'CA': Region.NorthAmerica,
    'KY': Region.Caribbean,
    'CF': Region.Africa,
    'TD': Region.Africa,
    'CL': Region.SouthAmerica,
    'CN': Region.Asia,
    'CX': Region.Asia,
    'CC': Region.Asia,
    'CO': Region.SouthAmerica,
    'KM': Region.Africa,
    'CD': Region.Africa,
    'CG': Region.Africa,
    'CK': Region.Oceania,
    'CR': Region.CentralAmerica,
    'HR': Region.Europe,
    'CU': Region.Caribbean,
    'CW': Region.Caribbean,
    'CY': Region.Europe,
    'CZ': Region.Europe,
    'CI': Region.Africa,
    'DK': Region.Europe,
    'DJ': Region.Africa,
    'DM': Region.Caribbean,
    'DO': Region.Caribbean,
    'EC': Region.SouthAmerica,
    'EG': Region.MiddleEast,
    'SV': Region.CentralAmerica,
    'GQ': Region.Africa,
    'ER': Region.Africa,
    'EE': Region.Europe,
    'SZ': Region.Africa,
    'ET': Region.Africa,
    'FK': Region.SouthAmerica,
    'FO': Region.Europe,
    'FJ': Region.Oceania,
    'FI': Region.Europe,
    'FR': Region.Europe,
    'GF': Region.SouthAmerica,
    'PF': Region.Oceania,
    'TF': Region.Antarctica,
    'GA': Region.Africa,
    'GM': Region.Africa,
    'GE': Region.Europe,
    'DE': Region.Europe,
    'GH': Region.Africa,
    'GI': Region.Europe,
    'GR': Region.Europe,
    'GL': Region.Europe,
    'GD': Region.Caribbean,
    'GP': Region.Caribbean,
    'GU': Region.Asia,
    'GT': Region.CentralAmerica,
    'GG': Region.Europe,
    'GN': Region.Africa,
    'GW': Region.Africa,
    'GY': Region.SouthAmerica,
    'HT': Region.Caribbean,
    'HM': Region.Antarctica,
    'VA': Region.Europe,
    'HN': Region.CentralAmerica,
    'HK': Region.Asia,
    'HU': Region.Europe,
    'IS': Region.Europe,
    'IN': Region.Asia,
    'ID': Region.Asia,
    'IR': Region.MiddleEast,
    'IQ': Region.MiddleEast,
    'IE': Region.Europe,
    'IM': Region.Europe,
    'IL': Region.Europe,
    'IT': Region.Europe,
    'JM': Region.Caribbean,
    'JP': Region.Asia,
    'JE': Region.Europe,
    'JO': Region.MiddleEast,
    'KZ': Region.Asia,
    'KE': Region.Africa,
    'KI': Region.Oceania,
    'KP': Region.Asia,
    'KR': Region.Asia,
    'KW': Region.MiddleEast,
    'KG': Region.Asia,
    'LA': Region.Asia,
    'LV': Region.Europe,
    'LB': Region.MiddleEast,
    'LS': Region.Africa,
    'LR': Region.Africa,
    'LY': Region.Africa,
    'LI': Region.Europe,
    'LT': Region.Europe,
    'LU': Region.Europe,
    'MO': Region.Asia,
    'MG': Region.Africa,
    'MW': Region.Africa,
    'MY': Region.Asia,
    'MV': Region.Asia,
    'ML': Region.Africa,
    'MT': Region.Europe,
    'MH': Region.Oceania,
    'MQ': Region.Caribbean,
    'MR': Region.Africa,
    'MU': Region.Africa,
    'YT': Region.Africa,
    'MX': Region.CentralAmerica,
    'FM': Region.Oceania,
    'MD': Region.Europe,
    'MC': Region.Europe,
    'MN': Region.Asia,
    'ME': Region.Europe,
    'MS': Region.Caribbean,
    'MA': Region.Africa,
    'MZ': Region.Africa,
    'MM': Region.Asia,
    'NA': Region.Africa,
    'NR': Region.Oceania,
    'NP': Region.Asia,
    'NL': Region.Europe,
    'NC': Region.Oceania,
    'NZ': Region.Oceania,
    'NI': Region.CentralAmerica,
    'NE': Region.Africa,
    'NG': Region.Africa,
    'NU': Region.Oceania,
    'NF': Region.Oceania,
    'MP': Region.Asia,
    'NO': Region.Europe,
    'OM': Region.MiddleEast,
    'PK': Region.Asia,
    'PW': Region.Oceania,
    'PS': Region.MiddleEast,
    'PA': Region.CentralAmerica,
    'PG': Region.Oceania,
    'PY': Region.SouthAmerica,
    'PE': Region.SouthAmerica,
    'PH': Region.Asia,
    'PN': Region.Oceania,
    'PL': Region.Europe,
    'PT': Region.Europe,
    'PR': Region.Caribbean,
    'QA': Region.MiddleEast,
    'MK': Region.Europe,
    'RO': Region.Europe,
    'RU': Region.Europe,
    'RW': Region.Africa,
    'RE': Region.Africa,
    'BL': Region.Caribbean,
    'SH': Region.Africa,
    'KN': Region.Caribbean,
    'LC': Region.Caribbean,
    'MF': Region.Caribbean,
    'PM': Region.NorthAmerica,
    'VC': Region.Caribbean,
    'WS': Region.Oceania,
    'SM': Region.Europe,
    'ST': Region.Africa,
    'SA': Region.MiddleEast,
    'SN': Region.Africa,
    'RS': Region.Europe,
    'SC': Region.Africa,
    'SL': Region.Africa,
    'SG': Region.Asia,
    'SX': Region.Caribbean,
    'SK': Region.Europe,
    'SI': Region.Europe,
    'SB': Region.Oceania,
    'SO': Region.Africa,
    'ZA': Region.Africa,
    'GS': Region.SouthAmerica,
    'SS': Region.Africa,
    'ES': Region.Europe,
    'LK': Region.Asia,
    'SD': Region.Africa,
    'SR': Region.SouthAmerica,
    'SJ': Region.Europe,
    'SE': Region.Europe,
    'CH': Region.Europe,
    'SY': Region.MiddleEast,
    'TW': Region.Asia,
    'TJ': Region.Asia,
    'TZ': Region.Africa,
    'TH': Region.Asia,
    'TL': Region.Asia,
    'TG': Region.Africa,
    'TK': Region.Oceania,
    'TO': Region.Oceania,
    'TT': Region.Caribbean,
    'TN': Region.Africa,
    'TR': Region.Europe,
    'TM': Region.Asia,
    'TC': Region.Caribbean,
    'TV': Region.Oceania,
    'UG': Region.Africa,
    'UA': Region.Europe,
    'AE': Region.MiddleEast,
    'GB': Region.Europe,
    'UM': Region.NorthAmerica,
    'US': Region.NorthAmerica,
    'UY': Region.SouthAmerica,
    'UZ': Region.Asia,
    'VU': Region.Oceania,
    'VE': Region.SouthAmerica,
    'VN': Region.Asia,
    'VG': Region.Caribbean,
    'VI': Region.Caribbean,
    'WF': Region.Oceania,
    'EH': Region.Africa,
    'YE': Region.MiddleEast,
    'ZM': Region.Africa,
    'ZW': Region.Africa,
    'AX': Region.Europe
};
