import React from 'react';
import { Table } from '@mui/material';
import CurrencyCountriesTableHeader from './CurrencyCountriesTableHeader';
import CurrencyCountriesTableBody from './CurrencyCountriesTableBody';

const CurrencyCountriesTable = () => {

    return (
        <Table className="table" size="small" padding="checkbox" stickyHeader>
            <CurrencyCountriesTableHeader/>
            <CurrencyCountriesTableBody />
        </Table>
    );
};

export default CurrencyCountriesTable;
