import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { FansDataFormValues } from './FansDataToggleSection';
import { useFeatureFlagsValue } from 'app/hooks/useFeatureFlagsValue';
import { FEATURE_FLAGS } from 'app/featureFlags/featureFlags';

interface FansDataModalProps {
	isDataProfileEnabled: boolean;
	isAnonymisedDataEnabled: boolean;
	isOpen: boolean;
	onSave: (value: FansDataFormValues) => void;
	onClose: () => void;
}

const FansDataModal = ({
	isDataProfileEnabled,
	isAnonymisedDataEnabled,
	isOpen,
	onSave,
	onClose
}: FansDataModalProps) => {
	const [isDataDownloadEnabled, isAnonymisedDataConfigEnabled] = useFeatureFlagsValue([
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_DATA_DOWNLOAD,
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_ANON_DATA
	]);

	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<Box>
				<DialogTitle>
					<strong>Edit Fans Data access</strong>
				</DialogTitle>
				<Formik
					enableReinitialize
					initialValues={{
						dataProfileEnabled: isDataProfileEnabled,
						anonymisedDataEnabled: isAnonymisedDataEnabled
					}}
					onSubmit={values => {
						onSave(values);
						onClose();
					}}
				>
					{({ dirty, isValid, values, setFieldValue }) => (
						<Form>
							<DialogContent>
								{isAnonymisedDataConfigEnabled && (
									<Box sx={{ paddingBottom: isDataDownloadEnabled ? '30px' : 'inherit' }}>
										<div className="helpText">
											Allow all channel admins to see anonymised fans data.
										</div>
										<div className="horizontalAlign">
											<FormControlLabel
												name="isAnonymisedDataEnabled"
												className="fanDataSwitch"
												onChange={() =>
													setFieldValue(
														'anonymisedDataEnabled',
														!values.anonymisedDataEnabled
													)
												}
												control={<Switch checked={values.anonymisedDataEnabled} />}
												label={
													<span className="additionalFormText">
														Enable Anonymised Fans Data
													</span>
												}
											/>
										</div>
									</Box>
								)}
								{isDataDownloadEnabled && (
									<Box>
										<div className="helpText">
											Allow fans to share their data profile with the channel and allow certain
											channel admins to download Fans Data. To grant permission for channel admins
											go to Admins tab.
										</div>
										<div className="horizontalAlign">
											<FormControlLabel
												name="isDataProfileEnabled"
												className="fanDataSwitch"
												onChange={() =>
													setFieldValue('dataProfileEnabled', !values.dataProfileEnabled)
												}
												control={<Switch checked={values.dataProfileEnabled} />}
												label={
													<span className="additionalFormText">
														Enable Fans Data Download
													</span>
												}
											/>
										</div>
									</Box>
								)}
							</DialogContent>
							<DialogActions>
								<Button onClick={onClose} variant="text" color="primary">
									Cancel
								</Button>
								<Button
									type="submit"
									color="secondary"
									variant="contained"
									disabled={!dirty || !isValid}
								>
									Confirm & Save
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Box>
		</Dialog>
	);
};

export default FansDataModal;
