import { SET_IS_AUTHENTICATE, UserActionTypes } from './actions';
import { getAuthAccessToken } from 'app/http/authApi/token';

export interface UserState {
	isAuthenticated: boolean;
}

const initialState: UserState = {
	isAuthenticated: !!getAuthAccessToken()
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
	if (action.type === SET_IS_AUTHENTICATE) {
		return { ...state, isAuthenticated: action.isAuthenticate };
	} else {
		return state;
	}
}
