import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { AssignmentTypes, VideoCategory } from 'app/domain/VideoCategory';
import { useAllCategories } from 'categories/categoriesUseCase';

const defaultValues: VideoCategory = {
	categoryId: 0,
	strength: 0,
	assignmentType: 'RECAST_SELECTION',
};

interface Props {
	isOpen: boolean;
	onSubmit: (v: VideoCategory)=>void;
	onClose: ()=>void;
}

const AddVideoCategoryModal = ({ onSubmit, isOpen, onClose }: Props) => {
	const { categories } = useAllCategories();
	const [ category, setCategory ] = useState(defaultValues);

	useEffect(()=>setCategory(defaultValues), [isOpen]);

	const submit = () => {
		const { categoryId, assignmentType } = category;
		if (categoryId && assignmentType) {
			onSubmit(category);
			onClose();
		}
	}

	return (
		<Dialog className="dialog" maxWidth="sm" open={isOpen} onClose={onClose}>
			<DialogTitle>Add new video category</DialogTitle>
			<DialogContent>
				<Autocomplete 
					renderInput={(params) => (
						<TextField {...params} label="Category" variant="standard" />
					)}
					options={categories || []}
					getOptionLabel={c=>c.name}
					//groupBy={c => "" + c.parentId}
					onChange={(_, c)=>c && setCategory({...category, categoryId: c.id})}
					clearOnBlur
					value={categories?.find(c=>c.id === category.categoryId) || undefined}
				/>
				<br/>
				<Autocomplete 
					renderInput={(params) => (
						<TextField {...params} label="Assignment type" variant="standard" />
					)}
					options={AssignmentTypes}
					getOptionLabel={c=>c[1]}
					//groupBy={c => "" + c.parentId}
					onChange={(_, c)=>c && setCategory({...category, assignmentType: c[0]})}
					clearOnBlur
					value={AssignmentTypes.find(c=>c[0] === category.assignmentType) || undefined}
				/>
				<br/>
				<TextField
					className="formField"
					label="Strength"
					fullWidth
					value={category.strength || 0}
					inputProps={{ inputMode: 'numeric' }}
					onChange={(c)=>{setCategory({...category,  strength: parseInt(c.target.value)})}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
				<Button onClick={submit} 
					variant="contained" color="secondary" autoFocus
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddVideoCategoryModal;
