import { AppThunk } from '../app/store/store';
import { fetchPublisherUsersApi } from './api/UsersStatisticsApi';
import { reportSetEndDate, reportSetStartDate } from './store/ReportsActions';
import { prepareInitialEndDate, prepareInitialStartDate } from './store/ReportReducer';
import { createPublisherUsersParams } from './api/utils/reportParamsUtils';
import { createVerifiedUsersData } from './api/utils/reportChartDataResponseUtils';
import { User } from '../app/domain/User';
import { GET_PARTNER } from '../app/http/partnerApi/partnerClient';
import { ChannelDetails } from '../app/domain/ChannelDetails';

interface ChannelNamesResponse {
	channels: ChannelDetails[];
}

export async function fetchChannelNames() {
    const response = await GET_PARTNER<ChannelNamesResponse>('/admin/channels/names');
    return response.data.data.channels instanceof Array ? response.data.data.channels as ChannelDetails[] : undefined;
}

export function filtersClear(): AppThunk {
    return async (dispatch) => {
        dispatch(reportSetStartDate(prepareInitialStartDate()));
        dispatch(reportSetEndDate(prepareInitialEndDate()));
    }
}

export const fetchAllChannels = async (): Promise<User[]> => {
    const params = createPublisherUsersParams();
    const data = await fetchPublisherUsersApi(params);
    return createVerifiedUsersData(data);
};

export const convertUnitGBPToGBP = (unit: number): number => {
    return unit / 1000000;
};
