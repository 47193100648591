import { FilterValue } from "./FilterUseCase";
import React from "react";
import FilterItem from "./FilterItem";
import { Box } from '@mui/material';

interface FilterGroupProps {
  currentValues: FilterValue[];
  allFilters: FilterValue[];
  setCurrentValues: (currValues: FilterValue[]) => void;
}

const FilterGroup = (props: FilterGroupProps) => {
  const { currentValues, allFilters, setCurrentValues } = props;

  return (
    <Box className="filterGroup">
      {
        allFilters.map(filter => <FilterItem key={filter.name} value={filter} values={currentValues} setValues={setCurrentValues}/>)
      }
    </Box>
  )
};

export default FilterGroup;
