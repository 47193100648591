import React from "react";
import {
  createEmpty,
  validateUserEditReferrer
} from "./UserEditReferrerUseCase";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { UserEditReferrerData } from "../UserEditApi";

interface UserEditReferrerModalProps {
  save: (referrerUid: string) => void;
  onClose: () => void;
}

const UserEditReferrerModal = (props: UserEditReferrerModalProps) => {
	const { onClose, save } = props;
  const initialValues = createEmpty();

  return (
    <Dialog
      className="editUserDialog"
      open={true}
      onClose={onClose}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateUserEditReferrer}
        onSubmit={(values: UserEditReferrerData) => save(values.referrerUid)}
      >
        <Form>
          <DialogTitle>Add Referrer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Remember you can only add a new referrer, not edit an existing one!
            </DialogContentText>
            <Field className="formField" label="Referrer Uid" fullWidth name="referrerUid" variant="outlined" component={TextField} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UserEditReferrerModal;
