import { CdnDrmInfo, DrmInfo } from 'videos/details/sections/summary/player/playerDrmManager';

export interface StatusMessage {
	type: 'ERROR' | 'INFO';
	text: string;
}

export interface Video {
	uid: string;
	name: string;
	created: string;
	cost: Cost | number;
	likeCount: number;
	thumbnail: Thumbnail;
	isLive: boolean;
	user: User;
	description: string | null;
	statusId: VideoStatus;
	status: VideoStatus;
	recasterPct: number;
	url: string;
	fullUrl: string;
	fullUrlDash?: string;
	hasFeatureRules: boolean;
	contributorPcts: ContributorPcts[];
	recasts: number;
	startDate: string;
	stopDate: string;
	locations: string[];
	stream: {
		inputUrl: string;
		outputUrl: string;
		outputUrlDash: string;
		status: string;
		isUsingRecastLive: boolean;
		height?: number;
	};
	unpublishTime: string;
	drm?: DrmInfo;
	cdns?: CdnInfo[];
	statusMessages?: StatusMessage[];
	stats?: {
		uid: string;
		directEarnings: number;
		purchases: number;
		views: number;
		paid: number;
		fanRecasterEarnings: number;
		publisherRecasterEarnings: number;
	};
}

export interface CdnInfo {
	endpoints: {
		hls: string;
		dash: string;
	};
	security: string;
	drm?: CdnDrmInfo;
}

export type VideoSortField = 'name' | 'created' | 'uid';

export enum VideoStatus {
	NEW = 0,
	READY = 1,
	PUBLISHED = 2,
	SUSPENDED = 3,
	DELETED = 4,
	MARKED_FOR_DELETION = 5,
	FAILED = 9
}

export const videoStatusNames = {
	0: 'new',
	1: 'ready',
	2: 'published',
	3: 'suspended',
	4: 'deleted',
	5: 'marked for deletion',
	9: 'failed'
};

export function isSuspended(status: VideoStatus): boolean {
	return status === VideoStatus.SUSPENDED;
}

export function isPublished(status: VideoStatus): boolean {
	return status === VideoStatus.PUBLISHED;
}

export function getVideoPublishedStatusIds(): number[] {
	return [VideoStatus.PUBLISHED];
}

export function getVideoUnpublishedStatusIds(): number[] {
	return [
		VideoStatus.NEW,
		VideoStatus.READY,
		VideoStatus.SUSPENDED,
		VideoStatus.DELETED,
		VideoStatus.MARKED_FOR_DELETION,
		VideoStatus.FAILED
	];
}

export const getReadyToPublishVideos = (videos: Video[]): Video[] => {
	return videos.filter(video => !!video.name && video.cost !== -1);
};

export interface Cost {
	units: number;
	currency: 'CST';
}

export interface Thumbnail {
	small: string;
	large: string;
}

interface User {
	uid: string;
	username: string;
	profileImage: string;
}

export interface ContributorPcts {
	pct: number;
	userUid: string;
}

export interface VideoContributor {
	username: string;
	contributorPct: ContributorPcts;
}
