import { Partner } from 'app/domain/Partner';
import { PUT_PARTNER } from '../../../app/http/partnerApi/partnerClient';
import { PartnerDetails } from '../edit/EditPartnerModal';

export const setPartnerStatusApi = (uid: string, status: string) =>
  PUT_PARTNER<any>(`/admin/partner/${uid}/status`, { status: status }).then(r=>r.data.data);

export const suspendPartnerApi = (uid: string) =>
  setPartnerStatusApi(uid, 'SUSPENDED_BY_ADMIN');

export const unsuspendPartnerApi = (uid: string) =>
  setPartnerStatusApi(uid, 'LIVE');

export const removePartnerApi = (uid: string) =>
  setPartnerStatusApi(uid, 'DELETED');

export const editPartnerApi = (uid: string, partner: PartnerDetails) =>
  PUT_PARTNER<any>(`/admin/partner/${uid}`, prepareEditPartnerData(partner)).then(r=>r.data.data);
  
export const createPartnerEditData = (partner: Partner) => ({
  name: partner.name ?? '',
  companyNumber: partner.companyNumber ?? '',
  email: partner.email ?? '',
  phone: partner.phone ?? '',
  countryCode: partner.countryCode ?? '',
  stateCode: partner.stateCode ?? '',
  postalCode: partner.postalCode ?? '',
  addressLines: partner.addressLines ?? '',
  secondAddressLine: partner.secondAddressLine ?? '',
  position: partner.position ?? '',
  vatNumber: partner.vatNumber ?? ''
});

function prepareEditPartnerData(userEditProfileData: PartnerDetails) {
  return {
    name: userEditProfileData.name || undefined,
    companyNumber: userEditProfileData.companyNumber || undefined,
    vatNumber: userEditProfileData.vatNumber || undefined,
    countryCode: userEditProfileData.countryCode || undefined,
    email: userEditProfileData.email || undefined,
    postalCode: userEditProfileData.postalCode || undefined,
    addressLines: userEditProfileData.addressLines || undefined,
    secondAddressLine: userEditProfileData.secondAddressLine || undefined,
    position: userEditProfileData.position || undefined,
    stateCode: userEditProfileData.stateCode || undefined,
    phone: userEditProfileData.phone || undefined
  };
}
  