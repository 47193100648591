import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Grid } from '@mui/material';
import { dateFormat } from 'app/store/common/Constants';
import { FetchVideoData } from '../fetchVideosApi';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface Props {
	criteria: FetchVideoData;
	changeCriteria: (criteria: FetchVideoData) => void;
}

const DateFilter = (props: Props) => {
	const { criteria, changeCriteria } = props;
	const { endDate, startDate } = criteria;

	const [hasStartDate, setHasStartDate] = useState(startDate);
	const [hasEndDate, setHasEndDate] = useState(endDate);

	const handleStartDateChange = (date: Dayjs | null) => {
		if (date !== null) {
			const startDate = date.toDate().toISOString();
			setHasStartDate(startDate);
			changeCriteria({ ...criteria, startDate })
		}
	};

	const handleEndDateChange = (date: Dayjs | null) => {
		if (date !== null) {
			const endDate = date.toDate().toISOString();
			setHasEndDate(endDate);
			changeCriteria({ ...criteria, endDate })
		}
	};

	return (
		<div className="filterDate">
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<DatePicker
							format={dateFormat}
							label="Start date"
							maxDate={dayjs(hasEndDate)}
							value={dayjs(hasStartDate)}
							onChange={handleStartDateChange}
							slotProps={{ textField: { variant: 'standard' } } }
						/>
					</Grid>
					<Grid item xs={3}>
						<DatePicker
							format={dateFormat}
							label="End date"
							minDate={dayjs(hasStartDate)}
							value={dayjs(hasEndDate)}
							onChange={handleEndDateChange}
							slotProps={{ textField: { variant: 'standard' } } }
						/>
					</Grid>
				</Grid>
			</LocalizationProvider>
		</div>
	);
};

export default DateFilter;
