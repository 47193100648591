import React, { useState } from 'react';
import { Box, Container, Divider, Grid, Tab, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Title from 'app/components/title/Title';
import { PartnerAccounts } from 'app/domain/PartnerAccount';
import { renderGBP } from 'accounts/AmountDisplay';
import AccountDetailsScreen from 'accounts/AccountDetailsScreen';

enum WalletTabs {
	PUBLISHER_TAB = 'publisher',
	ADVERTISER_TAB = 'advertiser'
}

interface Props {
	accounts: PartnerAccounts;
}

const WalletsScreen = ({ accounts }: Props) => {
	const [activeTab, setActiveTab] = useState(WalletTabs.PUBLISHER_TAB);
	const { publisher, advertiser } = accounts;

	const onTabChange = (_: React.ChangeEvent<unknown>, newValue: WalletTabs) => {
		setActiveTab(newValue);
	};

	return (
		<Container className="userDetailsScreen">
			<Grid container direction="row" alignItems="center">
				<Grid item xs={12} sm={6}>
					<Title>Wallets:</Title>
				</Grid>
			</Grid>

			<Divider />

			{!!publisher || !!advertiser ? (
				<Box className="section" component="div">
					<Grid container>
						<Grid item xs={12} md={6}>
							<div className="sectionHeader">
								<span className="sectionTitle">Publisher Info:</span>
							</div>
							<Grid container alignItems="center">
								<Grid item>
									<div className="sectionRow">
										<strong>Id: </strong>
										{publisher.id}
									</div>
									<div className="sectionRow">
										<strong>Balance: </strong>
										{renderGBP(publisher.balance)}
									</div>
									<div className="sectionRow">
										<strong>Type: </strong>
										{publisher.accountType}
									</div>
									<div className="sectionRow">
										<strong>Description: </strong>
										{publisher.description ? publisher.description : '-'}
									</div>
									<div className="sectionRow">
										<strong>Negative balance: </strong>
										{publisher.negativeBalance ? (
											<CheckIcon className="true" />
										) : (
											<ClearIcon className="false" />
										)}
									</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<div className="sectionHeader">
								<span className="sectionTitle">Advertiser Info:</span>
							</div>
							<Grid container alignItems="center">
								<Grid item>
									<div className="sectionRow">
										<strong>Id: </strong>
										{advertiser.id}
									</div>
									<div className="sectionRow">
										<strong>Balance: </strong>
										{renderGBP(advertiser.balance)}
									</div>
									<div className="sectionRow">
										<strong>Type: </strong>
										{advertiser.accountType}
									</div>
									<div className="sectionRow">
										<strong>Description: </strong>
										{advertiser.description ? advertiser.description : ' -'}
									</div>
									<div className="sectionRow">
										<strong>Negative balance: </strong>
										{advertiser.negativeBalance ? (
											<CheckIcon className="true" />
										) : (
											<ClearIcon className="false" />
										)}
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<br />
					<Divider />
					<br />
					<TabContext value={activeTab}>
						<TabList
							centered
							className="tabs"
							indicatorColor="primary"
							textColor="primary"
							onChange={onTabChange}
						>
							<Tab label="Publisher" value={WalletTabs.PUBLISHER_TAB} />
							<Tab label="Advertiser" value={WalletTabs.ADVERTISER_TAB} />
						</TabList>
						<TabPanel value={WalletTabs.PUBLISHER_TAB}>
							{!publisher.id ? 'Loading...' : <AccountDetailsScreen id={publisher.id} />}
						</TabPanel>
						<TabPanel value={WalletTabs.ADVERTISER_TAB}>
							{!advertiser.id ? 'Loading...' : <AccountDetailsScreen id={advertiser.id} />}
						</TabPanel>
					</TabContext>
				</Box>
			) : (
				<Typography>Wallets were not created</Typography>
			)}
		</Container>
	);
};

export default WalletsScreen;
