import { Page } from '../domain/Page';

export interface PageResponse<T> {
	items: T[];
	_meta: PageInfo;
	href?: string;
}

interface PageInfo {
	totalCount: number;
	pageCount: number;
	currentPage: number;
	perPage: number;
}

export function toPage<T>(input: PageResponse<T>): Page<T> {
	return {
		items: input.items,
		pageCount: input._meta.pageCount,
		currPage: input._meta.currentPage,
		totalCount: input._meta.totalCount,
		perPage: input._meta.perPage
	};
}
