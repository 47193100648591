
export const isEmail = (value: string): string => {
  let error = '';
  if (!value) {
    error = 'Please insert an e-mail';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Please insert a valid e-mail address';
  }
  return error;
};

export const isMandatory = (value: string): string => {
  return value !== undefined && value.length > 0 ? '' : 'This field is mandatory';
};
