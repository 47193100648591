import React from 'react';
import { Container } from '@mui/material';
import Title from '../app/components/title/Title';
import CategoriesTree from './tree/CategoriesTree';

const CategoriesScreen = () => {
	return (
		<Container className="categoriesScreen">
			<Title>Categories</Title>
			<CategoriesTree />
		</Container>
	);
};

export default CategoriesScreen;
