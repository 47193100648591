import { PageResponse, toPage } from '../../app/http/PageResponse';
import { AllChannelStatuses, Channel } from '../../app/domain/Channel';
import { GET_PARTNER } from '../../app/http/partnerApi/partnerClient';
import { ChannelsQuery } from './filter/ChannelFilter';

export const fetchChannelsWithImagesApi = async (data: ChannelsQuery) =>
  GET_PARTNER<PageResponse<Channel>>(`/admin/channels`, { params: createApiParams(data) })
    .then(r=>toPage(r.data.data));

const createApiParams = (data: ChannelsQuery) => {
  const { page, perPage, statuses, sort, channelNameSearchText, emailSearchText, partnerNameSearchText, channelUidSearchText, channelUids } = data;

  return {
    page, perPage, channelNameSearchText, emailSearchText, partnerNameSearchText, channelUidSearchText,
    order: sort.field, 
    sort: sort.direction,
    status: statuses?.length ? statuses : AllChannelStatuses,
    ...(channelUids && {'channels.id' : channelUids.join(',')})
  };
};

