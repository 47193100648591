import React, { useEffect, useState } from 'react';
import { AppThunkDispatch } from '../../app/store/store';
import { fetchAllChannels, fetchChannelNames, filtersClear } from '../ReportUseCase';
import { connect } from 'react-redux';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { reportSetCheckedVideoTitleData, reportSetVerifiedUser } from '../store/ReportsActions';
import { ChannelDetails } from '../../app/domain/ChannelDetails';

interface DispatchProps {
    setVerifiedUser: (uid: string) => void;
    filterClear: () => void;
}

type Props = DispatchProps;

const ReportFilter = (props: Props) => {
    const {setVerifiedUser, filterClear} = props;

    const [channels, setChannels] = useState<ChannelDetails[]>([]);

    useEffect(() => {
        let isSubscribed = true
        fetchAllChannels().then( channels => {
            fetchChannelNames().then(channelNames => {
                if(isSubscribed && channelNames) {
                    const ids = channels.map(channel => channel.uid)
                    const channelsDetails = channelNames.filter(channel => ids.includes(channel.uid))
                    setChannels(channelsDetails)
                }
            })
        })
        setVerifiedUser('');
        filterClear();
        return () => { isSubscribed = false };
    }, [])

	const handlePick = (_: unknown, selectedChannel: ChannelDetails | null) => {
		setVerifiedUser( selectedChannel?.uid ?? '' );
        filterClear();
    }

    return (
        <div className="filterDate">
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <FormControl className="formControl">
                        <Autocomplete
                            options={channels}
                            onChange={handlePick}
                            style={{width: "60em"}}
                            getOptionLabel={(option) => (option ? option.name : "")}
                            renderInput={(params) => (
                                <TextField {...params} label="Channel" />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    setVerifiedUser: (uid: string) => {
        dispatch(reportSetVerifiedUser(uid));
    },
    filterClear: () => {
        dispatch(reportSetCheckedVideoTitleData([]));
        dispatch(filtersClear());
    }
});

export default connect(null, mapDispatchToProps)(ReportFilter);
