import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Dialog, DialogActions, Table, TableBody } from '@mui/material';
import Title from '../../../../../../app/components/title/Title';
import UsersFilter, { UsersQuery } from '../../../../../../users/list/filter/UsersFilter';
import UsersPagination from '../../../../../../users/list/table/UsersPagination';
import { fetchUsersApi } from 'users/list/UsersUseCase';
import { Page } from 'app/domain/Page';
import { User } from 'app/domain/User';
import AdminRow from './list/AdminRow';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  reload: () => void
  saveAdmin: (uid: string) => Promise<any>;
}

const AddChannelAdminModal = (props: Props) => {
  const { closeModal, isOpen, saveAdmin, reload } = props;

  const [ data, setData ] = useState<Page<User>>({
		currPage: 0, //page,
		items: [],
		perPage: 10,
		totalCount: 0,
		pageCount: 0,
	});

  const [ query, setQuery ] = useState<UsersQuery>({ page: 1 });

  const fetch = () => {
    console.log("fetching users", query);
    fetchUsersApi(query)
    .then(s=>{ console.log("response", s); return s; })
    .then(setData);
	}

  useEffect(()=>{
    setData({ ...data, currPage: 0, items: []});
    fetch();
  }, [query]);

  return (
      <Dialog
          className="dialog"
          fullWidth
          maxWidth="xl"
          open={isOpen}
          onClose={closeModal}>
        <Container className="usersScreen">
          <Title>Users</Title>
          <UsersFilter usersQuery={query} onChange={setQuery}/>
          <Table className="table" size="small" padding="checkbox" stickyHeader>
            <TableBody>
              {
                data.items.map((user) => (
                  <AdminRow key={user.uid} user={user} saveAdmin={uid=>saveAdmin(uid).then(reload)} />
                ))
              }
            </TableBody>
          </Table>
          <Box justifyContent="center" className="paginationContainer">
            <UsersPagination page={data} changePage={page=>setQuery({ ...query, page })}/>
          </Box>
        </Container>
        <DialogActions>
				<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default AddChannelAdminModal;
