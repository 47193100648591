import { DELETE_COMMENTS, PUT_COMMENTS } from './commentsClient';
import { HTTP_STATUS_CODES } from 'constants/Common';
import { createErrorResult, createSuccessResult } from 'app/utils/result';
import axios from 'axios';

type NodeBBPrivilege =
	| 'groups:find'
	| 'groups:read'
	| 'groups:topics:read'
	| 'groups:topics:create'
	| 'groups:topics:reply'
	| 'groups:topics:schedule'
	| 'groups:topics:tag'
	| 'groups:posts:edit'
	| 'groups:posts:history'
	| 'groups:posts:delete'
	| 'groups:posts:upvote'
	| 'groups:posts:downvote'
	| 'groups:topics:delete'
	| 'groups:posts:view_deleted'
	| 'groups:purge'
	| 'groups:moderate';

interface ChannelPrivilegesRequest {
	channelId: string;
	data: {
		privileges: NodeBBPrivilege[];
	};
}

export const removeChannelPrivileges = async ({ channelId, data }: ChannelPrivilegesRequest) => {
	try {
		const res = await DELETE_COMMENTS(`/private/comments/channel/${channelId}/privileges`, {
			data,
			'Content-Type': 'application/json'
		});
		return createSuccessResult(res.data);
	} catch (e) {
		if (axios.isAxiosError(e) && e.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
			return createErrorResult('Unauthorised');
		}

		throw e;
	}
};

export const updateChannelPrivileges = async ({ channelId, data }: ChannelPrivilegesRequest) => {
	try {
		const res = await PUT_COMMENTS(`/private/comments/channel/${channelId}/privileges`, data);
		return createSuccessResult(res.data);
	} catch (e) {
		if (axios.isAxiosError(e) && e.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
			return createErrorResult('Unauthorised');
		}

		throw e;
	}
};
