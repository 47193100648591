import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

import React from 'react';
import * as Yup from 'yup';

interface ChannelPassesModalProps {
	max: number;
	isOpen: boolean;
	onSave: (value: number) => void;
	onClose: () => void;
}

const CHANNEL_PASSES_MIN = 0;
const CHANNEL_PASSES_MAX = 5;

const validationMessage = `Channel passes number can range from ${CHANNEL_PASSES_MIN} to ${CHANNEL_PASSES_MAX}`;

const ValidationSchema = Yup.object().shape({
	max: Yup.number().max(CHANNEL_PASSES_MAX, validationMessage).min(CHANNEL_PASSES_MIN, validationMessage)
});

const ChannelPassesModal = ({ max, isOpen, onSave, onClose }: ChannelPassesModalProps) => {
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>Edit maximum number of Channel Passes</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{ max }}
				onSubmit={values => {
					onSave(values.max);
					onClose();
				}}
				validationSchema={ValidationSchema}
			>
				{({ dirty, isValid }) => (
					<Form>
						<DialogContent>
							<div className="helpText">
								The maximum number refers to the quantity of passes that can be viewed simultaneously on
								the channel page. This number can range from 0 to 5.
							</div>
							<Field
								className="formField"
								label="Max number"
								fullWidth
								name="max"
								variant="outlined"
								component={TextField}
								helperText=" "
								type="number"
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button type="submit" color="secondary" variant="contained" disabled={!dirty || !isValid}>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ChannelPassesModal;
