import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { APIResponse } from 'app/http/mediaApi/mediaClient';
import { getBaseApiUrl } from 'app/http/request';
import { getConfig } from 'config/appConfig';
import { addBearerToken } from 'app/http/authApi/token';

const clientCube: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});
clientCube.interceptors.request.use(addBearerToken, Promise.reject);

const getCubeApiUrl = (suffix?: string): string => getBaseApiUrl('cubejs-api/v1', suffix, getConfig().CUBEJS_BASE);

export const GET_CUBE = <U>(url: string, config?: AxiosRequestConfig) => {
	return clientCube.get<APIResponse<U>>(getCubeApiUrl(url), config);
};
