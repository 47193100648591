import React, { useEffect, useState } from 'react';
import { Container, Tab, Table, TableBody } from '@mui/material';
import Title from 'app/components/title/Title';
import ChannelsTableHeader from 'channels/list/table/ChannelsTableHeader';
import ChannelsFilter, { ChannelsQuery } from 'channels/list/filter/ChannelFilter';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Pagination from 'app/components/pagination/Pagination';
import PartnersScreen from 'partners/list/PartnersScreen';
import { useHistory, useLocation } from 'react-router-dom';
import InvitationsScreen from 'invitations/list/InvitationsScreen';
import { AllChannelStatuses, Channel } from 'app/domain/Channel';
import { useDebouncedAction } from 'app/utils/searchManager';
import { Page } from 'app/domain/Page';
import { fetchChannelsWithImagesApi } from './ChannelsUseCase';
import ChannelRow from './table/ChannelRow';
import './_channels.scss';

const tabValues = ['channels', 'invitations', 'publishers'];

const ChannelsScreen = () => {
	const location = useLocation();
	const history = useHistory();
	const hash = location.hash?.substring(1).split('/');
	const selectedTab = hash[0] && tabValues.includes(hash[0]) ? hash[0] : 'channels';

	const handleChange = (_: React.ChangeEvent<unknown>, newValue: string) => {
		history.replace({ ...location, hash: newValue });
	};

	//Todo: This should be pushed to history
	const [filter, setFilter] = useState<ChannelsQuery>({
		statuses: AllChannelStatuses,
		sort: { field: 'created', direction: 'desc' }
	});

	const [page, setPage] = useState<Page<Channel>>({
		currPage: filter.page || 1,
		items: [],
		perPage: 20,
		totalCount: 0,
		pageCount: 0
	});

	const fetch = useDebouncedAction(() => fetchChannelsWithImagesApi(filter).then(setPage));

	useEffect(() => {
		fetch();
	}, [filter]);

	return (
		<Container className="channelsScreen">
			<TabContext value={selectedTab}>
				<TabList centered className="tabs" indicatorColor="primary" textColor="primary" onChange={handleChange}>
					<Tab label="Channels" value="channels" />
					<Tab label="Invitations" value="invitations" />
					<Tab label="Publishers" value="publishers" />
				</TabList>
				<TabPanel value="invitations">
					<InvitationsScreen />
				</TabPanel>
				<TabPanel value="publishers">
					<PartnersScreen />
				</TabPanel>
				<TabPanel value="channels">
					<Title>Channels</Title>
					<ChannelsFilter filters={filter} setFilters={setFilter} />
					<Table className="table" size="small" padding="checkbox" stickyHeader>
						<ChannelsTableHeader filter={filter} setFilter={setFilter} />
						<TableBody>
							{page?.items.map(channel => (
								<ChannelRow key={channel.channelUid} channel={channel} onRefresh={fetch} />
							))}
						</TableBody>
					</Table>
					<Pagination page={page} changePage={page => setFilter({ ...filter, page })} />
				</TabPanel>
			</TabContext>
		</Container>
	);
};

export default ChannelsScreen;
