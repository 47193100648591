import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { addBearerToken, resetBearerTokenOnUnauthorized } from 'app/http/authApi/token';
import { getAppConfig } from 'config/appConfig';

export interface APIResponse<T> {
	item: any;
	success: boolean;
	data: T;
}

export interface AxiosRequestConfigProps extends AxiosRequestConfig {
	['Content-Type']: string;
}

const client: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});
client.interceptors.response.use(res => res, resetBearerTokenOnUnauthorized);
client.interceptors.request.use(addBearerToken, Promise.reject);

export const POST = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return client.post<typeof data, Response>(`${getAppConfig().API_BASE}${url}`, data, config);
};

export const PUT = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return client.put<typeof data, Response>(`${getAppConfig().API_BASE}${url}`, data, config);
};

export const GET = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return client.get<typeof data, Response>(`${getAppConfig().API_BASE}${url}`, config);
};

export const GET_PUBLIC = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<U>;
	return client.get<typeof data, Response>(`${getAppConfig().API_BASE}${url}`, config);
};

export const DELETE = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return client.delete<typeof data, Response>(`${getAppConfig().API_BASE}${url}`, config);
};
