import React from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Title from '../../../../app/components/title/Title';
import PartnerChannelRow from './PartnerChannelRow';
import { Partner } from 'app/domain/Partner';

const PartnerChannelsScreen = ({ partner }: { partner: Partner }) => {

    return (
        <Container className="tagsScreen">
            <Title>Channels</Title>
            <Table className="table" size="small" padding="checkbox" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell align="left" className="header">Id</TableCell>
                    <TableCell align="left" className="header">Name</TableCell>
                    <TableCell align="left" className="header">Status</TableCell>
                    <TableCell align="left" className="header">Followers</TableCell>
                    <TableCell align="left" className="header">Created</TableCell>
                    <TableCell align="left" className="header">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {partner.channels?.map(channel=><PartnerChannelRow key={channel.uid} channel={channel} />)}
            </TableBody>
            </Table>
        </Container>
    );
};

export default PartnerChannelsScreen;
