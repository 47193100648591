import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import Title from 'app/components/title/Title';
import { GET } from 'app/http/mediaApi/mediaClient';
import { AccountType, PublicAccountDetail } from './apiTypes';
import AmountDisplay from './AmountDisplay';
import { userOpenManualCastModalAction } from 'users/actions/store/UsersActionsActions';

interface AccountsRequest {
	type: AccountType;
	title: string;
}

interface Accounts extends AccountsRequest {
	data: PublicAccountDetail[];
}

async function getAccounts(req: AccountsRequest) {
	const t = await GET('admin/ledger/accounts/' + req.type);
	return { ...req, data: t.data.data } as Accounts;
}

async function getAllAccounts() {
	return await Promise.all([
		getAccounts({ type: AccountType.TAX, title: 'Tax' }),
		getAccounts({ type: AccountType.RECAST, title: 'Recast' }),
		getAccounts({ type: AccountType.COUNTER, title: 'Counter' }),
		getAccounts({ type: AccountType.EXTERNAL, title: 'External' }),
		getAccounts({ type: AccountType.ADVERTISER, title: 'Avertiser' }),
		getAccounts({ type: AccountType.PUBLISHER, title: 'Publisher (temporary)' })
	]);
}

function renderAccount(a: PublicAccountDetail, onClick: () => void) {
	return (
		<div key={a.id} style={{ height: '1.5em', textAlign: 'left', cursor: 'pointer' }} onClick={onClick}>
			<span style={{ width: '4em', display: 'inline-block' }}>{a.id}</span>
			<span style={{ width: '12em', display: 'inline-block' }}>
				{a.description} {a.description && a.linkedId && ' - '} {a.linkedId}
			</span>
			<span style={{ width: '10em', display: 'inline-block', textAlign: 'right' }}>
				<AmountDisplay amt={a.current.balance} />
			</span>
		</div>
	);
}

const AccountsScreen = () => {
	const [accounts, setAccounts] = useState([] as Accounts[]);

	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		getAllAccounts().then(setAccounts);
	}, []);

	const onCsvUpload = () => {
		dispatch(userOpenManualCastModalAction());
	};

	return (
		<>
			<Container className="sportsScreen">
				<Button variant="contained" onClick={onCsvUpload}>
					Manual Cast Transfer
				</Button>
				{accounts.map(section => (
					<>
						<Title>{section.title}</Title>
						{section.data.map(a => renderAccount(a, () => history.push('/account/' + a.id, a)))}
					</>
				))}
			</Container>
		</>
	);
};

export default AccountsScreen;
