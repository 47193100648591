import React, { useState } from 'react';
import { Chip, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import EditIcon from '@mui/icons-material/Edit';
import { AppThunkDispatch } from '../../../../app/store/store';
import { currencyCountriesOpenEditCountriesModalAction } from '../store/CurrencyCountriesActions';
import { Remove } from '@mui/icons-material';
import { removeCurrencyCurrenciesUseCase } from './EditCurrencyCountriesUseCase';

const NUMBER_OF_COUNTRIES_DISPLAYED = 10;

interface StateProps {
    currencyCountry: CurrencyCountries;
}
interface DispatchProps {
    openEditModal: (currencyCountry: CurrencyCountries) => void;
    removeCurrencyCountries: (currencyCountry: CurrencyCountries) => void;
}

type Props = StateProps & DispatchProps;

const EditCurrencyCountriesRow = (props: Props) => {
    const { currencyCountry, openEditModal, removeCurrencyCountries } = props;

    const [showAllCountries, setShowCountries] = useState<boolean>( false );

    function onEdit() {
        openEditModal(currencyCountry)
    }

    function onRemove() {
        removeCurrencyCountries(currencyCountry)
    }

    return (
        <TableRow>
            <TableCell>{currencyCountry.currencyCode}</TableCell>
            <TableCell style={{ width: 200 }}>
                {!showAllCountries && <>
                    {currencyCountry.countryCodes.slice( 0, NUMBER_OF_COUNTRIES_DISPLAYED ).map( code =>
                        <Chip key={code} style={{ margin: '3px' }} label={code}/> )}
                    {currencyCountry.countryCodes.length > NUMBER_OF_COUNTRIES_DISPLAYED &&
                    <Chip onClick={() => setShowCountries( true )} label="..."/>}
                </>
                }
                {showAllCountries && <>
                    {currencyCountry.countryCodes.map( code => <Chip key={code} style={{ margin: '3px' }}
                                                              label={code}/> )}
                    <Chip onClick={() => setShowCountries( false )} label="..."/>
                </>
                }
            </TableCell>
            <TableCell>
                <Tooltip title="Edit" arrow>
					<IconButton className="button button-orange" onClick={onEdit} size="large">
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove" arrow>
					<IconButton className="button button-red" onClick={onRemove} size="large">
                        <Remove/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    openEditModal: async (currencyCountry: CurrencyCountries) => {
        dispatch(currencyCountriesOpenEditCountriesModalAction(currencyCountry));
    },
    removeCurrencyCountries: async (currencyCountry: CurrencyCountries) => {
        dispatch(removeCurrencyCurrenciesUseCase(currencyCountry));
    }
});

export default connect(null, mapDispatchToProps)(EditCurrencyCountriesRow);

