import {ReportStateTypes} from "./ReportsActions";
import dayjs from 'dayjs';
import {createDefaultSort, Sort} from 'app/domain/Sort';
import {VideosSortField} from 'app/domain/VideoStatistics';
import { Filter } from 'app/stats/statsManager';

export interface ReportState {
    filters: Filter;
    sort: Sort<VideosSortField>
}

const initialState: ReportState = {
    filters: createFilters(),
    sort: createDefaultSort('uid'),
};

export function reportReducer(state = initialState, action: ReportStateTypes): ReportState {
    switch (action.type) {
        case "REPORT_SET_VERIFIED_USER":
            return {...state, filters: {...state.filters, channelUid: action.uid}};
        case 'REPORT_SET_CHECKED_VIDEO_TITLE_DATA':
            return {...state, filters: {...state.filters, checkedTitles: action.checkedTitles }};
        case 'REPORT_SET_START_DATE':
            return {...state, filters: {...state.filters, dates: [action.startDate, state.filters.dates[1]]}};
        case 'REPORT_SET_END_DATE':
            return {...state, filters: {...state.filters, dates: [state.filters.dates[0], action.endDate]}};
        case 'VIDEOS_SET_SORT':
            return { ...state, sort: action.sort };
        default:
            return state;
    }
}

function createFilters(): Filter {
    return {
        channelUid: '',
        checkedTitles: [],
        dates: [prepareInitialStartDate(), prepareInitialEndDate()]
    };
}

export interface EarningsDailyData {
    labels: Date[];
    totals: number[];
    videos: number[];
    referrals: number[];
}

export interface EarningsAggregatedChartData {
    labels: Date[];
    totals: number[];
    videos: number[];
    referrals: number[];
}

export interface PurchasesChartData {
    labels: Date[];
    purchases: number[];
    recasts: number[];
}

export interface EarningsSourceChartData {
    labels: Date[];
    purchases: number[];
    recasts: number[];
    shares: number[];
}

export function prepareInitialStartDate() {
    return dayjs().subtract(1, 'day').subtract(1, 'month');
}

export function prepareInitialEndDate() {
    return dayjs().subtract(1, 'day');
}
