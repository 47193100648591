import React, {useEffect, useState} from "react";
import { Country } from "./CountryUseCase";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogTitle,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	MenuItem
} from '@mui/material';
import {Region, REGION_NAME, REGION_ORDER, COUNTRY_REGION} from "../../../../../../constants/Regions";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

interface Props {
    isOpen: boolean;
    countries: string[];
    save: (countries: string[]) => void;
    closeModal: ()=>void;
    all: Country[];
}

const CountryModal = (props: Props) => {
    const {isOpen, closeModal, save, countries: _countries, all} = props;

    const [countries, setCountries] = useState(_countries);

    useEffect(() => setCountries(_countries), [_countries, isOpen]);

    const toggleCountry = (code: string) => {
        const newCountries = countries.includes(code) 
            ? countries.filter(c => c !== code)
            : [ ...countries, code ];
        setCountries(newCountries);
    }

    const regionCountries = (region: Region) =>
        Object.keys(COUNTRY_REGION).filter(k=>COUNTRY_REGION[k]===region);

    const selectAllCountriesByRegion = (region: Region) => {
        setCountries([ ...countries, ...regionCountries(region) ]);
    }

    const deselectAllCountriesByRegion = (region: Region) => {
        const remove = regionCountries(region);
        setCountries(countries.filter(c=>!remove.includes(c)));
    }

    const selectAllCountries = () => setCountries(all?.map(c=>c.code) || []);

    const deselectAllCountries = () => setCountries([]);

    const isNoCountriesChecked = () => countries.length == 0;

    const isNoCountriesCheckedInRegion = (region: Region) => {
        return !regionCountries(region).filter(c=>countries.includes(c)).length
    }

    return (
        <Dialog
            className="countryAvailabilityDialog"
            open={isOpen}
            onClose={closeModal}
        >
            <div className="scroll">
                <Grid item xs={12}>
                    <DialogTitle><strong>Country availability</strong></DialogTitle>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Grid item xs={6}>
                            <Button className="button-availability" variant="contained" color="secondary"
                                    onClick={() => selectAllCountries()}>
                                SELECT ALL REGIONS
                                {isNoCountriesChecked() && <CheckIcon/>}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className="button-availability" variant="contained"
                                    onClick={() => deselectAllCountries()}>
                                DESELECT ALL REGIONS
                            </Button>
                        </Grid>
                    </DialogActions>
                </Grid>
                {REGION_ORDER.map(region =>
					<Accordion key={region} style={{ margin: '5px 10px' }}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div style={{flexBasis: '80%'}}>
                                <strong>{REGION_NAME[region]}</strong>
                            </div>
                            {isNoCountriesCheckedInRegion(region) &&
                            <IconButton size="small"
                                    onClick={() => selectAllCountriesByRegion(region)}>
                                <AddIcon />
                            </IconButton>
                            }
                            {!isNoCountriesCheckedInRegion(region) &&
                            <IconButton size="small"
                                    onClick={() => deselectAllCountriesByRegion(region)}>
                                <CheckIcon />
                            </IconButton>
                            }
						</AccordionSummary>
						<AccordionDetails>
                            <div className={"expansionPanelDetails"}>
                                <Typography>
                                    Live now in:
                                </Typography>
                                { regionCountries(region).map(country =>
									<MenuItem key={country} onClick={() => toggleCountry(country)}>
                                        <Checkbox checked={countries.includes(country)}/>
                                        {all?.find(c=>c.code === country)?.name}
                                    </MenuItem>
                                )}
                            </div>
						</AccordionDetails>
					</Accordion>
                )
                }
                <DialogActions>
                    <Button variant="contained" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="contained" color="secondary" 
                        onClick={()=>{ save(countries); closeModal(); }}
                    >Save</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default CountryModal;
