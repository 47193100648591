export enum HTTP_STATUS_CODES {
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	NOT_FOUND = 404,
	TOO_MANY_REQUESTS = 429,
	OK_REQUEST = 200
}

export const isHttpClientErrorStatus = (httpStatus: number): boolean => {
	return 400 <= httpStatus && httpStatus <= 499;
};

export const BEGINNING_OF_TIME_DATE = '2020-01-01';

export const ALL_REGIONS = '**';

export enum FormMessages {
	REQUIRED = 'Required',
	TOO_LONG = 'Too long!',
	TOO_SHORT = 'Too short!',
	NOT_NEGATIVE = 'Not a negative number!',
	TOO_MUCH = 'Too much!',
	TOO_MUCH_DECIMAL_PLACES = 'The number field must have 6 decimal places or less!',
	EXISTS = 'Exists!',
	INVALID_EMAIL = 'Invalid email'
}

export const TIME_TO_HIDE_PREMIUM_VIDEO_UI_IN_MILLISECONDS = 1.5 * 1000;
export const PLAYER_RESOLUTION = { width: 640, height: 360 };

export const MAX_CONTRIBUTORS_NUMBER = 3;
export const CONTRIBUTOR_DEFAULT_PCT = 15;
export const CONTRIBUTOR_MIN_PCT = 1;
export const SECONDS_IN_DAY = 60 * 60 * 24;
