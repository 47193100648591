import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/store';
import { closeFailedLedgersModalAction } from 'refunds/store/refundsActions';

const FailedLedgersModal = () => {
	const { isOpen, failedLedgers } = useSelector((state: RootState) => state.refunds.failedLedgersModal);
	const dispatch = useDispatch();

	const ledgerIds = failedLedgers.join(', ');
	const closeModal = () => {
		dispatch(closeFailedLedgersModalAction());
	};

	return (
		<Dialog
			open={isOpen}
			onClose={closeModal}>
			<DialogContent>
				<DialogContentText>
					Below ledgers could not be refunded:
				</DialogContentText>
				<div>{ledgerIds}</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeModal} color="primary">Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FailedLedgersModal;
