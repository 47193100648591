export const SET_IS_AUTHENTICATE = 'SET_IS_AUTHENTICATE';

interface SetIsAuthenticateAction {
  type: typeof SET_IS_AUTHENTICATE;
  isAuthenticate: boolean;
}

export type UserActionTypes = SetIsAuthenticateAction;

export function loginAction(): UserActionTypes {
  return {
    type: SET_IS_AUTHENTICATE,
    isAuthenticate: true,
  };
}

export function logoutAction(): UserActionTypes {
  return {
    type: SET_IS_AUTHENTICATE,
    isAuthenticate: false,
  };
}

