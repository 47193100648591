import { UsersShareStateTypes } from "./UsersActionsActions";
import {User} from "../../../app/domain/User";

interface UsersShareState {
  removeModal: {
    isOpen: boolean;
    uid: string;
  };
  invitePartnerModal: {
    isOpen: boolean;
    url: string;
  };
  suspendModal: {
    isOpen: boolean;
    uid: string;
    statusId: number;
  }
  confirmInvitePartnerModal: {
    isOpen: boolean;
    user: User;
  }
  awardCastModal: {
    isOpen: boolean;
    user: User;
  };
  manualCastModal: {
    isOpen: boolean;
  };
  deleteUserModal: {
    isOpen: boolean;
    user: User;
  };
  onRefresh: () => void;
}

const createEmptyUser = () => {
  return {} as User;
}

const initialState: UsersShareState = {
  removeModal: {
    isOpen: false,
    uid: '',
  },
  confirmInvitePartnerModal: {
    user: createEmptyUser(),
    isOpen: false
  },
  invitePartnerModal: {
    isOpen: false,
    url: '',
  },
  suspendModal: {
    isOpen: false,
    uid: '',
    statusId: 0
  },
  awardCastModal: {
    isOpen: false,
    user: createEmptyUser(),
  },
  deleteUserModal: {
    isOpen: false,
    user: createEmptyUser(),
  },
  manualCastModal: {
    isOpen: false,
  },
	onRefresh: () => undefined
};

export function usersActionsReducer(state = initialState, action: UsersShareStateTypes): UsersShareState {
  switch (action.type) {
    case "USER_OPEN_REMOVE_MODAL":
      return { ...state, removeModal: { ...state.removeModal, isOpen: true, uid: action.uid }, onRefresh: action.onRefresh };
    case "USER_CLOSE_REMOVE_MODAL":
			return {
				...state,
				removeModal: { ...state.removeModal, isOpen: false, uid: '' },
				onRefresh: () => undefined
			};
    case "USER_OPEN_INVITE_PARTNER_MODAL":
      return { ...state, invitePartnerModal: { ...state.invitePartnerModal, isOpen: true, url: action.url } };
    case "USER_CLOSE_INVITE_PARTNER_MODAL":
      return { ...state, invitePartnerModal: { ...state.invitePartnerModal, isOpen: false, url: '' } };
    case "USER_OPEN_SUSPEND_MODAL":
      return { ...state, suspendModal: { ...state.suspendModal, isOpen: true, uid: action.uid, statusId: action.statusId }, onRefresh: action.onRefresh };
    case "USER_CLOSE_SUSPEND_MODAL":
      return { ...state, suspendModal: { ...state.suspendModal, isOpen: false, uid: '' }, onRefresh: initialState.onRefresh };
    case "USER_OPEN_AWARD_CAST_MODAL":
      return { ...state, awardCastModal: { ...state.awardCastModal, isOpen: true, user: action.user }, onRefresh: action.onRefresh };
    case "USER_OPEN_DELETE_USER_MODAL":
      return { ...state, deleteUserModal: { ...state.deleteUserModal, isOpen: true, user: action.user }, onRefresh: action.onRefresh };
    case "USER_CLOSE_DELETE_USER_MODAL":
      return { ...state, deleteUserModal: { ...state.deleteUserModal, isOpen: false }};
    case "USER_CLOSE_AWARD_CAST_MODAL":
      return { ...state, awardCastModal: { ...state.awardCastModal, isOpen: false, user: createEmptyUser() }, onRefresh: initialState.onRefresh };
    case "USER_OPEN_CONFIRM_INVITE_MODAL":
      return { ...state, confirmInvitePartnerModal: { ...state.confirmInvitePartnerModal, isOpen: true, user: action.user }};
    case "USER_CLOSE_CONFIRM_INVITE_MODAL":
			return {
				...state,
				confirmInvitePartnerModal: {
					...state.confirmInvitePartnerModal,
					isOpen: false,
					user: createEmptyUser()
				},
				onRefresh: () => undefined
			};
      case "USER_OPEN_MANUAL_CAST_MODAL":
        return { ...state, manualCastModal: { ...state.manualCastModal, isOpen: true }};
      case "USER_CLOSE_MANUAL_CAST_MODAL":
        return { ...state, manualCastModal: { ...state.manualCastModal, isOpen: false }};

    default:
      return state;
  }
}
