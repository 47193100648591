import { createEmptyPartnerAccounts, PartnerAccounts } from './PartnerAccount';
import { ChannelDetails } from './ChannelDetails';
import { Sort } from './Sort';

export interface Partner {
	uid: string;
	name: string;
	created: string;
	updated: string;
	companyNumber: string;
	countryCode: string;
	stateCode: string;
	postalCode: string;
	addressLines: string;
	secondAddressLine: string;
	vatNumber: string;
	isPersonal?: boolean;
	email: string;
	phone?: string;
	status: PartnerStatus;
	currency: string;
	invitationId: number;
	position?: string;
	legalAgreementsAccepted?: {
		uid: string;
		date: string;
		signed: boolean;
	};
	walletPublisherInitialized: boolean;
	walletAdvertiserInitialized: boolean;
	roles: RolePartner[];
	channels?: ChannelDetails[];
	accounts: PartnerAccounts;
	socialMediaTierId?: number;
	contentProportionTierId?: number;
}

export interface RolePartner {
	userId: string;
	role: PartnerRole;
}

export const isSuspended = (partner: Partner): boolean => {
	return partner.status === 'SUSPENDED_BY_USER' || partner.status === 'SUSPENDED_BY_ADMIN';
}

export const isLive = (partner: Partner): boolean => {
	return partner.status === 'LIVE';
}

export const isApproved = (partner: Partner): boolean => {
	return partner.status === 'APPROVED';
}

export const isStatusToEditTiers = (partner: Partner): boolean => {
	return isApproved(partner) || isLive(partner);
}

export type PartnerStatus =
	| 'APPLICATION'
	| 'APPROVED'
	| 'LIVE'
	| 'SUSPENDED_BY_USER'
	| 'SUSPENDED_BY_ADMIN'
	| 'DELETED';

export const AllPartnerStatuses: PartnerStatus[] = ['APPLICATION', 'APPROVED', 'LIVE'];

export type PartnerSortField = 'uid' | 'created' | 'name' | 'followerCount';
export type PartnerRole = 'advertiser' | 'livestreamer' | 'devicestreamer' | 'publisher' | 'agent';

export interface PartnersQuery {
	page: number;
	sort: Sort<PartnerSortField>;
	partnerNameSearchText?: string;
	partnerUidSearchText?: string;
	emailSearchText?: string;
	statuses: PartnerStatus[];
}

export const existsInPartnerRoles = (roleName: PartnerRole, partner: Partner) =>
	partner.roles.map(el => el.role).includes(roleName);

export const getIsPersonalPartner = (partner: Partner): boolean => {
  return !!partner.isPersonal;
}

export function createEmptyPartner(): Partner {
	return {
		uid: '',
		name: '',
		created: '',
		updated: '',
		companyNumber: '',
		countryCode: '',
		stateCode: '',
		postalCode: '',
		addressLines: '',
		secondAddressLine: '',
		vatNumber: '',
		email: '',
		phone: '',
		status: 'DELETED',
		currency: '',
		invitationId: 0,
		walletPublisherInitialized: false,
		walletAdvertiserInitialized: false,
		roles: [],
		accounts: createEmptyPartnerAccounts()
	};
}
