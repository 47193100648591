export interface Invitation {
  id: number;
  email: string;
  status: InvitationStatus;
  created: string;
  adminId: string;
  adminRevoked: string;
  token: string;
  invitationLink: string;
  userId: string;
  ceilingCategoriesIds: number[];
}

export interface InvitationData {
  email: string;
  userId?: string;
  ceilingCategoriesIds: number[];
}

export type InvitationStatus = 'NEW' | 'SENT' | 'USED' | 'REVOKED';
export const AllInvitationStatuses: InvitationStatus[] = ['USED', 'SENT', 'NEW'];

export type InvitationSortField = 'id' | 'created' | 'email';

export function isSent(invitation: Invitation): boolean {
  return invitation.status === 'SENT';
}

export function isNew(invitation: Invitation): boolean {
  return invitation.status === 'NEW';
}

export function createEmptyInvitation(): Invitation {
  return {
    id: 0,
    email: 'Unknown',
    status: "REVOKED",
    created: "Unknown",
    adminId: "Unknown",
    adminRevoked: "Unknown",
    token: "Unknown",
    invitationLink: "Unknown",
    userId: "Unknown",
    ceilingCategoriesIds: []
  }
}

