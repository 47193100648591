import React from 'react';
import { connect } from 'react-redux';
import RemoveModal from '../../../app/components/modal/remove/RemoveModal';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { removeInvitationUseCase } from './RemoveInvitationUseCase';
import { invitationCloseRemoveModalAction } from '../store/InvitationActionsActions';

interface StateProps {
  invitationId: number;
  isOpen: boolean;
}

interface DispatchProps {
  removeModal: (invitationId: number) => void;
  closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const RemoveInvitationModal = (props: Props) => {
  const { invitationId, isOpen, removeModal, closeModal } = props;

  const handleRemove = () => {
    removeModal(invitationId);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <RemoveModal isOpen={isOpen} closeModal={handleCloseModal} onRemove={handleRemove} />
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.invitations.actions.removeModal.isOpen,
  invitationId: state.invitations.actions.removeModal.invitationId,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  removeModal: (invitationId: number) => {
    dispatch(removeInvitationUseCase(invitationId));
  },
  closeModal: () => {
    dispatch(invitationCloseRemoveModalAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveInvitationModal);
