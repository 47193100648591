import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AdminApiPagination from 'app/components/pagination/AdminApiPagination';
import { useLocalQuery } from 'app/utils/searchManager';
import {
	getUserChannelPassPurchasesData,
	revokeUserChannelPass
} from 'users/details/sections/channelPasses/UserChannelPassesUseCase';
import { getPurchaseStatus } from 'app/domain/Purchase';
import dateUtil from 'app/utils/dateUtil';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmActionModal from 'app/components/modal/confirm/confirmActionModal';

const UserChannelPassesSection = (props: { userUid: string }) => {
	const paramId = useParams<{ userUid: string }>().userUid;
	const userUid = props.userUid || paramId;

	const [url, setUrl] = useState<string>();
	const [showRevokeConfirmationModal, setShowRevokeConfirmationModal] = useState(false);
	const [selectedPurchaseId, setSelectedPurchaseId] = useState<string | null>(null);

	const [channelPassPurchases, refresh] = useLocalQuery(
		() => getUserChannelPassPurchasesData(userUid, url),
		[userUid, url]
	);

	const changePage = (url: string) => {
		setUrl(url)
	};

	const onRevokeChannelPassClicked = async (purchaseId: string) => {
		setSelectedPurchaseId(purchaseId);
		setShowRevokeConfirmationModal(true);
	};

	const onRevokeChannelPass = async () => {
		if (selectedPurchaseId) {
			await revokeUserChannelPass(userUid, selectedPurchaseId);
			setSelectedPurchaseId(null);
			setShowRevokeConfirmationModal(false);
			await refresh();
		}
	};

	return (
		<Container className="walletTransactions">
				<Table className="table" size="small" padding="checkbox" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell align="left" className="header">Channel Name</TableCell>
							<TableCell align="left" className="header">Status</TableCell>
							<TableCell align="left" className="header">Channel Pass Name</TableCell>
							<TableCell align="left" className="header">Time period</TableCell>
							<TableCell align="left" className="header">Price in Casts</TableCell>
							<TableCell align="left" className="header">Purchase Date</TableCell>
							<TableCell align="left" className="header">Expiration Date</TableCell>
							<TableCell align="left" className="header">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ channelPassPurchases && channelPassPurchases.items.map((channelPassPurchase) => (
							<TableRow key={channelPassPurchase.purchase.id} style={{height: "3em"}}>
								<TableCell>{channelPassPurchase.channel?.channelName}</TableCell>
								<TableCell>{getPurchaseStatus(channelPassPurchase.purchase)}</TableCell>
								<TableCell>{channelPassPurchase.tariff.description}</TableCell>
								<TableCell>{dateUtil.getDurationInDays(channelPassPurchase.tariff.rentalPeriod)}</TableCell>
								<TableCell>{channelPassPurchase.tariff.priceDetails.price}</TableCell>
								<TableCell>{channelPassPurchase.purchase.created}</TableCell>
								<TableCell>{channelPassPurchase.purchase.expires}</TableCell>
								<TableCell>
									{ getPurchaseStatus(channelPassPurchase.purchase) === 'ACTIVE' &&
										<IconButton
											onClick={() => onRevokeChannelPassClicked(channelPassPurchase.purchase.id)}
											size="large"
										>
											<DeleteIcon/>
										</IconButton>
									}
								</TableCell>
							</TableRow>
						)) }
					</TableBody>
				</Table>
				<Box justifyContent="center" className="paginationContainer">
					{ channelPassPurchases && <AdminApiPagination items={channelPassPurchases.items} paging={channelPassPurchases.paging} changePage={changePage} /> }
				</Box>
				<ConfirmActionModal
					show={showRevokeConfirmationModal}
					onClose={() => setShowRevokeConfirmationModal(false)}
					description={'Are you sure you want to revoke channel pass?'}
					confirmedAction={onRevokeChannelPass}
				/>
		</Container>

	);
};

export default UserChannelPassesSection;
