import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { getBaseApiUrl } from 'app/http/request';
import { addAccessToken } from 'app/http/authApi/token';

interface AxiosRequestConfigProps extends AxiosRequestConfig {
	['Content-Type']: string;
}

const clientComments: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

clientComments.interceptors.request.use(addAccessToken, Promise.reject);

const getCommentsApiUrl = (suffix: string): string => getBaseApiUrl('api', suffix);

export const PUT_COMMENTS = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<U>;
	return clientComments.put<typeof data, Response>(getCommentsApiUrl(url), data, config);
};

export const DELETE_COMMENTS = <U>(url: string, config?: AxiosRequestConfigProps) => {
	return clientComments.delete<U>(getCommentsApiUrl(url), config);
};
