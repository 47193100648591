import React, { useState } from 'react';
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { WidgetStaff } from 'app/domain/Widget';
import { Visibility, Code } from '@mui/icons-material';
import { useWidgetGenerator } from '../../config/useWidgetGenerator';
import WidgetModal from './widgetModal';
import { Colors } from 'Theme';

interface Props {
	widget: WidgetStaff;
}

const WidgetRow = (props: Props) => {
	const { widget } = props;
	const path = `/${widget.channelId}/${widget.id}`;
	const iframeId = `channel-${widget.channelId}`;
	const { code, src } = useWidgetGenerator(path, iframeId);

	const [showModal, setShowModal] = useState(false);

	return (
		<TableRow className="widget-content" key={widget.id}>
			<TableCell>{widget.name}</TableCell>
			<TableCell>
				<Box>
					<Tooltip title="Preview" arrow>
						<IconButton target="_blank" rel="noreferrer" href={src}>
							<Visibility style={{ fontSize: 'large', color: Colors.Black }} />
						</IconButton>
					</Tooltip>

					<Tooltip title="Widget Code" arrow>
						<IconButton onClick={() => setShowModal(true)} size="small">
							<Code style={{ fontSize: 'large', color: Colors.Black }} />
						</IconButton>
					</Tooltip>
				</Box>
			</TableCell>
			<WidgetModal
				isOpen={showModal}
				widgetName={widget.name}
				widgetCode={code}
				widgetSource={src}
				onCancel={() => setShowModal(false)}
			/>
		</TableRow>
	);
};

export default WidgetRow;
