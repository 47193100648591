import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import { CurrencyCountriesListStateTypes } from './CurrencyCountriesListActions';

export interface CurrencyCountriesState {
  currencyCountries: CurrencyCountries[];
}

const initialState: CurrencyCountriesState = {
  currencyCountries: [],
};

export function currencyCountriesListReducer(state = initialState, action: CurrencyCountriesListStateTypes): CurrencyCountriesState {
  switch (action.type) {
    case "CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES":
      return { ...state, currencyCountries: action.currencyCountries };
    default:
      return state;
  }
}
