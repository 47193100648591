import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import Title from '../../../../app/components/title/Title';
import AddIcon from '@mui/icons-material/Add';
import { Button, Container, Dialog, DialogActions, Grid, IconButton, Table } from '@mui/material';
import {
    currencyCountriesCloseEditModalAction,
    currencyCountriesOpenCreateModalAction,
} from '../store/CurrencyCountriesActions';
import EditCurrencyCountriesTableHeader from './EditCurrencyCountriesTableHeader';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import EditCurrencyCountriesRow from './EditCurrencyCountriesRow';
import CurrencyCountriesModal from '../country/CurrencyCountriesModal';
import { fetchSupportedCurrenciesUseCase } from '../../../CurrencyUseCase';
import { editCurrencyCurrenciesUseCase } from './EditCurrencyCountriesUseCase';
import CreateCurrencyCountriesModal from '../add/CreateCurrencyCountriesModal';

interface StateProps {
    currencyCountries: CurrencyCountries[]
    isOpen: boolean;
}

interface DispatchProps {
    closeModal: () => void;
    saveCurrencies: (currencyCountries: CurrencyCountries[]) => void;
    fetchSupportedCountries: () => void;
    createCurrencyCountries: () => void;
}

type Props = StateProps & DispatchProps;


const EditCurrencyCountriesModal = (props: Props) => {
    const { closeModal, isOpen, saveCurrencies, currencyCountries, fetchSupportedCountries, createCurrencyCountries } = props;

    useEffect( () => {
        fetchSupportedCountries();
    }, [currencyCountries] );

    function onAdd() {
        createCurrencyCountries();
    }

    function onSaveCurrencies() {
        saveCurrencies(currencyCountries);
    }

    return (
        <Dialog
            className="dialog"
            open={isOpen}
            onClose={closeModal}
            fullWidth={true}
            maxWidth={'md'}
        >

            <Container className="videosScreen">
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <Title>Edit currencies with countries</Title>
                    </Grid>
                    <Grid item xs={1}>
						<IconButton onClick={() => onAdd()} size="large">
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </Grid>

                <Table className="table" size="medium" padding="checkbox" stickyHeader style={{ padding: '3em' }}>
                    <EditCurrencyCountriesTableHeader/>
                    {
                        currencyCountries.map( (current) => (
                                <EditCurrencyCountriesRow key={current.currencyCode} currencyCountry={current}/>
                            ),
                        )
                    }
                </Table>
                <CurrencyCountriesModal />
                <CreateCurrencyCountriesModal />
                <DialogActions className={`dialogActions`}>
					<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
                        Cancel
                    </Button>
                    <Button onClick={onSaveCurrencies} variant="contained" color="secondary" className={`button-yes`}>
                        Save
                    </Button>
                </DialogActions>
            </Container>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ( {
    isOpen: state.currency.currencyCountries.actions.editModal.isOpen,
    currencyCountries: state.currency.currencyCountries.actions.currencyCountries,
} );

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    fetchSupportedCountries: () => {
        dispatch( fetchSupportedCurrenciesUseCase() );
    },
    createCurrencyCountries: () => {
        dispatch( currencyCountriesOpenCreateModalAction() );
    },
    saveCurrencies: (currencyCountries: CurrencyCountries[]) => {
        dispatch( editCurrencyCurrenciesUseCase(currencyCountries) );
    },
    closeModal: () => {
        dispatch( currencyCountriesCloseEditModalAction() );
    },
} );

export default connect( mapStateToProps, mapDispatchToProps )( EditCurrencyCountriesModal );

