import React, { CSSProperties, useMemo } from 'react';
import { FieldProps } from 'formik';
import { IconButton, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Color, Gradient, parseToGradient, stringifyGradient } from 'app/utils/gradientManager';

type BackgroundStyleInputProps = FieldProps<string> & { label?: string };

const DEFAULT_FIRST_COLOR = '#3f3f3f';
const DEFAULT_SECOND_COLOR = '#7f7f7f';
const DEFAULT_NEXT_COLOR = '#3f3f3f';

export const GradientStyleInput = ({ field, form, label }: BackgroundStyleInputProps) => {
	const background = useMemo<Gradient>(() => {
		try {
			if (field.value) {
				return parseToGradient(field.value);
			}
		} catch { /* empty */ }
		return {
			angle: 90,
			colors: []
		};
	}, [field.value]);

	const style: CSSProperties = {
		background: field.value
	};

	const updateGradient = (gradient: Gradient) => {
		form.setFieldValue(field.name, stringifyGradient(gradient));
	};

	const setAngle = (angle: number) => updateGradient({ ...background, angle });

	const setColor = (index: number, color: Color) =>
		updateGradient({
			...background,
			colors: [...background.colors.slice(0, index), color, ...background.colors.slice(index + 1)]
		});

	const setColorValue = (index: number, value: string) => setColor(index, { ...background.colors[index], value });

	const setColorPosition = (index: number, position: number) =>
		setColor(index, {
			...background.colors[index],
			position
		});

	const deleteColor = (index: number) => {
		if (background.colors.length <= 2) {
			updateGradient({
				...background,
				colors: []
			});
		} else {
			updateGradient({
				...background,
				colors: [...background.colors.slice(0, index), ...background.colors.slice(index + 1)]
			});
		}
	};

	const addColor = () => {
		if (background.colors.length < 2) {
			updateGradient({
				...background,
				colors: [
					{ value: DEFAULT_FIRST_COLOR, position: 0 },
					{ value: DEFAULT_SECOND_COLOR, position: 100 }
				]
			});
		} else {
			updateGradient({
				...background,
				colors: [...background.colors, { value: DEFAULT_NEXT_COLOR, position: 100 }]
			});
		}
	};

	const renderColor = (color: Color, index: number) => (
		<div key={`color_${index}`} className="backgroundStyleInput--gradientPoint">
			<span>Color {index}: </span>
			<input
				type="color"
				value={color.value}
				onChange={e => setColorValue(index, e.target.value)}
			/>
			<TextField
				label="Position"
				variant="outlined"
				type="number"
				value={color.position}
				onChange={e => setColorPosition(index, +e.target.value)}
			/>
			<Tooltip title="Delete color" arrow>
				<IconButton onClick={() => deleteColor(index)} size="large">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</div>
	);

	const renderLabel = (colors: Color[], gradientCss: string, name?: string) =>
		colors.length ? (
			<div>
				{`${name}: `}
				<span className="code">{`${gradientCss}`}</span>
			</div>
		) : (
			<div>{`${name} gradient`}</div>
		);

	return (
		<div className="backgroundStyleInput">
			{renderLabel(background.colors, field.value, label)}
			{!!background.colors.length && <div className="backgroundStyleInput--preview" style={style} />}
			{background.colors.map(renderColor)}
			<div>
				<Tooltip title="Add color" arrow>
					<IconButton onClick={addColor} size="large">
						<AddIcon />
					</IconButton>
				</Tooltip>
			</div>
			<TextField
				label="Angle"
				variant="outlined"
				type="number"
				value={background.angle}
				onChange={e => setAngle(+e.target.value)}
			/>
		</div>
	);
};
