import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import { AppThunk, RootState } from '../../../../app/store/store';
import {
    currenciesSetAllSelectedCodeAction,
    currenciesSetCurrencyCountriesAction,
    currencyCountriesCloseCreateModalAction,
} from '../store/CurrencyCountriesActions';
import { GET_PAYMENT } from '../../../../app/http/paymentApi/paymentClient';
import { CurrenciesData } from '../../../CurrencyUseCase';
import { Currency } from '../../../../app/domain/Currency';

export function createCurrencyCountriesUseCase(currencyCountry: CurrencyCountries): AppThunk {
    return ((dispatch, getState: () => RootState) => {

        const { currencyCountries } = getState().currency.currencyCountries.actions;
        dispatch( currenciesSetCurrencyCountriesAction([...currencyCountries, currencyCountry]) );

        const allSelectedCodes: string[] = [];
        [...currencyCountries, currencyCountry].map( country => country.countryCodes.map( code => allSelectedCodes.push( code ) ) );
        dispatch( currenciesSetAllSelectedCodeAction( allSelectedCodes ) );
        dispatch( currencyCountriesCloseCreateModalAction() );
    });
}

export const getCurrencyCountriesApi = async (): Promise<Currency[]> => {
    const res = await GET_PAYMENT<CurrenciesData>('/admin/currency/rates');
    return res.data.data.currencyRates;
};
