import { AppThunk, AppThunkDispatch } from 'app/store/store';
import { makeRequest } from 'app/http/request';
import { createSuccessResult } from 'app/utils/result';
import {
	invitationCloseCreateModalAction,
	invitationOnRefreshAction,
	invitationOpenInfoModalAction
} from 'invitations/actions/store/InvitationActionsActions';
import { InvitationData } from 'app/domain/Invitation';
import { POST_PARTNER } from 'app/http/partnerApi/partnerClient';

export interface CreateInviteLinkResponse {
	invitationLink: string;
}

export function createInvitationApi(email: string, ceilingCategoriesIds: number[], userId?: string) {
	return (dispatch: AppThunkDispatch) => {
		return makeRequest({
			dispatch,
			request: async () => {
				const result = await POST_PARTNER<CreateInviteLinkResponse>(`/invite`, {
					email,
					ceilingCategoriesIds,
					userId
				});
				return result.data.data;
			},
			responseSuccessHandler: (data: CreateInviteLinkResponse) =>
				createSuccessResult<CreateInviteLinkResponse, string>(data)
		});
	};
}

export function createInvitationUseCase(invitation: InvitationData): AppThunk {
	return async dispatch => {
		const result = await dispatch(createInvitationApi(invitation.email, invitation.ceilingCategoriesIds));
		dispatch(invitationCloseCreateModalAction());
		if (result.value) {
			dispatch(invitationOpenInfoModalAction(result.value.invitationLink, () => invitationOnRefreshAction()));
		}
	};
}
