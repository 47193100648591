import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const ScrollToError = () => {
	const formik = useFormikContext();
	const submitting = formik?.isSubmitting;

	useEffect(() => {
		const elementWithError = document.querySelector('.Mui-error, [data-error]');
		(elementWithError?.parentElement ?? elementWithError)?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'nearest'
		});
	}, [submitting]);

	return null;
};

export default ScrollToError;
