import { ApiEntity } from 'app/http/apiResponse';

export interface RefundApiEntity extends ApiEntity {
	id: string;
	user: { uid: string };
	admin: { uid: string };
	purchase: ApiEntity;
	created: string;
	updated: string;
	amount: number;
	ledgerId: number;
	bulkId?: { uid: string };
	bulkReason?: string;
}

export const isRefund = (item: ApiEntity): item is RefundApiEntity => item.href.startsWith('/api/admin/refunds');
