import React, { SyntheticEvent } from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { User, Status } from 'app/domain/User';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'app/navigation/RoutePaths';
import UserActionButtons from 'users/actions/UserActionButtons';
import 'users/list/table/usersScreen.scss';

interface UserRowProps {
	user: User;
	onRefresh: ()=>void;
}

type Props = UserRowProps;

const UserRow = (props: Props) => {
	const { user, onRefresh } = props;
	const history = useHistory();

	const navigateToUserDetails = (uid: string) => {
		history.push(`${RoutePaths.UserDetails}/${uid}`);
	};

	const navigateToUserReferrerDetails = (e: SyntheticEvent) => {
		history.push(`${RoutePaths.UserDetails}/${user.referrer}`);
		e.stopPropagation();
	};

	return (
		<TableRow className={'status_' + Status[user.statusId]} onClick={() => navigateToUserDetails(user.uid)}>
			<TableCell>
				<div className="uid">{user.uid}</div>
			</TableCell>
			<TableCell>
				<div className="name">{user.email}</div>
			</TableCell>
			<TableCell>
				<div className="name">{user.status}</div>
			</TableCell>
			<TableCell>{user.roles.map(el => el.role).join(', ')}</TableCell>
			<TableCell>
				<Button variant="contained" onClick={navigateToUserReferrerDetails}>
					{user.referrer}
				</Button>
			</TableCell>
			<TableCell>{user.created}</TableCell>
			<TableCell>
				<UserActionButtons user={user} onRefresh={onRefresh} />
			</TableCell>
		</TableRow>
	);
};
export default UserRow;
