import { GET_PARTNER, POST_FORM_DATA_PARTNER, PUT_PARTNER } from 'app/http/partnerApi/partnerClient';
import { ChannelDetails, ChannelRole } from 'app/domain/ChannelDetails';
import { Contributor } from 'app/domain/Contributor';
import { EditChannelValues } from 'channels/actions/edit/EditChannelModal';
import { POST } from 'app/http/mediaApi/mediaClient';
import { GET_DATA_PROFILE } from 'app/http/profileApi/profileClient';

export enum ImageType {
	banner = 'banner',
	avatar = 'avatar'
}

export interface ImageChannelData {
    partnerId: string;
    channelId: string;
    type: ImageType;
    file: File;
}

export interface ChannelRoleResponse {
    roles: ChannelRole[]
}

export const fetchChannelDetailsApi = async (uid: string) =>
  GET_PARTNER<ChannelDetails>(`/admin/channel/${uid}/details`)
    .then(r=>r.data.data);

export const fetchChannelRolesApi = (uid: string) =>
  GET_PARTNER<ChannelRoleResponse>(`/channel/${uid}/roles`).then(r=>r.data.data.roles);

export const editImageApi = (data: ImageChannelData) =>
  POST_FORM_DATA_PARTNER<void>(`/admin/partner/${data.partnerId}/channel/${data.channelId}/image/${data.type}`, {
      'locations[]': '*',
      imageBackground: 'transparent',
      image: data.file,
      description: 'Image description'
  }).then(r=>r.data.data);

export const editChannelDefaultContributorsApi = async (uid: string, contributors: Contributor[]) =>
  PUT_PARTNER<any>(`/admin/channel/${uid}`, { defaultContributors: contributors.map(({userUid, pct}) =>({ userUid, pct})) })
    .then(r=>r.data.data);

export const setChannelStatusApi = (uid: string, status: 'SUSPENDED' | 'LIVE' | 'DELETED') =>
  PUT_PARTNER<any>(`/admin/channel/${uid}/status`, { status })
    .then(r=>r.data.data);

export const editChannelApi = (uid: string, channel: EditChannelValues) =>
  PUT_PARTNER<any>(`/admin/channel/${uid}`, { ...channel })
    .then(r=>r.data.data);

export const publishVideo = async (videoId: string) =>
  await POST<void>(`admin/video/publish/${videoId}`);
export const fetchSharedDataCsv = async (uid: string) =>
	GET_DATA_PROFILE<any>(`/api/admin/profile/channel/${uid}/publisherDataShare`)
		.then(r=>r.data);
