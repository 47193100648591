import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from '@mui/material';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { invitationCloseInfoModalAction, invitationOnRefreshAction } from '../store/InvitationActionsActions';
import { connect } from 'react-redux';
import { copyToClipboard } from '../../../app/utils/copyManager';
import FileCopyIcon from '@mui/icons-material/FileCopy';

interface StateProps {
    isOpen: boolean;
    invitationLink: string
}

interface DispatchProps {
    closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const InfoInvitationModal = (props: Props) => {
  const { isOpen, closeModal, invitationLink } = props;

  const handleClose = () => {
      closeModal();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'lg'}
      disableEnforceFocus={true}
    >
      <DialogTitle>New invitation</DialogTitle>
      <DialogContent>
        <DialogContentText>
            This is a newly created invitation link. <br/>
            Invitation link: {invitationLink}
					<IconButton onClick={() => copyToClipboard(invitationLink)} size="large">
                <FileCopyIcon/>
            </IconButton>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
				<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
              Close
          </Button>
      </DialogActions>
    </Dialog>
  )
};

const mapStateToProps = (state: RootState) => ({
    isOpen: state.invitations.actions.infoModal.isOpen,
    invitationLink: state.invitations.actions.infoModal.invitationLink,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    closeModal: () => {
        dispatch(invitationCloseInfoModalAction());
        dispatch(invitationOnRefreshAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoInvitationModal);

