import { useHistory, useLocation } from "react-router-dom";

type UrlHashElement = string

/** Store and retrieve an array of strings from the url hash. The strings are
 * 	stored separated by "/". If there are no special characters in the strings
 *  they can be safely stored direct. Otherwise they should be encoded/decoded
 * 	using encodeURIComponent or the urlHashGetParam/urlHashSetParam from this
 * 	module */
export const useUrlHash = (): [ UrlHashElement[], (...hash: UrlHashElement[]) => void ] => {
	const history = useHistory();
	const location = useLocation();
	const hash = location.hash?.substring(1).split("/").map(decodeURIComponent);
	const setHash = (...hash: string[]) => history.replace({ ...location, 
		hash: hash.map(encodeURI).map(r=>r.replaceAll("/", "%3D")).join("/")
	});
    return [ hash, setHash ];
}

/** Get value from a string array as stored by urlHashSetParam */
export const urlHashGetParam = (hash: string[], key: string) => {
	const start = encodeURIComponent(key) + "=";
	const val = hash.find(h=>h.startsWith(start))?.substring(start.length);
	return val && decodeURIComponent(val);
}

/** Add a key/value pair into a string array. The pair will be uri-encoded
 *  suitably for storage in a uri and can be retrieved using urlHashGetParam */
export const urlHashSetParam = (hash: string[], key: string, value: string) => { 
	const start = encodeURIComponent(key) + "=";
	const current = hash.findIndex(h=>h.startsWith(start));
	if (current>=0) hash.splice(current, 1);
	hash.push(start + encodeURIComponent(value));
}

/** Convert a map object into a string array. The array will be uri-encoded
 *  suitably for storage in a uri and can be retrieved using urlHashGetParam */
export const urlHashObjectToHash = (params: { [key: string]: any; }) => {
	const hash: string[] = [];
	Object.keys(params).forEach(key=>params[key] !== undefined && urlHashSetParam(hash, key, params[key]));
	return hash;
}

/** Convert a string array to a map object. The array can be uri-encoded
 *  suitably for storage in a uri  */
export const urlHashGetParams = (hash: string[]) => {
	const out: { [key: string]: string } = {};
	hash.forEach(h=>{
		const pos = h.indexOf("=");
		const key = pos<0 ? h : h.substring(0, pos);
		const val = pos<0 ? "" : h.substring(pos + 1);
		out[key] = val;
	});
	return out;
}
