import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

const EditCurrentTableHeader = () => {

  return (
      <TableHead>
        <TableRow>
          <TableCell align="left" className="header">Code</TableCell>
          <TableCell align="left" className="header">Name</TableCell>
          <TableCell align="left" className="header">Rate</TableCell>
          <TableCell align="left" className="header">Symbol</TableCell>
          <TableCell align="left" className="header">Position</TableCell>
          <TableCell align="left" className="header">Actions</TableCell>
        </TableRow>
      </TableHead>
  );
};

export default EditCurrentTableHeader;
