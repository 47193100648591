import React from 'react';
import { Table } from '@mui/material';
import CurrencyTableHeader from './CurrencyTableHeader';
import CurrentTableBody from './CurrencyTableBody';

const CurrencyTable = () => {

  return (
    <Table className="table" size="small" padding="checkbox" stickyHeader>
      <CurrencyTableHeader />
      <CurrentTableBody/>
    </Table>
  );
};

export default CurrencyTable;
