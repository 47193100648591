import { Affiliate } from 'app/domain/Affiliate';
import { PageResponse, toPage } from 'app/http/PageResponse';
import { DELETE, GET, POST } from 'app/http/partnerApi/partnerClient';

export const addChannelAffiliate = async (channelUid: string, affiliateChannelUid: string) => {
	if (affiliateChannelUid === channelUid) return;
	POST('/admin/affiliate', {
		channelUid,
		affiliateChannelUid
	});
};

export const removeChannelAffiliate = async (channelUid: string, affiliateChannelUid: string) => {
	DELETE('/admin/affiliate', {
		channelUid,
		affiliateChannelUid
	});
};

export const getChannelAffiliates = async (channelUid: string, page: number) =>
	GET<PageResponse<Affiliate>>('/admin/affiliates', {
		params: { page, channelUid, perPage: 20 }
	}).then(r => toPage(r.data));
