export interface FilterValue {
  name: string;
  label: string;
  additionalData?: any;
}

export const existsFilter = (filters: FilterValue[], singleFilter: FilterValue): boolean => {
  return filters.find(el => el.name === singleFilter.name) !== undefined;
};

export const toggleFilter = (filters: FilterValue[], singleFilter: FilterValue): FilterValue[] => {
  if (existsFilter(filters, singleFilter)) {
    return filters.filter(el => el.name !== singleFilter.name);
  } else {
    return [...filters, singleFilter];
  }
};
