import React from 'react';
import { getIsPersonalPartner, Partner } from '../../../app/domain/Partner';
import { Dialog } from '@mui/material';
import IndividualPartnerForm from './IndividualPartnerForm';
import OrganisationPartnerForm from './OrganisationPartnerForm';
import { createPartnerEditData, editPartnerApi } from '../suspend/SuspendPartnerUseCase';

export interface PartnerDetails {
	name?: string;
	companyNumber?: string;
	vatNumber?: string;
	email?: string;
	phone?: string;
	position?: string;
	countryCode?: string;
	stateCode?: string;
	postalCode?: string;
	addressLines?: string;
	secondAddressLine?: string;
}

interface Props {
	isOpen: boolean;
	refresh: () => void;
	closeModal: () => void;
	partner: Partner;
}
  
const EditPartnerModal = ({closeModal, isOpen, refresh, partner}: Props) => {
	const details = createPartnerEditData(partner);

	const onSubmit = (values: PartnerDetails) => {
		editPartnerApi(partner.uid, values).then(refresh);
		closeModal();
	};

	return (
		<Dialog className="dialog" open={isOpen} onClose={closeModal} fullWidth={true} maxWidth={'lg'}>
			{getIsPersonalPartner(partner) 
				? <IndividualPartnerForm closeModal={closeModal} partner={details} onSubmit={onSubmit}/> 
				: <OrganisationPartnerForm closeModal={closeModal} partner={details} onSubmit={onSubmit} />}
		</Dialog>
	);
};

export default EditPartnerModal;
