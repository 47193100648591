import React from 'react';
import { RootState } from 'app/store/store';
import { useSelector } from 'react-redux';
import { createInvitationUseCase } from './CreateInvitationUseCase';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextField } from 'formik-material-ui';
import { invitationCloseCreateModalAction } from 'invitations/actions/store/InvitationActionsActions';
import { InvitationData } from 'app/domain/Invitation';
import { isEmail } from 'app/domain/validation';
import CategoriesMultiSelect from 'app/components/inputs/CategoriesMultiSelect';
import { useAppDispatch } from 'app/store/hooks';

interface FormValues {
	email: string;
	ceilingCategoriesIds: number[];
}

const CreateInvitationModal = () => {
	const dispatch = useAppDispatch();
	const isOpen = useSelector((state: RootState) => state.invitations.actions.createModal.isOpen);

	const initialValues: FormValues = {
		email: '',
		ceilingCategoriesIds: []
	};

	const ceilingCategoriesValidator = (value: number[]): string =>
		value.length ? '' : 'Please select at least one category';

	const onSubmit = async (values: FormValues) => {
		const invitationData: InvitationData = {
			email: values.email,
			ceilingCategoriesIds: values.ceilingCategoriesIds
		};
		dispatch(createInvitationUseCase(invitationData));
	};

	const closeModal = () => dispatch(invitationCloseCreateModalAction());

	return (
		<Dialog className="dialog" fullWidth maxWidth="sm" open={isOpen} onClose={closeModal}>
			<Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
				<Form>
					<DialogTitle>Create new invitation</DialogTitle>
					<DialogContent>
						<Field
							name="email"
							label="Email"
							component={TextField}
							validate={isEmail}
							className="formField"
							variant="outlined"
							fullWidth
						/>
						<Field
							name="ceilingCategoriesIds"
							label="Ceiling categories"
							component={CategoriesMultiSelect}
							validate={ceilingCategoriesValidator}
							fullWidth
							variant="outlined"
							className="formField"
						/>
						<ErrorMessage className="formError" name="ceilingCategoriesIds" />
					</DialogContent>
					<DialogActions className="dialogActions">
						<Button variant="contained" onClick={closeModal} className="button-cancel">
							Close
						</Button>
						<Button variant="contained" type="submit" color="secondary" className="button-yes">
							Save
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
};

export default CreateInvitationModal;
