import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { APIResponse, AxiosRequestConfigProps } from 'app/http/mediaApi/mediaClient';
import { getBaseApiUrl } from 'app/http/request';
import { getAuthAccessToken, resetAccessTokenOnUnauthorized } from 'app/http/authApi/token';

export const AUTH_TOKEN_NAME = 'Authorization';
export const ACCESS_TOKEN_NAME = 'accesstoken';

const AUTHORIZED_URLS = ['auth/login', 'auth/logout', 'auth/refresh-token'];

const clientAuth: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

const getAuthApiUrl = (suffix?: string): string => getBaseApiUrl('auth', suffix);

const isAuthorizedUrl = (url: string | undefined): boolean => {
	if (!url) return false;
	return !AUTHORIZED_URLS.some(authorizedUrl => url.includes(authorizedUrl));
};

const addAuthAccessToken = (config: InternalAxiosRequestConfig) => {
	const token = getAuthAccessToken();
	if (token && isAuthorizedUrl(config.url)) {
		config.headers.set(ACCESS_TOKEN_NAME, token);
	}
	return config;
};

clientAuth.interceptors.response.use(res => res, resetAccessTokenOnUnauthorized);
clientAuth.interceptors.request.use(addAuthAccessToken, Promise.reject);

export const POST_AUTH = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<U>;
	return clientAuth.post<typeof data, Response>(getAuthApiUrl(url), data, config);
};

export const GET_AUTH = <U>(url: string, config?: AxiosRequestConfig) => {
	return clientAuth.get<APIResponse<U>>(getAuthApiUrl(url), config);
};

export const DELETE_AUTH = <U>(url: string, data?: unknown) => {
	return clientAuth.delete<typeof data, AxiosResponse<APIResponse<U>>>(getAuthApiUrl(url), { data: data });
};
