export enum RoutePaths {
	Default = '/videos',
	VideoDetails = '/video',
	Users = '/users',
	UserDetails = '/user',
	Videos = '/videos',
	Categories = '/categories',
	Login = '/login',
	Accounts = '/accounts',
	AccountDetails = '/account',
	Channels = '/channels',
	CashOuts = '/cashouts',
	ChannelDetails = '/channel',
	Reports = '/reports',
	Invitations = '/invitations',
	InvitationDetails = '/invitation',
	Publishers = '/publishers',
	PublisherDetails = '/publisher',
	Unverified = '/unverified',
	Currency = '/currency',
	Purchases = '/purchases',
	Root = '/'
}

export default RoutePaths;
