import React from 'react';
import { Dialog } from '@mui/material';

interface Props { 
	isOpen: boolean, 
	categoryId: number,
	closeModal: ()=>void,
}

const DeleteCategoryModal = ({ isOpen, closeModal }: Props) => {
	return (
		<Dialog className="dialog" fullWidth maxWidth="sm" open={isOpen} onClose={closeModal}>
			TODO: Deleting category will be here
		</Dialog>
	);
};

export default DeleteCategoryModal;
