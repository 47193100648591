import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Checkbox, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup } from '@mui/material';
import { getReadyToPublishVideos, Video } from 'app/domain/Video';
import { publishVideo, setChannelStatusApi } from 'channels/details/section/summary/ChannelDetailsSummaryUseCase';

interface Props {
	isOpen: boolean;
	uid: string;
	videos: Video[];
	closeModal: ()=>void;
}

const PublishVideosModal = ({ isOpen, uid, closeModal, videos }: Props) => {
	const [selectedVideosIds, setSelectedVideosIds] = useState<string[]>([]);
	const preparedVideos = getReadyToPublishVideos(videos);

	const publishChannel = async () => {
		await setChannelStatusApi(uid, 'LIVE');
		selectedVideosIds.forEach(publishVideo);
		closeModal();
	};

	useEffect(() => {
		const videosUid = preparedVideos?.map(video => video.uid) as string[];
		videosUid?.length && setSelectedVideosIds(videosUid);
	}, [preparedVideos]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		const selectedId = event.target.name;

		if (isChecked) {
			setSelectedVideosIds([...selectedVideosIds, selectedId]);
		} else {
			const updatedSelectedVideosIds = selectedVideosIds.filter(id => id !== selectedId);
			setSelectedVideosIds(updatedSelectedVideosIds);
		}
	};

	const getIsChecked = (id: string): boolean => {
		return selectedVideosIds.includes(id);
	};

	const renderCheckBox = (video: Video) => {
		return (
			<FormControlLabel
				key={video.uid}
				control={
					<Checkbox
						checked={getIsChecked(video.uid)}
						onChange={handleChange}
						checkedIcon={<CheckIcon />}
						name={video.uid}
					/>
				}
				label={video.name}
			/>
		);
	};

	return (
		<Dialog open={isOpen} onClose={closeModal} className="publishVideosModal">
			<DialogTitle>The following videos will be published as part of this action:</DialogTitle>

			<FormGroup className="publishVideosModal__videos">{preparedVideos.map(renderCheckBox)}</FormGroup>

			<DialogActions className={`dialogActions`}>
				<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
					Cancel
				</Button>
				<Button variant="contained" onClick={publishChannel} color="secondary" className={`button-yes`}>
					{selectedVideosIds.length ? 'Publish and go LIVE' : 'Go LIVE without publishing'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PublishVideosModal;
