import { AppThunk, AppThunkDispatch } from '../../../app/store/store';
import { makeRequest } from '../../../app/http/request';
import { createSuccessResult } from '../../../app/utils/result';
import { invitationCloseRemoveModalAction, invitationOnRefreshAction, } from '../store/InvitationActionsActions';
import { PUT_PARTNER } from '../../../app/http/partnerApi/partnerClient';

export const removeInvitationApi = async (dispatch: AppThunkDispatch, invitationId: number) =>
  makeRequest({
    dispatch,
    request: async () => {
      return await PUT_PARTNER<any>(`/admin/invitation/${invitationId}/status`, {status: 'REVOKED'});
    },
    responseSuccessHandler: () => createSuccessResult<void, string>(),
  });

export function removeInvitationUseCase(invitationId: number): AppThunk {
  return async (dispatch) => {
    await removeInvitationApi(dispatch, invitationId);
    await dispatch(invitationOnRefreshAction());
    dispatch(invitationCloseRemoveModalAction());
  };
}
