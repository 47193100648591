import React, {useState} from 'react';
import {connect} from "react-redux";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AppThunkDispatch, RootState} from "../../app/store/store";
import {FormControl, Grid, InputLabel, ListItemText, MenuItem, Select} from '@mui/material';
import {reportSetEndDate, reportSetStartDate} from "../store/ReportsActions";
import {dateFormat} from "../../app/store/common/Constants";
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import dayOfYear from "dayjs/plugin/dayOfYear"
import dayjs, {Dayjs} from 'dayjs';
import {DateFilterConfig} from "./DateFilterConfig";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

dayjs.extend(quarterOfYear)
dayjs.extend(dayOfYear)

interface StateProps {
    startDate?: Dayjs;
    endDate?: Dayjs;
}

interface DispatchProps {
    setStartDate: (startDate: Dayjs) => void;
    setEndDate: (endDate: Dayjs) => void;
}

type Props = StateProps & DispatchProps;

const DateFilter = (props: Props) => {
    const [autoDateRange, setAutoDateRange] = useState('');

    const {startDate, setStartDate, endDate, setEndDate} = props;

    const handleDateChange = (startDate: Dayjs, endDate: Dayjs) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleStartDateChange = (date: Dayjs | null) => {
        if (date !== null) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date: Dayjs | null) => {
        if (date !== null) {
            setEndDate(date);
        }
    };

    const handleChange: SelectInputProps<string>['onChange'] = (event) => {
        const value = event.target.value;
        const dateFilter = DateFilterConfig.find(dateFilter => dateFilter.name === value);

        if (dateFilter) {
            setAutoDateRange(value);
            handleDateChange(dateFilter.getStartDate(), dateFilter.getEndDate())
        }
    };

	return (
		<div className="filterDate">
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Grid container justifyContent="flex-start">
					<Grid container item xs={12}>
						<Grid container item xs={12} sm={6} md={3}>
							<DatePicker
								className="dateControl"
								format={dateFormat}
								label="Start date"
								maxDate={endDate}
								value={startDate}
								onChange={handleStartDateChange}
								slotProps={{ textField: { variant: 'standard' } } }
							/>
						</Grid>
						<Grid container item xs={12} sm={6} md={3}>
							<DatePicker
								className="dateControl"
								format={dateFormat}
								label="End date"
								minDate={startDate}
								maxDate={dayjs().subtract(1, 'day')}
								value={endDate}
								onChange={handleEndDateChange}
								slotProps={{ textField: { variant: 'standard' } } }
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<FormControl className="formControl" variant="standard">
									<InputLabel id="select-label">Auto date range</InputLabel>
									<Select
										labelId="select-label"
										id="select"
										value={autoDateRange}
										onChange={handleChange}
									>
										{DateFilterConfig.map(dateFilter => (
											<MenuItem key={dateFilter.name} value={dateFilter.name}>
												<ListItemText primary={dateFilter.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</LocalizationProvider>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
    startDate: state.report.filters.dates[0],
    endDate: state.report.filters.dates[1],
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    setStartDate: (startDate: Dayjs) => {
        dispatch(reportSetStartDate(startDate));
    },
    setEndDate: (endDate: Dayjs) => {
        dispatch(reportSetEndDate(endDate));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
