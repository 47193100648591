import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import { getPlayerConfig } from './playerDrmManager';
import { isSafariBrowser } from './playerDrmManager';
import { DrmInfo } from './playerDrmManager';
import { TIME_TO_HIDE_PREMIUM_VIDEO_UI_IN_MILLISECONDS } from 'constants/Common';

require('videojs-contrib-eme');

interface MediaPlayerOptions extends VideoJsPlayerOptions {
	errorDisplay: boolean;
}

class MediaPlayer extends videojs.getComponent('Player') {
	private static readonly VIDEO_JS_CONFIG: MediaPlayerOptions = {
		preload: 'auto',
		autoplay: false,
		muted: false,
		controls: true,
		inactivityTimeout: TIME_TO_HIDE_PREMIUM_VIDEO_UI_IN_MILLISECONDS,
		controlBar: { volumePanel: { inline: true } },
		errorDisplay: false,
		techOrder: ['html5'],
		html5: {
			vhs: {
				overrideNative: !isSafariBrowser(),
				limitRenditionByPlayerDimensions: false
			},
			nativeAudioTracks: false,
			nativeVideoTracks: false
		}
	};

	videoElement: HTMLVideoElement;
	drmPlayerConfig?: any;
	isPlayPromiseFinished: boolean;

	// videojs extensions types/functions
	activePlugins_?: { eme?: unknown };
	eme?: (() => void);

	constructor(videoElement: any, width: number, height: number) {
		super(videoElement, MediaPlayer.VIDEO_JS_CONFIG);

		this.videoElement = videoElement;
		this.drmPlayerConfig = null;
		this.isPlayPromiseFinished = true;
		this.width(width);
		this.height(height);
	}

	playWithHandleError = (): any => {
		const playPromise = this.play();

		// when videos with sound won't autoplay
		if (playPromise) {
			this.isPlayPromiseFinished = false;
			playPromise
				.then(() => (this.isPlayPromiseFinished = true))
				.catch(() => {
					this.videoElement.muted = true;
					this.videoElement.play().finally(() => (this.isPlayPromiseFinished = true));
				});
		}
	};

	getExtension = (value: string): string | undefined => {
		return value.split('.').pop();
	};

	loadDrmStreamUrl = (streamUrl: string, drmProviderInfo: DrmInfo): void => {
		if (this.drmPlayerConfig) {
			return this.src(this.drmPlayerConfig);
		}

		if (!this.activePlugins_?.eme) {
			this.eme?.();

			getPlayerConfig(streamUrl, drmProviderInfo)
				.then((playerConfig: any) => {
					this.drmPlayerConfig = playerConfig;
					this.src(playerConfig);
				})
				.catch(error => {
					console.log(error);
				});
		}
	};

	loadStreamUrl = (streamUrl: string): void => {
		const srcExtension = this.getExtension(streamUrl);
		const srcType = srcExtension === 'm3u8' ? 'application/x-mpegURL' : `video/` + srcExtension;

		this.src({
			type: srcType,
			src: streamUrl
		});
	};

	destroy = (): void => {
		this.dispose();
	};
}

export default MediaPlayer;
