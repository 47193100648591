import React, { useEffect, useState } from 'react';
import { Box, Chip, Container, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Title from '../../app/components/title/Title';
import PartnerFilter from './filter/PartnerFilter';
import { Partner, PartnerSortField, PartnersQuery } from 'app/domain/Partner';
import { useDebouncedAction } from 'app/utils/searchManager';
import { fetchPartnersApi } from './PartnersUseCase';
import TableSortHeader from 'app/components/table/TableSortHeader';
import Pagination from 'app/components/pagination/Pagination';
import { Page } from 'app/domain/Page';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'app/navigation/RoutePaths';
import PartnerActionButtons from 'partners/actions/PartnerActionButtons';

const PartnersScreen = () => {
    const [ query, setQuery ] = useState<PartnersQuery>({
        page: 1,
        sort: { direction: 'desc', field: 'created'},
        statuses: []
    });

	const [ page, setPage] = useState<Page<Partner>>();
  
	const fetch = useDebouncedAction(()=>fetchPartnersApi(query).then(setPage));
  
	useEffect(()=>{ fetch(); }, [query]);

    const { sort } = query;
    const setSort = (field: PartnerSortField) => 
      setQuery({
         ...query, 
         sort: { field, direction: sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc' } 
      });

    const history = useHistory();

    const navigateToPartnerDetails = (uid: string) => {
        history.push(`${RoutePaths.PublisherDetails}/${uid}`);
    };

    return <Container className="tagsScreen">
            <Title>Publishers</Title>
            <PartnerFilter query={query} setQuery={setQuery}/>
            { !page ? <>Loading</> : <>
                <Table className="table" size="small" padding="checkbox" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableSortHeader label="Id" fieldName="uid" sort={sort} setSort={setSort} />
                        <TableSortHeader label="Name" fieldName="name" sort={sort} setSort={setSort} />
                        <TableCell align="left" className="header">Status</TableCell>
                        <TableCell align="left" className="header">Email</TableCell>
                        <TableSortHeader label="Created" fieldName="created" sort={sort} setSort={setSort} />
                        <TableCell align="left" className="header">Type</TableCell>
                        <TableCell align="left" className="header">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        { page.items.map((partner) => (
								<TableRow
									key={partner.uid}
									style={{ height: '3em' }}
									onClick={() => navigateToPartnerDetails(partner.uid)}
								>
                                <TableCell><div className="name">{partner.uid}</div></TableCell>
                                <TableCell><div className="name">{partner.name}</div></TableCell>
                                <TableCell><Chip label={partner.status} /></TableCell>
                                <TableCell><div className="name">{partner.email}</div></TableCell>
                                <TableCell><div className="name">{partner.created}</div></TableCell>
                                <TableCell><Chip label={partner.isPersonal ? 'individual' : 'organisation'} /></TableCell>
                                <TableCell><PartnerActionButtons partner={partner} onRefresh={fetch} /></TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
                <Box justifyContent="center" className="paginationContainer">
                    <Pagination page={page} changePage={page=>setQuery({ ...query, page })} />
                </Box>
            </>}
        </Container>;
};

export default PartnersScreen;
