import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Currency } from '../../../../app/domain/Currency';

interface UnverifiedRowProps {
    current: Currency;
}

type Props = UnverifiedRowProps;

const CurrencyRow = (props: Props) => {
    const { current } = props;

    return (
        <TableRow style={{ height: '2.5em' }}>
          <TableCell>{current.currencyCode}</TableCell>
          <TableCell>{current.currencyName}</TableCell>
          <TableCell>{current.rate}</TableCell>
          <TableCell>{current.currencySymbol}</TableCell>
          <TableCell>{current.currencySymbolDisplayPosition}</TableCell>
        </TableRow>
    );
};

export default CurrencyRow;
