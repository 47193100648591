import React from "react";
import { Pagination as MuiPagination } from '@mui/material';
import { Page } from "../../domain/Page";

interface PaginationProps {
  page: Page<any>;
  changePage: (page: number) => void;
}

interface Boundaries {
  from: number;
  to: number;
}

const Pagination = (props: PaginationProps) => {
  const { page, changePage } = props;
  const { from, to } = getBoundaries(page);
	const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    changePage(value);
  };
  return (
    <div className="pagination">
      <span className="overview">{from} - {to} of { page.totalCount }</span>
      <MuiPagination className="numbers" count={page.pageCount} page={page.currPage} onChange={handleChange} />
    </div>
  );
};

function getBoundaries(page: Page<any>): Boundaries {
  const { currPage, pageCount, perPage, totalCount } = page;

  if (totalCount === 0) {
    return { from: 0, to: 0 };
  } else {
    return {
      from: (currPage - 1) * perPage + 1,
      to: (currPage === pageCount) ? totalCount : currPage * perPage,
    };
  }
}

export default Pagination;
