import { AppThunk, AppThunkDispatch } from 'app/store/store';
import { makeRequest } from 'app/http/request';
import { POST } from 'app/http/mediaApi/mediaClient';
import { createSuccessResult } from 'app/utils/result';
import { videoCloseUnsuspendModalAction, videoOnRefreshAction } from 'videos/actions/store/VideoActionsActions';

const unsuspendVideoApi = async (dispatch: AppThunkDispatch, videoId: string, reason?: string) =>
	makeRequest({
		dispatch,
		request: async () => {
			return await POST<void>(`admin/video/unsuspend/${videoId}`, { reason });
		},
		responseSuccessHandler: () => createSuccessResult<void, string>()
	});

export function unsuspendVideoUseCase(videoId: string, reason?: string): AppThunk {
	return async dispatch => {
		await unsuspendVideoApi(dispatch, videoId, reason);
		await dispatch(videoOnRefreshAction());
		dispatch(videoCloseUnsuspendModalAction());
	};
}
