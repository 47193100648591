import React from 'react';
import { Box, TextField } from '@mui/material';
import { ChannelsQuery } from 'channels/list/filter/ChannelFilter';

interface AffiliatesFilterProps {
	usersQuery: ChannelsQuery;
	onChange: React.Dispatch<React.SetStateAction<ChannelsQuery>>;
}

const AffiliatesFilter = (props: AffiliatesFilterProps) => {
	const { usersQuery, onChange } = props;
	return (
		<div className="filter">
			<Box>
				<TextField
					style={{ width: '350px' }}
					id="standard-basic"
					label="Channel uid query"
					onChange={e => {
						onChange({ ...usersQuery, channelUidSearchText: e.target.value });
					}}
				/>
			</Box>
			<Box>
				<TextField
					style={{ width: '350px' }}
					id="standard-basic"
					label="Channel name query"
					onChange={e => {
						onChange({ ...usersQuery, channelNameSearchText: e.target.value });
					}}
				/>
			</Box>
		</div>
	);
};

export default AffiliatesFilter;
