import Hashids from 'hashids';
import { v4 as uuidv4 } from 'uuid';
import base32Encode from 'base32-encode';
import base32Decode from 'base32-decode';
import { Buffer } from 'buffer';

const HASHIDS_SALT = '4V;@da_V_"@GPg9)';
const HASHIDS_LENGTH = 5;

const GUID_BITS_LENGTH = 128;
const GROUP_BY_FOUR_DIGITS = /.{1,4}/g;

export const CROCKFORD_GUID_PATTERN = /^([0-9A-HJ-NP-Z]{4}-){6}[0-9A-HJ-NP-Z]{2}$/g;

export class GUID {
  private readonly uuid: string;

  public static create() {
    const uuid = uuidv4();
    return new GUID(uuid);
  }

  public static createFromUid(uid: string) {
    const uuid = this.convertLegacyUidToUuid(uid);
    return new GUID(uuid);
  }

  public static createFromUuid(uuid: string) {
    return new GUID(uuid);
  }

  public static createFromBase32(base32Id: string) {
    const bytes = base32Decode(base32Id.replace(/-/g, ''), 'Crockford');

    const uuidBuffer = new Uint8Array(bytes).buffer;
    const hexUuid = Array.from(new Uint8Array(uuidBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    return new GUID(GUID.toUuidFormat(hexUuid));
  }

  private constructor(value: string) {
    this.uuid = value;
  }

  toUuid() {
    return this.uuid;
  }

  toCrockfordBase32() {
    const base32String = base32Encode(Buffer.from(this.toHex(), 'hex'), 'Crockford');
    const groups = base32String.match(GROUP_BY_FOUR_DIGITS);
    return groups ? groups.join('-') : '';
  }

  toHex() {
    const buffer = Buffer.from(this.uuid.replace(/-/g, ''), 'hex');
    return buffer.toString('hex');
  }

  toLegacyUid() {
    return GUID.encodeSerialId(parseInt(this.toHex(), 16));
  }

  private static convertLegacyUidToUuid(uid: string): string {
    const serialId = GUID.decodeSerialId(uid);
    const binaryStr = (serialId >>> 0).toString(2);
    const paddingZeros = '0'.repeat(GUID_BITS_LENGTH - binaryStr.length);
    const paddedBinaryStr = paddingZeros + binaryStr;
    const hexStr = [];
    for (let i = 0; i < GUID_BITS_LENGTH; i += 8) {
      hexStr.push(
        parseInt(paddedBinaryStr.slice(i, i + 8), 2)
          .toString(16)
          .padStart(2, '0')
      );
    }
    return GUID.toUuidFormat(hexStr.join(''));
  }

  private static toUuidFormat(hex: string) {
    return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;
  }

  private static decodeSerialId(uid: string) {
    const hashids = new Hashids(HASHIDS_SALT, HASHIDS_LENGTH);
    return hashids.decode(uid)[0] as number;
  }

  private static encodeSerialId(serialId: number) {
    const hashids = new Hashids(HASHIDS_SALT, HASHIDS_LENGTH);
    return hashids.encode(serialId);
  }
}
