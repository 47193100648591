import React from 'react';
import { Chip, TableCell, TableRow } from '@mui/material';
import { useHistory } from 'react-router-dom';
import RoutePaths from '../../../app/navigation/RoutePaths';
import { Channel } from '../../../app/domain/Channel';
import ChannelActionButtons from '../../actions/ChannelActionButtons';

interface Props {
  channel: Channel;
  onRefresh: () => void;
}

const ChannelRow = ({ channel, onRefresh }: Props) => {
  const history = useHistory();

  const navigateToChannelDetails = (uid: string) => {
    history.push(`${RoutePaths.ChannelDetails}/${uid}`);
  };

  return (
		<TableRow onClick={ () => navigateToChannelDetails( channel.channelUid ) }>
      <TableCell className='id-cell'>
        <div className="id">{channel.channelUid}</div>
      </TableCell>
      <TableCell className='name-cell'>
        <div className="name">{channel.channelName}</div>
      </TableCell>
      <TableCell className='status-cell'>
       <Chip label={channel.channelStatus} />
      </TableCell>
      <TableCell className='followers-cell'>
        <div>{channel.channelFollowerCount}</div>
      </TableCell>
      <TableCell className='publisher-cell'>
       <div className="name">{channel.partnerName}</div>
        <div className='publisher-email'>{channel.partnerEmail}</div>
      </TableCell>
      <TableCell className='created-cell'>
        <div>{channel.channelCreated.replace(".000Z", "").replace("T", " ")}</div>
      </TableCell>
      <TableCell className='actions-cell'>
        <ChannelActionButtons uid={channel.channelUid} status={channel.channelStatus} refresh={onRefresh} />
      </TableCell>
    </TableRow>
  );
};

export default ChannelRow;
