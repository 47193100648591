import {AppThunk} from "../../app/store/store";
import {Sort} from "../../app/domain/Sort";
import {VideosSortField} from "../../app/domain/VideoStatistics";
import { Dayjs } from 'dayjs';

interface SetVerifiedUser {
    type: 'REPORT_SET_VERIFIED_USER';
    uid: string;
}

interface SetCheckedVideoTitlesData {
    type: 'REPORT_SET_CHECKED_VIDEO_TITLE_DATA';
    checkedTitles: string[];
}

interface SetStartDate {
    type: 'REPORT_SET_START_DATE';
    startDate: Dayjs;
}

interface SetEndDate {
    type: 'REPORT_SET_END_DATE';
    endDate: Dayjs;
}

interface SetSort {
    type: 'VIDEOS_SET_SORT';
    sort: Sort<VideosSortField>;
}

export type ReportStateTypes =
    SetVerifiedUser |
    SetCheckedVideoTitlesData |
    SetStartDate |
    SetEndDate |
    SetSort;

export function reportSetVerifiedUser(uid: string): AppThunk {
    return (dispatch) => {
        dispatch({type: 'REPORT_SET_VERIFIED_USER', uid});
    };
}

export function reportSetCheckedVideoTitleData(checkedTitles: string[]): AppThunk {
    return (dispatch) => {
        dispatch({type: 'REPORT_SET_CHECKED_VIDEO_TITLE_DATA', checkedTitles});
    };
}

export function reportSetStartDate(startDate?: Dayjs): AppThunk {
    return (dispatch) => {
        dispatch({type: 'REPORT_SET_START_DATE', startDate});
    };
}

export function reportSetEndDate(endDate?: Dayjs): AppThunk {
    return (dispatch) => {
        dispatch({type: 'REPORT_SET_END_DATE', endDate});
    };
}

export function videosSetSortAction(sort: Sort<VideosSortField>): AppThunk {
    return (dispatch) => {
        dispatch({ type: 'VIDEOS_SET_SORT', sort });
    };
}
