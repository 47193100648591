interface OpenEditModal {
  type: 'CURRENCIES_OPEN_EDIT_MODAL';
}

interface CloseEditModal {
  type: 'CURRENCIES_CLOSE_EDIT_MODAL';
}

export type CurrentActionsStateTypes =
    OpenEditModal |
    CloseEditModal;


export function currenciesOpenEditModalAction(): CurrentActionsStateTypes {
  return {
    type: 'CURRENCIES_OPEN_EDIT_MODAL',
  };
}

export function currenciesCloseEditModalAction(): CurrentActionsStateTypes {
  return {
    type: 'CURRENCIES_CLOSE_EDIT_MODAL',
  };
}
