import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-material-ui';
import React from 'react';

interface DrmModalProps {
	isEnabled: boolean;
	isOpen: boolean;
	onSave: (value: boolean) => void;
	onClose: () => void;
}

const DrmModal = ({ isEnabled, isOpen, onSave, onClose }: DrmModalProps) => {
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>Edit DRM on channel</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{ isEnabled }}
				onSubmit={values => {
					onSave(values.isEnabled);
					onClose();
				}}
			>
				{({ dirty, isValid, values }) => (
					<Form>
						<DialogContent>
							<div className="helpText">This will be applied to all types of content</div>
							<div className="horizontalAlign">
								<Field fullWidth name="isEnabled" component={Switch} checked={values.isEnabled} />
								<span className="additionalFormText">Enable DRM</span>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button type="submit" color="secondary" variant="contained" disabled={!dirty || !isValid}>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default DrmModal;
