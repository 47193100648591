import { AppThunk, AppThunkDispatch } from 'app/store/store';
import { makeRequest } from 'app/http/request';
import { POST_AUTH } from 'app/http/authApi/authClient';
import { POST } from 'app/http/mediaApi/mediaClient';
import { createSuccessResult } from 'app/utils/result';
import { userCloseSuspendModalAction } from 'users/actions/store/UsersActionsActions';
import { Status } from 'app/domain/User';

interface SuspendUserBody {
	suspend: boolean;
	reason: string;
}

const suspendUserAuth = async (dispatch: AppThunkDispatch, uid: string, body: SuspendUserBody) =>
	makeRequest({
		dispatch,
		request: async () => {
			const result = await POST_AUTH<void>(`admin/user/${uid}/suspend`, body);
			return result.data;
		},
		responseSuccessHandler: () => createSuccessResult()
	});

const suspendUserApi = async (dispatch: AppThunkDispatch, uid: string, statusId: number) =>
	makeRequest({
		dispatch,
		request: async () => {
			const result = await POST<any>(`admin/user/${uid}`, { statusId });
			return result.data;
		},
		responseSuccessHandler: () => createSuccessResult()
	});

export function suspendUserUseCase(uid: string, body: SuspendUserBody): AppThunk {
	const statusId = body.suspend ? Status.SUSPENDED : Status.LIVE;

	return async (dispatch, getState) => {
		await suspendUserAuth(dispatch, uid, body);
		await suspendUserApi(dispatch, uid, statusId);
		dispatch(userCloseSuspendModalAction());
		getState().users.actions.onRefresh();
	};
}
