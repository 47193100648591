import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider
} from '@mui/material';
import { Form, Formik } from 'formik';
import { RootState } from 'app/store/store';
import * as Yup from 'yup';
import { containsOnly } from 'app/utils/arrayManager';
import cashOutUseCase, { ManualCashOut, parseCashOutLine } from 'cashouts/action/CashOutUseCase';
import { closeUploadModal } from 'cashouts/store/cashOutsActions';
import { FormikHelpers } from 'formik/dist/types';

const CashOutsSchema = Yup.object().shape({
	lines: Yup.array().min(1, 'CSV Required').required('CSV Required')
});

const validHeaders = ['publisherId', 'amount', 'description', 'effectiveDate'];

interface FormValues {
	lines: ManualCashOut[];
}

const CashOutsModal = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector((state: RootState) => state.cashOuts.uploadModal.isOpen);

	const onClose = () => {
		setServerError(null);
		dispatch(closeUploadModal());
	};

	const [serverError, setServerError] = useState<string | null>(null);

	const onSubmit = async (values: any, onError: () => void) => {
		const result = await cashOutUseCase(values.lines);

		if (result.error) {
			setServerError(result.error.messages[0].message);
			onError();
		} else {
			dispatch(closeUploadModal());
		}
	};

	const parseCSVCashOuts = (lines: string[]): ManualCashOut[] => {
		const headers = lines.shift()?.split(',') || [];


		if (!containsOnly(validHeaders, headers) || headers.length !== 4) {
			throw new Error('Invalid CSV, headers must only include: publisherId, amount, description, effectiveDate');
		}

		if (!lines.length) {
			throw new Error('Invalid CSV, must include at least one publisher to update');
		}

		const nonEmptyLines = lines.map(line => line).filter(line => line.length);
		const formattedLines: ManualCashOut[] = [];
		const errors: string[] = [];

		nonEmptyLines.forEach((line, idx) => parseCashOutLine(line, idx, formattedLines, errors));

		if (errors.length) {
			throw new Error(...errors);
		}

		return formattedLines;
	};

	const onFileUpload = (
		event: React.ChangeEvent<HTMLInputElement>,
		setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
		setFieldError: FormikHelpers<FormValues>['setFieldError'],
		setFieldTouched: FormikHelpers<FormValues>['setFieldTouched']
	) => {
		const file = event.currentTarget?.files?.[0];
		if (!file) {
			return;
		}

		const reader = new FileReader();

		reader.onload = function (event) {
			serverError && setServerError(null);

			const result = event.target?.result;
			if (typeof result !== 'string') {
				setFieldError('lines', 'invalid file');
				return;
			}

			const lines = result.split(/\r?\n/);
			try {
				setFieldValue('lines', parseCSVCashOuts(lines));
			} catch (e) {
				setFieldError('lines', `${e}`);
			}
		};
		setFieldTouched('lines', true);
		reader.readAsText(file);
	};

	const onFileChange = (
		event: React.MouseEvent<HTMLInputElement, MouseEvent>,
		setFieldValue: FormikHelpers<FormValues>['setFieldValue']
	) => {
		setFieldValue('lines', []);
		(event.target as HTMLInputElement).value = '';
	};

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="lg" className="manualCastModal">
			<Formik
				initialValues={
					{
						lines: []
					} satisfies FormValues
				}
				validationSchema={CashOutsSchema}
				onSubmit={(values, { setSubmitting }) =>
					onSubmit(values, () => {
						setSubmitting(false);
					})
				}
			>
				{({
					errors,
					values,
					setFieldValue,
					setFieldError,
					isValid,
					dirty,
					touched,
					setFieldTouched,
					isSubmitting
				}) => (
					<Form>
						<DialogTitle>Manual Cash Outs</DialogTitle>
						<DialogContent>
							<DialogContentText>Upload a CSV to create cash outs</DialogContentText>
							<Divider className="divider" />
							<br />
							<input
								id="lines"
								name="lines"
								accept=".csv"
								type="file"
								onChange={event => onFileUpload(event, setFieldValue, setFieldError, setFieldTouched)}
								onClick={event => onFileChange(event, setFieldValue)}
							/>
							{!values.lines.length && touched.lines ? (
								<div className="manualCastModal__error">{errors.lines}</div>
							) : null}
							{serverError && <div className="manualCastModal__error">{serverError}</div>}
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Close
							</Button>
							<Button type="submit" color="primary" disabled={isSubmitting || !(isValid && dirty)}>
								Submit
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default CashOutsModal;
