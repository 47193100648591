import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';

interface RecastPercentageProps {
	isOpen: boolean;
	onSave: (value: number) => void;
	onClose: () => void;
	recastPercentage: number;
	resolutionHeight: number;
}

const min = 1;
const max = 100;
const validationMessage = `Recast percentage must be between ${min} and ${max}`;

const ValidationSchema = Yup.object().shape({
	recastPercentage: Yup.number().max(max, validationMessage).min(min, validationMessage)
});

const RecastPercentageModal = ({
	isOpen,
	onClose,
	onSave,
	recastPercentage,
	resolutionHeight
}: RecastPercentageProps) => {
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>Edit recast percentage</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{ recastPercentage }}
				onSubmit={values => {
					onSave(values.recastPercentage);
					onClose();
				}}
				validationSchema={ValidationSchema}
			>
				{({ dirty, isValid }) => (
					<Form>
						<DialogContent>
							<div className="helpText">Edit recast percentage for {resolutionHeight}p content.</div>
							<Field
								className="formField"
								label="Percentage"
								fullWidth
								name="recastPercentage"
								variant="outlined"
								component={TextField}
								helperText=" "
								type="number"
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button type="submit" color="secondary" variant="contained" disabled={!dirty || !isValid}>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default RecastPercentageModal;
