import React from "react";
import { Field, Form, Formik } from "formik";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { TextField } from "formik-material-ui";
import { VideoEditStatusMessages } from 'videos/details/VideoDetailsUseCase';

interface Props {
  isOpen: boolean;
  data: VideoEditStatusMessages;
  saveChanges: (basic: VideoEditStatusMessages) => void;
  closeModal: () => void;
}

const VideoEditStatusMessagesModal = (props: Props) => {
  const { isOpen, closeModal, data, saveChanges } = props;

  return (
    <Dialog
      className="dialog"
      open={isOpen}
      onClose={closeModal}>
      <Formik
        enableReinitialize={true}
        initialValues={data}
        onSubmit={(values: VideoEditStatusMessages) => { saveChanges(values); closeModal(); }}>
        <Form>
          <DialogTitle>Edit status messages</DialogTitle>
          <DialogContent>
            <Typography>Status messages can only be set for live events</Typography>
            <Field className="formField" label="Info Message" fullWidth name="info" variant="outlined" component={TextField} />
            <Field className="formField" label="Error Message" fullWidth name="error" variant="outlined" component={TextField} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default VideoEditStatusMessagesModal;
