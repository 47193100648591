import { AppThunk, AppThunkDispatch } from '../../../app/store/store';
import { makeRequest } from '../../../app/http/request';
import { createSuccessResult } from '../../../app/utils/result';
import { Invitation } from '../../../app/domain/Invitation';
import { invitationSetDetailsAction } from '../store/InvitationDetailsActions';
import { GET_PARTNER } from '../../../app/http/partnerApi/partnerClient';

export const fetchInvitationDetailsSummaryApi = async (dispatch: AppThunkDispatch, id: number) =>
  makeRequest({
    dispatch,
    request: async () => {
      const result = await GET_PARTNER<Invitation>(`admin/invitation/${id}`);
      return result.data.data;
    },
    responseSuccessHandler: (response: Invitation) => createSuccessResult(response)
  });

export const fetchInvitationLinkApi = async (id: number): Promise<string> => {
    const result = await GET_PARTNER<Invitation>(`admin/invitation/${id}`);
    return result.data.data.invitationLink;
};


export function fetchInvitationDetailsSummaryUseCase(id: number): AppThunk {
  return async (dispatch) => {
    const response = await fetchInvitationDetailsSummaryApi(dispatch, id);

    if (response.value) {
      const invitation = response.value;
      dispatch(invitationSetDetailsAction(invitation));
    }
  };
}
