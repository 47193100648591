import { PublisherStaff, ResourcePermissionType } from "../../../../../app/domain/Admin";
import { GET_PUBLIC, PUT } from '../../../../../app/http/adminApi/adminClient';

export const doFetchChannelAdminsApi = (channelUid: string): Promise<{ items: PublisherStaff[] }> =>
	GET_PUBLIC<{ items: PublisherStaff[] }>(`/api/admin/permissions/channel/${channelUid}/staff`, {
		params: { permission: 'ADMIN' }
	}).then(r => r.data);

export const setChannelAdminsPermissionsApi = ({ userUid, channelUid, permissions }: { channelUid: string, permissions: ResourcePermissionType[], userUid: string }) =>
	PUT(`/api/admin/permissions/channel/${channelUid}/staff/${userUid}`, { permissions })
