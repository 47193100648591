import React from 'react';
import { Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import VideoSummarySection from './sections/summary/VideoDetailsSummarySection';
import 'video.js/dist/video-js.css';
import VideoDetailsLiveStreamingSection from './sections/liveStreaming/VideoDetailsLiveStreamingSection';
import VideoStatsSection from './sections/videoStats/VideoStatsSection';
import { prepareTechnicalValue } from 'app/utils/technical';
import VideoCategoriesSection from './sections/categories/VideoCategoriesSection';
import Player from './sections/summary/player/Player';
import { getRandomCdn } from './sections/summary/player/playerDrmManager';
import { useParams } from 'react-router-dom';
import { doFetchAdminVideoDetailsApi } from './VideoDetailsUseCase';
import { useUrlHash } from 'app/utils/useUrlHash';
import { useLocalQuery } from 'app/utils/searchManager';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';

const VideoDetailsScreen = () => {
	const { videoId } = useParams<{ videoId: string }>();

	const [video, refresh] = useLocalQuery(() => doFetchAdminVideoDetailsApi(videoId), [videoId]);

	const cdn = getRandomCdn(video);

	const [[tab], setTab] = useUrlHash();

	return !video ? (
		<LoadingOverlay />
	) : (
		<Container className="videoDetailsScreen">
			<Paper>
				<h2>
					{video.name} ({video.user.username})
				</h2>
				<TabContext value={tab || 'summary'}>
					<TabList
						centered
						className="tabs"
						indicatorColor="primary"
						textColor="primary"
						onChange={(_, tab) => setTab(tab)}
						variant="scrollable"
					>
						<Tab label="Summary" value="summary" />
						<Tab label="Categories" value="categories" />
						<Tab label="Media" value="media" />
						<Tab label="Live streaming" value="live" />
						<Tab label="Video stats" value="stats" />
						<Tab label="Technical" value="technical" />
					</TabList>
					<TabPanel value="summary">
						<VideoSummarySection video={video} refresh={refresh} />
					</TabPanel>
					<TabPanel value="categories">
						<VideoCategoriesSection uid={videoId} />
					</TabPanel>
					<TabPanel value="live">
						<VideoDetailsLiveStreamingSection video={video} refresh={refresh} />
					</TabPanel>
					<TabPanel value="stats">
						<VideoStatsSection video={video} />
					</TabPanel>
					<TabPanel value="technical">
						<pre style={{ textAlign: 'left' }}>{prepareTechnicalValue(video)}</pre>
					</TabPanel>
					<TabPanel value="media">
						<div className="sectionRow">
							<img style={{ width: 640, height: 360 }} src={video.thumbnail.large} alt="" />
							<br />
							<div style={{ width: 640, margin: 'auto' }}>
								{cdn.url && <Player video={video} streamUrl={cdn.url} drm={cdn.drm} />}
							</div>
						</div>
					</TabPanel>
				</TabContext>
			</Paper>
		</Container>
	);
};

export default VideoDetailsScreen;
