import { CashOutsActionType, CashOutsActionTypes } from 'cashouts/store/cashOutsActions';

export interface CashOutsState {
	uploadModal: {
		isOpen: boolean;
	};
}

const initialState: CashOutsState = {
	uploadModal: {
		isOpen: false
	}
};

export function cashOutsReducer(
	state: CashOutsState = initialState,
	action: CashOutsActionTypes
): CashOutsState {
	switch (action.type) {
		case CashOutsActionType.OPEN_UPLOAD_MODAL:
			return { ...state, uploadModal: { isOpen: true } };
		case CashOutsActionType.CLOSE_UPLOAD_MODAL:
			return { ...state, uploadModal: { isOpen: false } };
		default:
			return state;
	}
}
