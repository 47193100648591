import React, { useState } from 'react';
import { Chip, Grid, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useLocalQuery } from 'app/utils/searchManager';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/store';
import { reportSetCheckedVideoTitleData } from 'report/store/ReportsActions';
import { fetchVideosTitleData } from 'app/stats/statsManager';
import { useAppDispatch } from 'app/store/hooks';

const VideoTitleFilter = () => {
	const filter = useSelector((state: RootState) => state.report.filters);
	const dispatch = useAppDispatch();
	const { checkedTitles } = filter;
	const [ titles ] = useLocalQuery(() => fetchVideosTitleData(filter), [filter.dates, filter.channelUid]);
	const setCheckedTitles = (checkedTitles: string[]): any => {
		dispatch(reportSetCheckedVideoTitleData(checkedTitles || []));
	}
	const uncheckTitle = (uid: string) => setCheckedTitles(checkedTitles.filter(c => c !== uid));
	const checkTitle = (uid: string) => setCheckedTitles([...checkedTitles, uid]);

	const [searchValue, setSearchValue] = useState('');

	return (
		<div className={`filterTitles title`}>
			<Grid container justifyContent="flex-start">
				<Grid item xs={12}>
					<Grid className="StatsVideoTitleFilter__selected">
						{checkedTitles.map(uid => (
							<Chip
								key={uid}
								style={{ margin: '3px' }}
								label={titles?.find(t => t.uid === uid)?.name || uid}
								onDelete={() => uncheckTitle(uid)}
							/>
						))}
					</Grid>
					<Autocomplete
						renderInput={params => (
							<TextField
								{...params}
								label="Pick a video title to add to filter"
								inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
							/>
						)}
						renderOption={(c: any) => (
							<Typography key={c.uid} style={{ width: '100%', fontSize: '0.8em' }} >
								{c.name}
							</Typography>
						)}
						options={titles?.filter(c => !checkedTitles.includes(c.uid)) || []}
						getOptionLabel={c => `${c.name} [${c.uid}]`}
						inputValue={searchValue}
						onInputChange={(_, value, reason) => reason === 'input' && setSearchValue(value)}
						onChange={(_, c) => {
							c && checkTitle(c.uid);
							setSearchValue('');
						}}
						fullWidth
						clearOnBlur
						autoHighlight
						style={{ marginTop: '5px' }}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default VideoTitleFilter;
