import React, { useEffect } from 'react';
import { Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ChannelDetailsSummaryScreen from './section/summary/ChannelDetailsSummaryScreen';
import ChannelDetailsVideosSection from './section/videos/ChannelDetailsVideosSection';
import AdminsScreen from './section/admins/AdminsScreen';
import ChannelWalletsScreen from './section/wallets/ChannelWalletsScreen';
import { prepareTechnicalValue } from '../../app/utils/technical';
import StatsScreen from './section/stats/StatsScreen';
import { reportSetVerifiedUser } from '../../report/store/ReportsActions';
import { useParams } from 'react-router-dom';
import { fetchChannelDetailsApi } from './section/summary/ChannelDetailsSummaryUseCase';
import { urlHashGetParams, urlHashObjectToHash, useUrlHash } from 'app/utils/useUrlHash';
import { useLocalQuery } from 'app/utils/searchManager';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';
import AffiliatesScreen from './section/affiliates/AffiliatesScreen';
import ChannelConfigScreen from './section/config/ChannelConfigScreen';
import StreamKeysScreen from './section/streamkeys/StreamKeysScreen';
import { useAppDispatch } from 'app/store/hooks';
import ChannelPassesScreen from 'channels/details/section/passes/channelPassesScreen';
import WidgetsScreen from './section/widgets/widgetsScreen';

const ChannelDetailsScreen = () => {
	const { uid } = useParams<{ uid: string }>();
	const [[tab, ...tabParams], setHash] = useUrlHash();

	//Ideally would get rid of this, but reports are a bit terrifying
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(reportSetVerifiedUser(uid));
	}, [dispatch, uid]);

	const [channel, refresh] = useLocalQuery(() => fetchChannelDetailsApi(uid), [uid]);

	return !channel ? (
		<LoadingOverlay />
	) : (
		<Container className="userDetailsScreen">
			<Paper>
				<h2>{channel.name}</h2>
				<TabContext value={tab || 'summary'}>
					<TabList
						className="tabs"
						indicatorColor="primary"
						textColor="primary"
						onChange={(_, tab) => setHash(tab as string)}
						variant="scrollable"
					>
						<Tab label="Summary" value="summary" />
						<Tab label="Config" value="config" />
						<Tab label="Admins" value="admins" />
						<Tab label="Affiliates" value="affliliates" />
						<Tab label="Videos" value="videos" />
						<Tab label="Wallets" value="wallets" />
						<Tab label="Widgets" value="widgets" />
						<Tab label="Passes" value="passes" />
						<Tab label="Stream Keys" value="streamkeys" />
						<Tab label="Statistics" value="stats" />
						<Tab label="Technical" value="technical" />
					</TabList>
					<TabPanel value="summary">
						<ChannelDetailsSummaryScreen channel={channel} uid={uid} refresh={refresh} />
					</TabPanel>
					<TabPanel value="config">
						<ChannelConfigScreen channel={channel} uid={uid} refreshChannel={refresh} />
					</TabPanel>
					<TabPanel value="admins">
						<AdminsScreen channelUid={uid} channelName={channel.name} />
					</TabPanel>
					<TabPanel value="affliliates">
						<AffiliatesScreen channelUid={uid} channelName={channel.name} />
					</TabPanel>
					<TabPanel value="videos">
						<ChannelDetailsVideosSection
							channelUid={uid}
							search={urlHashGetParams(tabParams)}
							changeSearch={s => setHash(tab, ...urlHashObjectToHash(s))}
						/>
					</TabPanel>
					<TabPanel value="wallets">
						<ChannelWalletsScreen channelUid={uid} />
					</TabPanel>
					<TabPanel value="widgets">
						<WidgetsScreen channelUid={uid} />
					</TabPanel>
					<TabPanel value="passes">
						<ChannelPassesScreen channelUid={uid} />
					</TabPanel>
					<TabPanel value="streamkeys">
						<StreamKeysScreen channelUid={uid} />
					</TabPanel>
					<TabPanel value="stats">
						<StatsScreen uid={uid} />
					</TabPanel>
					<TabPanel value="technical">
						<pre style={{ textAlign: 'left' }}>{prepareTechnicalValue(channel)}</pre>
					</TabPanel>
				</TabContext>
			</Paper>
		</Container>
	);
};

export default ChannelDetailsScreen;
