import { VideosActionsStateTypes } from "./VideoActionsActions";

interface VideosActionsState {
  removeModal: {
    isOpen: boolean;
    videoId: string;
  };
  suspendModal: {
    isOpen: boolean;
    videoId: string;
  };
  unsuspendModal: {
    isOpen: boolean;
    videoId: string;
  };
  inviteModal: {
    isOpen: boolean;
    url: string;
    videoName: string;
  };
  onRefresh: () => void;
}

const initialState: VideosActionsState = {
  removeModal: {
    isOpen: false,
    videoId: '',
  },
  suspendModal: {
    isOpen: false,
    videoId: '',
  },
  unsuspendModal: {
    isOpen: false,
    videoId: '',
  },
  inviteModal: {
    isOpen: false,
    url: '',
    videoName: '',
  },
	onRefresh: () => undefined
};

export function videoActionsReducer(state = initialState, action: VideosActionsStateTypes): VideosActionsState {
  switch (action.type) {
    case "VIDEO_OPEN_REMOVE_MODAL":
      return { ...state, removeModal: { ...state.removeModal, isOpen: true, videoId: action.videoId }, onRefresh: action.onRefresh };
    case "VIDEO_CLOSE_REMOVE_MODAL":
			return {
				...state,
				removeModal: { ...state.removeModal, isOpen: false, videoId: '' },
				onRefresh: () => undefined
			};
    case "VIDEO_OPEN_SUSPEND_MODAL":
      return { ...state, suspendModal: { ...state.suspendModal, isOpen: true, videoId: action.videoId }, onRefresh: action.onRefresh };
    case "VIDEO_CLOSE_SUSPEND_MODAL":
			return {
				...state,
				suspendModal: { ...state.suspendModal, isOpen: false, videoId: '' },
				onRefresh: () => undefined
			};
    case "VIDEO_OPEN_UNSUSPEND_MODAL":
      return { ...state, unsuspendModal: { ...state.unsuspendModal, isOpen: true, videoId: action.videoId }, onRefresh: action.onRefresh };
    case "VIDEO_CLOSE_UNSUSPEND_MODAL":
			return {
				...state,
				unsuspendModal: { ...state.unsuspendModal, isOpen: false, videoId: '' },
				onRefresh: () => undefined
			};
    case "VIDEO_OPEN_INVITE_MODAL":
      return { ...state, inviteModal: { ...state.inviteModal, isOpen: true, url: action.url, videoName: action.videoName } };
    case "VIDEO_CLOSE_INVITE_MODAL":
      return { ...state, inviteModal: { ...state.inviteModal, isOpen: false, url: '', videoName: '' } };
    default:
      return state;
  }
}
