import { AppThunk, AppThunkDispatch } from '../../../../app/store/store';
import { currenciesCloseEditModalAction } from '../store/CurrenciesActionsActions';
import { makeRequest } from '../../../../app/http/request';
import { POST_PAYMENT } from '../../../../app/http/paymentApi/paymentClient';
import { createSuccessResult } from '../../../../app/utils/result';
import { Currency, SymbolDisplayPosition } from '../../../../app/domain/Currency';
import { fetchCurrenciesUseCase } from '../../../CurrencyUseCase';
import { TypeCurrentModal } from './EditCurrenciesModal';

export const editCurrenciesApi = async (dispatch: AppThunkDispatch, currencies: Currency[]) =>
    makeRequest( {
        dispatch,
        request: async () => {
            const result = await POST_PAYMENT<void>( `/admin/currency/rates`, { currencyRates: currencies } );
            return result.data;
        },
        responseSuccessHandler: () => createSuccessResult<void, string>(),
    } );

export function editCurrenciesUseCase(currencies: Currency[]): AppThunk {
    return async (dispatch) => {
        const convertedCurrencies = currencies.map(current => {
            return {...current, rate: current.rate * 1000000} as Currency
        })
        await editCurrenciesApi( dispatch, convertedCurrencies );
        dispatch( currenciesCloseEditModalAction() );
        dispatch( fetchCurrenciesUseCase() );
    };
}

export const createInitialCurrent = (currency: Currency, typeModal: TypeCurrentModal): Currency => {
    if(TypeCurrentModal.Edit === typeModal) {
        return currency;
    }
    return {
        currencyCode: '',
        currencyName: '',
        rate: 1.000000,
        currencySymbol: '',
        currencySymbolDisplayPosition: SymbolDisplayPosition.right
    };
};
