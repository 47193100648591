import { VIDEO_INFO_USER } from '../UsersStatisticsApi';
import dayjs from "dayjs";
import {PublisherUserResponseData} from "./reportStatisticsResponseUtils";
import {User} from "../../../app/domain/User";

import minMax from 'dayjs/plugin/minMax'
dayjs.extend(minMax)

export interface EarningsDailyChartVideoResponseData {
    'Video.date': string;
    'Video.owner_earned_gbpmill': number;
    'Video.user_recaster_earned_cstthou': number;
    'Video.verified_recaster_earned_gbpmill': number;
    'Video.users_paid_cst': number;
}

export interface EarningsDailyChartPublisherResponseData {
    'PublisherEarnings.date': string;
    'PublisherEarnings.referral_gbpmill': number;
    'PublisherEarnings.spent200_gbpmill': number;
    'PublisherEarnings.share_earnings_gbpmill': number;
    'PublisherEarnings.royalties_gbpmill': number;
}

export interface PurchasesChartResponseData {
    'Video.date': string;
    'Video.purchases': number;
}

export const createVerifiedUsersData = (response: PublisherUserResponseData[]): User[] => {
    return response.map(userResponse => createUserFromResponse(userResponse));
}

function createUserFromResponse(userResponse: PublisherUserResponseData): User {
    return {
        uid: userResponse[VIDEO_INFO_USER],
    } as User;
}
