import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from '@mui/material';
import RoutePaths from './RoutePaths';
import AuthenticatedRoute from './AuthenticatedRoute';
import LoginScreen from 'login/LoginScreen';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import UserScreen from 'users/list/UsersScreen';
import { RootState } from 'app/store/store';
import { useSelector } from 'react-redux';
import VideosScreen from 'videos/list/VideosScreen';
import UserDetailsScreen from 'users/details/UserDetailsScreen';
import VideoDetailsScreen from 'videos/details/VideoDetailsScreen';
import AccountsScreen from 'accounts/AccountsScreen';
import AccountDetailsScreen from 'accounts/AccountDetailsScreen';
import InvitationDetailsScreen from 'invitations/details/InvitationDetailsScreen';
import ReportScreen from 'report/ReportScreen';
import ChannelsScreen from 'channels/list/ChannelsScreen';
import PartnerDetailsScreen from 'partners/details/PartnerDetailsScreen';
import ChannelDetailsScreen from 'channels/details/ChannelDetailsScreen';
import CurrencyScreen from 'currency/CurrencyScreen';
import CategoriesScreen from 'categories/CategoriesScreen';
import CashOutsScreen from 'cashouts/CashOutsScreen';
import PurchasesScreen from 'purchases/PurchasesScreen';

const MainNavigation = (): React.ReactElement => {
	const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

	return (
		<Container disableGutters maxWidth={false}>
			<Switch>
				<AuthenticatedRoute path={RoutePaths.Videos} component={VideosScreen} />
				<AuthenticatedRoute path={`${RoutePaths.VideoDetails}/:videoId`} component={VideoDetailsScreen} />
				<AuthenticatedRoute path={RoutePaths.Users} component={UserScreen} />
				<AuthenticatedRoute path={`${RoutePaths.UserDetails}/:uid`} component={UserDetailsScreen} />
				<AuthenticatedRoute path={RoutePaths.Categories} component={CategoriesScreen} />
				<AuthenticatedRoute path={RoutePaths.Accounts} component={AccountsScreen} />
				<AuthenticatedRoute path={RoutePaths.Channels} component={ChannelsScreen} />
				<AuthenticatedRoute path={RoutePaths.CashOuts} component={CashOutsScreen} />
				<AuthenticatedRoute path={RoutePaths.Reports} component={ReportScreen} />
				<AuthenticatedRoute path={RoutePaths.Currency} component={CurrencyScreen} />
				<AuthenticatedRoute path={RoutePaths.Purchases} component={PurchasesScreen} />
				<AuthenticatedRoute path={`${RoutePaths.AccountDetails}/:id`} component={AccountDetailsScreen} />
				<AuthenticatedRoute path={`${RoutePaths.InvitationDetails}/:id`} component={InvitationDetailsScreen} />
				<AuthenticatedRoute path={`${RoutePaths.ChannelDetails}/:uid`} component={ChannelDetailsScreen}/>
				<AuthenticatedRoute path={`${RoutePaths.PublisherDetails}/:uid`} component={PartnerDetailsScreen} />
				<UnauthenticatedRoute path={RoutePaths.Login} component={LoginScreen} />
				{isAuthenticated ? (
					<Route path="/">
						<Redirect to={RoutePaths.Videos} />
					</Route>
				) : (
					<Route path="/" component={LoginScreen} />
				)}
			</Switch>
		</Container>
	);
};

export default MainNavigation;
