import { InvitationActionsStateTypes } from './InvitationActionsActions';
import { createEmptyInvitation, Invitation } from '../../../app/domain/Invitation';

interface InvitationActionsState {
  removeModal: {
    isOpen: boolean;
    invitationId: number;
  };
  infoModal: {
    isOpen: boolean;
    invitationLink: string;
  };
  sentModal: {
    isOpen: boolean;
    invitationId: number;
  };
  invitePartnerModal: {
    isOpen: boolean;
    url: string;
    name: string;
  };
  copyModal: {
    isOpen: boolean;
    invitation: Invitation;
  };
  createModal: {
    isOpen: boolean;
  };
  onRefresh: () => void;
}

const initialState: InvitationActionsState = {
  removeModal: {
    isOpen: false,
    invitationId: 0,
  },
  infoModal: {
    isOpen: false,
    invitationLink: '',
  },
  invitePartnerModal: {
    isOpen: false,
    url: '',
    name: ''
  },
  copyModal: {
    isOpen: false,
    invitation: createEmptyInvitation(),
  },
  sentModal: {
    isOpen: false,
    invitationId: 0,
  },
  createModal: {
    isOpen: false
  },
	onRefresh: () => undefined
};

export function invitationActionsReducer(state = initialState, action: InvitationActionsStateTypes): InvitationActionsState {
  switch (action.type) {
    case "INVITATION_OPEN_REMOVE_MODAL":
      return {...state, removeModal: {...state.removeModal, isOpen: true, invitationId: action.invitationId}, onRefresh: action.onRefresh};
    case "INVITATION_CLOSE_REMOVE_MODAL":
			return {
				...state,
				removeModal: { ...state.removeModal, isOpen: false, invitationId: 0 },
				onRefresh: () => undefined
			};
    case "INVITATION_OPEN_INFO_MODAL":
      return {...state, infoModal: {...state.infoModal, isOpen: true, invitationLink: action.invitationLink}, onRefresh: action.onRefresh};
    case "INVITATION_CLOSE_INFO_MODAL":
			return {
				...state,
				infoModal: { ...state.infoModal, isOpen: false, invitationLink: '' },
				onRefresh: () => undefined
			};
    case "INVITATION_OPEN_SENT_MODAL":
      return {...state, sentModal: {...state.sentModal, isOpen: true, invitationId: action.invitationId}, onRefresh: action.onRefresh};
    case "INVITATION_CLOSE_SENT_MODAL":
			return {
				...state,
				sentModal: { ...state.sentModal, isOpen: false, invitationId: 0 },
				onRefresh: () => undefined
			};
    case "INVITATION_OPEN_COPY_MODAL":
      return {...state, copyModal: {...state.copyModal, isOpen: true, invitation: action.invitation}, onRefresh: action.onRefresh};
    case "INVITATION_CLOSE_COPY_MODAL":
			return { ...state, copyModal: { ...state.copyModal, isOpen: false }, onRefresh: () => undefined };
    case "INVITATION_OPEN_CREATE_MODAL":
      return {...state, createModal: {...state.createModal, isOpen: true}};
    case "INVITATION_CLOSE_CREATE_MODAL":
			return { ...state, createModal: { ...state.createModal, isOpen: false }, onRefresh: () => undefined };
    default:
      return state;
  }
}
