import { RefundTypes } from './refundsReducer';

interface OpenRefundConfirmationModal {
  type: 'REFUND_OPEN_CONFIRMATION_MODAL';
  refundTitle: string;
  refundType?: RefundTypes;
  singleRefund?: { userId: string, ledgerId: number };
  bulkRefund?: { productId: string, refundReason: string };
}

interface CloseRefundConfirmationModal {
  type: 'REFUND_CLOSE_CONFIRMATION_MODAL';
}

interface OpenFailedLedgersModal {
	type: 'FAILED_LEDGERS_OPEN_MODAL';
	failedLedgers: number[];
}

interface CloseFailedLedgersModal {
	type: 'FAILED_LEDGERS_CLOSE_MODAL';
}

export type RefundStateTypes =
    OpenRefundConfirmationModal |
    CloseRefundConfirmationModal |
    OpenFailedLedgersModal |
    CloseFailedLedgersModal;

export function openRefundConfirmationModalAction(userId: string, ledgerId: number, refundTitle: string, refundType: RefundTypes): RefundStateTypes {
  return {
    type: 'REFUND_OPEN_CONFIRMATION_MODAL',
    refundTitle,
	refundType,
    singleRefund: { userId, ledgerId }
  };
}

export function openRefundProductConfirmationModalAction(productId: string, refundTitle: string): RefundStateTypes {
	return {
		type: 'REFUND_OPEN_CONFIRMATION_MODAL',
		refundTitle,
		bulkRefund: { productId, refundReason: '' }
	};
}

export function closeRefundConfirmationModalAction(): RefundStateTypes {
  return {
    type: 'REFUND_CLOSE_CONFIRMATION_MODAL'
  };
}

export function openFailedLedgersModalAction(failedLedgers: number[]): RefundStateTypes {
	return {
		type: 'FAILED_LEDGERS_OPEN_MODAL',
		failedLedgers,
	};
}

export function closeFailedLedgersModalAction(): RefundStateTypes {
	return {
		type: 'FAILED_LEDGERS_CLOSE_MODAL'
	};
}
