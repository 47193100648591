import { ApiResponseData } from 'app/http/apiResponse';
import { GET as adminGET } from 'app/http/adminApi/adminClient';
import { AdminApiPage } from 'app/domain/AdminApiPage';
import { getApiResponseData } from 'app/utils/apiPaginationUtil';
import { API_PAGE_SIZE } from 'app/components/pagination/AdminApiPagination';
import { PurchaseVideo } from 'app/domain/PurchaseVideo';
import { isPurchase } from 'app/domain/Purchase';
import { fetchUserAuthDataApi } from 'app/http/authApi/userApi';

export const fetchVideoPurchases = async (videoUid: string, pageUrl?: string): Promise<ApiResponseData> => {
	const url = pageUrl ?? `/api/admin/product/${videoUid}/purchases?expand=tariffs&expand=products&limit=${API_PAGE_SIZE}`;
	const resp = await adminGET<ApiResponseData>(url);
	return getApiResponseData(resp);
};

export const getVideoPurchasesData = async (videoUid: string, pagingUrl?: string): Promise<AdminApiPage<PurchaseVideo>> => {
	const purchasesApiResponse = await fetchVideoPurchases(videoUid, pagingUrl);
	const purchases = purchasesApiResponse.expanded.filter(isPurchase);
	const users = purchases.length ?  await fetchUserAuthDataApi(purchases.map(purchase => purchase.user.uid)) : [];

	const videoPurchases = purchases.map(purchase => {
		const user = users.find(user => user.uid === purchase.user.uid);
		return {
			uid: purchase.user.uid,
			username: user?.username || '',
			email: user?.contacts.find(contactsData => contactsData.type === 'EMAIL')?.contact || '',
			created: purchase.created,
			revoked: purchase.revoked
		};
	});

	return {
		paging: purchasesApiResponse.paging || { count: 0 , self: ''},
		items: videoPurchases || []
	}

};
