
export interface PublisherStaff {
  userUid: string;
  username: string;
  email: string;
  resourceType: ResourceType;
  resourceUid: string;
  permission: ResourcePermissionType[];
}

export const hasAccessToFansData = (staff: PublisherStaff | null): boolean => {
	return staff?.permission.includes(ResourcePermissionType.fanDataAdmin) || false;
};

export enum ResourcePermissionType {
	admin = 'ADMIN',
	fanDataAdmin = 'FAN_DATA_ADMIN'
}

export enum ResourceType {
  PARTNER = 'PARTNER', CHANNEL = 'CHANNEL'
}
