import { AppActionTypes } from './actions';

export type SnackbarType = 'error' | 'success' | 'info';

interface AppState {
  redirect: string;
  snackbar: {
    isVisible: boolean;
    message: string;
    type: SnackbarType;
  };
}

const initialState: AppState = {
  redirect: '',
  snackbar: {
    isVisible: false,
    message: '',
    type: 'info',
  }
};

export function appReducer(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case 'REDIRECT':
      return {...state, redirect: action.path};
    case 'OPEN_SNACKBAR_MESSAGE':
      return { ...state, snackbar: { ...state.snackbar, isVisible: true, message: action.message, type: action.snackbarType } };
    case 'CLOSE_SNACKBAR_MESSAGE':
      return { ...state, snackbar: { ...state.snackbar, isVisible: false } };
    default:
      return state;
  }
}
