import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FilterGroup from 'app/components/filter/FilterGroup';
import { FilterValue } from 'app/components/filter/FilterUseCase';
import { VideoStatus } from 'app/domain/Video';
import VideoDateFilter from 'videos/list/filter/VideoDateFilter';
import { FetchVideoData } from '../fetchVideosApi';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

interface Props {
	criteria: FetchVideoData;
	changeCriteria: (criteria: FetchVideoData) => void;
}

const allFilters: FilterValue[] = [
	{ name: 'New', label: 'New', additionalData: { id: VideoStatus.NEW } },
	{ name: 'Ready', label: 'Ready', additionalData: { id: VideoStatus.READY } },
	{ name: 'Published', label: 'Published', additionalData: { id: VideoStatus.PUBLISHED } },
	{ name: 'Suspended', label: 'Suspended', additionalData: { id: VideoStatus.SUSPENDED } },
	{ name: 'Deleted', label: 'Deleted', additionalData: { id: VideoStatus.DELETED } },
	{ name: 'MarkedForDeletion', label: 'Marked for deletion', additionalData: { id: VideoStatus.MARKED_FOR_DELETION } }
];
const VideosFilter = (props: Props) => {
	const { criteria, changeCriteria } = props;
	const { statusIds, isLive, hasFeatureRules } = criteria;

	const statuses = allFilters.filter(el => criteria.statusIds?.includes(el.additionalData.id));

	const setStatuses = (statuses: FilterValue[]) => {
		const statusIds = statuses.map(el => el.additionalData.id);
		if (statusIds.length) {
			changeCriteria({...criteria,  statusIds });
		} else {
			removeCriteria("statusIds");
		}
	};

	const hasPublished = statusIds?.includes(VideoStatus.PUBLISHED);
	const publishedValued = hasPublished && statusIds?.length === 1 ? 'Published'
		: !hasPublished && statusIds?.length === 5 ? 'Unpublished'
		: 'None'

	const removeCriteria = (name: string) => {
		const newCriteria = { ...criteria } as any;
		delete newCriteria[name];
		changeCriteria(newCriteria);
	} 

	const onChange: SelectInputProps<string>['onChange'] = (event) => {
		const status = event.target.value;
		if (status === 'None') {
			setStatuses([]);
		} else if (status === 'Published') {
			setStatuses(allFilters.filter(s=>s.name === 'Published'));
		} else if (status === 'Unpublished') {
			setStatuses(allFilters.filter(s=>s.name !== 'Published'));
		}
	};

	const onChangeLiveStatus: SelectInputProps<string>['onChange'] = (event) => {
		const status = event.target.value;
		if (status === 'None') {
			removeCriteria("isLive");
		} else {
			changeCriteria({...criteria,  isLive: status === 'Live' });
		}
	};

	const onChangeFeaturedStatus: SelectInputProps<string>['onChange'] = (event) => {
		const status = event.target.value;
		if (status === 'None') {
			removeCriteria("hasFeatureRules");
		} else {
			changeCriteria({...criteria,  hasFeatureRules: status === 'Featured' });
		}
	};

	const onSearchText = (searchText?: string) =>
		searchText ? changeCriteria({ ...criteria, searchText }) : removeCriteria("searchText");

	const onUid = (searchUid: string) =>
		searchUid ? changeCriteria({ ...criteria, searchUid }) : removeCriteria("searchUid");

	return (
		<div className="filter">
			<div>
				<VideoDateFilter changeCriteria={changeCriteria} criteria={criteria}/>
			</div>
			<div className="row" style={{ marginBottom: '10px' }}>
				<div className="col-6">
					<TextField
						sx={{ width: '350px' }}
						id="standard-basic"
						label="Search text query"
						value={criteria.searchText}
						onChange={e => onSearchText(e.target.value)}
						variant="standard"
					/>
				</div>
				<div className="col-6">
					<TextField
						sx={{ width: '350px' }}
						id="standard-basic"
						label="Video uid query"
						value={criteria.searchUid}
						onChange={e => onUid(e.target.value)}
						variant="standard"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<FormControl variant="standard">
						<InputLabel id="is-published-select-label">Is Published</InputLabel>
						<Select
							sx={{ width: '350px' }}
							labelId="is-published-select-label"
							id="is-published-select"
							value={publishedValued}
							onChange={onChange}
						>
							<MenuItem value={'None'}>None</MenuItem>
							<MenuItem value={'Published'}>Published</MenuItem>
							<MenuItem value={'Unpublished'}>Unpublished</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="col-6">
					<FormControl variant="standard">
						<InputLabel id="is-live-select-label">Is live</InputLabel>
						<Select
							sx={{ width: '350px' }}
							labelId="is-live-select-label"
							id="is-live-select"
							value={isLive === undefined ? 'None' : isLive ? 'Live' : 'No Live'}
							onChange={onChangeLiveStatus}
						>
							<MenuItem value={'None'}>None</MenuItem>
							<MenuItem value={'Live'}>Live</MenuItem>
							<MenuItem value={'No live'}>No live</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			<div>
				<FormControl variant="standard">
					<InputLabel id="feature-select-label">Feature</InputLabel>
					<Select
						sx={{ width: '350px' }}
						labelId="feature-select-label"
						id="feature-select"
						value={hasFeatureRules === undefined ? 'None' : hasFeatureRules ? 'Live' : 'No Live'}
						onChange={onChangeFeaturedStatus}
					>
						<MenuItem value={'None'}>None</MenuItem>
						<MenuItem value={'Featured'}>Feature</MenuItem>
						<MenuItem value={'No feature'}>No feature</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div>
				<span className="label">Statuses</span>
				<FilterGroup currentValues={statuses} allFilters={allFilters} setCurrentValues={setStatuses} />
			</div>
		</div>
	);
};

export default VideosFilter;
