import React, { useEffect, useState } from 'react';
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import RoutePaths from 'app/navigation/RoutePaths';
import { Contributor } from 'app/domain/Contributor';
import ContributorsSplitModal from 'contributors/actions/ContributorsSplitModal';
import { fetchUsernamesForContributors } from 'videos/details/VideoDetailsUseCase';

interface Props {
	title: string;
	contributors: Contributor[];
	onSave: (contributors: Contributor[]) => void;
	isDisabled?: boolean;
}

const ContributorsSplitSection = ({ contributors, onSave, title, isDisabled = false }: Props) => {
	const [savedContributors, setSavedContributors] = useState<Contributor[]>();

	useEffect(() => {
		fetchUsernamesForContributors(contributors).then(setSavedContributors);
	}, [contributors]);

	const publisherShare = Math.min(100 - contributors.reduce((sum, c) => sum + c.pct, 0), 100);
	const [isEditing, setIsEditing] = useState(false);

	return (
		<>
			<Box className={`section ${isDisabled ? 'section--disabled' : ''}`} component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">{title}:</span>
					{!isDisabled && (
						<Tooltip title="Edit earnings split" arrow>
							<IconButton disabled={isDisabled} onClick={() => setIsEditing(true)} size="large">
								<EditIcon />
							</IconButton>
						</Tooltip>
					)}
				</div>
				<div className="sectionRow">
					<strong>Publisher share: </strong>
					{publisherShare}%
				</div>
				{savedContributors && savedContributors.length > 0 && (
					<div className="sectionRow">
						<strong>Contributor shares: </strong>
						<List>
							{!savedContributors.length
								? 'None'
								: savedContributors.map(user => (
										<ListItem key={user.userUid}>
											<ListItemIcon>
												<ArrowRightIcon />
											</ListItemIcon>
											<ListItemText
												primary={
													<>
														<Link
															to={`${RoutePaths.ChannelDetails}/${user.userUid}`}
															onClick={e => e.stopPropagation()}
														>
															{user.userUid} {user.username}
														</Link>{' '}
														- {user.pct}%
													</>
												}
											/>
										</ListItem>
								  ))}
						</List>
					</div>
				)}
			</Box>
			<ContributorsSplitModal
				title={title}
				onSave={onSave}
				isOpen={isEditing}
				channelUid={''}
				contributors={contributors}
				closeModal={() => setIsEditing(false)}
			/>
		</>
	);
};

export default ContributorsSplitSection;
