import { Amount } from 'accounts/apiTypes';
import { setManualCashOutsApi } from 'app/http/financeApi/financeApi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export interface ManualCashOut {
	publisherId: string;
	amount: Amount;
	description: string;
	effectiveDate: string;
}

const uidLength = 5;

export const cashOutUseCase = async (data: ManualCashOut[]) => {
	return  await setManualCashOutsApi(data);
};

export const parseCashOutLine = (line: string, idx: number, formattedCashOuts: ManualCashOut[], errors: string[]): void => {
	const [ publisherId, strAmount, description, effectiveDate ] = line.split(',');
	const MAX_TWO_DECIMAL_PLACES_REGEX = /^-?[0-9]+(?:\.[0-9]{1,2})?$/;


	if (publisherId.length !== uidLength) {
		errors.push(`Line ${idx + 1}: invalid 'publisherId' length`);
		return;
	}

	if (!strAmount.match(MAX_TWO_DECIMAL_PLACES_REGEX)) {
		errors.push(`Line ${idx + 1}: 'amount' must be a number with max two decimal places`);
		return;
	}

	if (!dayjs(effectiveDate, 'YYYY-MM-DD', true).isValid()) {
		errors.push(`Line ${idx + 1}: 'effectiveDate' must be in the YYYY-MM-DD format`);
		return;
	}

	const amount = +strAmount; 

	const thou = Math.abs(Math.round(amount * 1000)); //Rounding prevents floating point weirdness
	const gbpAmount: Amount = {
		units: Math.floor(thou / 1000),
		thousandths: thou % 1000,
		currency: 'GBP',
	};
	if (amount<0) gbpAmount.negative = true;

	formattedCashOuts.push({
		publisherId,
		amount: gbpAmount,
		description,
		effectiveDate
	});
};

export default cashOutUseCase;
