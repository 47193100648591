import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { isPublished, VideoStatus } from 'app/domain/Video';
import RoutePaths from 'app/navigation/RoutePaths';
import { useHistory } from 'react-router-dom';
import VideoActionButtons from 'videos/actions/VideoActionButtons';
import { VideoMetadata } from 'app/domain/VideoMetadata';

interface VideoRowProps {
	video: VideoMetadata;
	refreshList: () => void;
	hidePublisher?: boolean;
}

type Props = VideoRowProps;

const statusIdToColourMap = {
	[VideoStatus.NEW]: 'yellow',
	[VideoStatus.READY]: 'yellow',
	[VideoStatus.PUBLISHED]: '',
	[VideoStatus.SUSPENDED]: 'red',
	[VideoStatus.DELETED]: 'grey',
	[VideoStatus.MARKED_FOR_DELETION]: 'grey',
	[VideoStatus.FAILED]: '' // should be filtered by query
};

const VideoRow = (props: Props) => {
	const { video, refreshList, hidePublisher } = props;
	const rowColour = statusIdToColourMap[video.status];
	const history = useHistory();

	const navigateToVideoDetails = (videoId: string) => {
		history.push(`${RoutePaths.VideoDetails}/${videoId}`);
	};

	// Tidy up the nasty default names that we're stuck with in PHP
	const publisher = video.username?.replace(/_\w\w\w$/, "").replace(/_/g, "");

	return (
		<TableRow className={'video-row ' + rowColour} onClick={() => navigateToVideoDetails(video.uid)}>
			<TableCell className='image-cell'>
				<img className="image" src={video.thumbnail.large} alt="" />
			</TableCell>
			<TableCell className='uid-cell'>{video.uid}</TableCell>
			<TableCell className='name-cell'>{video.name?.replace(/^(Full )?Replay:/, "🔁")}</TableCell>
			{!hidePublisher && <TableCell className='publisher-cell'>{publisher}</TableCell>}
			<TableCell className='cost-cell'>{video.cost}</TableCell>
			<TableCell className='sales-cell'>{video.sales}</TableCell>
			<TableCell className='spend-cell'>£{(video.spend || 0)/100}</TableCell>
			<TableCell className='live-cell'>
				{video.isLive ? <CheckIcon className="true" /> : <ClearIcon className="false" />}
			</TableCell>
			<TableCell className='published-cell'>
				{isPublished(video.status) ? <CheckIcon className="true" /> : <ClearIcon className="false" />}
			</TableCell>
			<TableCell className='featured-cell'>
				{video.hasFeatureRules ? <CheckIcon className="true" /> : <ClearIcon className="false" />}
			</TableCell>
			<TableCell className='created-cell'>{video.created}</TableCell>
			<TableCell className='actions-cell'>
				<VideoActionButtons
					videoUid={video.uid}
					channelUid={video.username}
					name={video.name}
					status={video.status}
					onRefresh={refreshList}
				/>
			</TableCell>
		</TableRow>
	);
};

export default VideoRow;
