import React, { useEffect } from 'react';
import { Box, Chip, Container, Divider, Grid, IconButton } from '@mui/material';
import { RootState } from 'app/store/store';
import {  useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchInvitationDetailsSummaryUseCase } from './InvitationDetailsSummaryUseCase';
import Title from 'app/components/title/Title';
import InvitationActionButtons from 'invitations/actions/InvitationActionButtons';
import { copyToClipboard } from 'app/utils/copyManager';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CategoriesList from 'categories/CategoriesList';
import { useAppDispatch } from 'app/store/hooks';

const InvitationDetailsSummaryScreen = () => {
	const dispatch = useAppDispatch();
	const { id } = useParams<{id: string}>();
	const invitation = useSelector((state: RootState) => state.invitations.details.invitation);

	const initialFetchInvitationData = () => dispatch(fetchInvitationDetailsSummaryUseCase(parseInt(id)));

	useEffect(() => {
		id && initialFetchInvitationData();
	}, [id]);

	return (
		<Container className="userDetailsScreen">
			<Grid container direction="row" alignItems="center">
				<Grid item xs={12} sm={6}>
					<Title>Invitation id: {invitation.id}</Title>
				</Grid>
				<Grid item xs={12} sm={6}>
					<InvitationActionButtons invitation={invitation} onRefresh={initialFetchInvitationData} />
				</Grid>
			</Grid>
			<Divider />
			<Box className="section" component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">Invitation info:</span>
				</div>
				<div className="sectionRow">
					<strong>Email: </strong>
					{invitation.email}
				</div>
				<div className="sectionRow">
					<strong>Created: </strong>
					{invitation.created}
				</div>
				<div className="sectionRow">
					<strong>Status: </strong>
					<Chip label={invitation.status} />
				</div>
				<div className="sectionRow">
					<strong>Token: </strong>
					{invitation.token}
				</div>
				<div className="sectionRow">
					<strong>Ceiling categories: </strong>
					<CategoriesList ids={invitation.ceilingCategoriesIds} />
				</div>
				<div className="sectionRow">
					<strong>Invitation link: </strong>
					{invitation.invitationLink}
					<IconButton onClick={() => copyToClipboard(invitation.invitationLink)} size="large">
						<FileCopyIcon />
					</IconButton>
				</div>
			</Box>
		</Container>
	);
};

export default InvitationDetailsSummaryScreen;
