import React, { SyntheticEvent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { AppThunkDispatch } from '../../app/store/store';
import {
    invitationOpenCopyModalAction,
    invitationOpenRemoveModalAction,
    invitationOpenSentModalAction,
} from './store/InvitationActionsActions';
import { Invitation, isNew, isSent } from '../../app/domain/Invitation';
import RemoveInvitationModal from './remove/RemoveInvitationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import SentInvitationModal from './sent/SentInvitationModal';
import CheckIcon from '@mui/icons-material/Check';
import CopyInvitationModal from './copy/CopyInvitationModal';
import InfoInvitationModal from './info/InfoInvitationModal';

interface InvitationActionButtonsProps {
    invitation: Invitation;
    onRefresh: () => void;
}

interface DispatchProps {
    removeInvitation: (invitationId: number, onRefresh: () => void) => void;
    sentInvitation: (invitationId: number, onRefresh: () => void) => void;
    copyInvitation: (invitation: Invitation, onRefresh: () => void) => void;
}

type Props = InvitationActionButtonsProps & DispatchProps;

const InvitationActionButtons = (props: Props) => {
    const { invitation, removeInvitation, sentInvitation, copyInvitation, onRefresh } = props;

    const onRevokeInvitation = (e: SyntheticEvent, invitationId: number) => {
        removeInvitation( invitationId, onRefresh );
        e.stopPropagation();
    };

    const onSentInvitation = (e: SyntheticEvent, invitationId: number) => {
        sentInvitation( invitationId, onRefresh );
        e.stopPropagation();
    };

    const onCopyInvitation = (e: SyntheticEvent, invitation: Invitation) => {
        copyInvitation( invitation, onRefresh );
        e.stopPropagation();
    };

    return (
        <div className="actions">
            {
                isSent( invitation ) &&
                ( <Tooltip title="Revoke" arrow>
					<IconButton
						className="button button-red"
						onClick={e => onRevokeInvitation(e, invitation.id)}
						size="large"
					>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip> )
            }
            {
                isNew( invitation ) &&
                ( <Tooltip title="Mark as sent" arrow>
					<IconButton
						className="button button-orange"
						onClick={e => onSentInvitation(e, invitation.id)}
						size="large"
					>
                        <CheckIcon/>
                    </IconButton>
                </Tooltip> )
            }
            {
                (isNew( invitation ) || isSent( invitation )) &&
                ( <Tooltip title="Copy/create new invitation" arrow>
                    <IconButton className="button button-orange" onClick={ e => onCopyInvitation( e, invitation ) }>
                        <LinkIcon/>
                    </IconButton>
                </Tooltip> )
            }
        </div>
    )
        ;
};


export const InvitationActionModals = () => {
    return (
        <>
            <RemoveInvitationModal/>
            <SentInvitationModal/>
            <CopyInvitationModal/>
            <InfoInvitationModal/>
        </>
    );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    removeInvitation: (invitationId: number, onRefresh: () => void) => {
        dispatch( invitationOpenRemoveModalAction( invitationId, onRefresh ) );
    },
    sentInvitation: (invitationId: number, onRefresh: () => void) => {
        dispatch( invitationOpenSentModalAction( invitationId, onRefresh ) );
    },
    copyInvitation: (invitation: Invitation, onRefresh: () => void) => {
        dispatch( invitationOpenCopyModalAction( invitation, onRefresh ) );
    }
} );

export default connect( null, mapDispatchToProps )( InvitationActionButtons );
