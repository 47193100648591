import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { doFetchWidgetsApi } from '../api/fetchChannelWidgets';
import { useLocalQuery } from 'app/utils/searchManager';
import WidgetRow from './widgetsRow';

interface Props {
	channelUid: string;
}

const WidgetsTable = ({ channelUid }: Props) => {
	const [data] = useLocalQuery(fetchWidgetsData, [channelUid]);

	async function fetchWidgetsData() {
		const responseBody = await doFetchWidgetsApi(channelUid);
		return responseBody.data;
	}
	return (
		<Table className="widgets-table" size="small" padding="checkbox" stickyHeader>
			<TableHead>
				<TableRow>
					<TableCell className="name-cell header" align="left">
						Widget Name
					</TableCell>
					<TableCell className="actions-cell header" align="left">
						Actions
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>{data && data.map(widget => <WidgetRow key={widget.id} widget={widget} />)}</TableBody>
		</Table>
	);
};

export default WidgetsTable;
