import React from "react";
import Pagination from "../../../app/components/pagination/Pagination";
import { User } from "../../../app/domain/User";
import { Page } from "../../../app/domain/Page";

interface UsersPaginationProps {
  page: Page<User>;
  changePage: (page: number) => void;
}

const UsersPagination = (props: UsersPaginationProps) => {
  return <Pagination { ...props } />;
};

export default UsersPagination;
