import { setAuthTokens } from 'app/http/authApi/token';
import { createErrorResult, createSuccessResult, Result } from 'app/utils/result';
import { AppThunkDispatch } from 'app/store/store';
import { loginAction } from 'app/store/user/actions';
import jwtDecode from 'jwt-decode';
import { HTTP_STATUS_CODES } from 'constants/Common';
import { POST_AUTH } from 'app/http/authApi/authClient';
import axios from 'axios';

interface LoginResult {
	refreshToken: string;
	accessToken: string;
}

interface JwtToken {
	isAdmin: boolean;
}

const isAdmin = (accessToken: string) => {
	try {
		const jwtToken = jwtDecode(accessToken) as JwtToken;
		return jwtToken.isAdmin;
	} catch (e) {
		return false;
	}
};

const loginApi = async (username: string, password: string): Promise<Result<LoginResult, string>> => {
	try {
		const result = await POST_AUTH<LoginResult>('/login', { username, password });
		if (!isAdmin(result.data.accessToken)) {
			return createErrorResult('Unauthorized');
		}
		return createSuccessResult(result.data);
	} catch (e) {
		if (axios.isAxiosError(e) && e.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
			return createErrorResult('Unauthorized');
		} else {
			return createErrorResult('UnknownError');
		}
	}
};

const loginUseCase = async (username: string, password: string, dispatch: AppThunkDispatch) => {
	const apiResult = await loginApi(username, password);
	if (apiResult.error || !apiResult.value) {
		return apiResult;
	}

	const loginResponse = apiResult.value;

	setAuthTokens(loginResponse);
	dispatch(loginAction());
	return apiResult;
};

export default loginUseCase;
