import { PLAYER_RESOLUTION } from 'constants/Common';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import MediaPlayer from './MediaPlayer';
import { DrmInfo } from './playerDrmManager';

interface PlayerProps {
	streamUrl: string | undefined;
	video?: any;
	drm?: DrmInfo;
}

const Player: React.FC<PlayerProps> = (props): React.ReactElement => {
	const { streamUrl, video, drm } = props;

	const videoRef = useRef<HTMLVideoElement>(null) as MutableRefObject<HTMLVideoElement>;

	const streamUrlRef = useRef(streamUrl);
	streamUrlRef.current = streamUrl;

	const playerRef = useRef<MediaPlayer | any>();

	useEffect(() => {
		initPlayer();

		return () => {
			playerRef?.current?.destroy();
		};
	}, []);

	useEffect(() => {
		if (streamUrl) {
			playVideoStream(streamUrl);
		}
	}, [streamUrl]);

	const initPlayer = () => {
		playerRef.current = new MediaPlayer(videoRef.current, PLAYER_RESOLUTION.width, PLAYER_RESOLUTION.height);
	};

	const playVideoStream = (streamUrl: string) => {
		if (playerRef.current) {
			video && drm?.enabled
				? playerRef.current?.loadDrmStreamUrl(streamUrl, video?.drm)
				: playerRef.current?.loadStreamUrl(streamUrl);
			playerRef.current.playWithHandleError();
		}
	};

	return (
		<div>
			<div data-vjs-player>
				<video className={`video-js vjs-styles-defaults`} ref={videoRef} playsInline />
			</div>
		</div>
	);
};

Player.defaultProps = {
	streamUrl: ''
};

export default React.memo(Player);
