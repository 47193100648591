import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import { ChannelConfig } from './ChannelConfigScreen';
import SectionRow from 'app/components/SectionRow';
import FansDataModal from './FansDataModal';
import { useFeatureFlagsValue } from 'app/hooks/useFeatureFlagsValue';
import { FEATURE_FLAGS } from 'app/featureFlags/featureFlags';
import CustomisableConfig from './CustomisableConfig';

interface FansDataPackageDefaults {
	dataProfile: boolean;
	anonymisedData: boolean;
}
interface FansDataSectionProps {
	isDataProfileEnabled: boolean;
	isAnonymisedDataEnabled: boolean;
	saveConfiguration: (overrides: Partial<ChannelConfig>) => void;
	packageDefault: FansDataPackageDefaults;
}

export interface FansDataFormValues {
	dataProfileEnabled: boolean;
	anonymisedDataEnabled: boolean;
}

const FansDataToggleSection = ({
	isDataProfileEnabled,
	isAnonymisedDataEnabled,
	saveConfiguration,
	packageDefault
}: FansDataSectionProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const [isDataDownloadEnabled, isAnonymisedDataConfigEnabled] = useFeatureFlagsValue([
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_DATA_DOWNLOAD,
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_ANON_DATA
	]);

	const isFanDataAccessEnabled = isDataDownloadEnabled || isAnonymisedDataConfigEnabled;
	const isDataProfileCustomised = packageDefault.dataProfile !== isDataProfileEnabled;
	const isAnonymisedDataCustomised = packageDefault.anonymisedData !== isAnonymisedDataEnabled;

	const onSave = (value: FansDataFormValues) => {
		saveConfiguration({
			dataProfile: {
				isEnabled: value.dataProfileEnabled
			},
			anonymisedData: {
				isEnabled: value.anonymisedDataEnabled
			}
		});
	};
	return (
		<>
			{isFanDataAccessEnabled && (
				<Box className="section" component="div">
					<div className="sectionHeader">
						<span className="sectionTitle">Fans Data</span>
						<Tooltip title="Edit fans data config" arrow>
							<IconButton onClick={() => setIsEditing(true)} size="large">
								<EditIcon className="editIcon" />
							</IconButton>
						</Tooltip>
					</div>
					{isAnonymisedDataConfigEnabled && (
						<SectionRow
							label="Enable anonymised fans data"
							content={
								<CustomisableConfig
									value={isAnonymisedDataEnabled ? 'Yes' : 'No'}
									customisedText={
										isAnonymisedDataCustomised
											? `(default is ${packageDefault.anonymisedData ? 'Yes' : 'No'})`
											: ''
									}
								/>
							}
						/>
					)}
					{isDataDownloadEnabled && (
						<SectionRow
							label="Enable fans data download"
							content={
								<CustomisableConfig
									value={isDataProfileEnabled ? 'Yes' : 'No'}
									customisedText={
										isDataProfileCustomised
											? `(default is ${packageDefault.dataProfile ? 'Yes' : 'No'})`
											: ''
									}
								/>
							}
						/>
					)}
				</Box>
			)}

			<FansDataModal
				isDataProfileEnabled={isDataProfileEnabled}
				isAnonymisedDataEnabled={isAnonymisedDataEnabled}
				isOpen={isEditing}
				onSave={onSave}
				onClose={() => setIsEditing(false)}
			/>
		</>
	);
};

export default FansDataToggleSection;
