import React, { SyntheticEvent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { isSuspended, User, UserStatus } from '../../app/domain/User';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { Money } from '@mui/icons-material';
import { AppThunkDispatch } from '../../app/store/store';
import { connect } from 'react-redux';
import SuspendUserModal from './suspend/SuspendUserModal';
import {
	userOpenAwardCastModalAction,
	userOpenConfirmInvitePartnerModalAction,
	userOpenDeleteUserModalAction,
	userOpenSuspendModalAction
} from './store/UsersActionsActions';
import AwardCastModal from './awardCast/AwardCastModal';
import InvitePartnerModal from './infoInvitation/infoInvitationModal';
import ManualCastModal from 'users/actions/manualCast/ManualCastModal';
import DeleteUserModal from 'users/actions/deleteUser/DeleteUserModal';

interface UserActionButtonsProps {
  user: User;
  onRefresh: () => void;
}

interface DispatchProps {
  suspendUser: (user: User, onRefresh: () => void) => void;
  awardCastUser: (user: User, onRefresh: () => void) => void;
  deleteUser: (user: User, onRefresh: () => void) => void;
}

type Props = UserActionButtonsProps & DispatchProps;

const UserActionButtons = (props: Props) => {
	const { user, suspendUser, onRefresh, awardCastUser, deleteUser } = props;

  const onSuspendUser = (e: SyntheticEvent, user: User) => {
    suspendUser(user, onRefresh);
    e.stopPropagation();
  };

  const onAwardCastUser = (e: SyntheticEvent, user: User) => {
    awardCastUser(user, onRefresh);
    e.stopPropagation();
  };

  const onDeleteUser = (e: SyntheticEvent, user: User) => {
    deleteUser(user, onRefresh);
    e.stopPropagation();
  };

  return (
    <div className="actions">
      {
        isSuspended(user) ?
          (<Tooltip title="Unsuspend" arrow>
					<IconButton className="button button-green" onClick={e => onSuspendUser(e, user)} size="large">
              <LockOpenIcon/>
            </IconButton>
          </Tooltip>)
          :
          (<Tooltip title="Suspend" arrow>
					<IconButton className="button button-orange" onClick={e => onSuspendUser(e, user)} size="large">
              <LockIcon/>
            </IconButton>
          </Tooltip>)
      }
      <Tooltip title="Award cast" arrow>
				<IconButton className="button button-green" onClick={e => onAwardCastUser(e, user)} size="large">
          <Money/>
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete for GDPR" arrow>
				<IconButton
					disabled={user.status !== UserStatus.verified}
					className="button button-red"
					onClick={e => onDeleteUser(e, user)}
					size="large"
				>
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const UserActionModals = () => {
  return (
    <>
      <InvitePartnerModal />
      <SuspendUserModal />
      <AwardCastModal />
      <ManualCastModal />
      <DeleteUserModal />
    </>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  confirmInvitePartner: (user: User) => {
    dispatch(userOpenConfirmInvitePartnerModalAction(user));
  },
  suspendUser: (user: User, onRefresh: () => void) => {
    dispatch(userOpenSuspendModalAction(user.uid, user.statusId, onRefresh));
  },
  awardCastUser: (user: User, onRefresh: () => void) => {
    dispatch(userOpenAwardCastModalAction(user, onRefresh));
  },
  deleteUser: (user: User, onRefresh: () => void) => {
    dispatch(userOpenDeleteUserModalAction(user, onRefresh));
  }
});

export default connect(null, mapDispatchToProps)(UserActionButtons);
