import { AppThunk, RootState } from '../../../../app/store/store';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import {
    currenciesSetAllSelectedCodeAction,
    currenciesSetCurrencyCountriesAction,
    currencyCountriesCloseEditCountriesModalAction,
} from '../store/CurrencyCountriesActions';

export function currencyCountriesUseCase(currencyCountry: CurrencyCountries): AppThunk {
    return ((dispatch, getState: () => RootState) => {
        const { currencyCountries } = getState().currency.currencyCountries.actions;
        const newCurrencyCountries = currencyCountries.filter(country => country.currencyCode !== currencyCountry.currencyCode)
        dispatch( currenciesSetCurrencyCountriesAction([...newCurrencyCountries, currencyCountry]) );

        const allCheckedCode: string[] = [];
        [...newCurrencyCountries, currencyCountry].map( country => country.countryCodes.map( code => allCheckedCode.push( code ) ) );
        dispatch( currenciesSetAllSelectedCodeAction( allCheckedCode ) );
        dispatch( currencyCountriesCloseEditCountriesModalAction() );
    });
}
