import React, { useEffect } from 'react';
import './App.css';
import './static/sass/App.scss';
import MenuTop from './components/menutop/MenuTop';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import theme from 'Theme';
import MainNavigation from './navigation/MainNavigation';
import SnackbarMessage from './components/snackbar/SnackbarMessage';
import { UserActionModals } from 'users/actions/UserActionButtons';
import { VideoActionModals } from 'videos/actions/VideoActionButtons';
import { InvitationActionModals } from 'invitations/actions/InvitationActionButtons';
import { getConfigFromEndpointsApi } from './http/endpointsApi/endpointsApi';
import { saveAppConfigFromApi } from 'config/appConfig';
import { QueryClient, QueryClientProvider } from 'react-query';
import { initFlagsmith } from 'app/featureFlags/flagsmith';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const App = () => {
	useEffect(() => {
		getConfigFromEndpointsApi().then(saveAppConfigFromApi).then(initFlagsmith);
	}, []);

	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<div className="App">
						<MenuTop />
						<MainNavigation />
						<SnackbarMessage />
						<UserActionModals />
						<VideoActionModals />
						<InvitationActionModals />
					</div>
				</ThemeProvider>
			</StyledEngineProvider>
		</QueryClientProvider>
	);
};

export default App;
