import { FilterValue } from '../../../app/components/filter/FilterUseCase';
import FilterGroup from '../../../app/components/filter/FilterGroup';
import React from 'react';
import { TextField } from '@mui/material';
import { PartnersQuery, PartnerStatus } from '../../../app/domain/Partner';

  

interface Props {
    query: PartnersQuery;
    setQuery: (query: PartnersQuery)=>void;
}

const allFilters: FilterValue[] = [
  { name: 'APPLICATION', label: 'Application' },
  { name: 'APPROVED', label: 'Approved' },
  { name: 'LIVE', label: 'Live' },
  { name: 'SUSPENDED_BY_USER', label: 'Suspended by user' },
  { name: 'SUSPENDED_BY_ADMIN', label: 'Suspended by admin' },
  { name: 'DELETED', label: 'Deleted' }
];

const PartnersFilter = ({ query, setQuery }: Props) => {
  const roles = allFilters.filter(el => query.statuses.includes(el.name as PartnerStatus));

  const setStatuses = (values: FilterValue[]) => {
    const statuses = values.map(el => el.name) as PartnerStatus[];
    setQuery({ ...query, statuses });
  };

  return (
    <div className="filter">
      <div className="row">
          <div className="col-6">
            <TextField
              style={{width: '350px'}}
              id="standard-basic"
              label="Email"
              onChange={ e => setQuery({ ...query, emailSearchText: e.target.value })}
						variant="standard"
            />
          </div>
          <div className="col-6">
            <TextField
              style={{width: '350px'}}
              id="standard-basic"
              label="Publisher name"
              onChange={ e => setQuery({ ...query, partnerNameSearchText: e.target.value })}
						variant="standard"
            />
          </div>
      </div>
        <div className="row mb-2">
          <div className="col-6">
            <TextField
              style={{width: '350px'}}
              id="standard-basic"
              label="Publisher Uid"
              onChange={ e => setQuery({ ...query, partnerUidSearchText: e.target.value })}
						variant="standard"
            />
          </div>
        </div>
      <div>
        <span className="label">Roles</span>
        <FilterGroup currentValues={roles} allFilters={allFilters} setCurrentValues={setStatuses}/>
      </div>
    </div>
  );
};

export default PartnersFilter;
