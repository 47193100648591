import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { FormDataObject, jsonToFormData } from 'app/http/mediaApi/mediaClient';
import { getBaseApiUrl } from 'app/http/request';
import { addAccessToken, resetAccessTokenOnUnauthorized } from 'app/http/authApi/token';

export interface APIResponse<T> {
	success: boolean;
	data: T;
}

export interface AxiosRequestConfigProps extends AxiosRequestConfig {
	['Content-Type']: string;
}

const clientPartner: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});
clientPartner.interceptors.response.use(res => res, resetAccessTokenOnUnauthorized);
clientPartner.interceptors.request.use(addAccessToken, Promise.reject);

const getPartnerApiUrl = (suffix?: string): string => getBaseApiUrl('partner', suffix);

export const POST_PARTNER = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return clientPartner.post<typeof data, Response>(getPartnerApiUrl(url), data, config);
};

export const POST = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return clientPartner.post<typeof data, Response>(getBaseApiUrl('api', url), data, config);
};

export const GET_PARTNER = <U>(url: string, config?: AxiosRequestConfig) => {
	return clientPartner.get<APIResponse<U>>(getPartnerApiUrl(url), config);
};

export const GET = <U>(url: string, config?: AxiosRequestConfig) => {
	return clientPartner.get<U>(getBaseApiUrl('api', url), config);
};

export const PUT_PARTNER = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return clientPartner.put<typeof data, Response>(getPartnerApiUrl(url), data, config);
};

export const POST_FORM_DATA_PARTNER = <U>(url: string, data: FormDataObject, config?: AxiosRequestConfigProps) => {
	const newConfig = { ...(config || {}), 'Content-Type': 'multipart/form-data' };
	return POST_PARTNER<U>(url, jsonToFormData(data), newConfig);
};

export const DELETE_PARTNER = <U>(url: string, data?: unknown) => {
	return clientPartner.delete<typeof data, AxiosResponse<APIResponse<U>>>(getPartnerApiUrl(url), { data });
};

export const DELETE = <U>(url: string, data?: unknown) => {
	return clientPartner.delete<typeof data, AxiosResponse<APIResponse<U>>>(getBaseApiUrl('api', url), { data });
};
