export type AssignmentType = 
    | 'PUBLISHER_SELECTION'
    | 'RECAST_SELECTION'
    | 'ALGORITHMIC_SELECTION'
    | 'FAN_SUGGESTION'
;

export const AssignmentTypes: [ AssignmentType, string ][] = [
    [ 'PUBLISHER_SELECTION', 'Publisher selection' ],
    [ 'RECAST_SELECTION', 'Recast selection' ],
    [ 'ALGORITHMIC_SELECTION', 'Algorithmic selection' ],
    [ 'FAN_SUGGESTION', 'Fan Suggestion' ],
];

export interface VideoCategory {
    categoryId: number;
    strength: number;
    assignmentType: AssignmentType;
}
