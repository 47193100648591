import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { FormMessages } from '../../../../constants/Common';
import { Currency, SymbolDisplayPosition } from '../../../../app/domain/Currency';
import { TypeCurrentModal } from './EditCurrenciesModal';
import { TextField } from 'formik-material-ui';
import { createInitialCurrent } from './EditCurrenciesUseCase';

interface StateProps {
    currency: Currency;
    currentCodes: string[];
    typeModal: TypeCurrentModal;
}

interface DispatchProps {
    saveCurrent: (current: Currency, oldCurrentCode: string) => void;
    closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const EditCurrentModal = (props: Props) => {
    const { currency, closeModal, saveCurrent, currentCodes, typeModal } = props;

    const initialValues = createInitialCurrent( currency, typeModal );

    const CurrencyAddValidation = Yup.object().shape( {
        currencyCode: Yup.string()
            .max( 4, FormMessages.TOO_LONG )
            .required( FormMessages.REQUIRED )
            .notOneOf( currentCodes, FormMessages.EXISTS ),
        currencyName: Yup.string()
            .max( 50, FormMessages.TOO_LONG )
            .required( FormMessages.REQUIRED ),
        rate: Yup.number()
            .min( 0, FormMessages.NOT_NEGATIVE )
            .max( 100, FormMessages.TOO_MUCH )
            .required( FormMessages.REQUIRED )
            .test(
                'maxDigitsAfterDecimal',
                FormMessages.TOO_MUCH_DECIMAL_PLACES,
                (number) => Number.isInteger( ( number ? number : 0 ) * ( 10 ** 6 ) ),
            ),
        currencySymbol: Yup.string()
          .required(FormMessages.REQUIRED)
    } );

    const CommonCurrencyValidation = Yup.object().shape( {
        currencyCode: Yup.string()
            .max( 4, FormMessages.TOO_LONG )
            .required( FormMessages.REQUIRED ),
        currencyName: Yup.string()
            .max( 50, FormMessages.TOO_LONG )
            .required( FormMessages.REQUIRED ),
        rate: Yup.number()
            .min( 0, FormMessages.NOT_NEGATIVE )
            .max( 100, FormMessages.TOO_MUCH )
            .required( FormMessages.REQUIRED )
            .test(
                'maxDigitsAfterDecimal',
                FormMessages.TOO_MUCH_DECIMAL_PLACES,
                (number) => Number.isInteger( ( number ? number : 0 ) * ( 10 ** 6 ) ),
            ),
        currencySymbol: Yup.string()
          .required(FormMessages.REQUIRED)
    } );

    useEffect( () => {
        setOldCurrentCode( currency.currencyCode );
    }, [currency] );

    const [oldCurrentCode, setOldCurrentCode] = useState( currency.currencyCode );

    function onSaveCurrent(newCurrent: Currency) {
        saveCurrent( newCurrent, oldCurrentCode );
    }


    function getValidationSchema() {
        return typeModal === TypeCurrentModal.Add
            ? CurrencyAddValidation
            : CommonCurrencyValidation;
    }

    return (
        <Dialog
            className="dialog"
            open={typeModal !== TypeCurrentModal.Closed}
            onClose={closeModal}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={getValidationSchema}
                onSubmit={onSaveCurrent}
            >
                <Form>
                    <DialogTitle>
                        {
                            typeModal === TypeCurrentModal.Edit
                                ? `Edit currency: ${currency.currencyName}`
                                : `Add currency`
                        }
                    </DialogTitle>
                    <DialogContent>
                        <Field className="formField" fullWidth label="New currency code" name="currencyCode"
                               variant="outlined" type="text"
                               component={TextField}/>

                        <Field className="formField"
                               fullWidth
                               label="New currency name"
                               name="currencyName"
                               variant="outlined"
                               type="text"
                               component={TextField}
                        />

                        <Field className="formField"
                               fullWidth
                               label="Rate"
                               name="rate"
                               variant="outlined"
                               type="number"
                               component={TextField}
                        />

                        <Field className="formField"
                               fullWidth
                               label="Symbol"
                               name="currencySymbol"
                               variant="outlined"
                               type="text"
                               component={TextField}
                        />

                        <Field className="formField"
                               fullWidth
                               label="Symbol position"
                               name="currencySymbolDisplayPosition"
                               variant="outlined"
                               component={TextField}
                               select
                        >
                            {Object.values(SymbolDisplayPosition).map(position => (
                              <MenuItem value={position} key={position}>{position}</MenuItem>
                            ))}
                        </Field>
                    </DialogContent>
                    <DialogActions className={`dialogActions`}>
						<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="secondary" className={`button-yes`}>
                            Save
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default EditCurrentModal;
