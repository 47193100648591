import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/store';
import { copyInvitationUseCase } from './CopyInvitationUseCase';
import { invitationCloseCopyModalAction } from 'invitations/actions/store/InvitationActionsActions';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import { copyToClipboard } from 'app/utils/copyManager';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { fetchInvitationLinkApi } from 'invitations/details/section/InvitationDetailsSummaryUseCase';
import CategoriesList from 'categories/CategoriesList';
import { useAppDispatch } from 'app/store/hooks';

const CopyInvitationModal = () => {
	const dispatch = useAppDispatch();
	const invitation = useSelector((state: RootState) => state.invitations.actions.copyModal.invitation);
	const isOpen = useSelector((state: RootState) => state.invitations.actions.copyModal.isOpen);

	const [invitationLink, setInvitationLink] = useState('');

	useEffect(() => {
		invitation.id && fetchInvitationLinkApi(invitation.id).then(setInvitationLink);
	}, [invitation]);

	const handleAccept = () => dispatch(copyInvitationUseCase(invitation));

	const handleCloseModal = () => dispatch(invitationCloseCopyModalAction());

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseModal}
			fullWidth={true}
			maxWidth="sm"
			className="confirmationDialog"
			disableEnforceFocus={true}
		>
			<DialogTitle id="alert-dialog-slide-title">Are you sure?</DialogTitle>
			<DialogContent className="dialogContent">
				<DialogContentText id="alert-dialog-slide-description">
					Do you want to delete the invitation and generate a new one?
					<br />
					<br />
					<Divider />
					<br />
					<div className="sectionRow">
						<strong>Email: </strong>
						{invitation.email}
					</div>
					<div className="sectionRow">
						<strong>Ceiling categories: </strong>
						<CategoriesList ids={invitation.ceilingCategoriesIds} />
					</div>
					<div className="sectionRow">
						<strong>User id: </strong>
						{invitation.userId}
					</div>
					<div className="sectionRow">
						<strong>Invitation link: </strong>
						{invitationLink}
						<IconButton onClick={() => copyToClipboard(invitationLink)} size="large">
							<FileCopyIcon />
						</IconButton>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions className="dialogActions">
				<Button variant="contained" onClick={handleCloseModal} className="button-cancel">
					Cancel
				</Button>
				<Button variant="contained" onClick={handleAccept} color="secondary" className="button-yes">
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CopyInvitationModal;
