import React from 'react';
import { Container, Grid } from '@mui/material';
import Title from 'app/components/title/Title';
import ReportFilter from 'report/reportFilter/ReportFilter';
import ReportDashboard from 'report/reportDashboard/ReportDashboard';
import VideoStatisticsTable from 'report/videoTable/VideoStatisticsTable';

const StatsScreen = (props: { uid: string }) => {
    const { uid } = props;

    return (
        <Container className="reportsScreen">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Title>Statistics</Title>
                </Grid>
                { uid ? <Grid item xs={12}><ReportFilter/></Grid>  : null }
                { uid ? <Grid item xs={12}><ReportDashboard/></Grid>  : null }
                { uid ? <Grid item xs={12}><VideoStatisticsTable/></Grid>  : null }
            </Grid>
        </Container>
    );
};

export default StatsScreen;