import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { ChannelsQuery } from "channels/list/filter/ChannelFilter";
import { useDebouncedAction } from "app/utils/searchManager";
import { Page } from "app/domain/Page";
import { Channel } from "app/domain/Channel";
import { fetchChannelsWithImagesApi } from "channels/list/ChannelsUseCase";
import { Autocomplete } from '@mui/material';

interface Props {
    select: (channel: Channel)=> void;
    hideUids: string[];
    title: string;
}

const ChooseContributor = ({ select, hideUids, title }: Props) => {
	const [ filter, setFilter ] = useState<ChannelsQuery>({
		statuses: [],
		sort: { field: 'name', direction: 'asc' }
	});

	const [ page, setPage] = useState<Page<Channel>>();

	const fetch = useDebouncedAction(()=>fetchChannelsWithImagesApi(filter).then(setPage));

	useEffect(()=>{ fetch(); }, [filter]);
	useEffect(()=>{ setFilter({
		statuses: [],
		sort: { field: 'name', direction: 'asc' }
	}); }, []);
	const channelNameSearchText = (name: string) =>
		setFilter({ ...filter, channelNameSearchText: name });

    return (
        <Autocomplete
            renderInput={(params) => (
                <TextField {...params} label={title} variant="standard" />
            )}
            renderOption={(props, c)=>
                <Typography {...props} style={{"width":"100%","fontSize":"0.8em"}}>
                    <span style={{"float": "right"}}>{c.channelUid}</span>
                    {c.channelName}
                </Typography>}
            options={page?.items.filter(c=>!hideUids.includes(c.channelUid)) || []}
            getOptionLabel={c=>`${c.channelName} [${c.channelUid}]`}
            onInputChange={(_, value) => channelNameSearchText(value) }
            onChange={(_, c)=>c && select(c as any)}
            fullWidth clearOnBlur
        />
);
};
  
export default ChooseContributor;
