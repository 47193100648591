import { DrmInfo, SourceConfig } from 'videos/details/sections/summary/player/playerDrmManager';
import videojs from 'video.js';
import {
	arrayToString,
	base64DecodeUint8Array,
	base64EncodeUint8Array
} from '@content-technology-partners-ltd/shared-util';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { DrmStrategy } from 'videos/details/sections/summary/player/playerDrmManager';

export class BuyDrmStrategy implements DrmStrategy {
	private BUYDRM_PROXY: string;
	private BUYDRM_CERTIFICATE: string;

	constructor(drmProviderInfo: DrmInfo) {
		this.BUYDRM_PROXY = drmProviderInfo.buydrm_proxy;
		this.BUYDRM_CERTIFICATE = drmProviderInfo.buydrm_certificate;
	}

	async getDrmConfig(streamUrl: string) {
		if (isSafari || isMobileSafari) {
			return this.getFairPlayConfig(streamUrl);
		}
		return this.getWideVineOrPlayReadyConfig(streamUrl);
	}

	private async getWideVineOrPlayReadyConfig(streamUrl: string): Promise<SourceConfig> {
		return {
			type: 'application/dash+xml',
			src: streamUrl,
			keySystems: {
				'com.widevine.alpha': {
					url: this.BUYDRM_PROXY + '?drm-type=widevine'
				},
				'com.microsoft.playready': {
					url: this.BUYDRM_PROXY + '?drm-type=playready'
				}
			}
		};
	}

	private async getFairPlayConfig(streamUrl: string): Promise<SourceConfig> {
		return {
			type: 'application/x-mpegurl',
			src: streamUrl,
			keySystems: {
				'com.apple.fps.1_0': {
					certificateUri: this.BUYDRM_CERTIFICATE,
					getContentId: function (_emeOptions: any, initData: any) {
						const contentId = arrayToString(initData) || initData;
						return contentId.split('skd://')[1].substring(0, 32);
					},
					getLicense: (_emeOptions: any, _contentId: any, keyMessage: any, callback: any) => {
						videojs.xhr(
							{
								url: this.BUYDRM_PROXY + '?drm-type=fairplay',
								method: 'POST',
								responseType: 'text',
								body: 'spc=' + base64EncodeUint8Array(keyMessage) + '&assetId=' + _contentId,
								headers: {
									'Content-type': 'application/x-www-form-urlencoded'
								}
							},
							(err, _response, responseBody) => {
								if (err) {
									callback(err);
									return;
								}
								callback(null, base64DecodeUint8Array(responseBody));
							}
						);
					}
				}
			}
		};
	}
}
