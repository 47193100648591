import { createTheme } from '@mui/material';

export enum Colors {
	Blue = '#499bc4',
	DarkPurple = '#5c0bfe',
	Black = '#000000',
	Green = '#28BD76',
	LightPurple = '#d936fe',
	BackgroundApp = '#1a1a1a',
	GrayDark = '#8D8D8D',
	BackgroundInput = '#3a3a3a',
	BackgroundPlaceholder = '#ffffff80',
	White = '#ffffff',
	Salmon = '#cd4371',
	Error = '#f44336',
	Hover = '#f6f6f6',
	ButtonText = '#1e211dde',
	Border = '#979797',
	CodeBoxBackground = '#2f2f2f',
	Shadow = '#00000080'
}

export default createTheme({
	palette: {
		primary: {
			main: '#000'
		},
		secondary: {
			main: '#fed200'
		},
		text: {
			primary: '#000',
			secondary: '#000'
		}
	},
	typography: {
		fontFamily: ['Rawline', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
		fontSize: 16,
		body1: {
			fontSize: 16
		},
		body2: {
			fontSize: 12
		},
		h4: {
			fontSize: '2rem'
		}
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&:hover:not(.MuiOutlinedInput-disabled):not(.MuiOutlinedInput-focused):not(.MuiOutlinedInput-error) .MuiOutlinedInput-notchedOutline':
						{
							borderColor: '#000'
						}
				}
			}
		}
	}
});
