import React from 'react';
import ConfirmActionModal from '../../app/components/modal/confirm/confirmActionModal';

interface SetVideoCategoriesModalProps {
	onConfirm: () => void;
	isOpen: boolean;
	onClose: () => void;
}

const SetVideoCategoriesModal = ({ onConfirm, isOpen, onClose }: SetVideoCategoriesModalProps) => {
	return (
		<ConfirmActionModal
			show={isOpen}
			onClose={onClose}
			description="This will change this video categories, are you sure?"
			confirmedAction={()=>{ onConfirm(); onClose()}}
		/>
	);
};

export default SetVideoCategoriesModal;
