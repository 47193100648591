import { User } from '../../../app/domain/User';

interface OpenRemoveModal {
  type: 'USER_OPEN_REMOVE_MODAL';
  uid: string;
  onRefresh: () => void;
}

interface CloseRemoveModal {
  type: 'USER_CLOSE_REMOVE_MODAL';
}

interface OpenSuspendModal {
  type: 'USER_OPEN_SUSPEND_MODAL';
  uid: string;
  statusId: number;
  onRefresh: () => void;
}

interface CloseSuspendModal {
  type: 'USER_CLOSE_SUSPEND_MODAL';
}

interface OpenAwardCastModal {
  type: 'USER_OPEN_AWARD_CAST_MODAL';
  user: User;
  onRefresh: () => void;
}

interface CloseAwardCastModal {
  type: 'USER_CLOSE_AWARD_CAST_MODAL';
}


interface OpenDeleteUserModal {
  type: 'USER_OPEN_DELETE_USER_MODAL';
  user: User;
  onRefresh: () => void;
}

interface CloseDeleteUserModal {
  type: 'USER_CLOSE_DELETE_USER_MODAL';
}

interface OpenInvitePartnerModal {
  type: 'USER_OPEN_INVITE_PARTNER_MODAL';
  url: string;
  name: string;
}

interface CloseInvitePartnerModal {
  type: 'USER_CLOSE_INVITE_PARTNER_MODAL';
}

interface OpenConfirmInvitePartnerModal {
  type: 'USER_OPEN_CONFIRM_INVITE_MODAL';
  user: User;
}

interface CloseConfirmInvitePartnerModal {
  type: 'USER_CLOSE_CONFIRM_INVITE_MODAL';
}

interface OpenManualCastModal {
  type: 'USER_OPEN_MANUAL_CAST_MODAL';
}

interface CloseManualCastModal {
  type: 'USER_CLOSE_MANUAL_CAST_MODAL';
}

export type UsersShareStateTypes =
  OpenRemoveModal |
  CloseRemoveModal |
  OpenSuspendModal |
  CloseSuspendModal |
  OpenAwardCastModal |
  OpenInvitePartnerModal |
  CloseInvitePartnerModal |
  OpenConfirmInvitePartnerModal |
  CloseConfirmInvitePartnerModal  |
  CloseAwardCastModal |
  OpenManualCastModal |
  OpenDeleteUserModal |
  CloseDeleteUserModal |
  CloseManualCastModal;

export function userOpenRemoveModalAction(uid: string, onRefresh: () => void): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_REMOVE_MODAL',
    uid,
    onRefresh
  };
}

export function userCloseRemoveModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_REMOVE_MODAL'
  };
}

export function userOpenSuspendModalAction(uid: string, statusId: number, onRefresh: () => void): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_SUSPEND_MODAL',
    uid,
    statusId,
    onRefresh,
  };
}

export function userCloseSuspendModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_SUSPEND_MODAL'
  };
}

export function userOpenAwardCastModalAction(user: User, onRefresh: () => void): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_AWARD_CAST_MODAL',
    user,
    onRefresh,
  };
}
export function userOpenDeleteUserModalAction(user: User, onRefresh: () => void): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_DELETE_USER_MODAL',
    user,
    onRefresh,
  };
}

export function userCloseDeleteUserModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_DELETE_USER_MODAL'
  };
}


export function userCloseAwardCastModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_AWARD_CAST_MODAL'
  };
}


export function userOpenInvitePartnerModalAction(url: string, name: string): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_INVITE_PARTNER_MODAL',
    url,
    name
  };
}

export function userCloseInvitePartnerModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_INVITE_PARTNER_MODAL'
  };
}

export function userOpenConfirmInvitePartnerModalAction(user: User): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_CONFIRM_INVITE_MODAL',
    user
  };
}

export function userCloseConfirmInvitePartnerModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_CONFIRM_INVITE_MODAL'
  };
}

export function userOpenManualCastModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_OPEN_MANUAL_CAST_MODAL'
  };
}

export function userCloseManualCastModalAction(): UsersShareStateTypes {
  return {
    type: 'USER_CLOSE_MANUAL_CAST_MODAL'
  };
}
