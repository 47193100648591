import { Amount } from '../../accounts/apiTypes';
import { getTokenPayload } from 'app/http/authApi/token';

export interface User {
	uid: string;
	statusId: Status;
	username: string;
	fullName: string;
	email: string;
	phone: string;
	profileImage: string;
	bannerImage: string;
	created: string;
	updated: string;
	roles: Role[];
	interests: Interest[];
	followers: number;
	following: number;
	referrer: string;
	intro_pct: number;
	introducing_user?: IntroducingUser;
	isPublisher?: boolean;
	allAccounts?: {
		user?: UserWalletSummary;
		publisher?: UserWalletSummary;
		advertiser?: UserWalletSummary;
	};
	status?: UserStatus;
}

export enum UserStatus {
	verified = 'VERIFIED',
	deleted = 'DELETED',
	deletionRequested = 'DELETION_REQUESTED',
	suspended = 'SUSPENDED',
	verificationError = 'VERIFICATION_ERROR'
}
export interface UserAuthDetails {
	status: UserStatus;
	contacts: { contact: string; status: string; type: 'EMAIL' | 'PHONE' }[];
	uid: string;
	username: string;
}

/** This is the old style account summary. Should eventually be replaced by
 * a proper summary from wallet v2 */
export interface UserWalletSummary {
	id: number;
	accountType: 'USER';
	linkedId: string;
	balance: Amount;
	description?: string;
	negativeBalance: boolean;
	location: string;
}

export enum Status {
	NEW = 0,
	LIVE = 2,
	SUSPENDED = 3,
	DELETED = 4
}

export interface Role {
	userId: string;
	role: UserRole;
}

export interface IntroducingUser {
	username: string;
}

export interface Interest {
	id: number;
	name: string;
}

export function isSuspended(user: User): boolean {
	return user.statusId === Status.SUSPENDED;
}

export function createEmptyUser(): User {
	return {
		uid: 'Unknown',
		statusId: 0,
		bannerImage: '',
		created: '',
		email: '',
		followers: 0,
		following: 0,
		fullName: 'Unknown',
		interests: [],
		intro_pct: 0,
		phone: '',
		profileImage: '',
		referrer: '',
		roles: [],
		updated: '',
		username: 'Unknown'
	};
}

export const AllUserRoles = ['admin', 'advertiser', 'livestreamer', 'devicestreamer', 'publisher', 'user', 'agent'];
export type UserRole = 'admin' | 'advertiser' | 'livestreamer' | 'devicestreamer' | 'publisher' | 'user' | 'agent';
export type AuthUserRole = 'User' | 'ChannelManager' | 'PartnerManager' | 'Contributor' | 'Publisher' | 'Admin';

export const isPublisher = (roles: Role[]) => {
	return roles.map(role => role.role).includes('publisher');
};

export const isLoggedAsFinanceAdmin = (): boolean => {
	return !!getTokenPayload()?.isFinanceAdmin;
};
