import { ApiEntity } from 'app/http/apiResponse';
import dayjs from 'dayjs';
import dateUtil from 'app/utils/dateUtil';

export enum PurchaseStatus {
	ACTIVE = 'ACTIVE',
	REVOKED = 'REVOKED',
	EXPIRED = 'EXPIRED'
}

export interface PurchaseMetadata {
	source: string | null;
	widgetId: string | null;
	channelId: string | null;
}

export interface PurchaseApiEntity extends ApiEntity {
	id: string;
	location: string;
	tariff: ApiEntity;
	user: { uid: string };
	redemptions: { product: ApiEntity; firstRedeemed: string }[];
	created: string;
	expires: string | null;
	revoked: boolean;
	ledgerId: number | null;
	source: string | null;
	metadata: PurchaseMetadata | null;
}

export const isPurchase = (item: ApiEntity): item is PurchaseApiEntity =>
	item.href.startsWith('/api/public/purchases') || item.href.startsWith('/api/admin/purchases');

export const getPurchaseStatus = (purchase: PurchaseApiEntity) => {
	if (purchase.revoked) {
		return PurchaseStatus.REVOKED;
	}
	if (purchase.expires && dayjs.utc(purchase.expires).isBefore(dateUtil.now())) {
		return PurchaseStatus.EXPIRED;
	}
	return PurchaseStatus.ACTIVE;
};
