import React from "react";
import { Container } from '@mui/material';
import Title from "app/components/title/Title";
import VideoTable from "videos/list/table/VideoTable";
import VideosFilter from "videos/list/filter/VideosFilter";
import { useHistory, useLocation } from "react-router-dom";
import { FetchVideoData } from "./fetchVideosApi";

const VideosScreen = () => {
	const location = useLocation();
	const history = useHistory();
	const hash = location.hash?.substring(1).split("/");

  const criteria = {
    page: parseInt(hash.find(h=>h.startsWith("page="))?.substring(5) || "1"),
    order: hash.find(h=>h.startsWith("order="))?.substring(6),
    sort: hash.find(h=>h.startsWith("sort="))?.substring(5),
    statusIds: hash.find(h=>h.startsWith("statuses="))?.substring(9).split(",").map(s=>parseInt(s)),
    searchText: hash.find(h=>h.startsWith("search="))?.substring(7),
    searchUid: hash.find(h=>h.startsWith("uid="))?.substring(4),
    isLive: hash.includes("live") || undefined,
    hasFeatureRules: hash.includes("featured") || undefined,
    startDate: hash.find(h=>h.startsWith("created-after="))?.substring(14),
    endDate: hash.find(h=>h.startsWith("created-before="))?.substring(15),
  } as FetchVideoData;

  const changeCriteria = (criteria: FetchVideoData) => {
    const { page, order, sort, statusIds, searchText, searchUid, isLive, startDate, endDate, hasFeatureRules } = criteria;
    const hash: string[] = [];

    page && hash.push("page=" + page);
    order && hash.push("order=" + order);
    sort && hash.push("sort=" + sort);
    statusIds && hash.push("statuses=" + statusIds.join(","));
    searchText && hash.push("search=" + searchText);
    searchUid && hash.push("uid=" + searchUid);
    isLive && hash.push("live");
    hasFeatureRules && hash.push("featured");
    startDate && hash.push("created-after=" + startDate);
    endDate && hash.push("created-before=" + endDate);

    history.replace(location.pathname + "#" + hash.join("/"));
  }
    
  return (
    <Container className="videosScreen">
      <Title>Videos</Title>
      <VideosFilter
          criteria={criteria}
          changeCriteria={changeCriteria}/>
      <VideoTable
        changeCriteria={changeCriteria}
        criteria={criteria}
      />
    </Container>
  );
};

export default VideosScreen;
