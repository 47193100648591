import { GET } from 'app/http/mediaApi/mediaClient';
import { AllUserRoles, User, UserRole } from 'app/domain/User';
import { PageResponse, toPage } from 'app/http/PageResponse';
import { UsersQuery } from './filter/UsersFilter';
import { fetchUserAuthDataApi } from 'app/http/authApi/userApi';
import { Page } from 'app/domain/Page';

export interface FetchUserData {
	page: number;
	roles: UserRole[];
	usernameSearchText?: string;
	emailSearchText?: string;
	referrerSearchText?: string;
}

export const fetchUsersApi = async (data: UsersQuery): Promise<Page<User>> => {
	const params = createApiParams(data);
	const result = await GET<PageResponse<User>>(`/admin/users`, { params });
	const usersDataResult = result.data.data;
	const userAuthData = await fetchUserAuthDataApi(usersDataResult.items.map(user => user.uid));

	const usersData = {
		...usersDataResult,
		items: usersDataResult.items.map(user => ({
			...user,
			status: userAuthData.find(authUser => authUser.uid === user.uid)?.status
		}))
	};

	return toPage(usersData);
};

const createApiParams = (data: UsersQuery) => {
	const { usernameSearchText, emailSearchText, referrerSearchText, roles, page } = data;
	return {
		expand: 'email,roles,distinctFlags,intro_pct,introducing_user,referrer',
		page,
		query: usernameSearchText,
		email: emailSearchText,
		referrer: referrerSearchText,
		role: urlRoles(roles || [])
	};
};

const urlRoles = (userRoles: UserRole[]): string[] => {
	if (userRoles.length) {
		return userRoles.map(el => el.valueOf());
	} else {
		return AllUserRoles;
	}
};
