import React, { ChangeEvent, useEffect, useState } from 'react';
import { AuthUserRole } from '../../../../../../app/domain/User';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@mui/material';

interface UserEditProfileModalProps {
  roles: AuthUserRole[];
  save: (roles: AuthUserRole[]) => void;
  onClose: () => void;
}

const UserEditProfileModal = (props: UserEditProfileModalProps) => {
	const { onClose, save, roles } = props;

  const [selectedRoles, setSelectedRoles] = useState<AuthUserRole[]>(roles ?? []);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(roles.includes('Admin'));
  }, [roles]);

	const changeAdminRole = (_: ChangeEvent<unknown>, checked: boolean)  => {
    if(checked) {
      selectedRoles.push('Admin')
      setSelectedRoles(selectedRoles);
    } else {
      setSelectedRoles(roles => roles.filter(role => role !== 'Admin'));
    }
    setIsAdmin(checked);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      className="editUserDialog"
      open={true}
      onClose={onClose}>
        <DialogTitle>Edit User Admin Status</DialogTitle>
        <DialogContent>
          <FormControlLabel
              color="default"
              control={<Switch />}
              label="Is admin"
              checked={isAdmin}
              onChange={changeAdminRole}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button onClick={()=>save(selectedRoles)} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default UserEditProfileModal;
