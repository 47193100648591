import React from 'react';
import { ButtonBase, Typography, styled } from '@mui/material';
import { Colors } from 'Theme';

const ButtonText = styled(Typography)(() => ({
	opacity: '1',
	fontFamily: 'Poppins',
	fontSize: '14px',
	color: Colors.ButtonText,
	fontWeight: '700',
	fontStyle: 'Bold',
	letterSpacing: '0px',
	textAlign: 'left',
	textTransform: 'uppercase'
}));

const IconTextButtonBase = styled(ButtonBase)(() => ({
	border: `2px solid ${Colors.White}`,
	height: '36px',
	borderRadius: '2px',
	opacity: '1',
	padding: '10px 16px 8px 19px',
	backgroundColor: Colors.White,
	'&:hover': {
		border: `2px solid ${Colors.Hover}`,
		backgroundColor: Colors.Hover
	}
}));

const ActionButton = ({
	onClick,
	text
}: {
	onClick: () => void;
	text: React.ReactNode;
	iconStyles?: React.CSSProperties;
}) => {
	return (
		<IconTextButtonBase onClick={onClick}>
			<ButtonText>{text}</ButtonText>
		</IconTextButtonBase>
	);
};

export default ActionButton;
