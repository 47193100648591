import { Sort } from './Sort';

export enum SymbolDisplayPosition {
  left = 'LEFT',
  right = 'RIGHT'
}

export interface Currency {
  currencyCode: string;
  currencyName: string;
  currencySymbol: string;
  currencySymbolDisplayPosition: SymbolDisplayPosition;
  rate: number;
}

export type CurrenciesSortField = 'currencyCode'| 'rate';

export function sortCurrencies(tags: Currency[], sort: Sort<CurrenciesSortField>): Currency[] {
  const sortFunc = sort.direction === 'asc' ? ascSortCurrencies : descSortCurrencies;
  return [...tags].sort((a, b) => sortFunc(a, b, sort.field));
}

function ascSortCurrencies(a: Currency, b: Currency, sortField: CurrenciesSortField): number {
  let aValue = a[sortField] || 0;
  let bValue = b[sortField] || 0;

  if (sortField === "currencyCode") {
    aValue = aValue || '';
    bValue = bValue || '';
  }

  if (aValue > bValue) {
    return 1;
  }

  if (aValue < bValue) {
    return -1;
  }

  return 0;
}

function descSortCurrencies(a: Currency, b: Currency, sortField: CurrenciesSortField): number {
  return -ascSortCurrencies(a, b, sortField);
}
