import dayjs from 'dayjs';
import { ApiEntity } from 'app/http/apiResponse';

export enum TariffType {
	SINGLE_PRODUCT = 'SINGLE_PRODUCT',
	CHANNEL_PASS = 'CHANNEL_PASS'
}

export interface TariffApiEntity extends ApiEntity {
	id: string;
	products?: ApiEntity[];
	statusDetails: {
		status: string;
	};
	priceDetails: {
		price : number;
	};
	channel: ApiEntity;
	type: TariffType;
	rentalPeriod?: number;
	created: string;
	updated: string;
	description: string | null;
}

export interface Tariff {
	id: string;
	description: string | null;
	channelId: string;
	productIds: string[];
	type: TariffType;
	recastProp: number;
	publisherShareProp: number;
	fanShareProp: number;
	publisherSplits: {
		publisherUid: string;
		sharePct: number;
	}[];
	price: number;
	geography: string[];
	status: string;
	rentalPeriod?: number;
	created: dayjs.Dayjs;
	updated: dayjs.Dayjs;
}

export const isTariff = (item: ApiEntity): item is TariffApiEntity =>
	item.href.startsWith('/api/publisher/tariffs') || item.href.startsWith('/api/admin/tariffs');

