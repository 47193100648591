import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

interface TreeNodeActionsProps {
	nodeId: number;
	onAdd: (nodeId: number | null) => void;
	onEdit: (nodeId: number) => void;
	onDelete: (nodeId: number) => void;
}

const TreeNodeActions = (props: TreeNodeActionsProps) => {
	const { nodeId, onAdd, onEdit, onDelete } = props;

	const onAddClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		onAdd(nodeId);
	};

	const onEditClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		nodeId !== null && onEdit(nodeId);
	};

	const onDeleteClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		nodeId !== null && onDelete(nodeId);
	};

	return (
		<div className="actions">
			<Tooltip title="Add" arrow>
				<IconButton onClick={onAddClick} className="button button-green" size="large">
					<AddIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Edit" arrow>
				<IconButton onClick={onEditClick} className="button button-orange" size="large">
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Delete" arrow>
				<IconButton onClick={onDeleteClick} className="button button-red" size="large">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default TreeNodeActions;
