import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Title from '../app/components/title/Title';
import UnverifiedTable from './UnverifiedTable';
import UnverifiedFilter from './UnverifiedFilter';
import { useHistory, useLocation } from 'react-router-dom';
import { UnverifiedUser } from 'app/domain/UnverifiedUser';
import { createDefaultSort, Sort } from 'app/domain/Sort';
import { useDebouncedAction } from 'app/utils/searchManager';
import { fetchUnverifiedUserApi } from './UnverifiedUsersUseCase';

const UnverifiedScreen = () => {
  const location = useLocation();
	const history = useHistory();
	const hash = location.hash?.substring(1).split("/");
  const emailSearchText = hash.find(h=>h.startsWith("email="))?.substring(6);
  const [ page, setPage ] = useState<UnverifiedUser[]>(); 
  const [ sort, setSort ] = useState<Sort<string>>(createDefaultSort("created", "asc")); 

  const setEmailSearchText = (text?: string) => {
    //TODO this really should be chased back up to the top level the same as all the channels tabs
    history.replace(location.pathname + text ? "#unverified/email=" + text : "");
  }

  const fetchPage = useDebouncedAction(()=> {
    fetchUnverifiedUserApi({ emailSearchText }).then(setPage);
  }, 500);

  useEffect(() => fetchPage(), [emailSearchText]);

  return (
    <Container className="videosScreen">
      <Title>Unverified users</Title>
      <UnverifiedFilter query={emailSearchText || ""} setQuery={setEmailSearchText} />
			{page && (
				<UnverifiedTable
					sort={sort}
					setSort={(_, sort) => setSort(sort)}
					unverifiedUsers={page}
					refresh={fetchPage}
				/>
			)}
    </Container>
  );
};

export default UnverifiedScreen;
