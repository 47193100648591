import { userCloseDeleteUserModalAction } from 'users/actions/store/UsersActionsActions';
import { AppThunk, AppThunkDispatch, RootState } from 'app/store/store';
import { makeRequest } from 'app/http/request';
import { createSuccessResult } from 'app/utils/result';
import { DELETE_AUTH } from 'app/http/authApi/authClient';

const deleteUserApi = async (dispatch: AppThunkDispatch, uid: string) =>
	makeRequest({
		dispatch,
		request: async () => {
			const result = await DELETE_AUTH(`/admin/user/verified/${uid}`);
			return result.data.data;
		},
		responseSuccessHandler: () => createSuccessResult()
	});

export function deleteUserUseCase(uid: string): AppThunk {
	return async (dispatch, getState: () => RootState) => {
		await deleteUserApi(dispatch, uid);
		dispatch(userCloseDeleteUserModalAction());
		getState().users.actions.onRefresh();

	};
}
