import { POST_FINANCE } from 'app/http/financeApi/financeClient';
import { createErrorResult, createSuccessResult, Result } from 'app/utils/result';
import { ManualCast } from 'users/actions/manualCast/ManualCastUseCase';
import { ManualCashOut } from 'cashouts/action/CashOutUseCase';
import axios from 'axios';
import { ApiResponse } from 'app/http/apiResponse';


export const setManualCastApi = async (data: ManualCast): Promise<Result<unknown, ApiResponse>> => {
	try {
		const res = await POST_FINANCE('/admin/manualCastBonus', data);
		return createSuccessResult(res.data);
	} catch (e) {
		if (!axios.isAxiosError(e)) {
			throw e;
		}

		const response = e.response;
		return createErrorResult(response?.data);
	}
};
export const setManualCashOutsApi = async (cashOuts: ManualCashOut[]): Promise<Result<unknown, ApiResponse>> => {
	try {
		const res = await POST_FINANCE('admin/cashOuts', { cashOuts });
		return createSuccessResult(res.data);
	} catch (e) {
		if (!axios.isAxiosError(e)) {
			throw e;
		}

		return createErrorResult(e.response?.data);
	}
};
