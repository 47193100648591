import React from 'react';
import { Amount } from "./apiTypes";

/** This should probably be in some common logic */
export function renderGBP(amt: Amount) {
    const units = (amt.units || 0) +
      Math.floor((amt.hundredths || 0)/100) + 
      Math.floor((amt.thousandths || 0)/1000) + 
      Math.floor((amt.millionths || 0)/1000000)
    const hundredths = (amt.hundredths || 0) + 
      Math.floor((amt.thousandths || 0)/10) + 
      Math.floor((amt.millionths || 0)/10000)
    const millionths = ((amt.thousandths || 0)*1000 + (amt.millionths || 0)) % 10000;
    return <span className="amount gbp" style={ amt.negative ? { color:  "#A00" } : {}}>
      { amt.negative ? "-": "" }
      {`${units}.${`00`.substr(`${hundredths}`.length)}${hundredths}`}
      <small>{`${`0000`.substr(`${millionths}`.length)}${millionths}` }</small>
      <small style={{width: "3em", display:"inline-block"}}><em>{amt.currency}</em></small>
    </span>;
}

export function renderCST(amt: Amount) {
    const units = (amt.units || 0) +
        Math.floor((amt.hundredths || 0)/100) + 
        Math.floor((amt.thousandths || 0)/1000) + 
        Math.floor((amt.millionths || 0)/1000000)
    const thousandths = (amt.hundredths || 0) * 10 + (amt.thousandths || 0) + Math.floor((amt.millionths || 0) / 1000);
    return <span className="amount cst" style={ amt.negative ? { color:  "#A00" } : {}}>
        { amt.negative ? "-": "" }
        {`${units}`}
        <small>{`.${`000`.substr(`${thousandths}`.length)}${thousandths}`}</small>
    <small style={{width: "3em", display:"inline-block"}}><em>{amt.currency}</em></small>
    </span>;
}

const AmountDisplay = (props: { amt: Amount }) => {
  const { amt } = props;
    return amt.currency==="CST" ? renderCST(amt) : renderGBP(amt);
}

export default AmountDisplay;