import { ApiResponseData } from 'app/http/apiResponse';
import { GET as adminGET, DELETE as adminDELETE } from 'app/http/adminApi/adminClient';
import { AdminApiPage } from 'app/domain/AdminApiPage';
import { getApiResponseData } from 'app/utils/apiPaginationUtil';
import { API_PAGE_SIZE } from 'app/components/pagination/AdminApiPagination';
import { isPurchase, PurchaseApiEntity } from 'app/domain/Purchase';
import { isTariff, TariffApiEntity } from 'app/domain/Tariff';
import { fetchChannelsWithImagesApi } from 'channels/list/ChannelsUseCase';
import { ChannelsQuery } from 'channels/list/filter/ChannelFilter';
import { AllChannelStatuses, Channel } from 'app/domain/Channel';

export interface PurchasedChannelPass {
	purchase: PurchaseApiEntity;
	tariff: TariffApiEntity;
	channel?: Channel;
}

const fetchUserChannelPasses = async (userUid: string, pageUrl?: string): Promise<ApiResponseData> => {
	const url = pageUrl ?? `/api/admin/user/${userUid}/purchases?type=CHANNEL_PASS&expand=tariffs&expand=products&limit=${API_PAGE_SIZE}`;
	const resp = await adminGET<ApiResponseData>(url);
	return getApiResponseData(resp);
};

export const revokeUserChannelPass = async (userUid: string, purchaseId: string) => {
	await adminDELETE(`/api/admin/user/${userUid}/purchases/${purchaseId}`);
};

export const getUserChannelPassPurchasesData = async (
	videoUid: string,
	pagingUrl?: string
): Promise<AdminApiPage<PurchasedChannelPass>> => {
	const purchasesApiResponse = await fetchUserChannelPasses(videoUid, pagingUrl);
	const purchases = purchasesApiResponse.expanded.filter(isPurchase);
	const tariffs = purchasesApiResponse.expanded.filter(isTariff);


	const channelUids = tariffs.map(tariff => getChannelUid(tariff.channel.href)).filter(uid => !!uid);
	const filter: ChannelsQuery = {
		statuses: AllChannelStatuses,
		sort: { field: 'created', direction: 'desc' },
		channelUids: channelUids,
		perPage: channelUids.length
	};

	const channels = await fetchChannelsWithImagesApi(filter);

	const channelPassPurchases = purchases.map(purchase => {
		const channelPassTariff = tariffs.find(tariff => purchase.tariff.href.endsWith(tariff.id));
		const channel = channels.items.find(channel => channelPassTariff && channel.channelUid === getChannelUid(channelPassTariff.channel.href));
		return {
			purchase,
			tariff: channelPassTariff!,
			channel: channel
		};
	});

	return {
		paging: purchasesApiResponse.paging || { count: 0 , self: ''},
		items: channelPassPurchases || []
	}
};

const getChannelUid = (channelHref: string): string => {
	return channelHref.split("/").pop() || '';
};
