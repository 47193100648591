import { createEmptyPage, Page } from '../../../app/domain/Page';
import {
  AllInvitationStatuses,
  Invitation,
  InvitationSortField,
  InvitationStatus
} from '../../../app/domain/Invitation';
import { InvitationsListStateTypes } from './InvitationsListActions';
import { createDefaultSort, Sort } from '../../../app/domain/Sort';
import { prepareInitialEndDate, prepareInitialStartDate } from '../../../report/store/ReportReducer';

export interface InvitationsState {
  invitationPage: Page<Invitation>;
  filters: InvitationsQuery;
}

export interface InvitationsQuery {
  sort: Sort<InvitationSortField>
  adminIdSearchText?: string;
  emailSearchText?: string;
  statuses: InvitationStatus[];
  startDate: Date;
  endDate: Date;
}

const initialState: InvitationsState = {
  filters: {
    sort: createDefaultSort('created', 'desc'),
    adminIdSearchText: undefined,
    emailSearchText: undefined,
    statuses: AllInvitationStatuses,
    startDate: prepareInitialStartDate().toDate(),
    endDate: prepareInitialEndDate().toDate(),
  },
  invitationPage: createEmptyPage<Invitation>(),
};

export function invitationsListReducer(state = initialState, action: InvitationsListStateTypes): InvitationsState {
  switch (action.type) {
    case "INVITATION_SET_CURRENT_PAGE":
      return { ...state, invitationPage: { ...state.invitationPage, currPage: action.page } };
    case "INVITATION_SET_INVITATIONS_PAGE":
      return { ...state, invitationPage: action.invitationPage };
    case "INVITATION_CLEAR_PAGINATION":
      return { ...state, invitationPage: { ...initialState.invitationPage } };
    case "INVITATION_SET_ADMIN_ID_SEARCH_TEXT":
      return { ...state, filters: { ...state.filters, adminIdSearchText: action.adminIdSearchText } };
    case "INVITATION_SET_EMAIL_SEARCH_TEXT":
      return { ...state, filters: { ...state.filters, emailSearchText: action.emailSearchText } };
    case "INVITATION_SET_SORT":
      return { ...state, filters: { ...state.filters, sort: action.sort } };
    case "INVITATION_SET_FILTER_STATUSES":
      return { ...state, filters: { ...state.filters, statuses: action.statuses } };
    case "INVITATION_SET_START_DATE":
      return { ...state, filters: { ...state.filters, startDate: action.startDate } };
    case "INVITATION_SET_END_DATE":
      return { ...state, filters: { ...state.filters, endDate: action.endDate } };
    default:
      return state;
  }
}
