import {Invitation} from '../../../app/domain/Invitation';

interface InvitationSetDetails {
    type: 'INVITATION_SET_DETAILS';
    invitation: Invitation;
}

export type InvitationDetailsStateTypes = InvitationSetDetails;

export function invitationSetDetailsAction(invitation: Invitation): InvitationDetailsStateTypes {
    return {
        type: 'INVITATION_SET_DETAILS',
        invitation
    };
}
