import React, { useEffect, useState } from 'react';
import { TreeIndex } from '@nosferatu500/react-sortable-tree';
import TreeNodeActions from './TreeNodeActions';
import { TreeNodeData } from './treeModel';
import '@nosferatu500/react-sortable-tree/style.css';
import {
	ExtendedNodeData,
	FullTree,
	NodeData,
	OnMovePreviousAndNextLocation,
	TreeItem,
	TreeNode,
	TypedSortableTree
} from './TypedSortableTree';

interface TreeProps {
	className: string;
	data: TreeNodeData[];
	onAddNode: (parentNodeId: number | null) => void;
	onEditNode: (nodeId: number) => void;
	onDeleteNode: (nodeId: number) => void;
	onMoveNode: (nodeId: number, newParentId: number | null) => void;
}

const Tree = (props: TreeProps) => {
	const { className, data, onAddNode, onEditNode, onDeleteNode, onMoveNode } = props;
	const [treeData, setTreeData] = useState(data);

	const getNodeKey = (data: TreeNode<TreeNodeData> & TreeIndex) => data.node.id;

	const generateNodeProps = (data: ExtendedNodeData<TreeNodeData>) => ({
		title: (
			<span className="treeNode">
				<span>{data.node.title}</span>
				<TreeNodeActions nodeId={data.node.id} onAdd={onAddNode} onEdit={onEditNode} onDelete={onDeleteNode} />
			</span>
		)
	});

	const onChangeHandler = (data: TreeItem<TreeNodeData>[]) => setTreeData(data);

	const onMoveNodeHandler = (
		data: NodeData<TreeNodeData> & FullTree<TreeNodeData> & OnMovePreviousAndNextLocation<TreeNodeData>
	) => {
		data.node.id !== null && onMoveNode(data.node.id, data.nextParentNode?.id || null);
	};

	useEffect(() => setTreeData(data), [data]);

	return (
		<div className={className}>
			<TypedSortableTree
				treeData={treeData}
				onChange={onChangeHandler}
				getNodeKey={getNodeKey}
				generateNodeProps={generateNodeProps}
				onMoveNode={onMoveNodeHandler}
			/>
		</div>
	);
};

export default Tree;
