import { User } from "../../../../../../app/domain/User";
import { editUserApi, editUserAuthApi } from "../UserEditApi";

export interface UserEditContactData {
  email: string;
}

export const userEditContactApi = (user: User, email: string) =>
  Promise.all([
    editUserApi(user.uid, { email }),
    editUserAuthApi(user.uid, { email }),
  ]);

export const validateUserEditContact = (values: UserEditContactData) => {
  const errors: Record<string, string> = {};

  if (!values.email) {
    errors.email = 'Email is required';
  }
  return errors;
};
