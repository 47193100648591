import React from 'react';
import ReportValuePanel from 'report/reportDashboard/ReportValuePanel';
import { Grid, Paper } from '@mui/material';
import { RootState } from 'app/store/store';
import { useSelector } from 'react-redux';
import { CurrencyCode } from 'accounts/apiTypes';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';
import { ChannelDailyStats, useFetchChannelStats } from 'channels/details/section/stats/useFetchChannelStats';
import {
	addTotals,
	fetchPublisherEarnings,
	fetchVideosCount,
	TariffPurchasesData,
	VideoDayData
} from 'app/stats/statsManager';
import { useLocalQuery } from 'app/utils/searchManager';
import Chart, { LineConfig } from 'report/reportDashboard/charts/Chart';
import { Colors } from 'Theme';
import 'chart.js/auto';
import { TariffType } from 'app/domain/Tariff';

const videoEarningsChartConfig: LineConfig<VideoDayData>[] = [
	{
		label: 'Video Earnings',
		transform: d => d.directEarnings || 0,
		color: Colors.Blue
	}
];

const videoPurchasesChartConfig: LineConfig<VideoDayData>[] = [
  {
    label: 'Video Purchases',
    transform: d => d.purchases || 0,
    color: Colors.Blue
  }
];

const earningsChartConfig: LineConfig<ChannelDailyStats>[] = [
  {
    label: 'Total Earnings',
    transform: d =>
      (d.videoEarnings || 0) + (d.channelPassEarnings || 0) + (d.shareEarnings || 0) + (d.referralEarnings || 0),
    color: Colors.Black
  },
  {
    label: 'Video Earnings',
    transform: d => d.videoEarnings || 0,
    color: Colors.Blue
  },
  {
    label: 'Channel Pass Earnings',
    transform: d => d.channelPassEarnings || 0,
    color: Colors.Green
  },
  {
    label: 'Share Earnings',
    transform: d => d.shareEarnings || 0,
    color: Colors.LightPurple
  }
];

const referralChartConfig: LineConfig<ChannelDailyStats>[] = [
  ...earningsChartConfig,
  {
    label: 'Referral Earnings',
    transform: d => d.referralEarnings || 0,
    color: Colors.Salmon
  }
];

const purchaseChartConfig: LineConfig<ChannelDailyStats>[] = [
  {
    transform: (d: ChannelDailyStats) => (d.videoPurchases || 0) + (d.channelPassPurchases || 0),
    label: 'Total Purchases',
    color: Colors.Black
  },
  {
    transform: (d: ChannelDailyStats) => d.videoPurchases || 0,
    label: 'Video Purchases',
    color: Colors.Blue
  },
  {
    transform: (d: ChannelDailyStats) => d.channelPassPurchases || 0,
    label: 'Channel Pass Purchases',
    color: Colors.Green
  }
];

const colors = {
  grid: Colors.GrayDark,
  background: Colors.White
};

const preparePanelValue = (value: number, currency?: CurrencyCode) => {
    if ('GBP' === currency && value !== undefined && value !== null) {
        return `£${value.toFixed(2)}`;
    }
    return value ? value.toString() : '';
}

const preparePurchasesBySourceDetails = (data: TariffPurchasesData[] | undefined, type: TariffType) =>
	data
		?.filter(row => row.type === type)
		.map(({ source, purchases }) => ({
			name: source,
			value: `${purchases}`
		})) ?? [];

const ReportDashboard = () => {
	const filter = useSelector((state: RootState) => state.report.filters);
    const { channelUid } = filter;
	const [ videoCount = 1 ] = useLocalQuery(() => fetchVideosCount(channelUid || ''), [channelUid]);

	const {
		isLoading,
		channelDailyStats,
		videoDailyStats,
		videoTotalEarnings,
		videoTotalPurchases,
		channelPassTotalPurchases,
		channelPassTotalEarnings,
		totalEarnings,
		referralTotalEarnings,
		shareTotalEarnings,
		purchasesBySourceAndTypeData
	} = useFetchChannelStats(filter);
	const forVideos = filter.checkedTitles.length;

  // used for testing old values for DEV-6416
	const [{ shareGbp, referralGbp, royaltiesGbp }] = useLocalQuery(
    () => fetchPublisherEarnings(filter).then(d => addTotals(d, 'shareGbp', 'referralGbp', 'royaltiesGbp')),
    [filter],
    {}
  );
  const legacyTotalEarnings = shareGbp + referralGbp + royaltiesGbp;
  /////////////////////////////////////////////////

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={5} container spacing={1}>
				<Grid item xs={12}>
					<Paper className="paper" elevation={3}>
						<ReportValuePanel
							description="Total Videos & Events Purchases"
							value={preparePanelValue(videoTotalPurchases)}
							detailsLabel="PURCHASES ON:"
							details={preparePurchasesBySourceDetails(
								purchasesBySourceAndTypeData,
								TariffType.SINGLE_PRODUCT
							)}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className="paper" elevation={3}>
						<ReportValuePanel
							description="Total Channel Passes Purchases"
							isAvailable={!forVideos}
							value={preparePanelValue(channelPassTotalPurchases)}
							detailsLabel="PURCHASES ON:"
							details={preparePurchasesBySourceDetails(
								purchasesBySourceAndTypeData,
								TariffType.CHANNEL_PASS
							)}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className="paper" elevation={3}>
						<ReportValuePanel
							description="Total Earnings"
							isAvailable={!forVideos}
							value={preparePanelValue(totalEarnings, 'GBP')}
							detailsLabel="DETAILS"
							details={[
								{ name: 'Legacy', value: preparePanelValue(legacyTotalEarnings, 'GBP') },
								{ name: 'Direct video sales', value: preparePanelValue(videoTotalEarnings, 'GBP') },
								{ name: 'Channel Passes', value: preparePanelValue(channelPassTotalEarnings, 'GBP') },
								{ name: 'Referral Earnings', value: preparePanelValue(referralTotalEarnings, 'GBP') },
								{
									name: 'Other Videos you Shared',
									value: preparePanelValue(shareTotalEarnings, 'GBP')
								},
								{
									name: 'Avg. Earnings per Video',
									value: preparePanelValue(
										videoTotalEarnings / (filter.checkedTitles.length || videoCount),
										'GBP'
									)
								},
								{
									name: 'Avg. Earnings per Sale',
									value: preparePanelValue(videoTotalEarnings / videoTotalPurchases, 'GBP')
								}
							]}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className="paper" elevation={3}>
						<ReportValuePanel
							description="Avg. Daily Referrals"
							isAvailable={!forVideos}
							value={preparePanelValue(referralTotalEarnings / channelDailyStats.length)}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className="paper" elevation={3}>
						<ReportValuePanel
							description="Total Number of videos"
							isAvailable={!forVideos}
							value={preparePanelValue(videoCount)}
						/>
					</Paper>
				</Grid>
            </Grid>
            <Grid item xs={12} md={7} container spacing={1}>
              <Grid item xs={12}>
                {forVideos ? (
                  <Chart data={videoDailyStats} config={videoEarningsChartConfig} colors={colors} title="Video Earnings Daily" />
                ) : (
                  <Chart
                    data={channelDailyStats}
                    config={referralTotalEarnings ? referralChartConfig : earningsChartConfig}
                    colors={colors}
                    title="Earnings Daily"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {forVideos ? (
                  <Chart data={videoDailyStats} config={videoEarningsChartConfig} culmulative colors={colors} title="Aggregate Video Earnings Daily" />
                ) : (
                  <Chart
                    data={channelDailyStats}
                    config={referralTotalEarnings ? referralChartConfig : earningsChartConfig}
                    colors={colors}
                    culmulative
                    title="Aggregate Earnings Daily"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {forVideos ? (
                  <Chart data={videoDailyStats} config={videoPurchasesChartConfig} colors={colors} title="Video Purchases Daily" />
                ) : (
                  <Chart data={channelDailyStats} config={purchaseChartConfig} colors={colors} title="Purchases Daily" />
                )}
              </Grid>
            </Grid>
            { isLoading && <LoadingOverlay/> }
        </Grid>
    );
};

export default ReportDashboard;
