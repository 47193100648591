import { VideoCategory } from 'app/domain/VideoCategory';
import { GET_LISTINGS, PUT_LISTINGS } from 'app/http/listingsApi/listingsClient';

interface GetVideoCategoriesResponse {
	categories: VideoCategory[];
}

export const fetchVideoCategoriesApi = (videoUid: string) =>
	GET_LISTINGS<GetVideoCategoriesResponse>(`admin/videos/${videoUid}/categories`).then(r=>r.data.data.categories)

export const setVideoCategoriesApi = (uid: string, categories: VideoCategory[]) =>
	PUT_LISTINGS(`admin/videos/${uid}/categories`, {
		categories: categories
	}).then(r=>r.status);
