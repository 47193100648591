import React, { useState } from 'react';
import { IconButton, Switch, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CastIcon from 'app/static/images/icons/CastIcon.svg';
import MinimumPricingModal from './MinimumPricingModal';
import RecastPercentageModal from './RecastPercentageModal';
import { ChannelConfig, ResolutionDetails } from './ChannelConfigScreen';
import { DeepPartial } from 'redux';
import { FEATURE_FLAGS } from 'app/featureFlags/featureFlags';
import { useFeatureFlagsValue } from 'app/hooks/useFeatureFlagsValue';

interface ResolutionsSectionProps {
	resolution: ResolutionDetails & { isEnabled?: boolean };
	defaultResolution: ResolutionDetails & { isEnabled?: boolean };
	resolutionHeight: 1080 | 720;
	isDisabled: boolean;
	saveConfiguration: (overrides: DeepPartial<ChannelConfig>) => void;
}

const ResolutionsSection = ({
	resolutionHeight,
	resolution,
	defaultResolution,
	isDisabled,
	saveConfiguration
}: ResolutionsSectionProps) => {
	const [isEditingPrice, setIsEditingPrice] = useState(false);
	const [isEditingPercentage, setIsEditingPercentage] = useState(false);
	const [isMaxResolutionEnabled, isMinimumPricingEnabled] = useFeatureFlagsValue([
		FEATURE_FLAGS.ENABLE_MAX_TRANSCODE_RESOLUTION_OPTION,
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_MINIMUM_PRICING
	]);
	const canBeDisabled = isMaxResolutionEnabled && resolutionHeight !== 720 && !isDisabled;
	const canBeEdited = !canBeDisabled || resolution.isEnabled;

	const isLiveEventsMinPriceCustomised =
		resolution.minimumPricing.liveEvents !== defaultResolution.minimumPricing.liveEvents;
	const isOnDemandMinPriceCustomised =
		resolution.minimumPricing.onDemand !== defaultResolution.minimumPricing.onDemand;
	const isRecastPercentageCustomised = resolution.recastPercentage !== defaultResolution.recastPercentage;

	const onResolutionToggle = async (element: React.ChangeEvent<HTMLInputElement>) => {
		saveConfiguration({
			resolutions: {
				[resolutionHeight]: {
					isEnabled: element.target.checked
				}
			}
		});
	};

	const onSaveMinimumPricing = async (minimumPricing: { onDemand: number; liveEvents: number }) => {
		saveConfiguration({
			resolutions: {
				[resolutionHeight]: {
					minimumPricing
				}
			}
		});
	};

	const onSaveRecastPercentage = async (recastPercentage: number) => {
		saveConfiguration({
			resolutions: {
				[resolutionHeight]: {
					recastPercentage
				}
			}
		});
	};

	return (
		<>
			<div className={`resolutionBox ${!canBeEdited || isDisabled ? 'disabled' : ''}`}>
				<div className="header">
					{canBeDisabled && <Switch checked={resolution.isEnabled} onChange={onResolutionToggle} />}
					<span
						className={`resolutionName ${canBeDisabled ? 'toggleableResolution' : 'defaultResolution'} ${
							isDisabled ? 'resolutionName--disabled' : ''
						}`}
					>
						{resolutionHeight}p
					</span>
				</div>

				<div className="priceDetails">
					{isMinimumPricingEnabled && (
						<div className="priceDetailSection">
							<div className="priceDetailsSectionName">
								Minimum price
								{canBeEdited && !isDisabled && (
									<Tooltip title="Edit minimum pricing" arrow>
										<IconButton
											className="editButtonSmall"
											onClick={() => setIsEditingPrice(true)}
											size="large"
										>
											<EditIcon className="editIcon" />
										</IconButton>
									</Tooltip>
								)}
							</div>

							<div className="priceType">
								Live events:{' '}
								<span className="priceValue">
									{resolution.minimumPricing.liveEvents} <img src={CastIcon} className="castIcon" />{' '}
									per min
								</span>
								{isLiveEventsMinPriceCustomised && (
									<span className="customisedConfigText">
										{`(default is ${defaultResolution.minimumPricing.liveEvents})`}
									</span>
								)}
							</div>
							<div className="priceType">
								On-demand & replays:{' '}
								<span className="priceValue">
									{resolution.minimumPricing.onDemand} <img src={CastIcon} className="castIcon" /> per
									min
								</span>
								{isOnDemandMinPriceCustomised && (
									<span className="customisedConfigText">
										{`(default is ${defaultResolution.minimumPricing.onDemand})`}
									</span>
								)}
							</div>
						</div>
					)}
					<div className="priceDetailSection">
						<div className="priceDetailsSectionName">
							Recast%
							{canBeEdited && !isDisabled && (
								<Tooltip title="Edit minimum pricing" arrow>
									<IconButton
										className="editButtonSmall"
										onClick={() => setIsEditingPercentage(true)}
										size="large"
									>
										<EditIcon className="editIcon" />
									</IconButton>
								</Tooltip>
							)}
						</div>
						<span className="priceType">
							Value: <span className="priceValue">{resolution.recastPercentage}%</span>
							{isRecastPercentageCustomised && (
								<span className="customisedConfigText">
									{`(default is ${defaultResolution.recastPercentage}%)`}
								</span>
							)}
						</span>
					</div>
				</div>
			</div>
			<MinimumPricingModal
				isOpen={isEditingPrice}
				onDemand={resolution.minimumPricing.onDemand}
				liveEvents={resolution.minimumPricing.liveEvents}
				resolutionHeight={resolutionHeight}
				onSave={onSaveMinimumPricing}
				onClose={() => {
					setIsEditingPrice(false);
				}}
			/>
			<RecastPercentageModal
				isOpen={isEditingPercentage}
				onClose={() => {
					setIsEditingPercentage(false);
				}}
				recastPercentage={resolution.recastPercentage}
				resolutionHeight={resolutionHeight}
				onSave={onSaveRecastPercentage}
			/>
		</>
	);
};

export default ResolutionsSection;
