import { CurrencyCountriesActionsStateTypes } from './CurrencyCountriesActions';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import { Country } from '../../../../videos/details/sections/summary/edit/country/CountryUseCase';

export enum CurrencyCountriesActionTypes {
  CURRENCY_COUNTRIES_OPEN_EDIT_MODAL = 'CURRENCY_COUNTRIES_OPEN_EDIT_MODAL',
  CURRENCY_COUNTRIES_CLOSE_EDIT_MODAL = 'CURRENCY_COUNTRIES_CLOSE_EDIT_MODAL',
  CURRENCY_COUNTRIES_OPEN_EDIT_COUNTRIES_MODAL = 'CURRENCY_COUNTRIES_OPEN_EDIT_COUNTRIES_MODAL',
  CURRENCY_COUNTRIES_CLOSE_EDIT_COUNTRIES_MODAL = 'CURRENCY_COUNTRIES_CLOSE_EDIT_COUNTRIES_MODAL',
  CURRENCY_COUNTRIES_OPEN_CREATE_MODAL = 'CURRENCY_COUNTRIES_OPEN_CREATE_MODAL',
  CURRENCY_COUNTRIES_CLOSE_CREATE_MODAL = 'CURRENCY_COUNTRIES_CLOSE_CREATE_MODAL',
  CURRENCY_COUNTRIES_SET_ALL_SELECTED_CODE = 'CURRENCY_COUNTRIES_SET_ALL_SELECTED_CODE',
  CURRENCY_COUNTRIES_SET_SUPPORTED_COUNTRIES = 'CURRENCY_COUNTRIES_SET_SUPPORTED_COUNTRIES',
  CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES = 'CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES'
}
interface CurrencyCountriesActionsState {
  checkedCodes: string[];
  supportedCountries: Country[]
  currencyCountries: CurrencyCountries[];
  currencyCountry: CurrencyCountries;

  editModal: {
    isOpen: boolean;
  };
  editCountriesModal: {
    isOpen: boolean;
  };
  createModal: {
    isOpen: boolean;
  };
  onRefresh: () => void;
}

const initialState: CurrencyCountriesActionsState = {
  checkedCodes: [],
  supportedCountries: [],
  currencyCountries: [],
  currencyCountry: {currencyCode: '', countryCodes: []} as CurrencyCountries,

  editModal: {
    isOpen: false,
  },
  editCountriesModal: {
    isOpen: false,
  },
  createModal: {
    isOpen: false
  },
	onRefresh: () => undefined
};

export function currencyCountriesActionsReducer(state = initialState, action: CurrencyCountriesActionsStateTypes): CurrencyCountriesActionsState {
  switch (action.type) {
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_EDIT_MODAL:
      return {...state, editModal: {...state.editModal, isOpen: true}};
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_EDIT_MODAL:
			return { ...state, editModal: { ...state.editModal, isOpen: false }, onRefresh: () => undefined };
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_EDIT_COUNTRIES_MODAL:
      return {...state, editCountriesModal: {...state.editCountriesModal, isOpen: true}, currencyCountry: action.currencyCountry};
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_EDIT_COUNTRIES_MODAL:
			return {
				...state,
				editCountriesModal: { ...state.editCountriesModal, isOpen: false },
				onRefresh: () => undefined
			};
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_OPEN_CREATE_MODAL:
      return {...state, createModal: {...state.createModal, isOpen: true}};
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_CLOSE_CREATE_MODAL:
			return { ...state, createModal: { ...state.createModal, isOpen: false }, onRefresh: () => undefined };
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_ALL_SELECTED_CODE:
      return { ...state, checkedCodes: action.codes };
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_SUPPORTED_COUNTRIES:
      return { ...state, supportedCountries: action.supportedCountries };
    case CurrencyCountriesActionTypes.CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES:
      return { ...state, currencyCountries: action.currencyCountries };
    default:
      return state;
  }
}
