import React from 'react';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { connect } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { userCloseDeleteUserModalAction } from '../store/UsersActionsActions';

import { User } from '../../../app/domain/User';
import { deleteUserUseCase } from 'users/actions/deleteUser/DeleteUserUseCase';

interface StateProps {
	isOpen: boolean;
	user: User;
}

interface DispatchProps {
	deleteUser: (uid: string) => void;
	closeModal: () => void;
}

type Props = StateProps & DispatchProps;


const DeleteUserModal = (props: Props) => {
	const { isOpen, user, closeModal, deleteUser } = props;

	const onDeleteUser = () => {
		deleteUser(user.uid);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={closeModal}
			maxWidth="sm">
			<DialogTitle>Do you want to <strong>DELETE</strong> the user account?</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This action will deactivate user account from Fan app and delete it permanently from the
					database. This action cannot be undone and will take place within next 30 minutes.
				</DialogContentText>
				<br/>
				<br/>
				<DialogContentText>
					After the user account has been deleted the user data (email & phone number) will be kept in
					the database for 3 months to prevent user from creating a new account with the same details
					- more details can be found in Recast Terms & Conditions and Privacy Policy
				</DialogContentText>
				<DialogActions>
					<Button onClick={closeModal} color="primary">
						Cancel
					</Button>
					<Button onClick={onDeleteUser} type="button" color="secondary" variant="contained" autoFocus>
						DELETE
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};


const mapStateToProps = (state: RootState) => ({
	isOpen: state.users.actions.deleteUserModal.isOpen,
	user: state.users.actions.deleteUserModal.user
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
	deleteUser: (uid: string) => {
		dispatch(deleteUserUseCase(uid));
	},
	closeModal: () => {
		dispatch(userCloseDeleteUserModalAction());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
