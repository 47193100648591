import React, { useState } from 'react';
import { Box, Chip, Container, Divider, Grid, Tooltip } from '@mui/material';
import {
	editChannelApi,
	editImageApi,
	fetchChannelRolesApi,
	ImageType
} from 'channels/details/section/summary/ChannelDetailsSummaryUseCase';
import Title from 'app/components/title/Title';
import ImageUpload from 'users/actions/image/ImageUpload/ImageUpload';
import ChannelActionButtons from 'channels/actions/ChannelActionButtons';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { ChannelDetails, ChannelRole } from 'app/domain/ChannelDetails';
import EditChannelModal, { EditChannelValues } from 'channels/actions/edit/EditChannelModal';
import SectionRow from 'app/components/SectionRow';
import { useLocalQuery } from 'app/utils/searchManager';
import CategoriesList from 'categories/CategoriesList';
import { getConfig } from 'config/appConfig';
import { removeChannelPrivileges, updateChannelPrivileges } from 'app/http/commentsApi/commentsApi';

function preparePartnerEditChannelData(data: EditChannelValues): EditChannelValues {
	return {
		name: data.name ?? undefined,
		description: data.description ?? undefined,
		roles: data.roles ?? undefined,
		ceilingCategoriesIds: data.ceilingCategoriesIds ?? undefined
	};
}

const ChannelDetailsSummaryScreen = (props: { channel: ChannelDetails; uid: string; refresh: () => void }) => {
	const { channel, uid, refresh } = props;

	const [showEdit, setShowEdit] = useState(false);

	const [roles, refreshRoles] = useLocalQuery(() => fetchChannelRolesApi(uid), [uid]);
	const reFetchData = async () => Promise.all([refresh(), refreshRoles()]);

	const setImage = (type: ImageType, file: File) =>
		editImageApi({
			partnerId: channel.partnerId,
			channelId: channel.uid,
			type,
			file
		}).then(refresh);

	const setAvatarImage = (file: File) => setImage(ImageType.avatar, file);
	const saveBannerImage = (file: File) => setImage(ImageType.banner, file);

	const renderRole = (role: ChannelRole) => <Chip key={`role_${role}`} style={{ margin: '3px' }} label={role} />;

	const editChannel = (values: EditChannelValues) => {
		values && editChannelApi(uid, preparePartnerEditChannelData(values)).then(reFetchData);
	};

	const checkForRoleChange = async (initialRoles: ChannelRole[] = [], newRoles: ChannelRole[] = []) => {
		const addedRoles = newRoles.filter(role => !initialRoles.includes(role));
		const removedRoles = initialRoles.filter(role => !newRoles.includes(role));

		const commentsAdded = addedRoles.includes('COMMENTS');
		const commentsRemoved = removedRoles.includes('COMMENTS');

		if (commentsAdded) {
			await updateChannelPrivileges({
				channelId: channel.uid,
				data: {
					privileges: ['groups:topics:read', 'groups:topics:reply']
				}
			});
		}

		if (commentsRemoved) {
			await removeChannelPrivileges({
				channelId: channel.uid,
				data: {
					privileges: ['groups:topics:read', 'groups:topics:reply']
				}
			});
		}
	};

	const shareLink = `${getConfig().SHARE_BASE}/u/${channel.uid}?referrer=${channel.uid}`;

	return (
		<Container className="userDetailsScreen">
			<Grid container direction="row" alignItems="center">
				<Grid item xs={12} sm={6}>
					<Title>
						Channel: {channel.name}
						<Tooltip title="Edit channel" arrow>
							<IconButton onClick={() => setShowEdit(true)} size="large">
								<EditIcon />
							</IconButton>
						</Tooltip>
					</Title>
				</Grid>
				<Grid className="userDetailsActions" item xs={12} sm={6}>
					<ChannelActionButtons uid={channel.uid} status={channel.status} refresh={refresh} />
				</Grid>
			</Grid>
			<Divider />
			<Box className="section" component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">Profile Info</span>
				</div>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<ImageUpload
									className="bannerImage imageUpload--banner"
									isBannerImage={true}
									name="banner"
									defaultImage={channel.bannerImage}
									setFile={saveBannerImage}
								/>
							</Grid>
							<Grid item style={{ marginRight: '10px' }}>
								<ImageUpload
									className="profileImage imageUpload--profile"
									name="avatar"
									defaultImage={channel.avatarImage}
									setFile={setAvatarImage}
								/>
							</Grid>
							<Grid item>
								<SectionRow label="Uid" content={channel.uid} />
								<SectionRow label="Name" content={channel.name || '-'} />
								<SectionRow label="Description" content={channel.description || '-'} />
								<SectionRow label="Roles" content={roles?.map(renderRole)} />
								<SectionRow
									label="Ceiling categories"
									content={<CategoriesList ids={channel.ceilingCategoriesIds} />}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<SectionRow label="Followers" content={channel.followerCount || '-'} />
						<SectionRow label="Sign up date" content={channel.created || '-'} />
						<SectionRow label="Last update date" content={channel.updated || '-'} />
						<SectionRow label="Status" content={<Chip label={channel.status || '-'} />} />
						<SectionRow label="Type" content={channel.isPersonal ? 'INDIVIDUAL' : 'ORGANIZATION'} />
						<SectionRow label="T&C acceptance date" content={channel.acceptanceDate || '-'} />
						<SectionRow
							label="T&C acceptance"
							content={
								!!channel.acceptanceUser && (
									<a href={'/user/' + channel.acceptanceUser}>{channel.acceptanceUser}</a>
								)
							}
						/>
						<SectionRow
							label="Partner"
							content={<a href={'/publisher/' + channel.partnerId}>{channel.partnerId}</a>}
						/>
						<SectionRow label="Referral link" content={<a href={shareLink}>{shareLink}</a>} />
					</Grid>
				</Grid>
			</Box>
			<EditChannelModal
				isOpen={showEdit}
				closeModal={() => setShowEdit(false)}
				save={async values => {
					editChannel(values);
					await checkForRoleChange(values.initialRoles, values.roles);
				}}
				values={{
					name: channel.name,
					description: channel.description,
					roles,
					ceilingCategoriesIds: channel.ceilingCategoriesIds
				}}
			/>
		</Container>
	);
};

export default ChannelDetailsSummaryScreen;
