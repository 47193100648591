import { AppThunk } from '../../../app/store/store';

interface OpenRemoveModal {
  type: 'VIDEO_OPEN_REMOVE_MODAL';
  videoId: string;
  onRefresh: () => void;
}

interface CloseRemoveModal {
  type: 'VIDEO_CLOSE_REMOVE_MODAL';
}

interface OpenSuspendModal {
  type: 'VIDEO_OPEN_SUSPEND_MODAL';
  videoId: string;
  onRefresh: () => void;
}

interface CloseSuspendModal {
  type: 'VIDEO_CLOSE_SUSPEND_MODAL';
}

interface OpenUnsuspendModal {
  type: 'VIDEO_OPEN_UNSUSPEND_MODAL';
  videoId: string;
  onRefresh: () => void;
}

interface CloseUnsuspendModal {
  type: 'VIDEO_CLOSE_UNSUSPEND_MODAL';
}

interface OpenInviteModal {
  type: 'VIDEO_OPEN_INVITE_MODAL';
  url: string;
  videoName: string;
}

interface CloseInviteModal {
  type: 'VIDEO_CLOSE_INVITE_MODAL';
}


export type VideosActionsStateTypes =
  OpenRemoveModal |
  CloseRemoveModal |
  OpenSuspendModal |
  CloseSuspendModal |
  OpenInviteModal |
  CloseInviteModal |
  OpenUnsuspendModal |
  CloseUnsuspendModal;

export function videoOpenRemoveModalAction(videoId: string, onRefresh: () => void): VideosActionsStateTypes {
  return {
    type: 'VIDEO_OPEN_REMOVE_MODAL',
    videoId,
    onRefresh
  };
}

export function videoCloseRemoveModalAction(): VideosActionsStateTypes {
  return {
    type: 'VIDEO_CLOSE_REMOVE_MODAL'
  };
}

export function videoOpenSuspendModalAction(videoId: string, onRefresh: () => void): VideosActionsStateTypes {
  return {
    type: 'VIDEO_OPEN_SUSPEND_MODAL',
    videoId,
    onRefresh
  };
}

export function videoCloseSuspendModalAction(): VideosActionsStateTypes {
  return {
    type: 'VIDEO_CLOSE_SUSPEND_MODAL'
  };
}

export function videoOpenInviteModalAction(url: string, videoName: string): VideosActionsStateTypes {
  return {
    type: 'VIDEO_OPEN_INVITE_MODAL',
    url,
    videoName
  };
}

export function videoCloseInviteModalAction(): VideosActionsStateTypes {
  return {
    type: 'VIDEO_CLOSE_INVITE_MODAL'
  };
}

export function videoOpenUnsuspendModalAction(videoId: string, onRefresh: () => void): VideosActionsStateTypes {
  return {
    type: 'VIDEO_OPEN_UNSUSPEND_MODAL',
    videoId,
    onRefresh
  };
}

export function videoCloseUnsuspendModalAction(): VideosActionsStateTypes {
  return {
    type: 'VIDEO_CLOSE_UNSUSPEND_MODAL'
  };
}

export function videoOnRefreshAction(): AppThunk {
	return ((_, getState) => {
    const { onRefresh } = getState().videos.actions;
    onRefresh();
  });
}
