import { Category } from 'app/domain/Category';
import { GET_LISTINGS, POST_LISTINGS, PUT_LISTINGS } from 'app/http/listingsApi/listingsClient';

interface AdminCategoriesResponse {
	categories: Category[];
}

type AdminCategoryRequestBody = Omit<Category, 'id' | 'isChildrenGeneral'| 'created'>;

export const fetchCategoriesApi = () =>
	GET_LISTINGS<AdminCategoriesResponse>('admin/categories').then(r=>r.data.data.categories);

export const addCategoryApi = (category: AdminCategoryRequestBody) =>
	POST_LISTINGS<Category>('admin/categories', category).then(r=>r.data.data);

export const updateCategoryApi = (id: number, category: AdminCategoryRequestBody) =>
	PUT_LISTINGS<Category>(`admin/categories/${id}`, category).then(r=>r.data.data);
