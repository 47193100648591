import { DELETE_PARTNER, PUT_PARTNER } from '../../../../../../app/http/partnerApi/partnerClient';

export interface CreateInviteLinkResponse {
    invitationLink: string;
}

export const addChannelAdminApi = (uid: string, channelUid: string) =>
  PUT_PARTNER<any>(`/admin/permission/channel/${channelUid}/user/${uid}`)
      .then(r=>r.data.data);

export const removeChannelAdminApi = async (uid: string, channelUid: string) =>
  DELETE_PARTNER<any>(`/admin/permission/channel/${channelUid}/user/${uid}`)
    .then(r=>r.data.data);

