import React from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { updateCategoryUseCase, useAllCategories } from 'categories/categoriesUseCase';
import { Category } from 'app/domain/Category';

interface Props { 
	isOpen: boolean, 
	categoryId: number,
	newParentId: number, 
	closeModal: ()=>void,
}

const CategoryMovementConfirmationModal = ({ isOpen, newParentId, categoryId, closeModal }: Props) => {
	const { categories, refresh } = useAllCategories();
	const category = categories?.find(({ id }) => id === categoryId);

	if (!category) {
		return <></>;
	}

	const currentParent = category.parentId ? categories?.find(({ id }) => id === category.parentId) as Category : null;
	const newParent = newParentId ? categories?.find(({ id }) => id === newParentId) as Category : null;

	const onConfirm = () => {
		closeModal();
		const { id, ...categoryData } = category;
		updateCategoryUseCase(id, { ...categoryData, parentId: newParentId as number | null }).then(refresh);
	};

	return (
		<Dialog className='dialog' fullWidth maxWidth='sm' open={isOpen} onClose={closeModal}>
			<DialogContent>
				<div>
					You are about move &quot;{category.name}&quot; from &quot;{currentParent?.name || 'ROOT'}&quot; to
					&quot;{newParent?.name || 'ROOT'}&quot;
				</div>
				<div>
					<Button onClick={closeModal} color='primary'>
						Cancel
					</Button>
					<Button onClick={onConfirm} variant='contained' color='secondary' autoFocus>
						Confirm
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CategoryMovementConfirmationModal;
