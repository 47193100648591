import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { addAccessToken, resetAccessTokenOnUnauthorized } from 'app/http/authApi/token';
import { getConfig } from 'config/appConfig';

const clientProfile: AxiosInstance = axios.create({});

clientProfile.interceptors.response.use(res => res, resetAccessTokenOnUnauthorized);
clientProfile.interceptors.request.use(addAccessToken, Promise.reject);

const getProfileApiUrl = (suffix?: string): string => getConfig().API_BASE + suffix;

export const GET_DATA_PROFILE = <U>(url: string, data?: AxiosRequestConfig) => {
	type Response = AxiosResponse<U>;
	return clientProfile.get<typeof data, Response>(getProfileApiUrl(url), data);
};

