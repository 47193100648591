import React, { useState } from 'react';
import { UnverifiedUser } from '../app/domain/UnverifiedUser';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from '@mui/material';
import { resendUnverifiedUserApi } from 'unverified/UnverifiedUsersUseCase';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

export interface ResendVerificationCodeValues {
  email?: string;
  phone?: string;
}

export enum ContactMethodType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

interface Props {
  user: UnverifiedUser;
  isOpen: boolean;
  closeModal: () => void;
}

const ResendVerificationCodeModal = (props: Props) => {
  const { user, isOpen, closeModal } = props;

  const [method, setMethod] = useState('');

	const handleChange: SelectInputProps<string>['onChange'] = (event) => {
		setMethod(event.target.value);
	};

  const onCloseModal = () => {
    setMethod('')
    closeModal()
  };

  const handleResendVerificationCode = () => {
    switch (method) {
      case ContactMethodType.EMAIL :
        return resendUnverifiedUserApi({email: user.email});
      case ContactMethodType.PHONE :
        return resendUnverifiedUserApi({phone: user.phone});
    }
  };

  const disabledResendButton = () => {
    return !( ContactMethodType.EMAIL === method || ContactMethodType.PHONE === method );
  };

  return (
      <Dialog
          className="dialog"
          open={isOpen}
          onClose={onCloseModal}
          fullWidth={true}
          maxWidth={'sm'}
      >
        <DialogTitle>Resend validation code</DialogTitle>
        <DialogContent>
				<FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Contact method</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={method}
                label="Method"
                onChange={handleChange}
            >
              <MenuItem value={ContactMethodType.EMAIL}>Email: <strong>{user.email}</strong></MenuItem>
						{user.phone ? (
							<MenuItem value={ContactMethodType.PHONE}>
								Phone: <strong>{user.phone}</strong>
							</MenuItem>
						) : null}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className={`dialogActions`}>
				<Button variant="contained" onClick={onCloseModal} className={`button-cancel`}>
            Close
          </Button>
          <Button disabled={disabledResendButton()} variant="contained" onClick={handleResendVerificationCode} color="secondary" className={`button-yes`}>
            Resend
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default ResendVerificationCodeModal;
