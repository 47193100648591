import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { getBaseApiUrl } from 'app/http/request';
import { AxiosRequestConfigProps } from 'app/http/mediaApi/mediaClient';
import { addAccessToken } from 'app/http/authApi/token';

const clientFinance: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});
clientFinance.interceptors.request.use(addAccessToken, Promise.reject);

const getFinanceApiUrl = (suffix?: string): string => getBaseApiUrl('finance', suffix);

export const POST_FINANCE = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<U>;
	return clientFinance.post<typeof data, Response>(getFinanceApiUrl(url), data, config);
};
