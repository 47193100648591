import React from 'react';
import { Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PartnerChannelsScreen from './section/channels/PartnerChannelsScreen';
import WalletsScreen from './section/wallets/WalletsScreen';
import { prepareTechnicalValue } from '../../app/utils/technical';
import PartnerDetailsSummaryScreen from './section/summary/PartnerDetailsSummaryScreen';
import { useParams } from 'react-router-dom';
import { fetchPartnerDetailsApi } from './PartnerDetailsUseCase';
import { useUrlHash } from 'app/utils/useUrlHash';
import { useLocalQuery } from 'app/utils/searchManager';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';

const PartnerDetailsScreen = () => {
  const { uid } = useParams<{uid: string}>();
  const [ partner, refresh ] = useLocalQuery(()=>fetchPartnerDetailsApi(uid), [uid]);

  const [ [tab], setTab] = useUrlHash();

  return (
    <Container className="userDetailsScreen">
      <Paper>
        {!partner ? <LoadingOverlay/> : 
          <TabContext value={tab || 'summary'}>
            <TabList
              className="tabs"
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, tab)=>setTab(tab)} 
              variant="scrollable"
            >
              <Tab label="Summary" value='summary' />
              <Tab label="Channels" value='channels' />
              <Tab label="Wallets" value='wallets' />
              <Tab label="Technical" value='technical' />
            </TabList>
            <TabPanel value='summary'>
              <PartnerDetailsSummaryScreen partner={partner} refresh={refresh} />
            </TabPanel>
            <TabPanel value='channels'>
              <PartnerChannelsScreen partner={partner} />
            </TabPanel>
            <TabPanel value='wallets'>
              <WalletsScreen accounts={partner.accounts} />
            </TabPanel>
            <TabPanel value='technical'>
              <pre style={{textAlign: "left"}}>{prepareTechnicalValue(partner)}</pre>
            </TabPanel>
          </TabContext>
        }
      </Paper>
    </Container>
  );
};

export default PartnerDetailsScreen;


