import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';

interface StreamKeysModalProps {
	resolutionHeight: number;
	onDemand: number;
	liveEvents: number;
	isOpen: boolean;
	onSave: (value: { onDemand: number; liveEvents: number }) => void;
	onClose: () => void;
}

const minimum = 0.1;
const maximum = 9999;

const onDemandValidationMessage = `On demand minimum price must be between ${minimum} and ${maximum}`;
const liveEventsValidationMessage = `Live events minimum price must be between ${minimum} and ${maximum}`;

const ValidationSchema = Yup.object().shape({
	onDemand: Yup.number().max(maximum, onDemandValidationMessage).min(minimum, onDemandValidationMessage),
	liveEvents: Yup.number().max(maximum, liveEventsValidationMessage).min(minimum, liveEventsValidationMessage)
});

const MinimumPricingModal = ({
	resolutionHeight,
	onDemand,
	liveEvents,
	isOpen,
	onSave,
	onClose
}: StreamKeysModalProps) => {
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>Edit minimum pricing per minute</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{ onDemand, liveEvents }}
				onSubmit={values => {
					onSave(values);
					onClose();
				}}
				validationSchema={ValidationSchema}
			>
				{({ dirty, isValid }) => (
					<Form>
						<DialogContent>
							<div className="helpText">
								Edit minimum pricing in Cast Credits per minute for the following types of{' '}
								{resolutionHeight}p content.
							</div>
							<Field
								className="formField"
								label="Live events"
								fullWidth
								name="liveEvents"
								variant="outlined"
								component={TextField}
								helperText=" "
								type="number"
							/>
							<Field
								className="formField"
								label="On demand & replays"
								fullWidth
								name="onDemand"
								variant="outlined"
								component={TextField}
								helperText=" "
								type="number"
							/>
						</DialogContent>

						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button type="submit" color="secondary" variant="contained" disabled={!dirty || !isValid}>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default MinimumPricingModal;
