import * as Yup from 'yup';
import { FormMessages } from '../../../constants/Common';

export const commonPartnerValidation = {
	name: Yup.string().max(100, FormMessages.TOO_LONG).required(FormMessages.REQUIRED),
	email: Yup.string().email(FormMessages.INVALID_EMAIL).required(FormMessages.REQUIRED),
	phone: Yup.string().required(FormMessages.REQUIRED),
	addressLines: Yup.string()
		.min(3, FormMessages.TOO_SHORT)
		.max(100, FormMessages.TOO_LONG)
		.required(FormMessages.REQUIRED),
	secondAddressLine: Yup.string().min(3, FormMessages.TOO_SHORT).max(100, FormMessages.TOO_LONG),
	stateCode: Yup.string().min(2, FormMessages.TOO_SHORT).max(16, FormMessages.TOO_LONG),
	postalCode: Yup.string()
		.min(3, FormMessages.TOO_SHORT)
		.max(10, FormMessages.TOO_LONG)
		.required(FormMessages.REQUIRED),
	countryCode: Yup.string()
		.min(2, FormMessages.TOO_SHORT)
		.max(10, FormMessages.TOO_LONG)
		.required(FormMessages.REQUIRED)
};

export const organisationPartnerValidation = {
	...commonPartnerValidation,
	companyNumber: Yup.string()
		.min(3, FormMessages.TOO_SHORT)
		.max(16, FormMessages.TOO_LONG)
		.required(FormMessages.REQUIRED),
	position: Yup.string()
		.min(1, FormMessages.TOO_SHORT)
		.max(100, FormMessages.TOO_LONG)
		.required(FormMessages.REQUIRED),
	vatNumber: Yup.string()
		.min(5, FormMessages.TOO_SHORT)
		.max(15, FormMessages.TOO_LONG)
		.required(FormMessages.REQUIRED)
};
