import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { refundProductTransaction, refundPurchaseTransaction } from 'accounts/AccountDetailsUseCase';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/store';
import { Field, Form, Formik } from 'formik';
import {
	closeFailedLedgersModalAction,
	closeRefundConfirmationModalAction,
	openFailedLedgersModalAction
} from 'refunds/store/refundsActions';
import WarningIcon from '@mui/icons-material/Warning';

interface ConfirmActionProps {
	onRefresh: () => void;
}

export enum ConfirmationTypes {
	CONFIRM_REFUND = 'CONFIRM_REFUND',
	DENY_REFUND = 'DENY_REFUND'
}

const RefundConfirmationModal = (props: ConfirmActionProps) => {
	const { onRefresh } = props;
	const dispatch = useDispatch();
	const [errors, setErrors] = useState('');
	const [reason, onReasonChange] = useState('');
	const { isOpen, refundType, refundTitle, singleRefund, bulkRefund } = useSelector(
		(state: RootState) => state.refunds.confirmRefundModal
	);

	const onClickDiscard = () => {
		dispatch(closeRefundConfirmationModalAction());
		onReasonChange('');
	};
	const isBulkRefundReasonRequired = bulkRefund ? reason.trim().length === 0 : false;

	const onSubmit = async () => {
		try {
			if (singleRefund) {
				await refundPurchaseTransaction(singleRefund.userId, singleRefund.ledgerId);
			}
			if (bulkRefund) {
				bulkRefund.refundReason = reason;
				await refundProductTransaction(bulkRefund.productId, bulkRefund.refundReason).then(response => {
					const failedLedgers = response.status.value.failedLedgers;
					if (failedLedgers) {
						dispatch(openFailedLedgersModalAction(failedLedgers));
					}
				});
				onReasonChange('');
			}
			onRefresh();
			dispatch(closeRefundConfirmationModalAction());
			dispatch(closeFailedLedgersModalAction());

		} catch (e) {
			console.error(e);
			setErrors(typeof e === 'string' ? e : 'Unexpected error');
		}
	};
	return (
		<Dialog
			open={isOpen}
			onClose={onClickDiscard}
			fullWidth={true}
			maxWidth={'sm'}
			className="confirmationDialog refundsModal"
		>
			<Formik
				initialValues={{
					confirmation: ConfirmationTypes.DENY_REFUND
				}}
				onSubmit={onSubmit}
			>
				{({ values, isSubmitting }) => (
					<Form>
						{isSubmitting}
						<DialogContent>
							<WarningIcon style={{fontSize: '60px', color: '#AD130E'}}/>
						</DialogContent>
						<DialogTitle className="refundsModal-title">
							{ bulkRefund ?
							'Do you want to REFUND the Cast Credits for All users who purchased the video?' :
							'Do you want to reverse this transaction and refund Cast Credits for this purchase?' }
							<br /> (THIS CAN&apos;T BE UNDONE)
						</DialogTitle>
						<DialogContent className={'dialogContent'}>
							<DialogContentText>
								<div style={{color: 'grey'}}>
									Type: <strong style={{color: 'black'}}>{refundType}</strong>
								</div>
								<div style={{color: 'grey'}}>
									Title: <strong style={{color: 'black'}}>{refundTitle}</strong>
								</div>
							</DialogContentText>
							<DialogContentText id="alert-dialog-slide-description">
								{ bulkRefund ?
								'This action will deduct Cast Credits from the Publisher balance and refund Cast Credits ' +
								'to all users. Users won\'t have access to this content anymore.' :
								'This action will fully reverse the transaction removing money from the publisher wallet, ' +
								'returning the Recast commission and any publisher share and earn and split earnings ' +
								'amounts and return the full amount to the user. The item purchased will no longer be ' +
								'available to the user.'}
							</DialogContentText>
							{ bulkRefund ?
							<TextField
								fullWidth multiline minRows={3}
								label="Reason" variant="outlined"
								className={"refundsModal-reason"}
								value={reason} onChange={(e) => onReasonChange(e.target.value)}
							/> : <></> }
							<DialogContentText>Do you confirm this action?</DialogContentText>
							<div>
								<label>
									<Field type="radio" value={ConfirmationTypes.CONFIRM_REFUND} name="confirmation" />
									Yes
								</label>
								<br />
								<label>
									<Field type="radio" value={ConfirmationTypes.DENY_REFUND} name="confirmation" />
									No
								</label>
							</div>
						</DialogContent>
						<DialogContentText className="refundsModal-errors">{errors}</DialogContentText>

						<DialogActions className={`dialogActions`}>
							<Button
								onClick={onClickDiscard}
								className="refundsModal-cancelRefund"
							>
								Cancel
							</Button>
							<Button
								type="submit"
								disabled={isBulkRefundReasonRequired || isSubmitting || values.confirmation !== ConfirmationTypes.CONFIRM_REFUND}
								className="refundsModal-confirmRefund"
							>
								Confirm & Refund
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default RefundConfirmationModal;
