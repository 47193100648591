export const ALLOWED_UPLOAD_IMAGE_FORMATS = 'image/png,image/x-png,image/jpeg,.heif,.heic';

export const isHeicImageType = (file: File): boolean => {
	return file ? !!file.name.match(/\.(heic|heif)$/i) : false;
};

export const convertHeicFileToJpeg = async (file: File | any, actionOnEnd: (file: string) => void) => {
	const { default: heic2any } = await import('heic2any');

	file.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
		const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

		heic2any({
			blob,
			toType: 'image/jpeg'
		})
			.then(conversionResult =>
				actionOnEnd(
					URL.createObjectURL(Array.isArray(conversionResult) ? conversionResult[0] : conversionResult)
				)
			)
			.catch(e => console.log(e));
	});
};
