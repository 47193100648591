import React from 'react';
import { Chip, TableCell, TableRow } from '@mui/material';
import { useHistory } from 'react-router-dom';
import RoutePaths from '../../../app/navigation/RoutePaths';
import { AppThunkDispatch } from '../../../app/store/store';
import { connect } from 'react-redux';
import { invitationFetchPageAction } from '../store/InvitationsListActions';
import { Invitation } from '../../../app/domain/Invitation';
import InvitationActionButtons from '../../actions/InvitationActionButtons';

interface InvitationRowProps {
    invitation: Invitation;
}

interface DispatchProps {
    onRefresh: () => void;
}

type Props = InvitationRowProps & DispatchProps;

const InvitationRow = (props: Props) => {
    const { invitation, onRefresh } = props;
    const history = useHistory();

    const navigateToInvitationDetails = (id: number) => {
        history.push( `${ RoutePaths.InvitationDetails }/${ id }` );
    };

    return (
		<TableRow style={ { height: '3em' } } onClick={ () => navigateToInvitationDetails( invitation.id ) }>
            <TableCell>
                <div className="name">{ invitation.id }</div>
            </TableCell>
            <TableCell>
                <div className="name">{ invitation.email }</div>
            </TableCell>
            <TableCell>
                <Chip label={ invitation.status }/>
            </TableCell>
            <TableCell>
                <div className="name">{ invitation.created }</div>
            </TableCell>
            <TableCell>
                <div className="name">{ invitation.adminId }</div>
            </TableCell>
            <TableCell>
                <InvitationActionButtons invitation = { invitation } onRefresh={ onRefresh }/>
            </TableCell>
        </TableRow>
    );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    onRefresh: async () => {
        await dispatch( invitationFetchPageAction() );
    }
} );

export default connect( null, mapDispatchToProps )( InvitationRow );
