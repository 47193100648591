import { AppThunk } from '../../../../app/store/store';
import { CurrenciesSortField, Currency, sortCurrencies } from '../../../../app/domain/Currency';
import { Sort } from '../../../../app/domain/Sort';

interface SetCurrencies {
  type: 'CURRENCIES_SET_CURRENCIES';
  currencies: Currency[];
}

interface SetProcessedCurrencies {
  type: 'CURRENCIES_SET_PROCESSED_CURRENCIES';
  processedCurrencies: Currency[];
}

interface SetSort {
  type: 'CURRENCIES_SET_SORT';
  sort: Sort<CurrenciesSortField>;
}

export type CurrentListStateTypes =
    SetCurrencies |
    SetProcessedCurrencies |
    SetSort;


export function currenciesSetCurrenciesAction(currencies: Currency[]): AppThunk {
  return (dispatch) => {
    dispatch({ type: 'CURRENCIES_SET_CURRENCIES', currencies });
    dispatch(recomputeCurrencies());
  };
}

export function currenciesSetSortAction(sort: Sort<CurrenciesSortField>): AppThunk {
  return (dispatch) => {
    dispatch({ type: 'CURRENCIES_SET_SORT', sort });
    dispatch(recomputeCurrencies());
  };
}

function recomputeCurrencies(): AppThunk {
  return (dispatch, getState) => {
    const { currencies, sort } = getState().currency.currencies.list;
    const processedCurrencies = sortCurrencies(currencies, sort);
    dispatch({ type: 'CURRENCIES_SET_PROCESSED_CURRENCIES', processedCurrencies })
  };
}

