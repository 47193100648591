import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import './usersScreen.scss';
import { Status, User } from '../../../../../../../app/domain/User';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
	user: User;
	saveAdmin: (uid: string) => void;
}

const AdminRow = (props: Props) => {
	const { user, saveAdmin } = props;
	return (
		<TableRow className={'status_' + Status[user.statusId]}>
			<TableCell>
				<div className="name">{user.username}</div>
			</TableCell>
			<TableCell>
				<div className="name">{user.email}</div>
			</TableCell>
			<TableCell>{user.roles.map(el => el.role).join(', ')}</TableCell>
			{/* <TableCell>
        <Link to={`${RoutePaths.UserDetails}/${user.referrer}`} onClick={e => e.stopPropagation()}>
          {user.referrer}
        </Link>
      </TableCell> */}
			<TableCell>{user.created}</TableCell>
			<TableCell>
				<Tooltip title="Add admin" arrow>
					<IconButton className="button button-orange" onClick={() => saveAdmin(user.uid)} size="large">
						<CheckIcon />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};

export default AdminRow;
