import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Tooltip
} from '@mui/material';
import Title from '../../../../app/components/title/Title';
import AddIcon from '@mui/icons-material/Add';
import RemoveModal from 'app/components/modal/remove/RemoveModal';
import AddChannelAffiliateModal from './components/AddChannelAffiliateModal/AddChannelAffiliateModal';
import { addChannelAffiliate, getChannelAffiliates, removeChannelAffiliate } from './services';
import { Page } from 'app/domain/Page';
import { Affiliate } from 'app/domain/Affiliate';
import Pagination from 'app/components/pagination/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';

interface AffiliatesScreenProps {
	channelUid: string;
	channelName: string;
}
const AffiliatesScreen = (props: AffiliatesScreenProps) => {
	const { channelUid, channelName } = props;
	const [removeUid, setRemoveUid] = useState<string>('');
	const [showAddAffiliateModal, setShowAddAffiliateModal] = useState<boolean>(false);
	const [data, setData] = useState<Page<Affiliate>>({
		currPage: 1,
		items: [],
		perPage: 20,
		totalCount: 0,
		pageCount: 0
	});

	const fetch = (page: number) => {
		getChannelAffiliates(channelUid, page).then(setData);
	};

	const refresh = () => fetch(data.currPage);

	useEffect(() => fetch(1), [channelUid]);
	return (
		<Container className="tagsScreen">
			<Grid container spacing={2}>
				<Grid item xs={11}>
					<Title>Affiliates</Title>
					<Typography className="subtitle" variant="body2">
						The following affiliates will have access to use this channel content and create widgets. They
						will get share% set up for videos and events.
					</Typography>
				</Grid>
				<Grid item xs={1}>
					<IconButton onClick={() => setShowAddAffiliateModal(true)} size="large">
						<AddIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Table className="admin-table" size="small" padding="checkbox" stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell className="uid-cell header" align="left">
							Channel ID
						</TableCell>
						<TableCell className="username-cell header" align="left">
							Channel Name
						</TableCell>
						<TableCell className="actions-cell header" align="left">
							Actions
						</TableCell>
					</TableRow>
				</TableHead>{' '}
				<TableBody>
					{data.items.map(affiliate => (
						<TableRow key={affiliate.uid}>
							<TableCell className="uid-cell">{affiliate.uid}</TableCell>
							<TableCell className="username-cell">{affiliate.name}</TableCell>

							<TableCell className="actions-cell">
								<div className="actions">
									<Tooltip title="Delete" arrow>
										<IconButton
											className="button button-red"
											onClick={() => setRemoveUid(affiliate.uid)}
											size="large"
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{!!data.items.length && (
				<Box justifyContent="center" className="paginationContainer">
					<Pagination page={data} changePage={page => fetch(page)} />
				</Box>
			)}

			<AddChannelAffiliateModal
				isOpen={showAddAffiliateModal}
				closeModal={() => setShowAddAffiliateModal(false)}
				reload={refresh}
				channelName={channelName}
				channelUid={channelUid}
				saveAffiliate={uid => addChannelAffiliate(channelUid, uid)}
			/>

			<RemoveModal
				isOpen={!!removeUid}
				closeModal={() => setRemoveUid('')}
				onRemove={() => {
					removeUid &&
						removeChannelAffiliate(channelUid, removeUid).then(() => {
							setTimeout(() => refresh(), 500);
						});
					setRemoveUid('');
				}}
				text={'Do you want to remove this affiliate?'}
			/>
		</Container>
	);
};

export default AffiliatesScreen;
