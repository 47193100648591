import React from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { DeepPartial } from 'redux';
import { InfoTooltip, lightTheme } from '@content-technology-partners-ltd/shared-ui';

import StreamKeysSection from './StreamKeysSection';
import { ChannelConfig } from './ChannelConfigScreen';
import ChannelPassesSection from './ChannelPassesSection';
import DrmToggleSection from './DrmToggleSection';
import MaxResolutionSection from './MaxResolutionSection';
import ChannelWidgetSection from './ChannelWidgetSection';
import SectionRow from 'app/components/SectionRow';
import CustomisableConfig from './CustomisableConfig';

interface VideoFunctionalitySectionProps {
	uid: string;
	config: ChannelConfig;
	baseConfig: ChannelConfig;
	isDisabled: boolean;
	saveConfiguration: (overrides: DeepPartial<ChannelConfig>) => void;
}

export const VideoFunctionalitySection = ({
	uid,
	config,
	baseConfig,
	isDisabled,
	saveConfiguration
}: VideoFunctionalitySectionProps) => {
	return (
		<Box className="section section--video" component="div">
			<div className="sectionHeader">
				<span className="sectionTitle">Video Functionality</span>
				<ThemeProvider theme={lightTheme}>
					<InfoTooltip
						content={
							"To enable Video Functionality for a brand, you must change their Package from 'AFFILIATE ONLY'.  Before doing so, please ensure this vendor has agreed to subscribe to one of our other packages."
						}
						iconSx={{ width: '24px', height: '24px', marginLeft: '24px' }}
					/>
				</ThemeProvider>
			</div>
			<SectionRow label="Available" content={<CustomisableConfig value={isDisabled ? 'No' : 'Yes'} />} />
			<span className="sectionParagraph">
				Video functionality is available on every package except &apos;AFFILIATE ONLY&apos; and enables video
				uploads, live streaming and video content settings like DRM and resolution.
			</span>
			<StreamKeysSection
				max={config.streamKeys.max}
				saveConfiguration={saveConfiguration}
				packageDefault={baseConfig.streamKeys.max}
				isDisabled={isDisabled}
			/>
			<ChannelPassesSection
				max={config.channelPasses.max}
				saveConfiguration={saveConfiguration}
				packageDefault={baseConfig.channelPasses.max}
				isDisabled={isDisabled}
			/>
			<DrmToggleSection
				isEnabled={config.drm.isEnabled}
				saveConfiguration={saveConfiguration}
				packageDefault={baseConfig.drm.isEnabled}
				isDisabled={isDisabled}
			/>

			<MaxResolutionSection
				saveConfiguration={saveConfiguration}
				resolutions={config.resolutions}
				packageDefault={baseConfig.resolutions}
				isDisabled={isDisabled}
			/>
			<ChannelWidgetSection uid={uid} isDisabled={isDisabled} />
		</Box>
	);
};
