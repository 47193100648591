import React, { useEffect, useState } from 'react';
import WalletsScreen from '../../../../partners/details/section/wallets/WalletsScreen';
import { PartnerAccounts } from '../../../../app/domain/PartnerAccount';
import { fetchAccountsApi } from './ChannelWalletsUseCase';

const ChannelWalletsScreen = (props: { channelUid: string }) => {
    const { channelUid } = props;
    const [ accounts, setAccounts ] = useState<PartnerAccounts>();

    useEffect(()=>{ 
        fetchAccountsApi(channelUid).then(setAccounts); 
    }, [channelUid]);

    return <>{accounts && <WalletsScreen accounts={accounts}/>}</>;
};

export default ChannelWalletsScreen;


