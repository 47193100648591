import { createSuccessResult } from '../../app/utils/result';
import { AppThunkDispatch } from '../../app/store/store';
import { makeRequest } from '../../app/http/request';
import { createPublisherUsersParams } from './utils/reportParamsUtils';
import {
    PanelValueResponseData,
    PublisherStatisticsResponseData,
    PublisherUserResponseData,
    VideosTableResponseData,
} from './utils/reportStatisticsResponseUtils';
import { GET_CUBE } from 'app/http/cubeApi/cubeClientDeprecated';

export const VIDEO_OWNER_EARNED_GBP = 'Video.owner_earned_gbpmill';
export const VIDEO_USER_RECASTER_EARNED_CST_THOU = 'Video.user_recaster_earned_cstthou';
export const VIDEO_VERIFIED_RECASTER_EARNED_GBP = 'Video.verified_recaster_earned_gbpmill';
export const VIDEO_USERS_PAID_CST = 'Video.users_paid_cst';
export const VIDEO_PURCHASES = 'Video.purchases';
export const VIDEO_DATE = 'Video.date';
export const VIDEO_VIDEO_UID = 'Video.video_uid';
export const VIDEO_INFO_NAME = 'VideoInfo.name';
export const VIDEO_INFO_USER = 'VideoInfo.user';
export const VIDEO_INFO_CREATED = 'VideoInfo.created';
export const VIDEO_USER_UID = 'Video.user_uid';
export const PUBLISHER_REFERRAL = 'PublisherEarnings.referral_gbpmill';
export const PUBLISHER_SPENT200 = 'PublisherEarnings.spent200_gbpmill';
export const PUBLISHER_SHARE = 'PublisherEarnings.share_earnings_gbpmill';
export const PUBLISHER_ROYALTIES = 'PublisherEarnings.royalties_gbpmill';
export const PUBLISHER_DATE = 'PublisherEarnings.date';
export const PUBLISHER_UID = 'PublisherEarnings.uid';

export interface Report {
    totalPurchases: number;
    totalEarnings: number;
    earningsDirect: number;
    referralEarnings: number;
    recastedAndSharedEarningsFromYourVideo: number;
    earningsFromVideosYouRecastAndShare: number;
    avgEarningsPerVideo: number;
    avgDailyReferrals: number;
    totalRecasts: number;
    avgRecastsPerVideo: number;
    totalShares: number;
    avgSharesPerVideo: number;
    avgCostPerVideo: number;
    videosPurchasedInTimePeriod: number;
    videosUploadedInTimePeriod: number;
    totalReferrals: number;
    noReferralsSpent200: number;
    totalPurchasesFromShares: number;
    avgSharePurchasesPerVideo: number;
    avgPurchasePrice: number;
}

export async function fetchVideoPanelValueStatisticsApi(params: unknown) {
    const result = await GET_CUBE<any>(`/load`, {params});
    return  result.data.data as PanelValueResponseData[];
}

export async function fetchPublisherPanelValueStatisticsApi(params: unknown) {
    const result = await GET_CUBE<any>(`/load`, {params});
    return  result.data.data as PublisherStatisticsResponseData[];
}

export async function fetchVerifiedUserVideosApi(params: unknown) {
    const result = await GET_CUBE<any>(`/load`, {params});
    return  result.data.data as VideosTableResponseData[];
}

export async function fetchPublisherUsersApi(params: unknown) {
    const result = await GET_CUBE<any>(`/load`, {params});
    return result.data.data as PublisherUserResponseData[];
}

export async function fetchVideosTitleApi(params: unknown) {
    const result = await GET_CUBE<any>(`/load`, {params});
    return result.data.data as VideosTableResponseData[];
}

export const fetchPublisherUsers = () =>
    async (dispatch: AppThunkDispatch) => {
        const params = createPublisherUsersParams();
        return makeRequest({
            dispatch,
            request: async () => {
                const result = await GET_CUBE<any>(`/load`, {params});
                return  result.data.data as PublisherUserResponseData[];
            },
            responseSuccessHandler: (data: PublisherUserResponseData[]) => createSuccessResult(data),
        });
    };




