import React from 'react';
import { Box, Container, Grid, IconButton, Table } from '@mui/material';
import Title from '../../app/components/title/Title';
import InvitationsTableHeader from './table/InvitationsTableHeader';
import InvitationsTableBody from './table/InvitationsTableBody';
import InvitationsPagination from './table/InvitationsPagination';
import InvitationsFilter from './filter/InvitationFilter';
import AddIcon from '@mui/icons-material/Add';
import { AppThunkDispatch } from '../../app/store/store';
import { invitationOpenCreateModalAction } from '../actions/store/InvitationActionsActions';
import { connect } from 'react-redux';
import CreateInvitationModal from '../actions/add/CreateInvitationModal';

interface DispatchProps {
    openCreateInvitation: () => void;
}

type Props = DispatchProps;

const InvitationsScreen = (props: Props) => {

  const { openCreateInvitation } = props;

  return (
    <Container className="tagsScreen">
      <Title>Invitations</Title>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <InvitationsFilter />
          </Grid>
          <Grid item xs={1}>
					<IconButton onClick={() => openCreateInvitation()} size="large">
              <AddIcon/>
            </IconButton>
          </Grid>
        </Grid>
        <Table className="table" size="small" padding="checkbox" stickyHeader>
            <InvitationsTableHeader />
            <InvitationsTableBody />
        </Table>
        <Box justifyContent="center" className="paginationContainer">
            <InvitationsPagination />
        </Box>
        <CreateInvitationModal />
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    openCreateInvitation: () => {
        dispatch(invitationOpenCreateModalAction());
    }
});

export default connect(null, mapDispatchToProps)(InvitationsScreen);
