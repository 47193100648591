import React, { useState } from 'react';
import { Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import InvitationDetailsSummaryScreen from './section/InvitationDetailsSummaryScreen';

const InvitationDetailsScreen = () => {
  const [value, setValue] = useState('1');

	const handleChange = (_: React.ChangeEvent<unknown>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Container className="userDetailsScreen">
      <Paper>
        <TabContext value={value}>
          <TabList
            centered
            className="tabs"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange} >
            <Tab label="Summary" value="1" />
          </TabList>
          <TabPanel value="1">
            <InvitationDetailsSummaryScreen />
          </TabPanel>
        </TabContext>
      </Paper>
    </Container>
  );
};

export default InvitationDetailsScreen;

