import {
	Box,
	Button,
	Container,
	DialogActions,
	Typography,
	Table,
	Dialog,
	TableBody,
	TableHead,
	TableRow,
	TableCell
} from '@mui/material';
import Title from 'app/components/title/Title';
import { Page } from 'app/domain/Page';
import React, { useEffect, useState } from 'react';
import AffiliatesFilter from '../AffiliatesFilter/AffiliatesFilter';
import AffiliatesRow from '../AffiliatesRow/AffiliatesRow';
import Pagination from 'app/components/pagination/Pagination';
import { ChannelsQuery } from 'channels/list/filter/ChannelFilter';
import { fetchChannelsWithImagesApi } from 'channels/list/ChannelsUseCase';
import { Channel } from 'app/domain/Channel';

interface AddChannelAffiliateModalProps {
	isOpen: boolean;
	closeModal: () => void;
	reload: () => void;
	channelName: string;
	channelUid: string;
	saveAffiliate: (uid: string) => Promise<void>;
}

const AddChannelAffiliateModal = (props: AddChannelAffiliateModalProps) => {
	const { isOpen, closeModal, reload, channelName, saveAffiliate } = props;
	const initialQueryState = {
		page: 1,
		perPage: 20,
		sort: { field: 'created', direction: 'desc' },
		statuses: ['LIVE', 'DRAFT', 'READY']
	};
	const [data, setData] = useState<Page<Channel>>({
		currPage: 0,
		items: [],
		perPage: 10,
		totalCount: 0,
		pageCount: 0
	});

	const [query, setQuery] = useState<ChannelsQuery>(initialQueryState as ChannelsQuery);
	const fetch = () => fetchChannelsWithImagesApi(query).then(setData);

	useEffect(() => {
		setData({ ...data, currPage: 0, items: [] });
		fetch();
	}, [query]);

	useEffect(() => {
		if (!isOpen) setQuery(initialQueryState as ChannelsQuery);
	}, [isOpen]);

	return (
		<Dialog className="dialog" fullWidth maxWidth="xl" open={isOpen} onClose={closeModal}>
			<Container className="usersScreen">
				<Title>Affiliates</Title>
				<Typography variant="body2" className="subtitle">
					Add affiliates for: <b>{channelName}</b>
				</Typography>
				<AffiliatesFilter usersQuery={query} onChange={setQuery} />
				<Table className="table" size="small" padding="checkbox" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell className="uid-cell header" align="left">
								Channel ID
							</TableCell>
							<TableCell className="username-cell header" align="left">
								Channel Name
							</TableCell>
							<TableCell className="actions-cell header" align="left">
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.items.map(channel => (
							<AffiliatesRow
								key={channel.channelUid}
								channel={channel}
								saveAffiliate={uid =>
									saveAffiliate(uid).then(() => {
										setTimeout(() => reload(), 500);
									})
								}
							/>
						))}
					</TableBody>
				</Table>
				<Box justifyContent="center" className="paginationContainer">
					<Pagination page={data} changePage={page => setQuery({ ...query, page })} />
				</Box>
			</Container>
			<DialogActions>
				<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default AddChannelAffiliateModal;
