import { CurrentListStateTypes } from './CurrentListActions';
import { CurrenciesSortField, Currency } from '../../../../app/domain/Currency';
import { createDefaultSort, Sort } from '../../../../app/domain/Sort';

export interface CurrenciesState {
  currencies: Currency[];
  processedCurrencies: Currency[];

  sort: Sort<CurrenciesSortField>;
}

const initialState: CurrenciesState = {
  currencies: [],
  processedCurrencies: [],
  sort: createDefaultSort('currencyCode'),
};

export function currenciesListReducer(state = initialState, action: CurrentListStateTypes): CurrenciesState {
  switch (action.type) {
    case "CURRENCIES_SET_CURRENCIES":
      return { ...state, currencies: action.currencies };
    case 'CURRENCIES_SET_SORT':
      return { ...state, sort: action.sort };
    case 'CURRENCIES_SET_PROCESSED_CURRENCIES':
      return { ...state, processedCurrencies: action.processedCurrencies };
    default:
      return state;
  }
}
