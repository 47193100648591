import { GET } from 'app/http/mediaApi/mediaClient';
import { User } from 'app/domain/User';
import { GET_AUTH } from 'app/http/authApi/authClient';
import { fetchUserAuthDataApi } from 'app/http/authApi/userApi';

interface UserRolesResult {
	role: string[];
}

export const fetchUserDetailsApi = async (uid: string) => {
	const result = await GET<User>(`admin/user/profile/${uid}?expand=email,phone,roles,referrer,allAccounts`);
	const userAuthData = await fetchUserAuthDataApi([uid]);
	return { ...result.data.data, status: userAuthData[0]?.status }
}


export const fetchUserRolesApi = (uid: string) =>
	GET_AUTH<UserRolesResult>(`admin/user/${uid}/roles`).then(r=>r.data.data.role);
