import { POST_FORM_DATA } from '../../../../app/http/mediaApi/mediaClient';
import { Contributor } from '../../../../app/domain/Contributor';
import { Cost, Video } from 'app/domain/Video';

interface ContributorsSplitData {
    contributorPcts: string;
}

export const editVideoContributorsApi = (videoId: string, contributors: Contributor[]) =>
  POST_FORM_DATA<void>(`admin/video/${videoId}`, createEditContributorsSplitData(contributors))
    .then(r=>r.data.data);


const createEditContributorsSplitData = (contributors: Contributor[]): ContributorsSplitData => ({
  contributorPcts: JSON.stringify(contributors.map(contributor => contributor))
});

export interface VideoEditPricesSharingData {
	cost: number;
	recasterPct: number;
}

export const createVideoEditPricesSharingData = (video: Video): VideoEditPricesSharingData => {
	const cost: number = 'units' in (video.cost as any) ? (video.cost as Cost).units : (video.cost as number);
	return {
		cost,
		recasterPct: video.recasterPct
	};
};

export const editVideoPricesSharingApi = (videoId: string, data: VideoEditPricesSharingData) =>
	POST_FORM_DATA<void>(`admin/video/${videoId}`, data).then(r=>r.data.data);

