import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

import React from 'react';
import * as Yup from 'yup';

interface StreamKeysModalProps {
	max: number;
	isOpen: boolean;
	onSave: (value: number) => void;
	onClose: () => void;
}

const STREAM_KEY_MIN = 1;
const STREAM_KEY_MAX = 10;

const validationMessage = `Max stream keys must be between ${STREAM_KEY_MIN} and ${STREAM_KEY_MAX}`;

const ValidationSchema = Yup.object().shape({
	max: Yup.number().max(STREAM_KEY_MAX, validationMessage).min(STREAM_KEY_MIN, validationMessage)
});

const StreamKeysModal = ({ max, isOpen, onSave, onClose }: StreamKeysModalProps) => {
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>Edit maximum number of Stream Keys</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{ max }}
				onSubmit={values => {
					onSave(values.max);
					onClose();
				}}
				validationSchema={ValidationSchema}
			>
				{({ dirty, isValid }) => (
					<Form>
						<DialogContent>
							<div className="helpText">The maximum number can be between 1 and 10.</div>
							<Field
								className="formField"
								label="Max number"
								fullWidth
								name="max"
								variant="outlined"
								component={TextField}
								helperText=" "
								type="number"
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button type="submit" color="secondary" variant="contained" disabled={!dirty || !isValid}>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default StreamKeysModal;
