import React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { UnverifiedUser } from '../app/domain/UnverifiedUser';
import TableSortHeader from 'app/components/table/TableSortHeader';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { checkObjectExistence } from './UnverifiedUsersUseCase';
import RoutePaths from 'app/navigation/RoutePaths';
import { useHistory } from 'react-router-dom';
import UnverifiedUserActionButtons from 'unverified/UnverifiedUserActionButtons';
import { Sort } from 'app/domain/Sort';
import "./_unverified.scss";

interface Props {
    unverifiedUsers: UnverifiedUser[];
    refresh: ()=>void;
    sort: Sort<string>;
    setSort: (fieldName: string, sort: Sort<string>)=>void;
}

const UnverifiedTable = ({ unverifiedUsers, refresh, sort, setSort }: Props) => {
  const history = useHistory();

	const navigateToDetails = async (uid: string) => {
		const result = await checkObjectExistence(uid);

		if (result.existsChannel) {
			history.push(`${RoutePaths.ChannelDetails}/${uid}`);
		} else if (result.existsPartner) {
			history.push(`${RoutePaths.PublisherDetails}/${uid}`);
		} else {
			history.push(`${RoutePaths.UserDetails}/${uid}`);
		}
	};

  return (
    <Table className="table unverified-table" size="small" padding="checkbox" stickyHeader>
      <TableHead>
        <TableRow>
            <TableSortHeader className='email-cell' label="Email" fieldName="email" sort={sort} setSort={setSort} />
            <TableCell align="left" className="referrer-cell header">Referrer</TableCell>
            <TableCell align="left" className="marketing-cell header">Marketing consent</TableCell>
            <TableSortHeader className='expire-cell'  label="Expire time" fieldName="expireTime" sort={sort} setSort={setSort} />
            <TableCell align="left" className="actions-cell header">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {
        unverifiedUsers.map((unverified) => (
          <TableRow key={unverified.email} style={{ height: '3em' }}>
          <TableCell className='email-cell' >{unverified.email}</TableCell>
          <TableCell className='referrer-cell' >
            <Button variant="contained" onClick={() => navigateToDetails(unverified.referrer ?? '')}>{unverified.referrer}</Button>
          </TableCell>
          <TableCell className='marketing-cell' >
            {unverified.marketingConsent ? <CheckIcon className="true" /> : <ClearIcon className="false" />}
          </TableCell>
          <TableCell className='expire-cell' >{unverified.expireTime.toString().replace("T", " ").replace(/\.\d\d\dZ$/, "")}</TableCell>
          <TableCell className='actions-cell' >
            <UnverifiedUserActionButtons user={unverified} onRefresh={refresh} />
          </TableCell>
        </TableRow>
            ))
      }
    </TableBody>
    </Table>
  );
};

export default UnverifiedTable;
