import { Action, combineReducers, ThunkAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { userReducer } from './user/reducers';
import { appReducer } from './app/reducers';
import { usersReducer } from 'users/store/UsersReducer';
import { videosReducer } from 'videos/store/VideosReducer';
import { reportReducer } from 'report/store/ReportReducer';
import { invitationsReducer } from 'invitations/store/InvitationsReducer';
import { currencyReducer } from 'currency/store/CurrenciesReducer';
import { cashOutsReducer } from 'cashouts/store/cashOutsReducer';
import { refundsReducer } from 'refunds/store/refundsReducer';
import dayjs from 'dayjs';
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear';

// need to make sure that it's registered before store is created as it "mutates" all existing dayjs instances which we
// have on store, it's imported by date-pickers dayjs adapter but the issue is that it's happening after store setup, so
// it triggers immutability error in dev mode
dayjs.extend(weekOfYearPlugin);

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

const rootReducer = combineReducers({
	user: userReducer,
	app: appReducer,

	videos: videosReducer,
	users: usersReducer,
	report: reportReducer,
	invitations: invitationsReducer,
	currency: currencyReducer,
	cashOuts: cashOutsReducer,
	refunds: refundsReducer
});

function setupStore() {
	return configureStore({ reducer: rootReducer });
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
