import React from 'react';
import { Container } from '@mui/material';
import Title from 'app/components/title/Title';
import WidgetsTable from './list/widgetsTable';

interface Props {
	channelUid: string;
}

const WidgetsScreen = (props: Props) => {
	const { channelUid } = props;

	return (
		<Container className="channelWidgetScreen">
			<Title>Widgets</Title>
			<WidgetsTable channelUid={channelUid} />
		</Container>
	);
};

export default WidgetsScreen;
