import { getAppConfig, getConfig } from 'config/appConfig';

const WIDGET_PERMISSIONS: string[] = ['fullscreen', 'encrypted-media', 'picture-in-picture'];

const widgetAllow = (origin?: string) =>
	WIDGET_PERMISSIONS.map(permission => `${permission}${origin ? ` 'src' ${origin}` : ''};`).join('');

const widgetIframeId = (widgetId: string) => `recast-widget-${widgetId}`;

const widgetIFrame = (src: string, iframeId: string, origin?: string) =>
	`<iframe id="${widgetIframeId(iframeId)}" src="${src}" width="100%" allow="${widgetAllow(
		origin
	)}" style="border: none; overflow: hidden;"></iframe>`;

const widgetScript = (scriptUrl: string, iframeId: string) =>
	`<script defer src="${scriptUrl}" iframe-id="${widgetIframeId(iframeId)}"></script>`;

export const useWidgetGenerator = (path: string, iframeId: string) => {
	const src = `${getAppConfig().EMBEDDED_BASE}${path}`;
	const origin = getAppConfig().FAN_APP_BASE;

	const scriptUrl = getConfig().EMBEDDED_WIDGET_SCRIPT_URL;
	const iframe = widgetIFrame(src, iframeId, origin);
	const script = widgetScript(scriptUrl, iframeId);
	const code = `${iframe}\n\n${script}`;

	return { code, iframe, src };
};
