import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Radio,
	RadioGroup
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ChannelPackage } from './ChannelConfigScreen';

interface PackageModalProps {
	packageType: ChannelPackage;
	isOpen: boolean;
	onSave: (value: ChannelPackage) => void;
	onClose: () => void;
}

const PackageModal = ({ packageType, isOpen, onSave, onClose }: PackageModalProps) => {
	const [value, setValue] = useState<ChannelPackage>(packageType);
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>Edit Channel Package</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{ packageType }}
				onSubmit={values => {
					onSave(values.packageType);
					onClose();
				}}
			>
				{({ dirty, isValid, setFieldValue }) => (
					<Form>
						<DialogContent>
							<div className="helpText">
								Selecting a new package will clear any changes to the related settings and set up
								default settings for the selected package. If the channel has customized settings,
								please remember to restore them manually.
							</div>
							<div className="radioLabel">Select Package</div>
							<RadioGroup
								name="packageType"
								value={value}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValue(e.target.value as ChannelPackage);
									setFieldValue('packageType', e.target.value);
								}}
							>
								<FormControlLabel
									value={ChannelPackage.FREE}
									control={<Radio />}
									label={ChannelPackage.FREE}
								/>
								<FormControlLabel
									value={ChannelPackage.LITE}
									control={<Radio />}
									label={ChannelPackage.LITE}
								/>
								<FormControlLabel
									value={ChannelPackage.PRO}
									control={<Radio />}
									label={ChannelPackage.PRO}
								/>
								<FormControlLabel
									value={ChannelPackage.PROMOTE}
									control={<Radio />}
									label={ChannelPackage.PROMOTE}
								/>
								<FormControlLabel
									value={ChannelPackage.AFFILIATE_ONLY}
									control={<Radio />}
									label={ChannelPackage.AFFILIATE_ONLY}
								/>
							</RadioGroup>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button
								type="submit"
								color="secondary"
								variant="contained"
								disabled={!dirty || !isValid || value === packageType}
							>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default PackageModal;
