import { PageResponse, toPage } from '../../app/http/PageResponse';
import { AllPartnerStatuses, Partner, PartnersQuery, PartnerStatus } from '../../app/domain/Partner';
import { GET_PARTNER } from '../../app/http/partnerApi/partnerClient';

export const fetchPartnersApi = (data: PartnersQuery) =>
  GET_PARTNER<PageResponse<Partner>>(`/admin/partners`, { params:createApiParams(data) })
    .then(r=>toPage(r.data.data));

const urlStatuses = (statuses: PartnerStatus[]) =>
  statuses.length ? statuses : AllPartnerStatuses;

const createApiParams = (data: PartnersQuery) => {
  const { page, statuses, sort, emailSearchText, partnerNameSearchText, partnerUidSearchText } = data;

  return {
    page, emailSearchText, partnerNameSearchText, partnerUidSearchText,
    order: sort.field, 
    sort: sort.direction, 
    status: urlStatuses(statuses)
  };
};

