import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import { RootState } from 'app/store/store';
import { TextField } from 'formik-material-ui';
import { userCloseManualCastModalAction } from 'users/actions/store/UsersActionsActions';
import { Amount } from 'accounts/apiTypes';
import * as Yup from 'yup';
import manualCastUseCase, { ManualCast, ManualTypes } from './ManualCastUseCase';
import { getAuthAccessToken, TokenPayload } from 'app/http/authApi/token';
import { containsOnly } from 'app/utils/arrayManager';
import { FormikHelpers } from 'formik/dist/types';

const ManualCastSchema = Yup.object().shape({
	description: Yup.string().min(5, 'Too Short').max(100, 'Too Long').required('Description Required'),
	type: Yup.string().required('Type Required'),
	lines: Yup.array().min(1, 'CSV Required').required('CSV Required')
});

const uidLength = 5;
const maxTransactions = 1000;
const maxCaxAmount = 1000;
const validHeaders = ['userAccountId', 'amount'];

interface FormattedLines {
	userAccountId: string;
	amount: Amount;
}

interface FormValues {
	adminId: string;
	description: string;
	type: string;
	lines: FormattedLines[];
}

const ManualCastModal = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector((state: RootState) => state.users.actions.manualCastModal.isOpen);
	const onClose = () => dispatch(userCloseManualCastModalAction());
	const [serverError, setServerError] = useState<string | null>(null);

	const getAdminUid = (): string => {
		try {
			const { uid } = jwtDecode<TokenPayload>(getAuthAccessToken());
			return uid;
		} catch (e) {
			return '';
		}
	};
	const adminUid = getAdminUid();

	const onSubmit = async (values: ManualCast, onError: () => void) => {
		const result = await manualCastUseCase(values, dispatch);

		if (result.error) {
			setServerError(result.error.messages[0].message);
			onError();
		} else {
			dispatch(userCloseManualCastModalAction());
		}
	};

	const parseCastsCSV = (lines: string[]): FormattedLines[] => {
		const headers = lines.shift()?.split(',') ?? [];

		if (!containsOnly(validHeaders, headers) || headers.length !== 2) {
			throw new Error('Invalid CSV, headers must only include: userAccountId, amount');
		}

		if (!lines.length) {
			throw new Error('Invalid CSV, must include at least one user to update');
		}

		const nonEmptyLines = lines.map(line => line).filter(line => line.length);
		const formattedLines = [];
		const errors = [];

		for (let i = 0; i < nonEmptyLines.length; i++) {
			const values = lines[i].split(',');

			if (values[0].length !== uidLength) {
				errors.push(`Line ${i + 1}: invalid 'userAccountId' length`);
				continue;
			}

			const amountStr = values[1];
			const amount = parseInt(amountStr);
			if (!amount || amountStr.includes('.')) {
				errors.push(`Line ${i + 1}: 'amount' must be whole a number`);
				continue;
			}

			const thou = Math.abs(amount * 1000);
			const castAmt: Amount = {
				units: Math.floor(thou / 1000),
				thousandths: thou % 1000,
				negative: amount < 0,
				currency: 'CST'
			};

			if (castAmt.units > maxCaxAmount) {
				errors.push(`Line ${i + 1}: 'amount' can't be greater than 1000`);
				continue;
			}

			formattedLines.push({
				userAccountId: values[0],
				amount: castAmt
			});
		}

		if (formattedLines.length > maxTransactions) {
			throw new Error(`Invalid CSV, must have less than ${maxTransactions} transactions`);
		}

		if (errors.length) {
			throw new Error(...errors);
		}

		return formattedLines;
	};

	const onFileUpload = (
		event: React.ChangeEvent<HTMLInputElement>,
		setFieldValue: FormikHelpers<FormValues>['setFieldValue'],
		setFieldError: FormikHelpers<FormValues>['setFieldError'],
		setFieldTouched: FormikHelpers<FormValues>['setFieldTouched']
	) => {
		const file = event.currentTarget?.files?.[0];
		if (!file) {
			return;
		}

		const reader = new FileReader();

		reader.onload = function (event) {
			serverError && setServerError(null);
			const result = event.target?.result;
			if (typeof result !== 'string') {
				setFieldError('lines', 'invalid file');
				return;
			}

			const lines = result.split(/\r?\n/);
			try {
				setFieldValue('lines', parseCastsCSV(lines));
			} catch (e) {
				setFieldError('lines', `${e}`);
			}
		};
		setFieldTouched('lines', true);
		reader.readAsText(file);
	};

	const onFileChange = (
		event: React.MouseEvent<HTMLInputElement, MouseEvent>,
		setFieldValue: FormikHelpers<FormValues>['setFieldValue']
	) => {
		setFieldValue('lines', []);
		(event.target as HTMLInputElement).value = '';
	};

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="lg" className="manualCastModal">
			<Formik
				initialValues={
					{
						adminId: adminUid,
						description: '',
						type: '',
						lines: []
					} satisfies FormValues
				}
				validationSchema={ManualCastSchema}
				onSubmit={(values, { setSubmitting }) =>
					onSubmit(values, () => {
						setSubmitting(false);
					})
				}
			>
				{({
					errors,
					values,
					setFieldValue,
					setFieldError,
					isValid,
					dirty,
					touched,
					setFieldTouched,
					isSubmitting
				}) => (
					<Form>
						<DialogTitle>Manual Cast Transfer</DialogTitle>
						<DialogContent>
							<DialogContentText>Upload a CSV to award or remove casts from users</DialogContentText>
							<Divider className="divider" />
							<br />
							<DialogContentText>
								<strong> Transaction Description</strong>
							</DialogContentText>
							<div>
								<Field
									className="field"
									fullWidth
									label="description"
									name="description"
									variant="outlined"
									type="string"
									component={TextField}
								/>
							</div>
							<br />
							<DialogContentText>
								<strong> Type</strong>
							</DialogContentText>
							<div>
								<label>
									<Field type="radio" value={ManualTypes.GOODWILL} name="type" />
									Goodwill
								</label>
								<br />
								<label>
									<Field type="radio" value={ManualTypes.RETENTION} name="type" />
									Bonus: manual-retention
								</label>
								<label>
									<Field type="radio" value={ManualTypes.UNUSED} name="type" />
									Bonus: manual-unused
								</label>
							</div>
							<br />
							<DialogContentText>
								<strong> File Upload</strong>
							</DialogContentText>
							<input
								id="lines"
								name="lines"
								accept=".csv"
								type="file"
								onChange={event => onFileUpload(event, setFieldValue, setFieldError, setFieldTouched)}
								onClick={event => onFileChange(event, setFieldValue)}
							/>
							{!values.lines.length && touched.lines ? (
								<div className="manualCastModal__error">{errors.lines}</div>
							) : null}
							{serverError && <div className="manualCastModal__error">{serverError}</div>}
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Close
							</Button>
							<Button type="submit" color="primary" disabled={isSubmitting || !(isValid && dirty)}>
								Submit
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ManualCastModal;
