import { PublicAccountLine, Type } from 'accounts/apiTypes';
import { RefundedPurchase } from 'accounts/AccountDetailsUseCase';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { VideoListing } from 'app/domain/VideoListing';
import { openRefundConfirmationModalAction } from 'refunds/store/refundsActions';
import refundCastsIcon from '../../app/static/images/icons/i-refund-casts.svg';
import refundedCastsIcon from '../../app/static/images/icons/i-refunded-casts.svg';
import nonRefundableIcon from '../../app/static/images/icons/i-non-refundable.svg';
import { RefundTypes } from '../store/refundsReducer';

const RefundButton = (props: {
	transaction: PublicAccountLine;
	userId: string;
	videoListing?: VideoListing;
	existingRefund?: RefundedPurchase;
}) => {

	const { transaction, userId, existingRefund, videoListing } = props;
	const dispatch = useDispatch();
	const type = transaction.ledger.type;

	const isPurchaseRefundTransaction = type === Type.PURCHASE_REFUND;

	const isRefundable = type === Type.PURCHASE && transaction.type === 'DEBIT_PLAYER' && !existingRefund;

	const refundTooltipTitle = isRefundable ? 'Refund Casts' : "Can't be refunded";

	const refundIcon = isRefundable ? refundCastsIcon : nonRefundableIcon;

	const onRefundPurchaseTransaction = async (transaction: PublicAccountLine, userId: string) => {
		dispatch(openRefundConfirmationModalAction(
			userId,
			transaction.ledger.id,
			videoListing?.name || transaction.ledger.linkedId || '',
			videoListing ? RefundTypes.VIDEO : RefundTypes.CHANNEL_PASS
		));
	};

	return (
		<>
			{isPurchaseRefundTransaction && (
				<Tooltip title="Refunded" arrow>
					<IconButton size="large">
						<img src={refundedCastsIcon} alt=""/>
					</IconButton>
				</Tooltip>
			)}

			{!isPurchaseRefundTransaction && (
				<Tooltip title={refundTooltipTitle} arrow>
					{
						<IconButton
							className={`refund-${isRefundable ? 'active' : 'inactive'}`}
							onClick={() => isRefundable && onRefundPurchaseTransaction(transaction, userId)}
							size="large"
						>
							<img src={refundIcon} alt=""/>
						</IconButton>
					}
				</Tooltip>
			)}
		</>
	);
};
export default RefundButton;
