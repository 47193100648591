import React from 'react';
import {Container, Grid} from '@mui/material';
import Title from "../app/components/title/Title";
import ReportDashboard from "./reportDashboard/ReportDashboard";
import ReportFilter from "./reportFilter/ReportFilter";
import VerifiedUserFilter from "./verifiedUserFilter/VerifiedUserFilter";
import VideosTable from "./videoTable/VideoStatisticsTable";
import {RootState} from "../app/store/store";
import {connect} from "react-redux";

interface StateProps {
	uid?: string;
}

const ReportScreen = (props: StateProps) => {
    const { uid } = props;

    return (
        <Container className="reportsScreen">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Title>Report</Title>
                </Grid>
                <Grid item xs={12}>
                    <VerifiedUserFilter/>
                </Grid>
                { uid ? <Grid item xs={12}><ReportFilter/></Grid>  : null }
                { uid ? <Grid item xs={12}><ReportDashboard/></Grid>  : null }
                { uid ? <Grid item xs={12}><VideosTable/></Grid>  : null }
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    uid: state.report.filters.channelUid,
});

export default connect(mapStateToProps, {})(ReportScreen);
