export const UnknownError = 'UnknownError';

export interface Result<T, R> {
  value?: T;
  error?: R;
}

export function createSuccessResult<T, R>(value?: T): Result<T, R> {
  return { value };
}

export function createErrorResult<T, R>(error: R): Result<T, R> {
  return { error };
}

export function mapResult<T, R, S>(input: Result<T, R>, map: (value: T) => S): Result<S, R> {
  if (input.value && !input.error) {
    const mappedValue = map(input.value);
    return createSuccessResult(mappedValue);
  } else if(input.error) {
    return createErrorResult(input.error);
  } else {
    throw new Error(UnknownError);
  }
}
