import { combineReducers } from 'redux';
import { invitationsListReducer } from '../list/store/InvitationsListReducer';
import { invitationDetailsReducer } from '../details/store/InvitationDetailsReducer';
import { invitationActionsReducer } from '../actions/store/InvitationActionsReducer';

export const invitationsReducer = combineReducers({
  actions: invitationActionsReducer,
  list: invitationsListReducer,
  details: invitationDetailsReducer,
});
