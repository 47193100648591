import React from 'react';
import { connect } from 'react-redux';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { sentInvitationUseCase } from './SentInvitationUseCase';
import { invitationCloseSentModalAction } from '../store/InvitationActionsActions';
import ConfirmActionModal from '../../../app/components/modal/confirm/confirmActionModal';

interface StateProps {
    invitationId: number;
    isOpen: boolean;
}

interface DispatchProps {
    sentModal: (invitationId: number) => void;
    closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const SentInvitationModal = (props: Props) => {
    const { invitationId, isOpen, sentModal, closeModal } = props;

    const handleSent = () => {
        sentModal(invitationId);
    };

    const handleCloseModal = () => {
        closeModal();
    };

    return (
        <ConfirmActionModal show={ isOpen }
                            onClose={ () => handleCloseModal() }
                            description={ 'Are you sure you want to mark this invitation as sent?' }
                            confirmedAction={ handleSent }
        />
    );
};

const mapStateToProps = (state: RootState) => ( {
    isOpen: state.invitations.actions.sentModal.isOpen,
    invitationId: state.invitations.actions.sentModal.invitationId,
} );

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    sentModal: (invitationId: number) => {
        dispatch( sentInvitationUseCase( invitationId ) );
    },
    closeModal: () => {
        dispatch( invitationCloseSentModalAction() );
    }
} );

export default connect( mapStateToProps, mapDispatchToProps )( SentInvitationModal );
