import { CurrentActionsStateTypes } from './CurrenciesActionsActions';

interface CurrenciesActionsState {
  editModal: {
    isOpen: boolean;
  };
  onRefresh: () => void;
}

const initialState: CurrenciesActionsState = {

  editModal: {
    isOpen: false
  },
	onRefresh: () => undefined
};

export function currenciesActionsReducer(state = initialState, action: CurrentActionsStateTypes): CurrenciesActionsState {
  switch (action.type) {
    case "CURRENCIES_OPEN_EDIT_MODAL":
      return {...state, editModal: {...state.editModal, isOpen: true}};
    case "CURRENCIES_CLOSE_EDIT_MODAL":
			return { ...state, editModal: initialState.editModal, onRefresh: () => undefined};
    default:
      return state;
  }
}
