import React, { useEffect, useState } from 'react';
import { Box, Container, Divider, Grid, IconButton, Tooltip } from '@mui/material';
import Title from 'app/components/title/Title';
import EditIcon from '@mui/icons-material/Edit';
import UserActionButtons from 'users/actions/UserActionButtons';
import UserEditUsernameModal from 'users/details/sections/summary/edit/username/UserEditUsernameModal';
import UserEditContactModal from 'users/details/sections/summary/edit/contact/UserEditContactModal';
import UserEditProfileModal from 'users/details/sections/summary/edit/profile/UserEditProfileModal';
import { AuthUserRole, User } from 'app/domain/User';
import UserEditReferrerModal from 'users/details/sections/summary/edit/referrer/UserEditReferrerModal';
import { editUserApi, editUserAuthApi, editUserReferrerApi } from 'users/details/sections/summary/edit/UserEditApi';
import { UserEditRolesData } from 'users/details/sections/summary/edit/profile/UserEditProfileUseCase';
import { userEditContactApi } from 'users/details/sections/summary/edit/contact/UserEditContactUseCase';
import ImageUpload from 'users/actions/image/ImageUpload/ImageUpload';
import { getConfig } from 'config/appConfig';
import SectionRow from 'app/components/SectionRow';
import { fetchUserRolesApi } from 'users/details/UserDetailsUseCase';

function prepareInviteLink(user: User): string {
	return user.roles.map(role => role.role).includes('publisher')
		? `${getConfig().SHARE_BASE}/u/${user.uid}?referrer=${user.uid}`
		: `${getConfig().FAN_APP_BASE}?referrer=${user.uid}`;
}

export type Modal = 'USERNAME' | 'CONTACT' | 'PROFILE' | 'REFERRER' | 'USER_ROLES' | undefined;

interface Props {
	user: User;
	refresh: ()=>void;
}

const UserDetailsSummarySection = ({user, refresh}: Props) => {

	const [ roles, setRoles ] = useState<AuthUserRole[]>();
	const [ modal, setModal ] = useState<Modal>();

	const fetchRoles = 
		()=> fetchUserRolesApi(user.uid).then(r=>r && setRoles(r as AuthUserRole[]));

	useEffect(() => { fetchRoles() }, [user.uid]);

	const setProfileImage = (avatar: File) =>
		editUserApi(user.uid, { avatar }).then(refresh);

	const closeModal = () => setModal(undefined);

	const saveReferrer = (referrerUid: string) => {
		setModal(undefined);
		editUserReferrerApi(user.uid, referrerUid).then(refresh);
	}

	const saveProfile = (userRoles: AuthUserRole[]) => {
		setModal(undefined);
		editUserAuthApi(user.uid, { userRoles } as UserEditRolesData)
			.then(()=>{ refresh(); fetchRoles(); });
	}

	const saveContact = (email: string) => userEditContactApi(user, email).then(refresh);

	const saveNewUsername = (username: string) => 
		editUserApi(user.uid, { username })
			.then(()=>setModal(undefined))
			.then(refresh);

	const ModalButton = ({title, modal}: {title: string, modal: Modal}) =>
		<Tooltip title={title} arrow>
			<IconButton size='small' onClick={() => setModal(modal)}>
				<EditIcon />
			</IconButton>
		</Tooltip>

	return (
		<Container className="userDetailsScreen">
			<Grid container direction="row" alignItems="center">
				<Grid item xs={12} sm={6}>
					<Title>
						User: {user.username}
						<ModalButton title="Edit username" modal='USERNAME'/>
					</Title>
				</Grid>
				<Grid className="userDetailsActions" item xs={12} sm={6}>
					<UserActionButtons user={user} onRefresh={refresh} />
				</Grid>
			</Grid>

			<Divider />

			<Box className="section" component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">Profile Info</span>
				</div>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Grid container alignItems="center">
							{(!user.profileImage?.endsWith("avatar-default.png")) && <Grid item style={{ marginRight: '10px' }}>
								<ImageUpload
									className={`profileImage imageUpload--profile`}
									name={'avatar'}
									defaultImage={user.profileImage}
									setFile={setProfileImage}
								/>
							</Grid>}
							<Grid item>
								<SectionRow label="Uid" content={user.uid}/>
								<SectionRow label="Status" content={user.status}/>
								<SectionRow label="Roles" content={<>
										{roles?.map(el => el).join(', ')}
										<ModalButton title="Edit Profile Info" modal='PROFILE'/>
									</>}/>
								<SectionRow 
									label="Email" 
									content={<>
										{user.email}
										<ModalButton title="Edit email" modal='CONTACT'/>
									</>}
								/>
								<SectionRow label="Referrer Uid" 
									content={<>
										<a href={"/user/" + user.referrer}>{user.referrer}</a> 
										<ModalButton title="Edit referrer" modal='REFERRER'/>
									</>}
								/>
								<SectionRow label="Invite url" content={prepareInviteLink(user)}/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<SectionRow label="Following" content={user.following}/>
						<SectionRow label="Interests" content={user.interests.map(el => el.name).join(', ')}/>
						<SectionRow label="Sign up date" content={user.created}/>
						<SectionRow label="Last update date" content={user.updated}/>
					</Grid>
				</Grid>
			</Box>

			{modal === 'USERNAME' && 
			<UserEditUsernameModal user={user} save={saveNewUsername} onClose={closeModal} />}

			{modal === 'CONTACT' &&
				<UserEditContactModal user={user} save={saveContact} onClose={closeModal} />}

			{modal === 'PROFILE' && roles &&
				<UserEditProfileModal roles={roles} save={saveProfile} onClose={closeModal} />}

			{modal === 'REFERRER' &&
				<UserEditReferrerModal save={saveReferrer} onClose={closeModal} />}
		</Container>
	);
};

export default UserDetailsSummarySection;
