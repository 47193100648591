import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import TableSortHeader from '../../../../app/components/table/TableSortHeader';
import { changeSort, Sort } from '../../../../app/domain/Sort';
import { CurrenciesSortField } from '../../../../app/domain/Currency';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import { connect } from 'react-redux';
import { currenciesSetSortAction } from '../store/CurrentListActions';

interface StateProps {
    sort: Sort<CurrenciesSortField>;
}

interface DispatchProps {
    setSort: (field: CurrenciesSortField, sort: Sort<CurrenciesSortField>) => void;
}

type Props = StateProps & DispatchProps;

const CurrencyTableHeader = (props: Props) => {
    const { sort, setSort } = props;

    return (
        <TableHead>
            <TableRow>
                <TableSortHeader label="Code" fieldName="currencyCode" sort={sort} setSort={setSort}/>
                <TableCell align="left" className="header">Name</TableCell>
                <TableSortHeader label="Rate" fieldName="rate" sort={sort} setSort={setSort}/>
                <TableCell align="left" className="header">Symbol</TableCell>
                <TableCell align="left" className="header">Position</TableCell>
            </TableRow>
        </TableHead>
    );
};

const mapStateToProps = (state: RootState) => ({
    sort: state.currency.currencies.list.sort,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    setSort: (field: CurrenciesSortField, sort: Sort<CurrenciesSortField>) => {
        const newSort = changeSort(field, sort);
        dispatch(currenciesSetSortAction(newSort));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyTableHeader);


