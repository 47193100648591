import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import StreamKeysModal from './StreamKeysModal';
import { ChannelConfig } from './ChannelConfigScreen';
import SectionRow from 'app/components/SectionRow';
import CustomisableConfig from './CustomisableConfig';

interface StreamKeysSectionProps {
	max: number;
	saveConfiguration: (overrides: Partial<ChannelConfig>) => void;
	packageDefault: number;
	isDisabled?: boolean;
}

const StreamKeysSection = ({ max, saveConfiguration, packageDefault, isDisabled = false }: StreamKeysSectionProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const isCustomised = packageDefault !== max;

	const onSave = (value: number) => {
		saveConfiguration({
			streamKeys: {
				max: value
			}
		});
	};

	return (
		<>
			<Box className={`section ${isDisabled ? 'section--disabled' : ''}`} component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">Stream Keys</span>
					{!isDisabled && (
						<Tooltip title="Edit stream key config" arrow>
							<IconButton onClick={() => setIsEditing(true)} size="large">
								<EditIcon className="editIcon" />
							</IconButton>
						</Tooltip>
					)}
				</div>
				<SectionRow
					label="Max number"
					content={
						<CustomisableConfig
							value={max}
							customisedText={isCustomised ? `(default is ${packageDefault})` : ''}
						/>
					}
				/>
			</Box>
			<StreamKeysModal
				isOpen={isEditing}
				onSave={onSave}
				onClose={() => {
					setIsEditing(false);
				}}
				max={max}
			/>
		</>
	);
};

export default StreamKeysSection;
