import { VideoFeatureRequestBody } from './VideoFeatureApi';

export interface VideoFeatureFormData {
	uid: string;
	priority: number;
	startDate: string;
	endDate: string;
	interestId?: number; 
}

export function createVideoFeatureRequestBody(input: VideoFeatureFormData): VideoFeatureRequestBody {
	return {
		...input,
		startDate: input.startDate === '' ? null : input.startDate,
		endDate: input.endDate === '' ? null : input.endDate
	};
}
