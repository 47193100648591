import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { APIResponse } from 'app/http/mediaApi/mediaClient';
import { getBaseApiUrl } from 'app/http/request';
import { addAccessToken, resetAccessTokenOnUnauthorized } from 'app/http/authApi/token';

const clientListings: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

const getListingApiUrl = (suffix?: string): string => getBaseApiUrl('listings', suffix);

clientListings.interceptors.response.use(res => res, resetAccessTokenOnUnauthorized);
clientListings.interceptors.request.use(addAccessToken, Promise.reject);

export const GET_LISTINGS = <U>(url: string, config?: AxiosRequestConfig) => {
	return clientListings.get<APIResponse<U>>(getListingApiUrl(url), config);
};

export const POST_LISTINGS = <U>(url: string, data?: unknown) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return clientListings.post<typeof data, Response>(getListingApiUrl(url), data);
};

export const PUT_LISTINGS = <U>(url: string, data?: unknown) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return clientListings.put<typeof data, Response>(getListingApiUrl(url), data);
};
