import React, { useState } from 'react';
import { Video } from '../../../../app/domain/Video';
import { Box, Button, Chip, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
	isDeleted,
	isLive,
	isPending,
	isTesting,
	replaceRecastLiveWithMediaLive,
	resetRecastLiveStream,
	startStream,
	stopRecastLiveStream
} from './VideoDetailsLiveStreamingUseCase';
import ConfirmActionModal from '../../../../app/components/modal/confirm/confirmActionModal';
import { copyToClipboard } from '../../../../app/utils/copyManager';
import VideoResolutionToggle from './VideoResolutionToggle';
import { GET } from 'app/http/adminApi/adminClient';
import { useLocalQuery } from 'app/utils/searchManager';
import { ChannelConfig } from 'channels/details/section/config/ChannelConfigScreen';
import { useAppDispatch } from 'app/store/hooks';

interface Props {
	video: Video;
	refresh: () => Promise<void>;
}

const VideoDetailsLiveStreamingSection = (props: Props) => {
	const { video, refresh } = props;
	const dispatch = useAppDispatch();
	const [configResponse, setConfigResponse] = useState<{ config: ChannelConfig } | null>(null);

	useLocalQuery(async () => {
		const result = await GET(`/api/admin/channel/${video.user.uid}/config`);
		setConfigResponse(result.data.item);
	});

	const [showConfirmationStopStream, setShowConfirmationStopStream] = useState(false);
	const [showConfirmationStartStream, setShowConfirmationStartStream] = useState(false);
	const [showConfirmationResetStream, setShowConfirmationResetStream] = useState(false);
	const [showConfirmationReplaceRecastLive, setShowConfirmationReplaceRecastLive] = useState(false);

	const stopAndDeleteStreaming = async () => {
		setShowConfirmationStopStream(false);
		await stopRecastLiveStream(video.uid);
		dispatch(refresh);
	};
	const startStreaming = async () => {
		setShowConfirmationStartStream(false);
		await startStream(video.uid);
		dispatch(refresh);
	};

	const resetStreaming = async () => {
		setShowConfirmationResetStream(false);
		await resetRecastLiveStream(video.uid);
		dispatch(refresh);
	};

	const confirmReplaceRecastLiveWithMediaLive = async () => {
		setShowConfirmationReplaceRecastLive(false);
		await replaceRecastLiveWithMediaLive(video.uid);
		dispatch(refresh);
	};

	const showResolutionToggle = () => {
		return configResponse && configResponse.config.resolutions[1080].isEnabled;
	};

	return (
		<>
			{video?.stream && (
				<Box className="section" component="div">
					<div className="sectionRow">
						<strong>Stream status: </strong>
						<Chip size="small" label={video.stream?.status ? video.stream.status : ' -'} />
					</div>
					<div className="sectionRow">
						<strong>Start: </strong>
						{video.startDate}
					</div>
					<div className="sectionRow">
						<strong>End: </strong>
						{video.stopDate}
					</div>
					<div className="sectionRow">
						<strong>RTMP Endpoint URL: </strong>
						{video.stream.inputUrl}
						{video.stream.inputUrl && (
							<IconButton onClick={() => copyToClipboard(video.stream.inputUrl)} size="large">
								<FileCopyIcon />
							</IconButton>
						)}
					</div>
					<div className="sectionRow">
						<strong>Transcoder: </strong>
						{video.stream.isUsingRecastLive ? 'Recast Live' : (video.stream.status !== 'pending' ? 'MediaLive' : '')}
					</div>
					{showResolutionToggle() && <VideoResolutionToggle video={video} refresh={refresh} />}
					{isLive(video.stream?.status) && (
						<Button variant="contained" onClick={() => setShowConfirmationStopStream(true)}>
							Stop and delete stream
						</Button>
					)}
					{isTesting(video.stream?.status) && (
						<Button
							variant="contained"
							color="secondary"
							onClick={() => setShowConfirmationStartStream(true)}
						>
							Start stream
						</Button>
					)}
					{isDeleted(video.stream?.status) && (
						<Button variant="contained" onClick={() => setShowConfirmationResetStream(true)}>
							Reset stream
						</Button>
					)}
					{!isDeleted(video.stream?.status) &&
						!isPending(video.stream?.status) &&
						video.stream?.isUsingRecastLive && (
							<div className="sectionRow">
								<Button variant="contained" onClick={() => setShowConfirmationReplaceRecastLive(true)}>
									Replace RecastLive with MediaLive
								</Button>
							</div>
						)}
				</Box>
			)}
			<ConfirmActionModal
				show={showConfirmationStopStream}
				onClose={() => setShowConfirmationStopStream(false)}
				description={
					'Are you sure you want to stop this stream? This action cannot be undone and should normally be done by the publisher themself.'
				}
				confirmedAction={stopAndDeleteStreaming}
			/>
			<ConfirmActionModal
				show={showConfirmationStartStream}
				onClose={() => setShowConfirmationStartStream(false)}
				description={'Are you sure you want to start this stream?'}
				confirmedAction={startStreaming}
			/>
			<ConfirmActionModal
				show={showConfirmationResetStream}
				onClose={() => setShowConfirmationResetStream(false)}
				description={'Are you sure you want to reset this stream?'}
				confirmedAction={resetStreaming}
			/>
			<ConfirmActionModal
				show={showConfirmationReplaceRecastLive}
				onClose={() => setShowConfirmationReplaceRecastLive(false)}
				description={'Are you sure you want to replace recast live with medialive for this stream?'}
				confirmedAction={confirmReplaceRecastLiveWithMediaLive}
			/>
		</>
	);
};

export default VideoDetailsLiveStreamingSection;
