import { DependencyList, useEffect, useRef, useState } from "react";

/** Don't run the requested action for delay. If any further requests come in during the
 *  wait then just replace them with the newer request. This is useful to debounce 
 *  expensive actions which are being triggered by potentially rapid user actions such
 *  as typing.
 * 
 *  Uses useRef to increase the chance of working correctly in a React component */
export function useDebouncedAction(action: () => void, delay = 500) {
  const pending = useRef<number>();

  return () => {
    pending.current && clearTimeout(pending.current);
        pending.current = setTimeout(()=>{
      action();
      pending.current = 0;
    }, delay) as any;
  };
}

/** Use the provided query to fetch the data initially and cache it in the component state
 * 
 *  Example:
 *  ```
 *      // Get and cache some data about a user with uid. 
 *      // Passing the uid as a dependency means that the data will reload for a new uid
 *      // If you make changes client-side and want to force a refresh, call the refresh
 *      // function 
 *      const [ user, refresh ] = useLocalQuery(()=>fetchUserDetailsApi(uid), [uid]);
 *  ```
 * 
 *  Avoiding a global state cache such as react-query reduces the risk of stale data being
 *  cached, caches growing needlessly large, and unintended side effects across modules. 
 *  The admin app doesn't need to be especially performant, and is generally structured in
 *  components which can easily pass the user function through props anyway, so many of 
 *  the benefits of react-query are lost */
export function useLocalQuery <T>(fetch: ()=>Promise<T>, deps: DependencyList = [], def?: { data?: T }) {
  const [ data, setData ] = useState<T>();
  let error;
  const refresh = ()=>fetch().then(setData).catch(e=>error = e);
  useEffect(() => { refresh() }, deps);
  return [ data === undefined ? def : data, ()=>refresh(), error ] as [ T, ()=>Promise<void>, any ];
}
