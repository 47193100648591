import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WidgetCodeBox from 'app/components/modal/codeBox/codeBox';
import IconTextButton from 'app/components/buttons/IconTextButton';
import { copyToClipboard } from 'app/utils/copyManager';
import ActionButton from 'app/components/buttons/actionButton';

interface Props {
	widgetName: string;
	widgetCode: string;
	widgetSource: string;
	onCancel: () => void;
	isOpen: boolean;
}
const WidgetModal = ({ widgetName, widgetCode, widgetSource, onCancel, isOpen }: Props) => {
	console.log(`WIDGET SRC: ${widgetSource}`);
	return (
		<Dialog className="channelWidgetModal" fullWidth open={isOpen} onClose={onCancel}>
			<DialogTitle>
				<strong>Widget code</strong>
			</DialogTitle>
			<DialogContent>
				<div className="helpText">Widget name: {widgetName}</div>
				<WidgetCodeBox codeContent={widgetCode} />

				<IconTextButton
					onClick={() => copyToClipboard(widgetCode)}
					icon={FileCopyIcon}
					text="Copy to Clipboard"
					clickedText="Copied"
				/>
				<IconTextButton
					onClick={() => {
						window.open(widgetSource, '_blank');
					}}
					icon={VisibilityIcon}
					text="Preview"
				/>
			</DialogContent>

			<DialogActions className="widget-buttons">
				<ActionButton text="Close" onClick={onCancel} />
			</DialogActions>
		</Dialog>
	);
};

export default WidgetModal;
