export interface Category {
	id: number;
	parentId: number | null;
	created: string;
	name: string;
	translationId: string;
	status: 'VISIBLE' | 'INVISIBLE';
	isFollowable: boolean;
	isFeatured: boolean;
	background: {
		color: string;
		gradient: string;
	};
	isChildrenGeneral: boolean;
}

export const categoriesToString = (allCategories: Category[], ids: number[]) =>
	ids.map(categoryId => allCategories.find(c => c.id === categoryId)?.name ?? 'Invalid').join(',');
