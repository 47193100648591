import { UnverifiedUser } from 'app/domain/UnverifiedUser';
import { DELETE_AUTH, GET_AUTH, POST_AUTH } from 'app/http/authApi/authClient';
import { GET_PARTNER } from 'app/http/partnerApi/partnerClient';
import { createSuccessResult } from 'app/utils/result';
import { ResendVerificationCodeValues } from 'unverified/ResendVerificationCodeModal';

export interface ObjectExistence {
  existsChannel: boolean;
  existsPartner: boolean;
}

export const checkObjectExistence = async (uid: string) =>
  GET_PARTNER<ObjectExistence>(`/admin/exists/${uid}`)
    .then(r=>r.data.data);

export const removeUnverifiedUserApi = (email: string) =>
  DELETE_AUTH(`/admin/user/unverified`, { email: email })
    .then(r=>r.data.data);

export const resendUnverifiedUserApi = async (values: ResendVerificationCodeValues) =>
  POST_AUTH<any>(`/admin/user/unverified/verify-code`, values)
    .then(r=>r.data.data);

export const verifyUnverifiedUserApi = (email: string) =>
		POST_AUTH(`/admin/verify-user`, { email: email })
      .then(r=>createSuccessResult(r.data));

export const fetchUnverifiedUserApi = ({ emailSearchText }: { emailSearchText?: string }) =>
  GET_AUTH<UnverifiedUser[]>(`/admin/users/unverified`, {params: { emailSearchText }})
    .then(r=>r.data.data);
