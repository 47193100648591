import { AppThunk, AppThunkDispatch, RootState } from '../../../../app/store/store';
import { makeRequest } from '../../../../app/http/request';
import { POST_PAYMENT } from '../../../../app/http/paymentApi/paymentClient';
import { createSuccessResult } from '../../../../app/utils/result';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';
import {
    currenciesSetAllSelectedCodeAction,
    currenciesSetCurrencyCountriesAction,
    currencyCountriesCloseEditModalAction,
} from '../store/CurrencyCountriesActions';

export const editCurrencyCurrenciesApi = async (dispatch: AppThunkDispatch, currencyCountries: CurrencyCountries[]) =>
    makeRequest({
      dispatch,
      request: async () => {
        const result = await POST_PAYMENT<void>(`/admin/currency/countries`, {currencyCountries});
        return result.data;
      },
      responseSuccessHandler: () => createSuccessResult<void, string>()
    });

export function editCurrencyCurrenciesUseCase(currencyCountries: CurrencyCountries[]): AppThunk {
  return async (dispatch) => {
    await editCurrencyCurrenciesApi(dispatch, currencyCountries);
    dispatch( currencyCountriesCloseEditModalAction() );
  };
}

export function removeCurrencyCurrenciesUseCase(currencyCountry: CurrencyCountries): AppThunk {
    return ((dispatch, getState: () => RootState) => {

        const { currencyCountries } = getState().currency.currencyCountries.actions;
        const newCurrencyCountries = currencyCountries.filter(country => country.currencyCode !== currencyCountry.currencyCode)
        dispatch( currenciesSetCurrencyCountriesAction([...newCurrencyCountries]) );

        const allCheckedCode: string[] = [];
        [...newCurrencyCountries].map( country => country.countryCodes.map( code => allCheckedCode.push( code ) ) );
        dispatch( currenciesSetAllSelectedCodeAction( allCheckedCode ) );
    });
}
