import flagsmith from 'flagsmith';
import { getConfig } from 'config/appConfig';
import { getLoggedUserId, getTokenPayload } from 'app/http/authApi/token';

export const initFlagsmith = async () => {
	const token = getTokenPayload();

	flagsmith.init({
		identity: token && getLoggedUserId(),
		traits: {
			isAdmin: !!token?.isAdmin
		},
		cacheFlags: true,
		environmentID: getConfig().FLAGSMITH_ENVIRONMENT_ID,
		api: 'https://watch.recast.tv/flagsmith/api/v1/'
		// defaultFlags: {
		// If you want to default your flag to something other than false in case flagsmith goes down
		// 	e.g. myDefaultFlag: { enabled: true }
		// }
	});

	flagsmith.startListening(getConfig().FLAGSMITH_POLLING_INTERVAL_MS);
};
