import React from "react";
import { Chip } from '@mui/material';
import { useAllCategories } from "./categoriesUseCase";

/** Turn a list of ids into a summary list of names in Chips 
 *  Gets the full categories list via react-query and stores in key 'all-categories' */
export const CategoriesList = ({ids}: {ids: number[]}) => {
	const { categories } = useAllCategories();
	return <> 
		{categories?.filter(c=>ids.includes(c.id)).map(({id, name})=>
			<Chip key={`c${id}`} style={{ margin: '3px' }} label={name} />)} 
	</>;
}

export default CategoriesList;
