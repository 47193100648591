
export interface Page<T> {
  items: T[];
  perPage: number;
  pageCount: number;
  currPage: number;
  currentPage?: number;
  totalCount: number;
}

export function createEmptyPage<T>(): Page<T> {
  return {
    currPage: 1,
    pageCount: 1,
    totalCount: 0,
    perPage: 0,
    items: [],
  };
}

export const isLastPage = <T>(res?: Page<T>): boolean => {
  if (!res) {
    return true;
  }
  return res.currPage >= res.pageCount;
};
