import React from "react";
import { connect } from "react-redux";
import RemoveModal from "../../../app/components/modal/remove/RemoveModal";
import { AppThunkDispatch, RootState } from "../../../app/store/store";
import { removeVideoUseCase } from "./RemoveVideoUseCase";
import { videoCloseRemoveModalAction } from "../store/VideoActionsActions";

interface StateProps {
  videoId: string;
  isOpen: boolean;
}

interface DispatchProps {
  removeModal: (videoId: string) => void;
  closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const RemoveVideoModal = (props: Props) => {
  const { videoId, isOpen, removeModal, closeModal } = props;

  const handleRemove = () => {
    removeModal(videoId);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <RemoveModal isOpen={isOpen} closeModal={handleCloseModal} onRemove={handleRemove} />
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.videos.actions.removeModal.isOpen,
  videoId: state.videos.actions.removeModal.videoId,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  removeModal: (videoId: string) => {
    dispatch(removeVideoUseCase(videoId));
  },
  closeModal: () => {
    dispatch(videoCloseRemoveModalAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveVideoModal);
