import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem, ListItemIcon,
    ListItemText,
    TextField as MuiTextField,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { Contributor } from 'app/domain/Contributor';
import { CONTRIBUTOR_MIN_PCT, MAX_CONTRIBUTORS_NUMBER } from 'constants/Common';
import { Channel } from "app/domain/Channel";
import ChooseContributor from "./ChooseContributor";

export interface Props {
    isOpen: boolean;
    title: string;
    contributors: Contributor[];
    channelUid: string;
    onSave: (contributors: Contributor[]) => void;
    closeModal: ()=>void;
}

const ContributorsSplitModal = (props: Props) => {

    const { onSave, title, contributors: _contributors, closeModal, isOpen, channelUid } = props;

    const [contributorsPcts, setContributorsPcts] = useState<Contributor[]>([]);
    const [contributorTotalPcts, setContributorTotalPcts] = useState(0);


    useEffect(() => {
        setContributorsPcts([..._contributors]);
    }, [_contributors]);

    useEffect(() => {
        setContributorTotalPcts(contributorsPcts.reduce((sum, c) => sum + c.pct, 0));
    }, [contributorsPcts]);

    const setContributorPct = (uid: string, pct: number) =>
        setContributorsPcts(contributorsPcts.map(c=>c.userUid !== uid ? c : { ...c, pct }));


    const onContributorValueChange = (user: Contributor, e: any): void => {
        const value = e.currentTarget.value;
        if(isNaN(value)){
            return;
        }
		const numericValue = +value;
        const diff = numericValue - user.pct;
        if (diff < (100 - contributorTotalPcts)) {
            setContributorPct(user.userUid, numericValue);
        }
    };

    const onContributorPctFieldBlur = (user: Contributor, e: any) => {
        if (e.currentTarget.value <= 0) {
            setContributorPct(user.userUid, CONTRIBUTOR_MIN_PCT);
        }
    };

    const addUser = (user: Channel) => {
        setContributorsPcts([
            ...contributorsPcts, 
            { userUid: user.channelUid, username: user.channelName, pct: 0 } as Contributor 
        ]);
    }

    const removeUser = (user: Contributor) => {
        setContributorsPcts(contributorsPcts.filter(c=>c.userUid!==user.userUid));
    };

    const saveChanges = () => {
        onSave(contributorsPcts);
        closeModal();
    };

    const isAddingUsersAllowed = 
        contributorsPcts.length < MAX_CONTRIBUTORS_NUMBER &&  contributorTotalPcts < 99;

    const contributorRow = (user: Contributor) =>
        <ListItem alignItems="flex-start" key={user.userUid}>
            <ListItemText  primary={user.userUid} secondary={user.username} />
            <MuiTextField
                className="formField"
                label="Share %"
                value={user.pct}
                variant="outlined"
                type="text"
                onChange={(value) => onContributorValueChange(user, value)}
                onBlur={(value) => onContributorPctFieldBlur(user, value)}
            />
            <ListItemIcon>
				<IconButton className="button button-red" onClick={() => removeUser(user)} size="large">
                    <DeleteIcon/>
                </IconButton>
            </ListItemIcon>
        </ListItem>;

    return <>
        <Dialog
            className="editSplitEarningsDialog"
            open={isOpen}
            onClose={closeModal}>

            <DialogTitle>Edit {title}</DialogTitle>
            <DialogContent>
                <div className="sectionRow">
                    <strong>Publisher share: </strong>
                    {100 - contributorTotalPcts}%
                </div>
                <div className="sectionRow">
                    <List>
                        { contributorsPcts.map(contributorRow) }
                    </List>
                    {isAddingUsersAllowed && <ChooseContributor
                        title="Type a publisher name to add"
                        hideUids={[ ...contributorsPcts.map(c=>c.userUid), channelUid ]} 
                        select={addUser}
                    />}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeModal} variant="outlined" color="primary">
                    Close
                </Button>
                <Button onClick={saveChanges} type="submit" color="secondary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};


export default ContributorsSplitModal;
