import React, { useState } from 'react';
import { ButtonBase, Typography, styled } from '@mui/material';
import { Colors } from 'Theme';

interface IconTextButtonProps {
	onClick: () => void;
	icon: React.ElementType;
	text: React.ReactNode;
	clickedText?: React.ReactNode;
}

const IconText = styled(Typography)(() => ({
	opacity: '1',
	fontFamily: 'Poppins',
	fontSize: '14px',
	lineHeight: '24px',
	color: Colors.ButtonText,
	fontWeight: '600',
	fontStyle: 'SemiBold',
	letterSpacing: '0.3px',
	textAlign: 'left',
	padding: '6px 16px 6px 8.5px'
}));

const IconTextButtonBase = styled(ButtonBase)(({ clickedText }: { clickedText: React.ReactNode }) => ({
	border: `2px solid ${Colors.White}`,
	borderRadius: '4px',
	height: '36px',
	opacity: '1',
	padding: '10px 16px 8px 19px',
	backgroundColor: Colors.White,
	textAlign: 'left',
	justifyContent: clickedText ? 'center' : 'flex-start',
	minWidth: clickedText ? '225px' : 'unset',
	'&:hover': {
		border: `2px solid ${Colors.Hover}`,
		backgroundColor: Colors.Hover
	}
}));

const IconTextButton: React.FC<IconTextButtonProps> = ({ onClick, icon: Icon, text, clickedText }) => {
	const [clicked, setClicked] = useState(false);
	const [displayText, setDisplayText] = useState(text);

	const handleClick = () => {
		onClick();
		setClicked(true);
		setTimeout(() => {
			setClicked(false);
			setDisplayText(text);
		}, 1000);
	};

	return (
		<IconTextButtonBase onClick={handleClick} clickedText={clickedText}>
			<Icon style={{ fontSize: 'large', color: Colors.Black }} />
			<IconText>{clicked ? (clickedText ? clickedText : text) : displayText}</IconText>
		</IconTextButtonBase>
	);
};

export default IconTextButton;
