import React, { ChangeEvent, useRef, useState } from 'react';
import {
	ALLOWED_UPLOAD_IMAGE_FORMATS,
	convertHeicFileToJpeg,
	isHeicImageType
} from 'users/actions/image/ImageUpload/utils';
import CropperImageModal from 'users/actions/image/CropperImage/CropperImageModal';

interface ImageUploadProps {
	setFile: (file: File) => void;
	className?: string;
	defaultImage?: string;
	isCroppingModalHidden?: boolean;
	isBannerImage?: boolean;
	name?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = props => {
	const inputRef: React.Ref<HTMLInputElement> = useRef(null);
	const [imageSource, setImageSource] = useState<string>();
	const [showImageCropper, setShowImageCropper] = useState(false);

	const { isBannerImage, setFile, defaultImage, name, className } = props;

	const onImageClick = (): void => {
		inputRef?.current?.click?.();
	};

	const onFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
		const files = event.target.files;

		if (files && files?.length) {
			const selectedFile = files[0];

			if (isHeicImageType(selectedFile)) {
				convertHeicFileToJpeg(selectedFile, setImageSource);
			}

			const reader = new FileReader();

			reader.onload = function (e: ProgressEvent<FileReader>): void {
				if (typeof e.target?.result === 'string') {
					setImageSource(e.target.result);
					isBannerImage ? setFile(selectedFile) : setShowImageCropper(true);
				}
			};
			reader.readAsDataURL(selectedFile);
		}
	};

	const setCroppedImage = (file: File): void => {
		hideCropperModal();
		setFile(file);
	};

	const hideCropperModal = () => {
		setShowImageCropper(false);
	};

	return (
		<>
			{showImageCropper && imageSource && (
				<CropperImageModal
					isBannerImage={!!isBannerImage}
					imageSource={imageSource}
					defaultImage={defaultImage}
					onClose={hideCropperModal}
					onSave={setCroppedImage}
				/>
			)}

			<div
				className={`imageUpload ${className} ${isBannerImage ? 'imageUpload--banner' : 'imageUpload--profile'}`}
			>
				<div
					onClick={onImageClick}
					className={`imageUpload__content `}
					style={{
						backgroundImage: 'url(' + defaultImage + ')'
					}}
				>
					<input
						type="file"
						accept={ALLOWED_UPLOAD_IMAGE_FORMATS}
						onChange={onFileUpload}
						name={name}
						value=""
						ref={inputRef}
					/>
				</div>
			</div>
		</>
	);
};

export default ImageUpload;
