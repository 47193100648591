import { RefundStateTypes } from 'refunds/store/refundsActions';

export enum RefundTypes {
  VIDEO = 'video',
  CHANNEL_PASS = 'channel pass',
}

interface RefundState {
  confirmRefundModal: {
    isOpen: boolean;
    refundType?: RefundTypes;
    refundTitle: string;
    singleRefund?: { userId: string, ledgerId: number };
    bulkRefund?: { productId: string, refundReason: string };
  };
  failedLedgersModal: {
	isOpen: boolean;
	failedLedgers: number[];
  }
}

const initialState: RefundState = {
  confirmRefundModal: {
    isOpen: false,
    refundType: RefundTypes.VIDEO,
    refundTitle: '',
    singleRefund: { userId: '', ledgerId: 0 },
    bulkRefund: { productId: '', refundReason: '' }
  },
  failedLedgersModal: {
  	isOpen: false,
  	failedLedgers: [],
  }
};

export function refundsReducer(state = initialState, action: RefundStateTypes): RefundState {
  switch (action.type) {
    case "REFUND_OPEN_CONFIRMATION_MODAL":
      return {
      	...state,
      	confirmRefundModal:
      		{ ...state.confirmRefundModal,
      			isOpen: true,
      			refundTitle: action.refundTitle,
				refundType: action.refundType,
      			singleRefund: action.singleRefund,
      			bulkRefund: action.bulkRefund,
      		}};
    case "REFUND_CLOSE_CONFIRMATION_MODAL":
      return {
      	...state,
      	confirmRefundModal:
      		{ ...state.confirmRefundModal,
      			isOpen: false,
      			singleRefund: { userId: '', ledgerId: 0 },
      			bulkRefund: { productId: '', refundReason: '' }
      		}};
	case "FAILED_LEDGERS_OPEN_MODAL":
		return { ...state, failedLedgersModal: { ...state.failedLedgersModal, isOpen: true, failedLedgers: action.failedLedgers }};
	case 'FAILED_LEDGERS_CLOSE_MODAL':
		return { ...state, failedLedgersModal: { ...state.failedLedgersModal, isOpen: false, failedLedgers: [] } };
    default:
      return state;
  }
}
