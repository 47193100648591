export interface ChannelPassStaff {
	id: string;
	originId: string;
	statusDetails: { status: ChannelPassStatus };
	created: string;
	priceDetails: { price: number };
	rentalPeriod: number;
	description: string;
}

export interface ChannelPassStaffWithPurchases extends ChannelPassStaff {
	purchases?: number;
}

export enum ChannelPassStatus {
	PRESALE = 'PRESALE',
	PURCHASEABLE = 'PURCHASEABLE',
	UNPURCHASEABLE = 'UNPURCHASEABLE',
	SUSPENDED = 'SUSPENDED',
	ARCHIVED = 'ARCHIVED',
	REMOVED = 'REMOVED'
}
