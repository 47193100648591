import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { Grid } from '@mui/material';
import { dateFormat } from '../../../app/store/common/Constants';
import { invitationSetEndDate, invitationSetStartDate } from '../store/InvitationsListActions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface StateProps {
    startDate?: Date;
    endDate?: Date;
}

interface DispatchProps {
    setStartDate: (startDate: Date) => void;
    setEndDate: (endDate: Date) => void;
}

type Props = StateProps & DispatchProps;

const DateFilter = (props: Props) => {
    const { startDate, setStartDate, endDate, setEndDate } = props;

    const [hasStartDate, setHasStartDate] = useState(startDate);
    const [hasEndDate, setHasEndDate] = useState(endDate);

    const handleStartDateChange = (date: Dayjs | null) => {
        if (date !== null) {
            setHasStartDate(date.toDate());
            setStartDate(date.toDate());
        }
    };

    const handleEndDateChange = (date: Dayjs | null) => {
        if (date !== null) {
            setHasEndDate(date.toDate());
            setEndDate(date.toDate());
        }
    };

    return (
        <div className="filterDate">
			<LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
						<DatePicker
                            format={dateFormat}
                            label="Start date"
							maxDate={dayjs(hasEndDate)}
							value={dayjs(hasStartDate)}
                            onChange={handleStartDateChange}
							slotProps={{ textField: { variant: 'standard' } } }
                        />
                    </Grid>
                    <Grid item xs={3}>
						<DatePicker
                            format={dateFormat}
                            label="End date"
							minDate={dayjs(hasStartDate)}
							value={dayjs(hasEndDate)}
                            onChange={handleEndDateChange}
							slotProps={{ textField: { variant: 'standard' } } }
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    startDate: state.invitations.list.filters.startDate,
    endDate: state.invitations.list.filters.endDate,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    setStartDate: (startDate: Date) => {
        dispatch(invitationSetStartDate(startDate));
    },
    setEndDate: (endDate: Date) => {
        dispatch(invitationSetEndDate(endDate));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
