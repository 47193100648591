import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ChannelPackage } from './ChannelConfigScreen';
import EditIcon from '@mui/icons-material/Edit';
import SectionRow from 'app/components/SectionRow';
import PackageModal from './PackageModal';
import CustomisableConfig from './CustomisableConfig';

interface PackageSectionProps {
	packageType: ChannelPackage;
	updatePackage: (packageId: ChannelPackage) => void;
	isCustomised: boolean;
}

const PackageSection = (props: PackageSectionProps) => {
	const { packageType, updatePackage, isCustomised } = props;
	const [isEditing, setIsEditing] = useState(false);

	const onSave = (value: ChannelPackage) => {
		updatePackage(value);
	};

	return (
		<>
			<Box className="section" component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">Package</span>
					<Tooltip title="Edit package" arrow>
						<IconButton onClick={() => setIsEditing(true)} size="large">
							<EditIcon className="editIcon" />
						</IconButton>
					</Tooltip>
				</div>
				<SectionRow
					label="Name"
					content={
						<CustomisableConfig value={packageType} customisedText={isCustomised ? 'Customised' : ''} />
					}
				/>
			</Box>

			<PackageModal
				isOpen={isEditing}
				onSave={onSave}
				onClose={() => {
					setIsEditing(false);
				}}
				packageType={packageType}
			/>
		</>
	);
};

export default PackageSection;
