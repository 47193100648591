import React, { useMemo } from 'react';
import * as Yup from 'yup';
import {
	Button,
	Checkbox as MuiCheckbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel as MuiFormControlLabel,
	MenuItem,
	TextField as MuiTextField
} from '@mui/material';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Category } from 'app/domain/Category';
import { GradientStyleInput } from 'app/components/inputs/GradientStyleInput';
import { FormMessages } from 'constants/Common';
import { CategoryData, useAllCategories } from 'categories/categoriesUseCase';
import { FormikHelpers } from 'formik/dist/types';
import { getSubcategories } from 'categories/tree/categoriesTreeManager';

const PARENT_ID_FIELD_NO_PARENT = -1;

const defaultValues: CategoryData = {
	parentId: 0,
	name: '',
	created: '',
	translationId: '',
	status: 'VISIBLE',
	isFollowable: false,
	isFeatured: false,
	isChildrenGeneral: false,
	background: {
		color: '#3f3f3f',
		gradient: ''
	}
};

const categoryValidator = Yup.object({
	name: Yup.string().required(FormMessages.REQUIRED),
	isFollowable: Yup.boolean().when('isFeatured', {
		is: true,
		then: Yup.boolean().isTrue('Featured categories must also be followable')
	})
});

interface GenericCategoryModalProps {
	initialValues?: Partial<CategoryData>;
	onSubmit: FormikConfig<CategoryData>['onSubmit'];
	isOpen: boolean;
	onClose?: () => void;
	title?: string;
	id?: number;
}

const filterAllowedCategoryParents = (categoryId: number, categories: Category[]) => {
	const parentGeneralIds = categories
		.filter(c => c.isChildrenGeneral)
		.map(c => c.id);
	const generalChildren = categories.filter(
		c => c.parentId && parentGeneralIds.includes(c.parentId)
	);
	const subCategories = categoryId ? getSubcategories(categoryId, categories) : [];

	const notAllowedCategoriesIds = new Set([
		categoryId,
		...[generalChildren, subCategories].flat().map(c => c.id)
	]);

	return categories.filter(c => !notAllowedCategoriesIds.has(c.id));
};

const GenericCategoryModal = (props: GenericCategoryModalProps) => {
	const { isOpen, onClose, title, id } = props;
	const initialValues = useMemo(() => {
		const parentId = props.initialValues?.parentId || defaultValues.parentId;
		return {
			...defaultValues,
			...props.initialValues,
			parentId: parentId === null ? PARENT_ID_FIELD_NO_PARENT : parentId
		};
	}, [props.initialValues]);

	const { categories }  = useAllCategories();

	const allowedParents = useMemo(
		() => filterAllowedCategoryParents(id || 0, categories || []), 
		[id, categories]
	);

	const onSubmit = (category: typeof initialValues, formikHelpers: FormikHelpers<any>) =>
		props.onSubmit(
			{
				...category,
				parentId: category.parentId === PARENT_ID_FIELD_NO_PARENT ? null : category.parentId,
				translationId: category.name?.toLowerCase()
			},
			formikHelpers
		);

	return (
		<Dialog className="dialog" maxWidth="sm" open={isOpen} onClose={onClose}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={categoryValidator}
				onSubmit={(c, h)=>onSubmit(c, h) && onClose?.()}
			>
				{({ errors }) => (
					<Form>
						<DialogTitle>{title}</DialogTitle>
						<DialogContent>
							{id && (
								<MuiTextField
									className="formField"
									label="id"
									value={id}
									variant="outlined"
									disabled
									fullWidth
								/>
							)}
							<Field
								className="formField"
								label="Name"
								fullWidth
								name="name"
								variant="outlined"
								component={TextField}
							/>
							<Field
								className="formField"
								label="Parent"
								fullWidth
								name="parentId"
								variant="outlined"
								component={TextField}
								select
							>
								<MenuItem value={PARENT_ID_FIELD_NO_PARENT}>----</MenuItem>
								{allowedParents?.map(parent => (
									<MenuItem key={parent.id} value={parent.id}>
										{parent.name}
									</MenuItem>
								))}
							</Field>
							<Field
								className="formField"
								label="Status"
								fullWidth
								name="status"
								variant="outlined"
								component={TextField}
								select
							>
								<MenuItem value={'INVISIBLE'}>invisible</MenuItem>
								<MenuItem value={'VISIBLE'}>visible</MenuItem>
							</Field>
							<Field
								className="formField"
								Label={{ label: 'Followable' }}
								type="checkbox"
								name="isFollowable"
								variant="outlined"
								component={CheckboxWithLabel}
							/>
							<Field
								className="formField"
								Label={{ label: 'Featured' }}
								type="checkbox"
								name="isFeatured"
								variant="outlined"
								component={CheckboxWithLabel}
							/>
							{errors.isFollowable && (
								<DialogContentText className="formError">{errors.isFollowable}</DialogContentText>
							)}
							{!!initialValues?.isChildrenGeneral && (
								<MuiFormControlLabel
									label="General categories parent"
									control={<MuiCheckbox className="formField" checked={true} disabled />}
								/>
							)}
							<Field
								className="formField"
								label="Background"
								name="background.gradient"
								component={GradientStyleInput}
							/>
							<Field
								className="formField"
								label="Color"
								name="background.color"
								type="color"
								variant="outlined"
								component={TextField}
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Close
							</Button>
							<Button variant="contained" type="submit" color="secondary" autoFocus>
								Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default GenericCategoryModal;
