import { ChannelPassStaff } from 'app/domain/ChannelPass';
import { GET_PUBLIC } from 'app/http/adminApi/adminClient';
import { fetchChannelPassesBreakdownData, Filter } from 'app/stats/statsManager';
import { ChannelPassStatus } from 'app/domain/ChannelPass';
import dayjs from 'dayjs';

export const doFetchChannelPassesApi = async (
	channelUid: string
): Promise<{ data: { expanded: ChannelPassStaff[] } }> => {
	const { data } = await GET_PUBLIC<{ data: { expanded: ChannelPassStaff[] } }>(
		`/api/admin/publisher/channel/${channelUid}/tariffs`
	);
	const filteredPasses = data.data.expanded
		?.filter(pass =>
			[ChannelPassStatus.PURCHASEABLE, ChannelPassStatus.UNPURCHASEABLE, ChannelPassStatus.SUSPENDED].includes(
				pass.statusDetails.status
			)
		)
		.map(pass => pass);

	const passIds = filteredPasses?.map(pass => pass.id);
	const passDates = filteredPasses?.map(pass => dayjs(pass.created));
	const oldestDate = passDates && passDates.length > 0 ? dayjs.min(passDates) : dayjs();

	const filter: Filter = {
		checkedTitles: passIds,
		dates: [oldestDate as dayjs.Dayjs, dayjs()],
		channelUid
	};
	const purchases = await fetchChannelPassesBreakdownData(filter);

	const passesWithPurchases = filteredPasses.map(pass => {
		const purchaseData = purchases.find(purchase => purchase.id === pass.id);
		return {
			...pass,
			purchases: purchaseData ? purchaseData.purchases : 0
		};
	});
	return { data: { expanded: passesWithPurchases } };
};
