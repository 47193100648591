import { useFlags } from 'flagsmith/react';
import { FeatureFlagName } from 'app/featureFlags/featureFlags';

export const useFeatureFlagsValue = (flagNames: FeatureFlagName[]): boolean[] => {
	const flags = useFlags(flagNames);
	return flagNames.map(flagName => flags[flagName].enabled);
};

export const useFeatureFlags = <T extends FeatureFlagName>(flagNames: T[]) => {
	const flags = useFlags<T>(flagNames);

	return flagNames.map(flagNames => flags[flagNames]);
};
