import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import TableSortHeader from '../../../app/components/table/TableSortHeader';
import { ChannelSortField, ChannelsQuery } from '../filter/ChannelFilter';

interface Props {
    filter: ChannelsQuery;
    setFilter: (filter: ChannelsQuery) => void;
}

const ChannelsTableHeader = ({ filter, setFilter }: Props) => {
    const { sort } = filter;

    const setSort = (field: ChannelSortField) => 
      setFilter({
         ...filter, 
         sort: { field, direction: sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc' } 
      });

    return (
    <TableHead>
      <TableRow>
        <TableSortHeader className='id-cell' label="Id" fieldName="uid" sort={sort} setSort={setSort} />
        <TableSortHeader className='name-cell' label="Name" fieldName="name" sort={sort} setSort={setSort} />
        <TableCell className='status-cell header' align="left">Status</TableCell>
        <TableCell className='followers-cell header' align="left">Followers</TableCell>
        <TableSortHeader className='publisher-cell' label="Publisher" fieldName="partnerName" sort={sort} setSort={setSort} />
        <TableSortHeader className='created-cell' label="Created" fieldName="created" sort={sort} setSort={setSort} />
        <TableCell className='actions-cell header' align="left">Actions</TableCell>
      </TableRow>
    </TableHead>
  )
};

export default ChannelsTableHeader;

