import React, {useEffect, useState} from "react";
import { Video, VideoStatus, videoStatusNames } from "../../../../app/domain/Video";
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import Title from "../../../../app/components/title/Title";
import { Link } from "react-router-dom";
import VideoActionButtons from "../../../actions/VideoActionButtons";
import EditIcon from '@mui/icons-material/Edit';
import RoutePaths from "../../../../app/navigation/RoutePaths";
import VideoEditBasicModal from "./edit/basic/VideoEditBasicModal";
import VideoEditPricesSharingModal from "./edit/pricesharing/VideoEditPricesSharingModal";
import VideoDetailsSummaryFeatureSection from "./feature/VideoDetailsSummaryFeatureSection";
import CountryModal from "./edit/country/CountryModal";
import Button from '@mui/material/Button';
import {Country, editVideoAvailabilityApi } from "./edit/country/CountryUseCase";
import {getSupportedCountriesApi} from "../../../../app/store/appApi/me";
import dayjs from "dayjs";
import ContributorsSplitSection from '../../../../contributors/details/ContributorsSplitSection';
import { getConfig } from "config/appConfig";
import { createVideoEditPricesSharingData, editVideoContributorsApi, editVideoPricesSharingApi } from "./VideoDetailsSummaryUseCase";
import { editVideoApi, editVideoStatusMessagesApi } from "videos/details/VideoDetailsUseCase";
import VideoEditStatusMessagesModal from "./edit/basic/VideoEditStatusMessages";

const NUMBER_OF_COUNTRIES_DISPLAYED = 4;

interface Props {
  video: Video;
  refresh: ()=>void;
}

const VideoDetailsSummarySection = ({ video, refresh }: Props) => {
  const isAfterStart = dayjs(video.created).isBefore(dayjs());
  const isBeforeEnd = !video.unpublishTime || dayjs(video.unpublishTime).isAfter(dayjs());
  const canBeFeatured = video.status === VideoStatus.PUBLISHED && isAfterStart && isBeforeEnd;

  const [ isEditing, setIsEditing ] = useState(false);
  const [ isEditingPricing, setIsEditingPricing ] = useState(false);
  const [ isEditingCountries, setIsEditingCountries ] = useState(false);
  const [ isEditingStatusMessages, setIsEditingStatusMessages ] = useState(false);

  // Slightly lazy getting this every time, but it will cache
  const [allCountries, setAllCountries] = useState<Country[]>();
  useEffect(() => { getSupportedCountriesApi().then(setAllCountries) }, []);

  const statusName = videoStatusNames[video.status] || "unknown";

  const cost = typeof video.cost == "object"
    ? (video.cost.units + ' ' + video.cost.currency)
    : video.cost + ' CST';

  const shareLink = `${getConfig().SHARE_BASE}/v/${video.uid}?referrer=${video.user.uid}`;

  const statusMessages =  Array.isArray(video.statusMessages) ? video.statusMessages : [];

  const info = statusMessages.find(m=>m.type=='INFO')?.text || '';
  const error = statusMessages.find(m=>m.type=='ERROR')?.text || '';


  return (
    <Container className="videoDetailsScreen">
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Title>Video: {video.uid}</Title>
        </Grid>
        <Grid item xs={12} sm={6}>
          <VideoActionButtons videoUid={video.uid} channelUid={video.user.uid} status={video.status} name={video.name} onRefresh={refresh}/>
        </Grid>
      </Grid>
      <Divider />
      <Box className="section" component="div">
        <div className="sectionHeader">
          <span className="sectionTitle">Basic Info</span>
          <Tooltip title="Edit Basic Info" arrow>
						<IconButton onClick={ () => setIsEditing(true) } size="large">
              <EditIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <div className="sectionRow">
          <strong>Name: </strong>{video.name}
        </div>
        <div className="sectionRow">
          <strong>Description: </strong>{video.description}
        </div>
        <div className="sectionRow">
          <strong>Channel: </strong>
          <Link to={`${RoutePaths.ChannelDetails}/${video.user.uid}`} onClick={e => e.stopPropagation()}>
            {video.user.username} ({video.user.uid})
          </Link>
        </div>
        <div className="sectionRow">
          <strong>Referral link to video: </strong>
          <a href={shareLink}>{shareLink}</a>
        </div>
        <br/>
        <div className="sectionRow">
          <strong>Status: </strong>{statusName}
        </div>
        <div className="sectionRow">
          <strong>Publish Date: </strong>{video.created}
        </div>
        <div className="sectionRow">
          <strong>Unpublish Date: </strong>{video.unpublishTime}
        </div>
        <div className="sectionRow">
          <strong>Status Messages: </strong>
          <Tooltip title="Edit Status Messages" arrow>
						<IconButton onClick={() => setIsEditingStatusMessages(true)} size="large">
              <EditIcon/>
            </IconButton>
          </Tooltip>
          { (info || error) ? <>
              {info && <div style={{paddingLeft: "2em"}}>INFO {info}</div>}
              {error && <div style={{paddingLeft: "2em"}}>ERROR {error}</div>}
            </> : "No Messages"
          }
        </div>
        <div className="sectionRow">
          <strong>Availability: </strong>
          <CountryList all={allCountries || []} selectedCodes={video.locations}/>
					<Button
						color="secondary"
						style={{ margin: '0 30px' }}
						size="small"
						variant="contained"
						onClick={() => setIsEditingCountries(true)}
					>
						Change
					</Button>
        </div>
      </Box>
      <Divider />
      <Box className="section" component="div">
        <div className="sectionHeader">
          <span className="sectionTitle">Price and sharing details</span>
          <Tooltip title="Edit price and sharing" arrow>
						<IconButton onClick={() => setIsEditingPricing(true)} size="large">
              <EditIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <div className="sectionRow">
          <strong>Price: </strong>{cost}
        </div>
        <div className="sectionRow">
          <strong>Recaster share: </strong>{video.recasterPct}%
        </div>
      </Box>
      <Divider />
      <ContributorsSplitSection
          title="Earnings Split"
          contributors={video.contributorPcts || []}
          onSave={data=>editVideoContributorsApi(video.uid, data).then(refresh)}/>
      <Divider />
      <VideoDetailsSummaryFeatureSection video={video} canBeFeatured={canBeFeatured}/>
      <VideoEditBasicModal
        isOpen={isEditing}
        closeModal={()=>setIsEditing(false)}
        saveChanges={data=>editVideoApi(video.uid, data).then(refresh)}
        data={{ name: video.name, description: video.description || '' }}
      />
      <VideoEditStatusMessagesModal
        isOpen={isEditingStatusMessages}
        closeModal={()=>setIsEditingStatusMessages(false)}
        saveChanges={data=>editVideoStatusMessagesApi(video.uid, data).then(refresh)}
        data={{ info, error }}
      />
      <VideoEditPricesSharingModal
        closeModal={()=>setIsEditingPricing(false)}
        data={createVideoEditPricesSharingData(video)}
        isOpen={isEditingPricing}
        saveChanges={data=>editVideoPricesSharingApi(video.uid, data).then(refresh) }
      />
      <CountryModal
        closeModal={()=>setIsEditingCountries(false)}
        countries={video.locations}
        isOpen={isEditingCountries}
        all={allCountries || []}
        save={data=>editVideoAvailabilityApi(video.uid, data).then(refresh) }
      />
    </Container>
  )
};

const CountryList = ({ selectedCodes, all } : { selectedCodes: string[], all: Country[] })=> {
  const [ showAll, setShowAll ] = useState(false);
  const global = selectedCodes.length==1 && selectedCodes[0]=='**';
  const showExceptions = selectedCodes.length > all.length / 1.5;
  const selected = all.filter(c=>showExceptions !== selectedCodes.includes(c.code));
  const display = showAll ? selected : selected.slice(0, NUMBER_OF_COUNTRIES_DISPLAYED);

  return <>
      { (global || showExceptions) && "Everywhere Recast is available" }
      { !global && showExceptions && selected.length && " except" }
			{display.map(c => (
				<Chip key={c.code} style={{ margin: '3px' }} label={c.name} />
			))}
      { selected.length > NUMBER_OF_COUNTRIES_DISPLAYED &&
          <Chip onClick={() => setShowAll(!showAll)} label="..." /> }
    </>;
}


export default VideoDetailsSummarySection;
