import { IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Channel } from 'app/domain/Channel';

interface AffiliatesRowProps {
	channel: Channel;
	saveAffiliate: (uid: string) => void;
}
const AffiliatesRow = (props: AffiliatesRowProps) => {
	const { channel, saveAffiliate } = props;
	return (
		<TableRow className="status_LIVE">
			<TableCell>
				<Typography className="name">{channel.channelUid}</Typography>
			</TableCell>
			<TableCell>
				<Typography className="name">{channel.channelName}</Typography>	
			</TableCell>
			<TableCell>
				<Tooltip title="Add affiliate" arrow>
					<IconButton
						className="button button-orange"
						onClick={() => saveAffiliate(channel.channelUid)}
						size="large"
					>
						<CheckIcon />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};

export default AffiliatesRow;
