import React, { ReactElement } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box } from '@mui/material';
import { isEmail, isMandatory } from '../app/domain/validation';
import loginUseCase from './LoginUseCase';
import RoutePaths from '../app/navigation/RoutePaths';
import { useHistory } from 'react-router-dom';
import { AppThunkDispatch } from "../app/store/store";
import { connect } from "react-redux";
import { Result } from "../app/utils/result";

interface DispatchProps {
  login: (username: string, password: string) => Promise<Result<any, string>>;
}

interface FormValues {
  username: string;
  password: string;
}

export type Props = DispatchProps;

interface LocationState {
  fromPath?: string;
}

const LoginScreen = (props: Props): ReactElement => {
  const { login } = props;
  const history = useHistory<LocationState>();
  const fromPath: string | undefined = history.location.state?.fromPath;

  const loginAction = async (username: string, password: string, onError: () => void) => {
    const result = await login(username, password);

    if (result.error) {
      onError();
    } else {
      const redirectPath = fromPath || RoutePaths.Default;
      history.push(redirectPath);
    }
  };

  return (
    <Box component="main" className="loginScreen">
      <Container maxWidth="xs">
        <CssBaseline />
        <div className="paper">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
              loginAction(values.username, values.password, () => { setSubmitting(false) });
            }}
          >
            {({ isSubmitting, isValid, dirty }): React.ReactElement => (
              <Form className="form">
                <Field
                  style={{ height: '70px' }}
                  name="username"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  component={TextField}
                  validate={isEmail}
                />
                <Field
                  style={{ height: '70px' }}
                  className="field"
                  name="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  component={TextField}
                  validate={isMandatory}
                />
                <Button type="submit" fullWidth variant="contained" color="primary" className="submit" disabled={isSubmitting || !(isValid && dirty)}>
                  Sign In
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  login: async (username: string, password: string) => {
    return loginUseCase(username, password, dispatch);
  }
});

export default connect(null, mapDispatchToProps)(LoginScreen);
