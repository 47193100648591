import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from "react";
import { User } from "../../../../../../app/domain/User";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

interface Values {
  username: string;
}

interface Props { 
  user: User 
  save: (username: string) => void;
  onClose: () => void;
};

const UserEditUsernameModal = (props: Props) => {
  const { user, save, onClose } = props;

  return (
    <Dialog
      className="dialog"
      open={true}
      onClose={onClose}>
      <Formik
        enableReinitialize={true}
        initialValues={{ username: user.username }}
        onSubmit={(values: Values) => user && save(values.username) }>
        <Form>
          <DialogTitle>Edit username</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action might confuse user so do it sparingly!
            </DialogContentText>
            <Field className="formField" fullWidth label="New username" name="username" variant="outlined" component={TextField} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UserEditUsernameModal;
