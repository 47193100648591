import React, { ReactElement } from 'react';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { RootState } from '../../store/store';
import { connect } from 'react-redux';
import { closeSnackbarMessageAction } from '../../store/app/actions';
import { SnackbarType } from "../../store/app/reducers";
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';

interface StateProps {
  isVisible: boolean;
  message: string;
  type: SnackbarType;
}

interface DispatchProps {
  close: () => void;
}

type Props = StateProps & DispatchProps;

const SnackbarMessage = (props: Props): ReactElement => {
  const { isVisible, message, close, type } = props;

	const handleClose: SnackbarProps['onClose'] = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    close();
  };

  return (
    <Snackbar open={isVisible && !!message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
			<Alert severity={type} variant="filled" elevation={6} onClose={close}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (rootState: RootState) => ({
  isVisible: rootState.app.snackbar.isVisible,
  message: rootState.app.snackbar.message,
  type: rootState.app.snackbar.type,
});

const mapDispatchToProps = {
  close: closeSnackbarMessageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarMessage);
