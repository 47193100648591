export interface Channel {
	channelUid: string;
	channelCreated: string;
	channelUpdated: string;
	channelName: string;
	channelDescription: string;
	channelFeaturedVideoId: string[];
	channelFollowerCount: number;
	channelStatus: ChannelStatus;
	partnerUid: string;
	partnerName: string;
	partnerEmail: string;
}

export interface ChannelData {
	email: string;
	adminId: string;
}

export interface CastRefer {
	[key: string]: number;
}

export function isSuspended(channel: Channel): boolean {
	return channel.channelStatus === 'SUSPENDED';
}

export type ChannelStatus = 'DRAFT' | 'READY' | 'LIVE' | 'SUSPENDED' | 'DELETED';
export const AllChannelStatuses: ChannelStatus[] = ['DRAFT', 'READY', 'LIVE', 'SUSPENDED'];
