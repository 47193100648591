import { addCategoryApi, fetchCategoriesApi, updateCategoryApi } from 'app/http/listingsApi/adminCategoriesApi';
import { Category } from 'app/domain/Category';
import { useQuery } from 'react-query';

export type CategoryData =  Partial<Category>;

export function addCategoryUseCase(category: CategoryData) {
	delete category['isChildrenGeneral'];
	delete category['created'];
	return addCategoryApi(category as Category);
}

export function updateCategoryUseCase(id: number, category: CategoryData) {
	delete category['isChildrenGeneral'];
	delete category['created'];
	delete category['id'];
	return updateCategoryApi(id, category as Category);
}

/** Get the full category list via react-query and a parameter-free refresh function */
export const useAllCategories = () => {
	const { data, refetch } = useQuery<Category[]>('all-categories', ()=>fetchCategoriesApi());
	return { categories: data, refresh: ()=>refetch() };
}
