import React from "react";
import { Chip, Grid } from '@mui/material';

interface TabLabelProps {
  label: string;
  counter: number | Element | string | JSX.Element;
}

const TabLabelWithCounter = (props: TabLabelProps) => {
  const { label, counter } = props;

  return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>{label}</Grid>
      <Grid item className="counter">
        <Chip size="small" label={counter} color="secondary"/>
      </Grid>
    </Grid>
  );
};

export default TabLabelWithCounter;
