import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

interface Props {
  isOpen: boolean;
  message: string;
  action?: string;
  onConfirm: () => void;
  closeModal: () => void;
}

const ConfirmModal = ({ isOpen, onConfirm, closeModal, message, action }: Props) => {

  return (
    <Dialog className="" open={isOpen} onClose={closeModal} maxWidth="md">
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Button onClick={closeModal} color="primary">Disagree</Button>
				<Button
					onClick={() => {
						onConfirm();
						closeModal();
					}}
					color="primary"
				>
					{action || 'Agree'}
				</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
