import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { VideoEditPricesSharingData } from '../../VideoDetailsSummaryUseCase';

interface Props {
  isOpen: boolean;
  data: VideoEditPricesSharingData;
  saveChanges: (data: VideoEditPricesSharingData) => void;
  closeModal: () => void;
}

const VideoEditPricesSharingModal = (props: Props) => {
  const { isOpen, data, saveChanges, closeModal } = props;

  const validate = (values: VideoEditPricesSharingData) => {
    const errors: Record<string, string> = {};
  
    if (values.cost < 0) {
      errors.costUnits = 'Units are required and should be greater than 0';
    }
  
    if (values.recasterPct == null) {
      errors.recasterPct = 'Recaster % is required';
    }
  
    if (values.recasterPct < 0 || values.recasterPct > 100) {
      errors.recasterPct = 'Recaster % should be between 0 and 100';
    }
  
    return errors;
  };
  
  return (
    <Dialog
      className="dialog"
      open={isOpen}
      onClose={closeModal}>
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validate={validate}
        onSubmit={data=>{ saveChanges(data); closeModal(); }}>
        <Form>
          <DialogTitle>Edit prices and sharing of video</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Should only do it on customer request!
            </DialogContentText>
            <div>
              <Field className="numericField"
                     label="New units"
                     name="cost"
                     variant="outlined"
                     type="number"
                     component={TextField}/>
            </div>
            <div>
              <Field className="numericField"
                     label="New recaster %"
                     name="recasterPct"
                     variant="outlined"
                     type="number"
                     component={TextField} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default VideoEditPricesSharingModal;
