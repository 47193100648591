import React from 'react';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { connect } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from '@mui/material';
import { userCloseInvitePartnerModalAction } from '../store/UsersActionsActions';
import { copyToClipboard } from '../../../app/utils/copyManager';
import FileCopyIcon from '@mui/icons-material/FileCopy';

interface StateProps {
  isOpen: boolean;
  url: string;
}

interface DispatchProps {
  closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const InfoInvitationModal = (props: Props) => {
  const { isOpen, closeModal, url } = props;

  return (
      <Dialog
          open={isOpen}
          onClose={closeModal}
          fullWidth={true}
          maxWidth={'lg'}
          disableEnforceFocus={true}
      >
        <DialogTitle>New invitation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This is a newly created invitation link. <br/>
            Invitation link: {url}
					<IconButton onClick={() => copyToClipboard(url)} size="large">
              <FileCopyIcon/>
            </IconButton>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
				<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.users.actions.invitePartnerModal.isOpen,
  url: state.users.actions.invitePartnerModal.url,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  closeModal: () => {
    dispatch(userCloseInvitePartnerModalAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoInvitationModal);
