import React from 'react';
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { RootState } from "../../store/store";
import { connect } from "react-redux";
import LoggedInUserMenuOptions from "./LoggedInUserMenuOptions";
import logo from "Recast_Portrait_Teal_RGB.svg";

interface StateProps {
  isAuthenticate: boolean;
}

type Props = StateProps;

const MenuTop = (props: Props) => {
  const { isAuthenticate } = props;

  return (
    <AppBar className="menu-top" position="static">
      <Toolbar disableGutters={true} variant="dense">
				<IconButton edge="start" className="menuButton" color="inherit" aria-label="menu" size="large" />
          <img src={logo} alt="recast logo" width="30vw"/>
        {
          isAuthenticate ? <LoggedInUserMenuOptions /> : <></>
        }
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticate: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {})(MenuTop);
