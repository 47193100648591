import { AppThunk, AppThunkDispatch, RootState } from '../app/store/store';
import { makeRequest } from '../app/http/request';
import { createSuccessResult } from '../app/utils/result';
import { Currency } from '../app/domain/Currency';
import { currenciesSetCurrenciesAction } from './currencies/list/store/CurrentListActions';
import { GET_PAYMENT } from '../app/http/paymentApi/paymentClient';
import { CurrencyCountries } from '../app/domain/CurrencyCountries';
import { getSupportedCountriesApi } from '../app/store/appApi/me';
import {
    currenciesSetAllSelectedCodeAction,
    currenciesSetCurrencyCountriesAction,
    currenciesSetSupportedCountriesAction,
    currencyCountriesOpenEditModalAction,
} from './currencyCountries/actions/store/CurrencyCountriesActions';
import { currenciesSetCurrencyCountriesActionList } from './currencyCountries/list/store/CurrencyCountriesListActions';

export interface CurrenciesData {
    currencyRates: Currency[]
}

export interface CurrencyCountriesData {
    currencyCountries: CurrencyCountries[]
}

export const fetchCurrenciesApi = async (dispatch: AppThunkDispatch) => {
    return makeRequest( {
        dispatch,
        request: async () => {
            const result = await GET_PAYMENT<CurrenciesData>( `/admin/currency/rates` );
            return result.data.data;
        },
        responseSuccessHandler: (data: CurrenciesData) => createSuccessResult( data ),
    } );
};

export const fetchCurrencyCountriesApi = async (dispatch: AppThunkDispatch) => {
    return makeRequest( {
        dispatch,
        request: async () => {
            const result = await GET_PAYMENT<CurrencyCountriesData>( `/admin/currency/countries` );
            return result.data.data;
        },
        responseSuccessHandler: (data: CurrencyCountriesData) => createSuccessResult( data ),
    } );
};

export function fetchCurrenciesUseCase(): AppThunk {
    return async (dispatch) => {
        const result = await fetchCurrenciesApi( dispatch );

        if (result.value && !result.error) {
            const convertedCurrency = result.value.currencyRates.map( currency => {
                return {...currency, rate : currency.rate/1000000} as Currency
            })
            dispatch( currenciesSetCurrenciesAction( convertedCurrency ) );
        }
    };
}

export function fetchCurrencyCountriesUseCase(): AppThunk {
    return async (dispatch) => {
        const result = await fetchCurrencyCountriesApi( dispatch );

        if (result.value && !result.error) {
            dispatch( currenciesSetCurrencyCountriesActionList( result.value.currencyCountries ) );
            const allCheckedCode: string[] = [];
            result.value.currencyCountries.map( country => country.countryCodes.map( code => allCheckedCode.push( code ) ) );
            dispatch( currenciesSetAllSelectedCodeAction( allCheckedCode ) );
        }
    };
}

export function fetchSupportedCurrenciesUseCase(): AppThunk {
    return async (dispatch) => {
        const result = await getSupportedCountriesApi();
        dispatch( currenciesSetSupportedCountriesAction( result ) );
    };
}

export function editCurrencyCountriesUseCase(): AppThunk {
    return ( (dispatch, getState: () => RootState) => {
        const { currencyCountries } = getState().currency.currencyCountries.list;
        dispatch( currenciesSetCurrencyCountriesAction( currencyCountries ) );
        dispatch( currencyCountriesOpenEditModalAction() );
    } );
}
