import devConfig from './dev/devConfig';
import localConfig from './local/localConfig';
import prodConfig from './prod/prodConfig';

export interface AppConfig {
	API_BASE: string;
	CUBEJS_BASE: string;
	EMBEDDED_BASE: string;
	FAN_APP_BASE: string;
	SHARE_BASE: string;
}

export interface ConfigFromEnvVariables {
	ENDPOINTS_API: string;
	FALLBACK_API_BASE: string;
	FALLBACK_CUBEJS_BASE: string;
	FALLBACK_FAN_APP_BASE: string;
	FALLBACK_SHARE_BASE: string;
	FALLBACK_EMBEDDED_BASE: string;
	FLAGSMITH_ENVIRONMENT_ID: string;
	FLAGSMITH_POLLING_INTERVAL_MS: number;
	EMBEDDED_WIDGET_SCRIPT_URL: string;
	RTMP_BASE_URL: string;
}

export type AppConfigWithFallback = AppConfig & ConfigFromEnvVariables;

export const isLocal = process.env.NODE_ENV === 'development';

enum Environments {
	LOCAL = 'LOCAL',
	PRODUCTION = 'PRODUCTION'
}

export const currEnv: Environments = isLocal ? Environments.LOCAL : Environments.PRODUCTION;

const CONFIG_FROM_API_KEY = 'configFromApi';

const getConfigFromEnvironmentalVariables = (env: Environments = currEnv): ConfigFromEnvVariables => {
	let configFromEnvVariables = devConfig;

	if (env === Environments.PRODUCTION) {
		configFromEnvVariables = prodConfig;
	}

	if (process.env.REACT_APP_USE_LOCAL) {
		configFromEnvVariables = localConfig;
	}

	return configFromEnvVariables;
};

const getFallbackConfig = (): AppConfig => {
	const config = getConfigFromEnvironmentalVariables();
	return {
		API_BASE: config.FALLBACK_API_BASE,
		CUBEJS_BASE: config.FALLBACK_CUBEJS_BASE,
		FAN_APP_BASE: config.FALLBACK_FAN_APP_BASE,
		EMBEDDED_BASE: config.FALLBACK_EMBEDDED_BASE,
		SHARE_BASE: config.FALLBACK_SHARE_BASE
	};
};

export const getAppConfig = (): AppConfig => {
	const config = sessionStorage.getItem(CONFIG_FROM_API_KEY);
	return config ? JSON.parse(config) : getFallbackConfig();
};

export const saveAppConfigFromApi = (config: AppConfig) =>
	sessionStorage.setItem(CONFIG_FROM_API_KEY, JSON.stringify(config));

export const getConfig = (env: Environments = currEnv): AppConfigWithFallback => {
	return { ...getConfigFromEnvironmentalVariables(env), ...getAppConfig() };
};

export default getConfig(currEnv);
