import React from 'react';
import { CurrencyCode } from 'accounts/apiTypes';

export function renderGBPStat(value?: number) {
	return renderStat(value, 'GBP');
}
export function renderCSTStat(value?: number) {
	return renderStat(value, 'CST');
}

function renderStat(value: number | undefined, currency: CurrencyCode) {
	return <span className="amount gbp" style={ value !== undefined && value < 0 ? { color:  "#A00" } : {}}>
		{value === undefined ? '- ' : value}
		<small style={{width: "3em", display:"inline-block"}}><em>{currency}</em></small>
	</span>;
}
