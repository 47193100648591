import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import TableSortHeader from '../../../app/components/table/TableSortHeader';
import { changeSort, Sort } from '../../../app/domain/Sort';
import { InvitationSortField } from '../../../app/domain/Invitation';
import { AppThunkDispatch, RootState } from '../../../app/store/store';
import { connect } from 'react-redux';
import { invitationSetSortAction } from '../store/InvitationsListActions';

interface StateProps {
    sort: Sort<InvitationSortField>;
}

interface DispatchProps {
    setSort: (field: InvitationSortField, sort: Sort<InvitationSortField>) => void;
}

type Props = StateProps & DispatchProps;

const InvitationsTableHeader = (props: Props) => {
    const { sort, setSort } = props;

    return (
    <TableHead>
      <TableRow>
        <TableSortHeader label="Id" fieldName="id" sort={sort} setSort={setSort} />
        <TableSortHeader label="Email" fieldName="email" sort={sort} setSort={setSort} />
        <TableCell align="left" className="header">Status</TableCell>
        <TableSortHeader label="Created" fieldName="created" sort={sort} setSort={setSort} />
        <TableCell align="left" className="header">Admin id</TableCell>
        <TableCell align="left" className="header">Actions</TableCell>
      </TableRow>
    </TableHead>
  )
};

const mapStateToProps = (state: RootState) => ({
    sort: state.invitations.list.filters.sort,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    setSort: (field: InvitationSortField, sort: Sort<InvitationSortField>) => {
        const newSort = changeSort(field, sort);
        dispatch(invitationSetSortAction(newSort));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsTableHeader);

