import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { AppThunkDispatch, RootState } from "../../../app/store/store";
import { connect } from "react-redux";
import { suspendVideoUseCase } from "./SuspendVideoUseCase";
import { videoCloseSuspendModalAction } from "../store/VideoActionsActions";

interface StateProps {
  videoId: string;
  isOpen: boolean;
}

interface DispatchProps {
  suspendVideo: (videoId: string, reason?: string) => void;
  closeModal: () => void;
}

type Props = StateProps & DispatchProps;

const SuspendVideoModal = (props: Props) => {
  const { videoId, isOpen, closeModal, suspendVideo } = props;
  const [reason, onReasonChange] = useState('');
  const handleSuspend = () => {
    suspendVideo(videoId, reason);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will suspend the item. It will no longer be visible publicly.
        </DialogContentText>
        <TextField fullWidth label="Reason (optional)" variant="outlined" value={reason} onChange={e => onReasonChange(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Disagree
        </Button>
        <Button onClick={handleSuspend} color="primary" autoFocus>
          Suspend
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.videos.actions.suspendModal.isOpen,
  videoId: state.videos.actions.suspendModal.videoId,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  suspendVideo: (videoId: string, reason?: string) => {
    dispatch(suspendVideoUseCase(videoId, reason));
  },
  closeModal: () => {
    dispatch(videoCloseSuspendModalAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendVideoModal);
