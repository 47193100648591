import React, { ReactNode } from 'react';

interface CustomisedConfigProps {
	value: ReactNode;
	customisedText?: ReactNode;
}

const CustomisableConfig = (props: CustomisedConfigProps) => {
	const { value, customisedText } = props;
	return (
		<>
			<span>{value}</span>
			{customisedText && <span className="customisedConfigText">{customisedText}</span>}
		</>
	);
};
export default CustomisableConfig;
