import React from 'react';

interface Props {
	description: string;
	value: string;
	isAvailable?: boolean;
	detailsLabel?: string;
	details?: { name: string; value: string }[];
}

const ReportValuePanel = (props: Props) => {
	const { description, value, isAvailable = true, detailsLabel, details } = props;

	return (
		<div className="panel">
			<div className="description">{description}</div>
			{isAvailable ? (
				<>
					<div className="value-panel">{value || 0}</div>
					{detailsLabel ? <div className="details-label">{detailsLabel}</div> : null}
					{details ? (
						<div className="details">
							{details.map(({ name, value }) => (
								<div className="details-item" key={name}>
									<div className="details-item-name">{name}</div>
									<div className="details-item-spacer" />
									<div className="details-item-value">{value}</div>
								</div>
							))}
						</div>
					) : null}
				</>
			) : (
				<div className="description">Not applicable to current filter</div>
			)}
		</div>
	);
};

export default ReportValuePanel;
