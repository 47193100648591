import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { VideoFeatureFormData } from './VideoFeatureUseCase';
import { TextField } from 'formik-material-ui';
import dayjs from 'dayjs';

interface InputProps {
	title: string;
	saveButtonText: string;
	isOpen: boolean;
	initialValues: VideoFeatureFormData;
	closeModal: () => void;
	submitFeature: (data: VideoFeatureFormData) => void;
}

function parseToInputDateFormat(input: string): string {
	return input && dayjs(input).format('YYYY-MM-DDTHH:mm:ss');
}

function parseFromInputDateFormat(input: string): string {
	return input && dayjs(input).format('YYYY-MM-DD HH:mm:ss');
}

const VideoFeatureFormModal = (props: InputProps) => {
	const { title, saveButtonText } = props;
	const { isOpen, initialValues, closeModal, submitFeature } = props;
	const [isStartDateRemoveBlocked, setIsStartDateRemoveBlocked] = useState(initialValues.startDate !== '');
	const [isEndDateRemoveBlocked, setIsEndDateRemoveBlocked] = useState(initialValues.endDate !== '');
	const [hasStartDate, setHasStartDate] = useState(initialValues.startDate !== '');
	const [hasEndDate, setHasEndDate] = useState(initialValues.endDate !== '');

	useEffect(() => {
		setIsStartDateRemoveBlocked(initialValues.startDate !== '');
		setIsEndDateRemoveBlocked(initialValues.endDate !== '');
		setHasStartDate(initialValues.startDate !== '');
		setHasEndDate(initialValues.endDate !== '');
	}, [initialValues]);

	initialValues.startDate = parseToInputDateFormat(initialValues.startDate);
	initialValues.endDate = parseToInputDateFormat(initialValues.endDate);

	const handleHasStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHasStartDate(event.target.checked);
	};

	const handleHasEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHasEndDate(event.target.checked);
	};

	return (
		<Dialog className="dialog" fullWidth maxWidth="sm" open={isOpen} onClose={closeModal}>
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				onSubmit={(values: VideoFeatureFormData) => {
					const data = {
						...values,
						startDate: hasStartDate ? parseFromInputDateFormat(values.startDate) : '',
						endDate: hasEndDate ? parseFromInputDateFormat(values.endDate) : ''
					};
					submitFeature(data);
				}}
			>
				<Form>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<Field
							className="formField"
							label="Priority"
							type="number"
							fullWidth
							name="priority"
							variant="outlined"
							component={TextField}
							required
							InputProps={{ inputProps: { min: 1, max: 100 } }}
						/>
						<Grid component="label" container alignItems="center" spacing={1}>
							<Grid item>Start immediately</Grid>
							<Grid item>
								<Switch
									disabled={isStartDateRemoveBlocked}
									checked={hasStartDate}
									onChange={handleHasStartDateChange}
								/>
							</Grid>
							<Grid item>Start in the future</Grid>
						</Grid>
						<Field
							className="formField"
							label="Start date"
							type="datetime-local"
							fullWidth
							name="startDate"
							variant="outlined"
							component={TextField}
							disabled={!hasStartDate}
							InputLabelProps={{ shrink: true }}
						/>

						<Grid component="label" container alignItems="center" spacing={1}>
							<Grid item>Without end date</Grid>
							<Grid item>
								<Switch
									disabled={isEndDateRemoveBlocked}
									checked={hasEndDate}
									onChange={handleHasEndDateChange}
								/>
							</Grid>
							<Grid item>Set end date</Grid>
						</Grid>
						<Field
							className="formField"
							label="End Date"
							type="datetime-local"
							fullWidth
							name="endDate"
							variant="outlined"
							component={TextField}
							disabled={!hasEndDate}
							InputLabelProps={{ shrink: true }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={closeModal} color="primary">
							Close
						</Button>
						<Button type="submit" color="primary" autoFocus>
							{saveButtonText}
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
};

export default VideoFeatureFormModal;
