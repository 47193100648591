import { useLocalQuery } from 'app/utils/searchManager';
import { GET, PUT } from 'app/http/adminApi/adminClient';
import React, { useState } from 'react';
import Title from 'app/components/title/Title';
import { ChannelDetails } from 'app/domain/ChannelDetails';
import { Divider, Grid } from '@mui/material';
import ContributorsSplitSection from 'contributors/details/ContributorsSplitSection';
import { Contributor } from 'app/domain/Contributor';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';
import { editChannelDefaultContributorsApi } from '../summary/ChannelDetailsSummaryUseCase';
import { DeepPartial } from 'redux';
import { useFeatureFlagsValue } from 'app/hooks/useFeatureFlagsValue';
import { FEATURE_FLAGS } from 'app/featureFlags/featureFlags';
import FansDataToggleSection from './FansDataToggleSection';
import PackageSection from './PackageSection';
import { isEqual } from 'lodash';
import { VideoFunctionalitySection } from './VideoFunctionalitySection';

interface ChannelConfigProps {
	uid: string;
	channel: ChannelDetails;
	refreshChannel: () => void;
}

export interface ResolutionDetails {
	minimumPricing: {
		onDemand: number;
		liveEvents: number;
	};
	recastPercentage: number;
}

export interface ChannelConfig {
	dataProfile: {
		isEnabled: boolean;
	};
	anonymisedData: {
		isEnabled: boolean;
	};
	drm: {
		isEnabled: boolean;
	};
	streamKeys: {
		max: number;
	};
	resolutions: {
		720: ResolutionDetails;
		1080: ResolutionDetails & { isEnabled: boolean };
	};
	channelPasses: {
		max: number;
	};
	globalOverride?: {
		disableVideo: boolean;
	};
}

export enum ChannelPackage {
	FREE = 'FREE',
	LITE = 'LITE',
	PRO = 'PRO',
	PROMOTE = 'PROMOTE',
	AFFILIATE_ONLY = 'AFFILIATE_ONLY'
}

const ChannelConfigScreen = ({ channel, uid, refreshChannel }: ChannelConfigProps) => {
	const [configResponse, setConfigResponse] = useState<{
		config: ChannelConfig;
		packageType: ChannelPackage;
		baseConfig: ChannelConfig;
	} | null>(null);
	const [isDataDownloadEnabled, isChannelPackageEnabled] = useFeatureFlagsValue([
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_DATA_DOWNLOAD,
		FEATURE_FLAGS.ENABLE_CHANNEL_CONFIG_TIER_SELECTION
	]);

	useLocalQuery(async () => {
		const result = await GET(`/api/admin/channel/${uid}/config`);
		setConfigResponse(result.data.item);
	});

	const saveConfiguration = async (overrides: DeepPartial<ChannelConfig>) => {
		const requestBody = { overrides };
		const result = await PUT(`/api/admin/channel/${uid}/config`, requestBody);
		setConfigResponse(result.data.item);
	};

	const updatePackage = async (packageType: ChannelPackage) => {
		const requestBody = { packageType };
		const result = await PUT(`/api/admin/channel/${uid}/package`, requestBody);
		setConfigResponse(result.data.item);
	};

	const saveChannelDefaultContributors = (contributors: Contributor[]) => {
		editChannelDefaultContributorsApi(uid, contributors).then(refreshChannel);
	};

	const isFansDataToggleEnabled = isDataDownloadEnabled; //Will include anonymised data

	if (!configResponse) {
		return <LoadingOverlay />;
	} else {
		const { config, packageType, baseConfig } = configResponse;
		const isPackageCustomised = !isEqual(config, baseConfig);
		const isVideoDisabled = baseConfig.globalOverride?.disableVideo || false;

		return (
			<div className="channelConfigScreen">
				<Title>Config</Title>
				<Divider />
				<div className="miscTitle">PLAN RELATED</div>
				<Grid item xs={12}>
					{isChannelPackageEnabled && packageType && (
						<PackageSection
							packageType={packageType}
							updatePackage={updatePackage}
							isCustomised={isPackageCustomised}
						/>
					)}
					{isFansDataToggleEnabled && (
						<FansDataToggleSection
							isDataProfileEnabled={config.dataProfile.isEnabled}
							isAnonymisedDataEnabled={config.anonymisedData?.isEnabled}
							saveConfiguration={saveConfiguration}
							packageDefault={{
								dataProfile: baseConfig.dataProfile.isEnabled,
								anonymisedData: baseConfig.anonymisedData.isEnabled
							}}
						/>
					)}
					<Divider className="miscDivider" />
					<VideoFunctionalitySection
						uid={uid}
						config={config}
						baseConfig={baseConfig}
						isDisabled={isVideoDisabled}
						saveConfiguration={saveConfiguration}
					/>

					<Divider className="miscDivider" />
					<div className={`miscTitle ${isVideoDisabled ? 'miscTitle--disabled' : ''}`}>Miscellaneous</div>
					<ContributorsSplitSection
						title="Earnings Split"
						contributors={channel.defaultContributors}
						onSave={saveChannelDefaultContributors}
						isDisabled={isVideoDisabled}
					/>
				</Grid>
			</div>
		);
	}
};

export default ChannelConfigScreen;
