import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import { editCurrenciesUseCase } from './EditCurrenciesUseCase';
import { Button, Container, Dialog, DialogActions, Grid, IconButton, Table, TableBody } from '@mui/material';
import { currenciesCloseEditModalAction } from '../store/CurrenciesActionsActions';
import { Currency } from '../../../../app/domain/Currency';
import EditCurrentRowModal from './EditCurrentRowModal';
import EditCurrentTableHeader from './EditCurrentTableHeader';
import EditCurrentModal from './EditCurrentModal';
import Title from '../../../../app/components/title/Title';
import AddIcon from '@mui/icons-material/Add';

export enum TypeCurrentModal {
    Add = 'ADD',
    Edit = 'EDIT',
    Closed = 'CLOSED',
}

interface StateProps {
    currencies: Currency[];
    isOpen: boolean;
}

interface DispatchProps {
    closeModal: () => void;
    saveCurrencies: (currencies: Currency[]) => void;
}

type Props = StateProps & DispatchProps;


const EditCurrenciesModal = (props: Props) => {
    const { currencies, closeModal, isOpen, saveCurrencies } = props;

    const [selectedCurrencies, setCurrencies] = useState( [] as Currency[] );
    const [typeCurrentModal, setTypeCurrentModal] = useState( TypeCurrentModal.Closed );
    const [editCurrent, setEditCurrent] = useState( {} as Currency );

    useEffect( () => {
        setCurrencies( currencies );
    }, [currencies] );

    function onRevoke(code: string) {
        setCurrencies( selectedCurrencies.filter( current => current.currencyCode !== code ) );
    }

    function onSave(current: Currency, oldCurrentCode: string) {
        setTypeCurrentModal( TypeCurrentModal.Closed );
        const newCurrencies = selectedCurrencies.filter( current => current.currencyCode !== oldCurrentCode );
        setCurrencies( [...newCurrencies, current] );
    }

    function onEdit(current: Currency) {
        setEditCurrent( current );
        setTypeCurrentModal( TypeCurrentModal.Edit );
    }

    function onAdd() {
        setEditCurrent( {} as Currency );
        setTypeCurrentModal( TypeCurrentModal.Add );
    }

    function closeCurrentModal() {
        setTypeCurrentModal( TypeCurrentModal.Closed );
    }

    function onSaveCurrencies() {
        saveCurrencies(selectedCurrencies);
    }

    return (
        <Dialog
            className="dialog"
            open={isOpen}
            onClose={closeModal}
            fullWidth={true}
            maxWidth={'md'}
        >

            <Container className="videosScreen">
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <Title>Edit currencies</Title>
                    </Grid>
                    <Grid item xs={1}>
						<IconButton onClick={() => onAdd()} size="large">
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </Grid>

                <Table className="table" size="medium" padding="checkbox" stickyHeader style={{ padding: '3em' }}>
                    <EditCurrentTableHeader/>
                    <TableBody>
                        {selectedCurrencies.map( (current) => (
                          <EditCurrentRowModal key={current.currencyCode}
                                               current={current} revoke={(code: string) => onRevoke( code )}
                                               edit={(current: Currency) => onEdit( current )}/>
                        ))}
                    </TableBody>
                </Table>

                <EditCurrentModal closeModal={closeCurrentModal}
                                  typeModal={typeCurrentModal}
                                  currency={editCurrent}
                                  saveCurrent={onSave}
                                  currentCodes={selectedCurrencies.map(current => current.currencyCode)}
                />

                <DialogActions className={`dialogActions`}>
					<Button variant="contained" onClick={closeModal} className={`button-cancel`}>
                        Cancel
                    </Button>
                    <Button onClick={onSaveCurrencies} variant="contained" color="secondary" className={`button-yes`}>
                        Save
                    </Button>
                </DialogActions>
            </Container>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ( {
    isOpen: state.currency.currencies.actions.editModal.isOpen,
    currencies: state.currency.currencies.list.processedCurrencies,
} );

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    saveCurrencies: (currencies: Currency[]) => {
        dispatch( editCurrenciesUseCase(currencies) );
    },
    closeModal: () => {
        dispatch( currenciesCloseEditModalAction() );
    },
} );

export default connect( mapStateToProps, mapDispatchToProps )( EditCurrenciesModal );
