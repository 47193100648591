import { Page } from 'app/domain/Page';
import { VideoSortField, VideoStatus } from 'app/domain/Video';
import { PageResponse } from 'app/http/PageResponse';
import { VideoMetadata } from 'app/domain/VideoMetadata';
import { GET_LISTINGS } from 'app/http/listingsApi/listingsClient';
import { SortDirection } from 'app/domain/Sort';
import { fetchVideosPurchaseData } from 'app/stats/statsManager';

export interface FetchVideoData {
	page: number;
	statusIds?: VideoStatus[];
	searchText?: string;
	searchUid?: string;
	userId?: string;
	startDate?: string;
	endDate?: string;
	isLive?: boolean;
	hasFeatureRules?: boolean;
	sort?: SortDirection;
	order?: VideoSortField;
}

const mapToPage = (data: PageResponse<VideoMetadata>) => {
	const { items, _meta: { pageCount, totalCount, perPage, currentPage } } = data;
	return {
		pageCount, items, totalCount, perPage, currPage: currentPage
	} as Page<VideoMetadata>;
  };
  

const createApiParams = (data: FetchVideoData) => {
	const { page, searchText, searchUid, statusIds, startDate, endDate, isLive, sort, order, hasFeatureRules } = data;
	return { page, nameSearchText: searchText, videoUidSearchText: searchUid, statusIds, userUid: data.userId,  startCreatedDate: startDate, endCreatedDate: endDate, isLive, sort, order, hasFeatureRules};
};

/** Just make the call and map the response. Use this in preference to the state */
export const doFetchVideoList = async (data: FetchVideoData) => {
	const params = createApiParams(data);
	const result = await GET_LISTINGS<PageResponse<VideoMetadata>>(`/admin/videos`, { params }).then(r=>r.data.data);
	const stats = await fetchVideosPurchaseData(result.items.map(video => video.uid));

	result.items = result.items.map(video => {
		const statsRow = stats.find(videoStat => videoStat.uid === video.uid);
		return !statsRow ? video : { ...video, sales: statsRow.purchases, spend: statsRow.paid }
	});
	return { ...mapToPage(result) };
};
