import React, { useRef } from 'react';
import { FieldAttributes, useField } from 'formik';
import { TextField, OutlinedTextFieldProps } from '@mui/material';

interface CustomTextFieldProps extends Partial<OutlinedTextFieldProps> {
	label?: string;
	inputPropsNative?: any;
	isPercentageType?: boolean;
	isAutofocus?: boolean;
	inputRef?: React.Ref<any>;
	autoComplete?: string;
	onlyIntegerNumber?: boolean;
	onErrorChange?: (errorMessage: string) => void;
}

const CustomTextField: React.FC<FieldAttributes<CustomTextFieldProps>> = props => {
	const [field, meta] = useField(props);

	const getErrorMessage = () => {
		return meta.error && meta.touched ? meta.error : '';
	};

	const lastErrorMessage = useRef(getErrorMessage());

	const {
		label,
		type,
		InputProps,
		inputPropsNative,
		placeholder,
		isPercentageType,
		multiline,
		inputRef,
		isAutofocus,
		onFocus,
		autoComplete,
		onlyIntegerNumber,
		onErrorChange
	} = props;

	const errorMessage = getErrorMessage();
	if (lastErrorMessage.current !== errorMessage) {
		lastErrorMessage.current = errorMessage;
		onErrorChange?.(errorMessage);
	}

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		props.onChange?.(e);
		field.onChange(e);
	};

	const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
		props.onBlur?.(e);
		field.onBlur(e);
	};

	const forceIntegerNumber = (e: any) => {
		if (e.key === '.' || e.key === ',' || e.key === 'e') {
			e.preventDefault();
		}
	};

	return (
		<TextField
			className={`formField `}
			inputRef={inputRef}
			fullWidth
			helperText={lastErrorMessage.current}
			error={!!lastErrorMessage.current}
			label={label}
			type={type}
			placeholder={placeholder}
			InputProps={InputProps}
			inputProps={inputPropsNative}
			name={field.name}
			onBlur={onBlur}
			autoComplete={autoComplete}
			onFocus={onFocus}
			value={isPercentageType ? field.value + `%` : field.value}
			onChange={onChange}
			multiline={multiline}
			autoFocus={isAutofocus}
			onKeyDown={onlyIntegerNumber ? forceIntegerNumber : undefined}
		/>
	);
};

CustomTextField.defaultProps = {
	className: ''
};

export default CustomTextField;
