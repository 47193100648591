import React from 'react';
import { Container } from '@mui/material';
import Title from 'app/components/title/Title';
import PassesTable from './list/passesTable';

interface Props {
	channelUid: string;
}

const ChannelPassesScreen = (props: Props) => {
	const { channelUid } = props;

	return (
		<Container className="passesScreen">
			<Title>Channel Passes</Title>
			<PassesTable channelUid={channelUid} />
		</Container>
	);
};

export default ChannelPassesScreen;
