import { NO_TIER_ID } from 'partners/actions/edit/editTier/EditPartnerTierModal';
import { EditPartnerTier } from 'partners/actions/edit/editTier/EditPartnerTierUseCase';

export interface Tier {
	socialMediaTier: SocialMediaTier | null;
	contentProportionTier: ContentProportionTier | null;
}

export interface ContentProportionTier {
	id: number;
	tier: string;
	live: number;
	onDemand: number;
}

export interface SocialMediaTier {
	id: number;
	tier: string;
	lowerThreshold: number | null;
	upperThreshold: number | null;
}

export const isContentProportionSet = (values: EditPartnerTier) => {
	return values.contentProportionTierId !== NO_TIER_ID;
}

export const isSocialMediaSet = (values: EditPartnerTier) => {
	return values.socialMediaTierId !== NO_TIER_ID;
}
