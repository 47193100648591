import { createEmptyInvitation, Invitation } from '../../../app/domain/Invitation';
import { InvitationDetailsStateTypes } from './InvitationDetailsActions';

export interface InvitationDetailsState {
  invitation: Invitation;
}

const initialState: InvitationDetailsState = {
  invitation: createEmptyInvitation(),
};

export function invitationDetailsReducer(state = initialState, action: InvitationDetailsStateTypes): InvitationDetailsState {
  if (action.type === "INVITATION_SET_DETAILS") {
    return { ...state, invitation: action.invitation };
  } else {
    return state;
  }
}
