import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/store';
import { suspendUserUseCase } from './SuspendUserUseCase';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { userCloseSuspendModalAction } from 'users/actions/store/UsersActionsActions';
import { Status } from 'app/domain/User';
import { useAppDispatch } from 'app/store/hooks';

const SuspendUserModal = () => {
	const dispatch = useAppDispatch();
	const { uid, isOpen, statusId } = useSelector((state: RootState) => state.users.actions.suspendModal);
	const [reason, onReasonChange] = useState('');
	const isSuspended = statusId === Status.SUSPENDED;

	const closeModal = () => dispatch(userCloseSuspendModalAction());
	const suspendUser = (uid: string, reason: string) => dispatch(suspendUserUseCase(uid, { suspend: !isSuspended, reason }));

	return (
		<Dialog className="" open={isOpen} onClose={closeModal} maxWidth="md">
			<DialogTitle>Do you really want to {isSuspended ? 'unsuspend' : 'suspend'} the user?</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					label="Reason"
					variant="outlined"
					value={reason}
					onChange={e => onReasonChange(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeModal} color="primary">
					Disagree
				</Button>
				<Button onClick={() => suspendUser(uid, reason)} color="primary">
					{isSuspended ? 'Unsuspend' : 'Suspend'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SuspendUserModal;
