import { Partner } from 'app/domain/Partner';
import { ContentProportionTier, isContentProportionSet, isSocialMediaSet, SocialMediaTier } from 'app/domain/Tier';
import { GET_PARTNER, PUT_PARTNER } from 'app/http/partnerApi/partnerClient';

export interface EditPartnerTier {
	socialMediaTierId?: number | null;
	contentProportionTierId?: number | null;
}

export const fetchPartnerSocialTiersApi = async () =>
	GET_PARTNER('/admin/tier/social-media')
		.then(r=>r.data.data)
		.then(r=>r instanceof Array ? r as SocialMediaTier[] : undefined);

export const fetchPartnerContentProportionTiersApi = async () => {
	const resp = await GET_PARTNER('/admin/tier/content-proportion');
	return resp.data.data instanceof Array ? (resp.data.data as ContentProportionTier[]) : undefined;
};

export const createPartnerEditTierData = (partner: Partner) =>
	({
		socialMediaTierId: partner.socialMediaTierId ?? 0,
		contentProportionTierId: partner.contentProportionTierId ?? 0
	} as EditPartnerTier);

function prepareEditData(tier: EditPartnerTier) {
	return {
		socialMediaTierId: tier.socialMediaTierId === 0 || null ? null : tier.socialMediaTierId,
		contentProportionTierId: tier.contentProportionTierId === 0 || null ? null : tier.contentProportionTierId
	} as EditPartnerTier;
}

export const editPartnerTierApi = (uid: string, tier: EditPartnerTier) =>
	PUT_PARTNER<any>(`/admin/partner/${uid}/tiers`, { ...prepareEditData(tier) }).then(r=>r.data.data);

export const validateTiersData = (values: EditPartnerTier) => {
	const errors: Record<string, string> = {};

	if (isSocialMediaSet(values) && !isContentProportionSet(values)) {
		errors.contentProportionTierId = 'Content proportion tier are required';
	}

	if (!isSocialMediaSet(values) && isContentProportionSet(values)) {
		errors.socialMediaTierId = 'Social media tier are required';
	}
	return errors;
};
