import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip, TextField as MuiTextField } from '@mui/material';
import Tree from 'app/components/tree/Tree';
import DeleteCategoryModal from 'categories/actions/DeleteCategoryModal';
import {
	buildTreeFromCategories,
	filterCategoriesByName,
} from './categoriesTreeManager';
import { addCategoryUseCase, updateCategoryUseCase, useAllCategories } from 'categories/categoriesUseCase';
import AddIcon from '@mui/icons-material/Add';
import { TreeNodeData } from 'app/components/tree/treeModel';
import CategoryMovementModal from 'categories/actions/CategoryMovementConfirmationModal';
import GenericCategoryModal from 'categories/actions/GenericCategoryModal';
import { useDebouncedAction } from 'app/utils/searchManager';
import { Category } from 'app/domain/Category';

const CategoriesTree = () => {
	const [treeData, setTreeData] = useState<TreeNodeData[]>([]);
	const [search, setSearch] = useState<string>();

	const { categories: all, refresh } = useAllCategories();

	const [ showAdd, setShowAdd ] = useState(-1); 
	const [ showEdit, setShowEdit ] = useState<Category>(); 
	const [ showDelete, setShowDelete ] = useState(-1); 
	const [ showMove, setShowMove ] = useState<{id: number, newParentId: number | null}>();

	const redrawTreeData = () => {
		const filtered = search ? filterCategoriesByName(all || [], search) : all || [];
		const isTreeExpanded = !!search;
		setTreeData(buildTreeFromCategories(filtered, isTreeExpanded));
	}

	useEffect(useDebouncedAction(() => redrawTreeData(), 200), [search, all]);

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

	return <>
		<MuiTextField
			className="categoriesSearch"
			label="Search"
			variant="outlined"
			onChange={onSearchChange}
		/>
		<div className="actions categoriesActions">
			<Tooltip title="Add" arrow>
					<IconButton onClick={() => setShowAdd(0)} className="button button-green" size="large">
						<AddIcon />
					</IconButton>
			</Tooltip>
		</div>
		<div className="categoriesTree">
				{search && treeData.length === 0 && <span>Category Doesn`&apos;t Exist</span>}
			{treeData && treeData.length > 0 &&
				<Tree
					className="categoriesTree"
					data={treeData}
					onAddNode={v=>setShowAdd(v || 0)}
					onEditNode={(id)=>setShowEdit(all?.find(c=>c.id===id))}
					onDeleteNode={setShowDelete}
					onMoveNode={(id, newParentId)=>setShowMove({id, newParentId})}
				/>
			}
		</div>
		<GenericCategoryModal
			onSubmit={(values)=>addCategoryUseCase(values).then(refresh) }
			isOpen={showAdd>=0}
			onClose={()=>setShowAdd(-1)}
			title="Add category"
			initialValues={{ parentId: showAdd }}
		/>
		{showEdit && <GenericCategoryModal
			onSubmit={values => showEdit && updateCategoryUseCase(showEdit?.id, values).then(refresh)}
			isOpen={!!showEdit}
			onClose={()=>setShowEdit(undefined)}
			title="Edit category"
			initialValues={showEdit}
			id={showEdit?.id}
		/>}
		
		<DeleteCategoryModal isOpen={showDelete>=0} categoryId={showDelete} closeModal={()=>setShowDelete(-1)} />
		<CategoryMovementModal 
			isOpen={!!showMove} 
			categoryId={showMove?.id || 0} 
			newParentId={showMove?.newParentId || 0} 
			closeModal={()=>setShowMove(undefined)}
		/>
	</>
};

export default CategoriesTree;
