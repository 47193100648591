export enum CashOutsActionType {
	OPEN_UPLOAD_MODAL = 'OPEN_UPLOAD_MODAL',
	CLOSE_UPLOAD_MODAL = 'CLOSE_UPLOAD_MODAL',
}

interface OpenUploadModal {
	type: CashOutsActionType.OPEN_UPLOAD_MODAL;
}

interface CloseUploadModal {
	type: CashOutsActionType.CLOSE_UPLOAD_MODAL;
}

export type CashOutsActionTypes =
	| OpenUploadModal
	| CloseUploadModal

export function openUploadModal(): CashOutsActionTypes {
	return {
		type: CashOutsActionType.OPEN_UPLOAD_MODAL,
	};
}

export function closeUploadModal(): CashOutsActionTypes {
	return {
		type: CashOutsActionType.CLOSE_UPLOAD_MODAL,
	};
}
