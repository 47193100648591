import React, { useState } from 'react';
import { Container, Grid, IconButton, Paper, Tab } from '@mui/material';
import Title from '../app/components/title/Title';
import CurrencyTable from './currencies/list/table/CurrencyTable';
import EditIcon from '@mui/icons-material/Edit';
import { AppThunkDispatch } from '../app/store/store';
import { connect } from 'react-redux';
import { currenciesOpenEditModalAction } from './currencies/actions/store/CurrenciesActionsActions';
import EditCurrenciesModal from './currencies/actions/edit/EditCurrenciesModal';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CurrencyCountriesTable from './currencyCountries/list/table/CurrencyCountriesTable';
import { editCurrencyCountriesUseCase } from './CurrencyUseCase';
import EditCurrencyCountriesModal from './currencyCountries/actions/edit/EditCurrencyCountriesModal';

enum TabValue {
    CURRENCIES_TAB = 'currencies',
    COUNTRIES_TAB = 'countries',
}

interface DispatchProps {
    openEditRate: () => void;
    editCurrencyCountries: () => void;
}

type Props = DispatchProps;

const CurrencyScreen = (props: Props) => {
	const { editCurrencyCountries } = props;
    const [value, setValue] = useState( TabValue.CURRENCIES_TAB );


	const handleChange = (_: React.ChangeEvent<unknown>, newValue: TabValue) => {
        setValue( newValue );
    };

    return (
        <Container className="userDetailsScreen">
            <Paper>
                <TabContext value={value}>
                    <TabList
                        centered
                        className="tabs"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}>
                        <Tab label="Currencies" value={TabValue.CURRENCIES_TAB}/>
                        <Tab label="Location" value={TabValue.COUNTRIES_TAB}/>
                    </TabList>
                    <TabPanel value={TabValue.CURRENCIES_TAB}>
                        <Container className="videosScreen">
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Title>Currencies</Title>
                                </Grid>
                            </Grid>
                            <CurrencyTable/>
                            <EditCurrenciesModal/>
                        </Container>
                    </TabPanel>
                    <TabPanel value={TabValue.COUNTRIES_TAB}>
                        <Container className="videosScreen">
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Title>Currencies with countries</Title>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={editCurrencyCountries} size="large">
                                        <EditIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <CurrencyCountriesTable/>
                            <EditCurrencyCountriesModal />
                        </Container>
                    </TabPanel>
                </TabContext>
            </Paper>
        </Container>
    );
};
const mapDispatchToProps = (dispatch: AppThunkDispatch) => ( {
    openEditRate: () => {
        dispatch( currenciesOpenEditModalAction() );
    },
    editCurrencyCountries: () => {
        dispatch( editCurrencyCountriesUseCase() );
    },
} );

export default connect( null, mapDispatchToProps )( CurrencyScreen );

