import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'react-query';
import { GET } from 'app/http/adminApi/adminClient';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Visibility } from '@mui/icons-material';
import { useWidgetGenerator } from 'channels/details/section/config/useWidgetGenerator';

interface WidgetStyles {
	backGroundColour: string;
	accentColour: string;
	headingFontFamilyColour: string;
	videoTitleColour: string;
	metaColour: string;
	headingFontFamily: string;
	videoTitleFontFamily: string;
	metaFontFamily: string;
}

interface Widget {
	id: string;
	name: string;
	created: string;
	data: WidgetStyles;
}

const validationSchema = Yup.object({
	style: Yup.string().required()
});

const DEFAULT_WIDGET_STYLES: WidgetStyles = {
	backGroundColour: '#2f2f2f',
	accentColour: '#00e8d0',
	headingFontFamilyColour: '#ffffff',
	videoTitleColour: '#ffffff',
	metaColour: '#aaaaaa',
	headingFontFamily: 'Recast Sans',
	videoTitleFontFamily: 'Recast Sans',
	metaFontFamily: 'Recast Sans'
};

interface StyleStepProps {
	onClose: () => void;
	channelWidgets: Widget[];
	onConfirm: (widgetId: string) => void;
}

const StyleStep = ({ onClose, channelWidgets, onConfirm }: StyleStepProps) => (
	<>
		<DialogTitle>
			<strong>Set up channel widget style</strong>
		</DialogTitle>

		<DialogContent>
			<p>Choose a widget created by the Publisher to copy the style from, or use the Recast default style.</p>
			<p>Copy style from:</p>
			<Formik
				initialValues={{ style: '' }}
				onSubmit={data => onConfirm(data.style)}
				validationSchema={validationSchema}
			>
				<Form>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
						{channelWidgets
							?.toSorted((a, b) => dayjs(b.created).diff(a.created))
							.slice(0, 5)
							.map(widget => (
								<label key={widget.id}>
									<Field type="radio" value={widget.id} name="style" />
									{widget.name}
								</label>
							))}
						<label>
							<Field type="radio" value="RECAST_DEFAULT" name="style" />
							Recast default
						</label>
					</Box>
					<DialogActions>
						<Button onClick={onClose} variant="text" color="primary">
							Cancel
						</Button>
						<Button type="submit" color="secondary" variant="contained">
							Confirm & Save
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</DialogContent>
	</>
);

interface CodeStep {
	channelUid: string;
	channelWidgets: Widget[];
	widgetId: string;
	onClose: () => void;
}

const CodeStep = ({ channelUid, channelWidgets, widgetId, onClose }: CodeStep) => {
	const styles = channelWidgets.find(widget => widget.id === widgetId)?.data ?? DEFAULT_WIDGET_STYLES;
	const styleParams = new URLSearchParams({
		backGroundColour: styles.backGroundColour,
		accentColour: styles.accentColour,
		headingFontFamilyColour: styles.headingFontFamilyColour,
		videoTitleColour: styles.videoTitleColour,
		metaColour: styles.metaColour,
		headingFontFamily: styles.headingFontFamily,
		videoTitleFontFamily: styles.videoTitleFontFamily,
		metaFontFamily: styles.metaFontFamily
	});
	const path = `/channel/${channelUid}?${styleParams.toString()}`;
	const iframeId = `channel-${channelUid}`;
	const { code, src } = useWidgetGenerator(path, iframeId);

	const copyCode = async () => {
		if (window.isSecureContext) {
			try {
				await navigator.clipboard.writeText(code);
			} catch (error) {
				console.error(error);
			}
		} else {
			console.error('Cannot copy to clipboard in insecure context (HTTP)');
		}
	};

	return (
		<>
			<DialogTitle>
				<strong>Channel widget code</strong>
			</DialogTitle>
			<DialogContent>
				<Box
					component="pre"
					sx={{
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-all',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '4px',
						padding: '12px 10px'
					}}
				>
					{code}
				</Box>
				<Box>
					<Tooltip title="Copy code" arrow>
						<IconButton onClick={copyCode} size="large">
							<FileCopyIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Preview" arrow>
						<IconButton target="_blank" rel="noreferrer" href={src} size="large">
							<Visibility />
						</IconButton>
					</Tooltip>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="text" color="primary">
					Close
				</Button>
			</DialogActions>
		</>
	);
};

interface ChannelConfigModalProps {
	uid: string;
	onClose: () => void;
}

const ChannelConfigModal = ({ uid, onClose }: ChannelConfigModalProps) => {
	const { data: channelWidgets = [] } = useQuery<Widget[]>(`channel-${uid}-widgets`, async () => {
		const response = await GET(`/api/admin/channel/${uid}/widget`);
		return response.data.data as Widget[];
	});

	const [step, setStep] = useState<'style' | 'code'>('style');
	const [widgetId, setWidgetId] = useState('');

	return (
		<Dialog className="channelConfigModal" open onClose={onClose}>
			{step === 'style' ? (
				<StyleStep
					onClose={onClose}
					channelWidgets={channelWidgets}
					onConfirm={widgetId => {
						setWidgetId(widgetId);
						setStep('code');
					}}
				/>
			) : (
				<CodeStep channelUid={uid} channelWidgets={channelWidgets} widgetId={widgetId} onClose={onClose} />
			)}
		</Dialog>
	);
};

interface ChannelWidgetSectionProps {
	uid: string;
	isDisabled: boolean;
}

const ChannelWidgetSection = ({ uid, isDisabled }: ChannelWidgetSectionProps) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	return (
		<>
			<Box className={`section ${isDisabled ? 'section--disabled' : ''}`}>
				<div className="sectionHeader">
					<span className="sectionTitle">Channel widget code:</span>
					{!isDisabled && (
						<Tooltip title="Generate channel widget code" arrow>
							<IconButton onClick={() => setIsModalVisible(true)} size="large">
								<EditIcon className="editIcon" />
							</IconButton>
						</Tooltip>
					)}
				</div>
			</Box>
			{isModalVisible && <ChannelConfigModal uid={uid} onClose={() => setIsModalVisible(false)} />}
		</>
	);
};

export default ChannelWidgetSection;
