import { POST_FORM_DATA } from 'app/http/mediaApi/mediaClient';
import { AuthUserRole } from '../../../../../app/domain/User';
import { POST_AUTH } from '../../../../../app/http/authApi/authClient';
import { UserEditContactData } from './contact/UserEditContactUseCase';
import { UserEditRolesData } from './profile/UserEditProfileUseCase';

export interface UserEditApiData {
  username?: string;
  email?: string;
  phone?: string;
  fullName?: string;
  userRole?: AuthUserRole[];
  avatar?: File;
}

export interface UserEditReferrerData {
  referrerUid: string;
}

export const editUserApi = (uid: string, data: UserEditApiData) =>
  POST_FORM_DATA<void>(`/admin/user/${uid}`, data).then(r=>r.data.data);

export const editUserAuthApi = (uid: string, data: UserEditRolesData | UserEditContactData) =>
  POST_AUTH<void>(`/admin/user/${uid}`, data).then(r=>r.data);

export const editUserReferrerApi = (uid: string, referrerUid: string) =>
  POST_FORM_DATA<void>(`/admin/user/referrer/${uid}`, { referrerUid }).then(r=>r.data.data);
