import React from 'react';
import {Grid, Paper} from '@mui/material';
import VideoDateFilter from "./DateFilter";
import VideoTitleFilter from "./VideoTitleFilter";

const ReportFilter = () => {
    return (
      <Grid container spacing={1}  justifyContent="flex-start">
          <Grid item xs={12}>
              <Paper elevation={3}>
                  <VideoDateFilter />
              </Paper>
          </Grid>
          <Grid item xs={12}  justifyContent="flex-start">
              <Paper elevation={3}>
                  <VideoTitleFilter />
              </Paper>
          </Grid>
      </Grid>
    );
}

export default ReportFilter;
