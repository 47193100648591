import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { doFetchChannelPassesApi } from '../api/fetchChannelPassApi';
import PassesRow from './passesRow';
import { useLocalQuery } from 'app/utils/searchManager';
import '../_channelPasses.scss';

interface Props {
	channelUid: string;
}

const PassesTable = ({ channelUid }: Props) => {
	const [data] = useLocalQuery(fetchChannelPassesData, [channelUid]);

	async function fetchChannelPassesData() {
		const responseBody = await doFetchChannelPassesApi(channelUid);
		return responseBody.data.expanded.sort(
			(firstChannelPass, secondChannelPass) =>
				firstChannelPass.statusDetails.status.localeCompare(secondChannelPass.statusDetails.status) ||
				firstChannelPass.rentalPeriod - secondChannelPass.rentalPeriod
		);
	}

	return (
		<Table className="channel-passes-table" size="small" padding="checkbox" stickyHeader>
			<TableHead>
				<TableRow>
					<TableCell className="id-cell header" align="left">ID</TableCell>
					<TableCell className="channel-pass-name-cell header" align="left">Name</TableCell>
					<TableCell className="channel-pass-status-cell header" align="left">Status</TableCell>
					<TableCell className="price-cell header" align="left">Price</TableCell>
					<TableCell className="rental-period-cell header" align="left">Rental Period</TableCell>
					<TableCell className="purchases-cell header" align="left">Purchases</TableCell>
					<TableCell className="created-cell header" align="left">Created</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data && data.map(channelPass => (
					<PassesRow key={channelPass.id} channelPass={channelPass} />
				))}
			</TableBody>
		</Table>
	);
};

export default PassesTable;
