import { DELETE, POST } from 'app/http/mediaApi/mediaClient';
import { POST as adminPOST } from 'app/http/adminApi/adminClient';

export enum StreamStatus {
	PENDING = 'pending',
	FETCHING_URL = 'fetching-url',
	CREATING = 'creating',
	CREATE_FAILED = 'create-failed',
	CREATED = 'created',
	TESTING = 'testing',
	LIVE = 'live',
	DELETING = 'deleting',
	DELETE_FAILED = 'delete-failed',
	DELETED = 'deleted'
}

export const isLive = (status?: string) => {
	return StreamStatus.LIVE === status;
};

export const isTesting = (status?: string) => {
	return StreamStatus.TESTING === status;
};

export const isDeleted = (status?: string) => {
	return StreamStatus.DELETED === status;
};

export const isPending = (status?: string) => {
	return StreamStatus.PENDING === status;
};

export const resetStream = (videoId: string) => POST(`admin/stream/reset/${videoId}`);

export const startStream = (videoId: string) => POST(`stream/start/${videoId}`);

export const stopAndDeleteStream = (videoId: string) => DELETE(`stream/${videoId}`);

export const replaceRecastLiveWithMediaLive = (videoId: string) =>
	adminPOST(`/api/admin/stream/${videoId}/medialivebackup`);

export const startRecastLiveStream = (videoId: string) => adminPOST(`/api/admin/stream/${videoId}/start`);

export const stopRecastLiveStream = (videoId: string) => adminPOST(`/api/admin/stream/${videoId}/stop`);

export const resetRecastLiveStream = (videoId: string) => adminPOST(`/api/admin/stream/${videoId}/reset`);
