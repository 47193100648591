import { TableBody } from '@mui/material';
import React, { useEffect } from 'react';
import CurrencyRow from './CurrencyRow';
import { Currency } from '../../../../app/domain/Currency';
import { AppThunkDispatch, RootState } from '../../../../app/store/store';
import { connect } from 'react-redux';
import { fetchCurrenciesUseCase } from '../../../CurrencyUseCase';

interface StateProps {
  currencies: Currency[];
}

interface DispatchProps {
  fetchCurrencies: () => Promise<void>;
}

type Props = StateProps & DispatchProps;

const CurrencyTableBody = (props: Props) => {
  const { currencies, fetchCurrencies } = props;

  useEffect(() => {
    fetchCurrencies().then()
  }, [fetchCurrencies]);

  return (
    <TableBody>
      {
        currencies.map((current) => (
          <CurrencyRow key={current.currencyCode} current={current}/>
        ))
      }
    </TableBody>
  )
};

const mapStateToProps = (state: RootState) => ({
  currencies: state.currency.currencies.list.processedCurrencies,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  fetchCurrencies: async () => {
    dispatch(fetchCurrenciesUseCase());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyTableBody);
