import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { ChannelDetails } from 'app/domain/ChannelDetails';
import LinkIcon from '@mui/icons-material/Link';
import { getConfig } from 'config/appConfig';
import RoutePaths from 'app/navigation/RoutePaths';
import { useHistory } from 'react-router-dom';

const PartnerChannelRow = ({ channel }: { channel: ChannelDetails }) => {
    const history = useHistory();

    const navigateToFanApp = () => {
        window.open(`${getConfig().FAN_APP_BASE}/user/${channel.uid}`, '_blank')?.focus();
    };

    const navigateToChannelDetails = () => {
        history.push(`${RoutePaths.ChannelDetails}/${channel.uid}`);
    }

    return (
        <TableRow style={{ height: '3em' }}>
            <TableCell>{channel.uid}</TableCell>
            <TableCell>{channel.name}</TableCell>
            <TableCell>{channel.status}</TableCell>
            <TableCell>{channel.followerCount}</TableCell>
            <TableCell>{channel.created}</TableCell>
            <TableCell>
                <Tooltip title="Go to fan app" arrow>
					<IconButton className="button button-orange" onClick={navigateToFanApp} size="large">
                        <LinkIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Go to partner details" arrow>
					<IconButton className="button button-orange" onClick={navigateToChannelDetails} size="large">
                        <LinkIcon/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default PartnerChannelRow;


