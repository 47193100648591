import axios, { AxiosInstance } from 'axios';
import { getAppConfig } from 'config/appConfig';
import { addBearerToken } from 'app/http/authApi/token';

export type TimeGranularity = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export type Date = `${number}-${number}-${number}`;
export type DateRange = [Date, Date] | [string, string];
export const dateRangeEquals = (a: DateRange, b: DateRange) => a[0] === b[0] && a[1] === b[1];

type DateTime = `${number}-${number}-${number}T${number}:${number}:${number}.${number}`;
type DateTimeRange = [Date] | [Date, Date] | [DateTime, DateTime] | [string, string];

type MultiOperators = 'equals' | 'notEquals' | 'contains' | 'notContains' | 'startsWith' | 'endsWith';

type SingleNumberOperators = 'gt' | 'gte' | 'lt' | 'lte';

type NoParamOperators = 'set' | 'notSet' | 'measureFilter';

type DateRangeOperators = 'inDateRange' | 'notInDateRange';

type SingleDateOperators = 'beforeDate' | 'afterDate';

interface Cube {
    name: string;
    dimension: string;
    timeDimension: string;
    segment: string;
    measure: string;
}

type Cubed<C extends Cube> = {
    //[ R in keyof C as `${string & R}Name`] : `${C["name"]}.${string & C[R]}`;
    dimension: `${C['name']}.${C['dimension']}`;
    timeDimension: `${C['name']}.${C['timeDimension']}`;
    allDimension: `${C['name']}.${C['dimension'] | C['timeDimension']}`;
    dimensionOrMeasure: `${C['name']}.${C['dimension'] | C['timeDimension'] | C['measure']}`;
    measure: `${C['name']}.${C['measure']}`;
    segment: `${C['name']}.${C['segment']}`;
};

type _Filter<C extends Cube, D extends Cubed<C>> =
    | {
    member: `${D['dimensionOrMeasure']}`;
    operator: MultiOperators;
    /** Pass numbers or dates as strings */
    values: (string | null)[];
}
    | {
    member: `${D['dimensionOrMeasure']}`;
    operator: SingleNumberOperators;
    /** Pass numbers or dates as strings */
    values: [`${number}`];
}
    | {
    member: `${D['dimensionOrMeasure']}`;
    operator: NoParamOperators;
}
    | {
    member: `${D['timeDimension']}`;
    operator: DateRangeOperators;
    values: [Date, Date];
}
    | {
    member: `${D['timeDimension']}`;
    operator: SingleDateOperators;
    values: [Date];
};

interface _TimeDimension<C extends Cube, D extends Cubed<C>> {
    /** dimension to filter by */
    dimension: `${D['timeDimension']}`;
    /** start date and end date in the form '2015-01-01' */
    dateRange: DateTimeRange;
    /** size of groups to divide the results into */
    granularity?: TimeGranularity;
}
interface _Query<C extends Cube, D extends Cubed<C>> {
    /** The numbers to report back */
    measures?: `${D['measure']}`[];
    /** Dimensions to group the individual data by */
    dimensions?: (`${D['allDimension']}` | `${D['timeDimension']}.${TimeGranularity}`)[];
    /** Filters to apply to the data */
    filters?: _Filter<C, D>[];
    /** Combined Dimensions & Filters to select and group by date/time */
    timeDimensions?: _TimeDimension<C, D>[];
    /** A segment is a named filter, created in the Data Schema */
    segments?: C['segment'][];
    /** Maximum number of rows to return */
    limit?: number;
    /** Row to start at for paged responses */
    offset?: number;
    /** ordering to apply to the responses */
    order?: [`${D['dimensionOrMeasure']}`, 'asc' | 'desc'][];
    /** TZ Database name. For Recast purposes only UTC should be used right now */
    timezone?: 'UTC';
    /** Forces a data refresh. For Recast purposes we shouldn't do this for now */
    renewQuery?: false;
    /** For Recast purposes we shouldn't do this for now */
    ungrouped?: false;
}

export type CubeFilter<C extends Cube> = _Filter<C, Cubed<C>>;
export type CubeTimeDimension<C extends Cube> = _TimeDimension<C, Cubed<C>>;
export type CubeQuery<C extends Cube> = _Query<C, Cubed<C>>;
export type JoinCubeQuery<C extends Cube, D extends Cube> = _Query<C | D, Cubed<C> | Cubed<D>>;

interface CubeAnnotation {
    title: string;
    shortTitle: string;
    type: 'number' | 'count' | 'sum' | 'string' | 'date'; //TODO: this may not be complete
}

interface _Response<X extends Cube, C extends Cubed<X>> {
    query: _Query<X, C>;
    data: [{ [P in C['dimensionOrMeasure']]: string }];
    annotation: {
        measures: { [P in C['measure']]: CubeAnnotation };
        dimensions: { [P in C['dimension']]: CubeAnnotation };
        segments: { [P in C['segment']]: CubeAnnotation };
        timeDimensions: { [P in C['timeDimension']]: CubeAnnotation };
    };
}
export type CubeResponse<C extends Cube> = _Response<C, Cubed<C>>;
export type JoinCubeResponse<C extends Cube, D extends Cube> = _Response<D, Cubed<C> | Cubed<D>>;

export interface VideoCube extends Cube {
    name: 'WHVideo';
    dimension: 'pk' | 'video_uid' | 'user_uid';
    timeDimension: 'date';
    measure:
        | 'purchases'
        | 'users_paid_cst'
        | 'owner_earned_gbpmill'
        | 'user_recaster_earned_cstthou'
        | 'verified_recaster_earned_gbpmill';
    segment: never;
}

export interface VideoInfoCube extends Cube {
    name: 'WHVideoInfo';
    dimension: 'uid' | 'user' | 'name' | 'created';
    timeDimension: never;
    measure: 'count';
    segment: never;
}

export interface PublisherCube extends Cube {
    name: 'WHPublisherEarnings';
    dimension: 'pk' | 'uid';
    timeDimension: 'date';
    measure: 'share_earnings_gbpmill' | 'referral_gbpmill' | 'spent200_gbpmill' | 'royalties_gbpmill';
    segment: never;
}

export interface WHVideoStatsCube extends Cube {
    name: 'WHVideoStats';
    dimension: 'video_uid' | 'channel_uid' | 'location' | 'date' | 'source';
    timeDimension: 'date';
    measure:
        | 'purchase_count'
		| 'refund_count'
        | 'total_fans_paid'
		| 'total_fans_refund'
        | 'total_owner_earned'
		| 'total_owner_refunded'
        | 'total_fan_referrals_earned'
        | 'total_publisher_referrals_earned'
        | 'total_collaborators_earned';
    segment: never;
}

export interface WHTariffStatsCube extends Cube {
    name: 'WHTariffStats';
    dimension: 'tariff_id' | 'channel_uid' | 'location' | 'date';
    timeDimension: 'date';
    measure:
        | 'purchase_count'
        | 'total_fans_paid'
        | 'total_owner_earned'
        | 'total_fan_referrals_earned'
        | 'total_publisher_referrals_earned'
        | 'total_collaborators_earned';
    segment: never;
}

export interface WHTariffTypeCube extends Cube {
    name: 'WHTariffType';
    dimension: 'uid' | 'channel_uid' | 'location' | 'date' | 'type' | 'source';
    timeDimension: 'date';
    measure:
      | 'purchases'
      | 'users_paid_cst'
      | 'owner_earned_gbpmill'
      | 'user_recaster_earned_cstthou'
      | 'verified_recaster_earned_gbpmill';
    segment: never;
}

const clientCube: AxiosInstance = axios.create({
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

clientCube.interceptors.request.use(addBearerToken, Promise.reject);

const cubeApiUrl = () => `${getAppConfig().CUBEJS_BASE}/cubejs-api/v1/load`;

export const cubeQuery = <T extends Cube>(query: CubeQuery<T>) => {
    const data = { params: { query: JSON.stringify(query) } };
	return clientCube.get<CubeResponse<T>>(cubeApiUrl(), data).then(r => r.data);
};

export const publisherCubeQuery = (query: CubeQuery<PublisherCube>) => cubeQuery(query);
export const videoCubeQuery = <C extends Cube, D extends Cube>(query: JoinCubeQuery<C, D>) =>
    cubeQuery(query) satisfies Promise<JoinCubeResponse<C, D>>;
export const whVideoStatsCubeQuery = (query: CubeQuery<WHVideoStatsCube>) => cubeQuery(query);
export const whTariffStatsCubeQuery = (query: CubeQuery<WHTariffStatsCube>) => cubeQuery(query);
export const whTariffTypeStatsCubeQuery = (query: CubeQuery<WHTariffTypeCube>) => cubeQuery(query);
