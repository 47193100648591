import dayjs, { Dayjs } from 'dayjs';
import { BEGINNING_OF_TIME_DATE } from 'constants/Common';
import { Date } from 'app/http/cubeApi/cube';

export const asCubeDate = (dayjs: Dayjs) => dayjs.format('YYYY-MM-DD') as Date;
export const asCubeDateRange = (range: [ Dayjs, Dayjs ]): [Date, Date] => range.map(asCubeDate) as [Date, Date];

export interface DateFilter {
    name: string;
    getStartDate: () => Dayjs;
    getEndDate: () => Dayjs;
}

const yesterday = () => dayjs().subtract(1, 'day');

export const DateFilterConfig: DateFilter[] = [
    {name: "All time", getStartDate: () => dayjs(BEGINNING_OF_TIME_DATE), getEndDate: () => yesterday()},
    {name: "Last 7 days", getStartDate: () => dayjs().subtract(7, 'd'), getEndDate: () => yesterday()},
    {name: "Last 14 days", getStartDate: () => dayjs().subtract(14, 'd'), getEndDate: () => yesterday()},
    {name: "Last 28 days", getStartDate: () => dayjs().subtract(28, 'd'), getEndDate: () => yesterday()},
    {name: "Last 30 days", getStartDate: () => dayjs().subtract(30, 'd'), getEndDate: () => yesterday()},
    {name: "Yesterday", getStartDate: () => dayjs().subtract(1, 'd'), getEndDate: () => yesterday()},
    {name: "This week starts sunday", getStartDate: () => dayjs().day(0), getEndDate: () => yesterday()},
    {name: "This week to date starts sunday", getStartDate: () => dayjs().day(0), getEndDate: () => yesterday()},
    {name: "Last week starts sunday", getStartDate: () => dayjs().day(0).subtract(1, "w"), getEndDate: () => dayjs().day(0)},
    {name: "Last week starts monday", getStartDate: () => dayjs().day(1).subtract(1, "w"), getEndDate: () => dayjs().day(1)},
    {name: "This week to date starts monday", getStartDate: () => dayjs().day(1), getEndDate: () => yesterday()},
    {name: "This month", getStartDate: () => dayjs().date(1), getEndDate: () => yesterday()},
    {name: "this month to date", getStartDate: () => dayjs().date(1), getEndDate: () => yesterday()},
    {name: "Last month", getStartDate: () => dayjs().date(1).subtract(1, 'month'), getEndDate: () => dayjs().date(1)},
    {name: "This year", getStartDate: () => dayjs().subtract(1, 'y'), getEndDate: () => yesterday()},
    {name: "this year to date", getStartDate: () => dayjs().subtract(dayjs().dayOfYear() - 1, 'd'), getEndDate: () => yesterday()},
    {name: "Last year", getStartDate: () => dayjs().subtract(dayjs().dayOfYear() - 1, 'd').subtract(1, 'y'), getEndDate: () => dayjs().subtract(dayjs().dayOfYear() - 1, 'd')},
    {name: "This quarter", getStartDate: () => dayjs().subtract(1, 'quarter'), getEndDate: () => yesterday()},
    {name: "This quarter to date", getStartDate: () => dayjs().subtract(dayjs().dayOfYear() - 1, 'd').subtract(- (dayjs().quarter() -1) * 3, "month"), getEndDate: () => yesterday()},
]
