import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getBaseApiUrl } from 'app/http/request';
import { addBearerToken, resetBearerTokenOnUnauthorized } from 'app/http/authApi/token';

export interface APIResponse<T> {
	success: boolean;
	data: T;
}

export interface AxiosRequestConfigProps extends AxiosRequestConfig {
	['Content-Type']: string;
}

const client: AxiosInstance = axios.create({
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});
client.interceptors.response.use(res => res, resetBearerTokenOnUnauthorized);
client.interceptors.request.use(addBearerToken, Promise.reject);

const getMediaApiUrl = (suffix?: string): string => getBaseApiUrl('v2', suffix);

export const GET = <U>(url: string, config?: AxiosRequestConfig) => {
	return client.get<APIResponse<U>>(getMediaApiUrl(url), config);
};

export const POST = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return client.post<typeof data, Response>(getMediaApiUrl(url), data, config);
};

export const PUT = <U>(url: string, data?: unknown, config?: AxiosRequestConfigProps) => {
	type Response = AxiosResponse<APIResponse<U>>;
	return client.put<typeof data, Response>(getMediaApiUrl(url), data, config);
};

export const DELETE = <U>(url: string, config?: AxiosRequestConfig) => {
	return client.delete<APIResponse<U>>(getMediaApiUrl(url), config);
};

export const POST_FORM_DATA = <U>(url: string, data: FormDataObject, config?: AxiosRequestConfigProps) => {
	const newConfig = { ...(config || {}), 'Content-Type': 'multipart/form-data' };
	return POST<U>(url, jsonToFormData(data), newConfig);
};

export interface FormDataObject {
	[key: string]: any;
}

export const jsonToFormData = (jsonData: FormDataObject): FormData => {
	const formData = new FormData();
	for (const prop in jsonData) {
		// noinspection JSUnfilteredForInLoop
		if (jsonData[prop] !== undefined) {
			formData.append(prop, jsonData[prop]);
		}
	}
	return formData;
};
