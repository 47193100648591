import React from 'react';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from '../store/store';
import { connect } from 'react-redux';
import RoutePaths from './RoutePaths';

interface StateProps {
  isAuthenticate: boolean;
}

type Props = StateProps & RouteProps;

const AuthenticatedRoute: React.FC<Props> = (props) => {
  const { isAuthenticate } = props;

  if (isAuthenticate) {
    return <Route {...props} />;
  } else {
    const redirect = () => <Redirect to={{ pathname: RoutePaths.Login, state: { fromPath: props.location?.pathname } }} />;
    return <Route {...props} component={redirect} render={undefined} />;
  }
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticate: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {})(AuthenticatedRoute);
