import React from "react";
import { Field, Form, Formik } from "formik";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextField } from "formik-material-ui";
import { VideoEditBasicData } from "videos/details/VideoDetailsUseCase";

interface Props {
  isOpen: boolean;
  data: VideoEditBasicData;
  saveChanges: (basic: VideoEditBasicData) => void;
  closeModal: () => void;
}

const VideoEditBasicModal = (props: Props) => {
  const { isOpen, closeModal, data, saveChanges } = props;

  const validate = (values: VideoEditBasicData) => {
    const errors: Record<string, string> = {};
  
    values.name || (errors.name = 'Name is required');
    values.description || (errors.description = 'Description is required');
  
    return errors;
  };
  
  return (
    <Dialog
      className="dialog"
      open={isOpen}
      onClose={closeModal}>
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validate={validate}
        onSubmit={(values: VideoEditBasicData) => { saveChanges(values); closeModal(); }}>
        <Form>
          <DialogTitle>Edit video</DialogTitle>
          <DialogContent>
            <Field className="formField" label="New name" fullWidth name="name" variant="outlined" component={TextField} />
            <Field className="formField" label="New description" fullWidth name="description" variant="outlined" component={TextField} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default VideoEditBasicModal;
