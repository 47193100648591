import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { ChannelPassStaffWithPurchases, ChannelPassStatus } from 'app/domain/ChannelPass';
import { oneDayInSeconds } from 'app/store/common/Constants';

const passRentalPeriod = (channelPassRentalPeriod: number) => {
	const rentalDays = channelPassRentalPeriod / oneDayInSeconds;
	return `${rentalDays} ${rentalDays === 1 ? 'day' : 'days'}`;
};

const passStatus = (channelPassStatus: ChannelPassStatus) => {
	if (channelPassStatus === ChannelPassStatus.PURCHASEABLE) {
		return 'ACTIVE';
	} else if (channelPassStatus === ChannelPassStatus.UNPURCHASEABLE) {
		return 'INACTIVE';
	} else if (channelPassStatus === ChannelPassStatus.SUSPENDED) {
		return 'SUSPENDED';
	} else return 'UNKNOWN';
};

interface Props {
	channelPass: ChannelPassStaffWithPurchases;
}

const PassesRow = (props: Props) => {
	const { channelPass } = props;
	return (
		<TableRow key={channelPass.id}>
			<TableCell className="id-cell">{channelPass.id}</TableCell>
			<TableCell className="channel-pass-name-cell">{channelPass.description}</TableCell>
			<TableCell className="channel-pass-status-cell">{passStatus(channelPass.statusDetails.status)}</TableCell>
			<TableCell className="price-cell">{channelPass.priceDetails.price}</TableCell>
			<TableCell className="rental-period-cell">{passRentalPeriod(channelPass.rentalPeriod)}</TableCell>
			<TableCell className="purchases-cell">{channelPass.purchases}</TableCell>
			<TableCell className="created-cell">{channelPass.created}</TableCell>
		</TableRow>
	);
};

export default PassesRow;
