import { POST_FORM_DATA } from 'app/http/mediaApi/mediaClient';
import { Region } from 'constants/Regions';

export interface Country {
	name: string;
	code: string;
	enabled: boolean;
	checked: boolean;
	region: Region;
}

export const editVideoAvailabilityApi = (videoId: string, countryCodes: string[]) =>
	POST_FORM_DATA<void>(`admin/video/${videoId}`, {
		locations: JSON.stringify(countryCodes)
	}).then(r=>r.data.data);
