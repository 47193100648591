import { AppThunk, AppThunkDispatch } from '../../app/store/store';
import { makeRequest } from '../../app/http/request';
import { createSuccessResult } from '../../app/utils/result';
import { invitationsSetPageAction } from './store/InvitationsListActions';
import { PageResponse, toPage } from '../../app/http/PageResponse';
import { createEmptyPage, Page } from '../../app/domain/Page';
import { AllInvitationStatuses, Invitation, InvitationSortField, InvitationStatus } from '../../app/domain/Invitation';
import { SortDirection } from '../../app/domain/Sort';
import { GET_PARTNER } from '../../app/http/partnerApi/partnerClient';

export interface FetchInvitationData {
  page: number;
  statuses: InvitationStatus[];
  adminIdSearchText?: string;
  emailSearchText?: string;
  sort: SortDirection;
  order: InvitationSortField;
  startDate: string;
  endDate: string;
}

export const fetchInvitationsApi = async (dispatch: AppThunkDispatch, data: FetchInvitationData) => {
  const params = createApiParams(data);
  return makeRequest({
    dispatch,
    request: async () => {
      const result = await GET_PARTNER<PageResponse<Invitation>>(`/admin/invitations`, { params });
      return result.data.data;
    },
    responseSuccessHandler: (data: PageResponse<Invitation>) => createSuccessResult<Page<Invitation>, string>(toPage(data))
  });
};

export function fetchInvitationsPageUseCase(data: FetchInvitationData): AppThunk {
  return async (dispatch) => {
    const result = await fetchInvitationsApi(dispatch, data);
    if (result.value && !result.error) {
      dispatch(invitationsSetPageAction(result.value));
    } else  {
      dispatch(invitationsSetPageAction(createEmptyPage()));
    }
  };
}

const urlStatuses = (statuses: InvitationStatus[]): string[] => {
  if (statuses.length) {
    return statuses;
  } else {
    return AllInvitationStatuses;
  }
};

const createApiParams = (data: FetchInvitationData) => {
  const { page, statuses, adminIdSearchText, emailSearchText, order, sort, startDate, endDate } = data;

  return {
    page, adminIdSearchText, emailSearchText, order, sort, startDate, endDate,
    status: urlStatuses(statuses)
  };
};

