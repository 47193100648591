import { ApiResponseData } from 'app/http/apiResponse';

export const getApiResponseData = async (apiResponseData: any): Promise<ApiResponseData> => {
	return {
		...apiResponseData.data.data,
		...(apiResponseData.data.data.paging && {
			paging: {
				...apiResponseData.data.data.paging,
				self: apiResponseData.data.data.href
			}
		})
	};
};
