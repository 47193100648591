import React from 'react';
import { Dialog } from '@mui/material';
import CropperImage from 'users/actions/image/CropperImage/CropperImage';

interface CropperImageModalProps {
	isBannerImage: boolean;
	imageSource: string;
	defaultImage?: string;
	onSave: (file: File) => void;
	onClose: () => void;
}

const CropperImageModal: React.FC<CropperImageModalProps> = props => {
	const { isBannerImage, imageSource, defaultImage, onSave, onClose } = props;
	const profilePictureRatio = 1;
	const bannerPictureRatio = 2;

	return (
		<Dialog open maxWidth="lg" onClose={onClose}>
			<CropperImage
				isBannerImage={isBannerImage}
				ratio={isBannerImage ? bannerPictureRatio : profilePictureRatio}
				image={imageSource ?? defaultImage}
				onSave={onSave}
				onClose={onClose}
			/>
		</Dialog>
	);
};

export default CropperImageModal;
