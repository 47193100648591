import { AppThunk } from '../../../../app/store/store';
import { CurrencyCountries } from '../../../../app/domain/CurrencyCountries';

interface SetCurrencyCountries {
  type: 'CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES';
  currencyCountries: CurrencyCountries[];
}

export type CurrencyCountriesListStateTypes = SetCurrencyCountries;

export function currenciesSetCurrencyCountriesActionList(currencyCountries: CurrencyCountries[]): AppThunk {
  return (dispatch) => {
    dispatch( { type: 'CURRENCY_COUNTRIES_SET_CURRENCY_COUNTRIES', currencyCountries } );
  };
}
