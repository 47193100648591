import { Line } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { CoreChartOptions, defaults, PluginChartOptions, ScaleChartOptions } from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import { Grid } from '@mui/material';
import LoadingOverlay from 'app/components/loader/LoadingOverlay';

// Set some defaults in ChartJS
defaults.font.family = ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(',');
defaults.animation = false;

/** Given an _ordered_ array of objects which have a Dayjs object as their date
 * field, this function will cycle one day at a time from the lowest date in the
 * array to the highest, and inserting either the provided object for the day,
 * or a copy of the provided empty object with the relevant date appended, so
 * for example, the call:
 *
 * 	addEmptyDays(
 * 		[{ date: dayjs('2021-01-01'), r: 3 }, { date: dayjs('2021-01-03'), r: 6 }],
 *      { r: 0 }
 *  )
 *  Would return:
 *   [
 * 		{ date: dayjs('2021-01-01'), r: 3 },
 * 		{ date: dayjs('2021-01-02'), r: 0 },
 * 		{ date: dayjs('2021-01-03'), r: 6 }
 *   ]
 *
 * Taking the approach of requiring a sorted array and iterating through it is a
 * bit fiddlier, but significantly more performant on multi-year datasets. This
 * function kept in Chart since it is unlikely for it to be used elsewhere, and
 * so the chart object easier to separately package/reuse across projects. */
export const addEmptyDays = <T extends { date: Dayjs }>(dates: T[], empty: T = {} as T) => {
	const [min, max] = [dates.slice().shift(), dates.slice().pop()];
	if (!min || !max) return [];
	const sparse: { date: Dayjs }[] = [];

	for (let day = min.date, next = dates.shift(); day.isSame(max.date) || day.isBefore(max.date); day = day.add(1, 'day')) {
		if (next && day.isSame(next.date)) {
			sparse.push(next);
			next = dates.shift();
		} else {
			sparse.push({ ...empty, date: day });
		}
	}
	return sparse as T[];
};

export interface LineConfig<T extends { date: string }> {
	/** Function to get the line's values */
	transform: (d: T) => number;
	/** Label for the line */
	label: string;
	/** Color for the line */
	color: string;
}

interface Colors {
	/** Colors for grid and labels */
	grid: string;
	/** Background color for the component. Mostly things will work without this */
	background?: string;
}

interface Props<T extends { date: string }> {
	/** Array of objects with the date in string format */
	data?: T[];
	/** Information about how to display the lines */
	config: LineConfig<T>[];
	/** Should the lines be culmulative? */
	culmulative?: boolean;
	/** Colors for rendering the chart */
	colors: Colors;
	title?: string;
}

type ChartOptions = DeepPartial<CoreChartOptions<'line'> & PluginChartOptions<'line'> & ScaleChartOptions<'line'>>;

const getChartOptions = ({ colors, title }: { colors: Colors, title?: string }): ChartOptions => ({
	responsive: true,
	scales: {
		xAxes: { grid: { color: colors.grid }, ticks: { padding: 12 } },
		yAxes: { grid: { color: colors.grid }, ticks: { padding: 8 } }
	},
	datasets: { line: { tension: 0.4 } },
	plugins: {
		title: {
			display: !!title,
			text: title || ''
		},
		legend: {
			display: true,
			labels: {
				generateLabels: (chart: any) => {
					if (chart.legend?.legendItems && chart.legend?.legendItems?.length > 0) {
						return chart.legend?.legendItems;
					}

					return chart.data.datasets.map((data: any, datasetIndex: number) => ({
						datasetIndex: datasetIndex,
						text: ` ${data.label}  `,
						fillStyle: data.borderColor as string
					}));
				},
				pointStyle: 'circle',
				usePointStyle: true
			},
			onClick(_, legendItem, legend) {
				const index = legendItem.datasetIndex || 0;
				const { chart } = legend;
				if (chart.isDatasetVisible(index)) {
					legendItem.hidden = true;
					chart.hide(index);
				} else {
					legendItem.hidden = false;
					chart.show(index);
				}
			}
		},
		tooltip: {
			backgroundColor: colors.background,
			callbacks: {
				label: c => {
					const y = c.parsed.y;
					const yDisplay = Number.isInteger(y) ? y : y !== null ? y.toFixed(2) : '';
					return ` ${c.dataset.label} ${yDisplay}`;
				},
				labelColor: ({ dataset: { borderColor } }) => ({
					borderColor: borderColor as string,
					backgroundColor: borderColor as string,
					borderWidth: 2
				}),
				labelTextColor: () => colors.grid
			},
			padding: 12,
			usePointStyle: true,
			bodySpacing: 8,
			titleColor: colors.grid
		}
	}
});

/** Render a chartjs line chart from date-series data. This object replaces a mssing
 * dataset with a loading spinner, and takes parameters to define line colours and
 * how to get line data from the provided dataset. The dataset can be sparse and the
 * Chart component will attempt to add missing data */
const Chart = <T extends { date: string }>({ data, config, culmulative, colors, title }: Props<T>) => {
	if (!data) {
		return (
			<Grid className="stats-chart">
				<LoadingOverlay/>
			</Grid>
		);
	}

	const nonSparse = addEmptyDays(data.map(d => ({ ...d, date: dayjs(d.date) })));

	const graphData = {
		datasets: config.map(t => ({
			label: t.label,
			borderColor: t.color,
			data: nonSparse.map(
				function (this: [number], d) {
					return {
						x: d.date.format('YYYY-MM-DD'),
						y: culmulative ? (this[0] = this[0] + t.transform(d)) : t.transform(d)
					};
				},
				[0]
			)
		}))
	};

	return (
		<Grid className="stats-chart">
			<Line data={graphData} options={getChartOptions({ title, colors })} />
		</Grid>
	);
};

export default React.memo(Chart) as typeof Chart; //needs type because of generic
