import React, { useEffect, useState } from 'react';
import { Container, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import Title from '../../../../app/components/title/Title';
import AddIcon from '@mui/icons-material/Add';
import { doFetchChannelAdminsApi, setChannelAdminsPermissionsApi } from './api/fetchAdminApi';
import AddChannelAdminModal from './actions/add/AddChannelAdminModal';
import { addChannelAdminApi, removeChannelAdminApi } from './actions/add/ManageChannelAdminUseCase';
import RemoveModal from 'app/components/modal/remove/RemoveModal';
import DeleteIcon from '@mui/icons-material/Delete';
import './_admins.scss';
import EditAdminModal, { DisabledEditAdminModal } from './actions/EditAdminModal';
import EditIcon from '@mui/icons-material/Edit';
import { hasAccessToFansData, PublisherStaff, ResourcePermissionType } from '../../../../app/domain/Admin';
import { ChannelConfig } from '../config/ChannelConfigScreen';
import { doFetchChannelConfigApi } from './api/fetchPartnerApi';

interface Props {
	channelUid: string;
	channelName: string;
}

const AdminsScreen = (props: Props) => {
	const { channelUid, channelName } = props;
	const [showAddModal, setShowAddModal] = useState(false);
	const [removeUid, setRemoveUid] = useState<string>();
	const [adminToEdit, setAdminToEdit] = useState<PublisherStaff | null>(null);
	const [data, setData] = useState<PublisherStaff[]>([]);
	const [channelConfig, setChannelConfig] = useState<ChannelConfig | null>(null);

	const fetchChannelData = () => {
		doFetchChannelAdminsApi(channelUid).then(responseBody => setData(responseBody.items));
		doFetchChannelConfigApi(channelUid).then(config => setChannelConfig(config));
	};

	const refresh = () => fetchChannelData();

	const onRemove = () => {
		removeUid &&
			removeChannelAdminApi(removeUid, channelUid).then(() => {
				setRemoveUid(undefined);
				refresh();
			});
	};

	const onEditSave = (userUid: string, permissions: ResourcePermissionType[]) => {
		setAdminToEdit(null);

		setChannelAdminsPermissionsApi({ permissions, channelUid, userUid: userUid }).then(() => {
			refresh();
		});
	};

	useEffect(() => fetchChannelData(), [channelUid]);

	const isSharedDataEnabled = channelConfig?.dataProfile.isEnabled ?? false;

	const editModal = isSharedDataEnabled ? (
		<EditAdminModal
			channelName={channelName}
			admin={adminToEdit!}
			onCancel={() => setAdminToEdit(null)}
			onSave={onEditSave}
		/>
	) : (
		<DisabledEditAdminModal channelName={channelName} onCancel={() => setAdminToEdit(null)} />
	);

	return (
		<Container className="tagsScreen">
			<Grid container spacing={2}>
				<Grid item xs={11}>
					<Title>Admins</Title>
				</Grid>
				<Grid item xs={1}>
					<IconButton onClick={() => setShowAddModal(true)} size="large">
						<AddIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Table className="admin-table" size="small" padding="checkbox" stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell className="uid-cell header" align="left">
							Id
						</TableCell>
						<TableCell className="username-cell header" align="left">
							Username
						</TableCell>
						<TableCell className="email-cell header" align="left">
							Email
						</TableCell>
						{isSharedDataEnabled ? (
							<TableCell className="fans-data-cell header" align="left">
								Access to Fans Data
							</TableCell>
						) : (
							<></>
						)}
						<TableCell className="actions-cell header" align="left">
							Actions
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(admin => (
						<TableRow key={admin.userUid}>
							<TableCell className="uid-cell">{admin.userUid}</TableCell>
							<TableCell className="username-cell">{admin.username}</TableCell>
							<TableCell className="email-cell">{admin.email}</TableCell>
							{isSharedDataEnabled ? (
								<TableCell className="fans-data-cell">
									{hasAccessToFansData(admin) ? 'Yes' : 'No'}
								</TableCell>
							) : (
								<></>
							)}
							<TableCell className="actions-cell">
								<div className="actions">
									<Tooltip title="Edit Admin Access" arrow>
										<IconButton
											className="button"
											onClick={() => setAdminToEdit(admin)}
											size="large"
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title="Delete" arrow>
										<IconButton
											className="button button-red"
											onClick={() => setRemoveUid(admin.userUid)}
											size="large"
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<AddChannelAdminModal
				isOpen={showAddModal}
				saveAdmin={uid => addChannelAdminApi(uid, channelUid)}
				closeModal={() => setShowAddModal(false)}
				reload={refresh}
			/>
			{adminToEdit ? editModal : <></>}
			<RemoveModal
				isOpen={!!removeUid}
				closeModal={() => setRemoveUid(undefined)}
				onRemove={onRemove}
				text={'Do you want to remove this administrator?'}
			/>
		</Container>
	);
};

export default AdminsScreen;
