import SortableTree, { GetTreeItemChildrenFn, TreeIndex, TreePath } from '@nosferatu500/react-sortable-tree';
import { ReactSortableTreeProps } from '@nosferatu500/react-sortable-tree/react-sortable-tree';
import { ReactElement, ReactNode } from 'react';

// fork have typings in much worse state than original so making these to fix
// issues which they introduced
//
// it doesn't include fully accurate typings, but it's enough to make ts happy
// in admin app
//
// created using original types from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-sortable-tree/index.d.ts
//
// and we can't use those types directly too as there were some changes in fork,
// so it's better for now to have them explicitly here

export interface FullTree<T> {
	treeData: Array<TreeItem<T>>;
}

export interface NodeData<T> extends TreeNode<T>, TreePath, TreeIndex {}

export interface ExtendedNodeData<T> extends NodeData<T> {
	parentNode: TreeItem<T>;
	lowerSiblingCounts: number[];
	isSearchMatch: boolean;
	isSearchFocus: boolean;
}

export interface OnMovePreviousAndNextLocation<T> {
	nextParentNode: TreeItem<T> | null;
}

export type TreeItem<T> = T & {
	title?: ReactNode;
	subtitle?: ReactNode;
	expanded?: boolean;
	children?: Array<TreeItem<T>> | GetTreeItemChildrenFn;
};

export interface TreeNode<T> {
	node: TreeItem<T>;
}

interface TypedReactSortableTreeProps<T>
	extends Omit<ReactSortableTreeProps, 'getNodeKey' | 'generateNodeProps' | 'onMoveNode'> {
	getNodeKey?: (data: TreeNode<T> & TreeIndex) => string | number;
	generateNodeProps?: (data: ExtendedNodeData<T>) => { [index: string]: any };
	onMoveNode?: (data: NodeData<T> & FullTree<T> & OnMovePreviousAndNextLocation<T>) => void;
}

export const TypedSortableTree = SortableTree as <T>(props: TypedReactSortableTreeProps<T>) => ReactElement;
